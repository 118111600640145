import { usePost } from '../API';
import React, { Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAsyncEffect } from '../Hooks/AsyncEffect';
import { calcEnableKey, ConditionResultExplanation, enableKeyIsValid, HasEnableKey, paymentExtraChecks } from '@aidkitorg/types/lib/eval';
import { BooleanExpr, ClickQuery, Field, Filter, GiveCardMailing, Notification, NotificationGroup, Payment, Queue, SQLQuery } from '@aidkitorg/types/lib/survey';
import { traverseForKinds } from '@aidkitorg/types/lib/traverse';
import BasicCombobox from './Combobox';
import { DistroDashboardTable } from '../DistroDashboard/DistroDashboardTable';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Badge, Card, CardBody, CardHeader, Checkbox, Fieldset, Tabs, TextArea } from '@aidkitorg/component-library';
import { classNames, lowerCaseCompare, mapStrToList } from '../Util';
import { CustomDateTimePicker } from '../Components/DateTimePicker';
import { useLocalizedStrings, useLocalTimeZoneId, useProgramTimeZoneId } from '../Localization';
import { useDebouncedCallback } from '../Hooks/Debounce';
import InterfaceContext from '../Context';
import { expandGenericTemplates } from '@aidkitorg/types/lib/translation/templates';
import { DetailList } from '../Config/CommonComponents';

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

export type EligiblityCheckable = Required<
  Payment
  | Queue
  | GiveCardMailing
  | Notification
  | (Required<NotificationGroup>['followups'][number] & { kind: 'Notification Followup', targetPrefix: string })
>;

type EligibilityType<T extends EligiblityCheckable['kind']> = Extract<EligiblityCheckable, { kind: T }>

type Renderer<E extends EligiblityCheckable['kind']> = {
  render: (e: EligibilityType<E>) => React.ReactNode,
  filter: (e: EligibilityType<E>, query: string) => boolean,
  display: (e: EligibilityType<E>) => string
};

const Renderers: { [K in EligiblityCheckable['kind']]: Renderer<K> } = {
  'Payment': {
    display: (item) => item.name ? `[${item.kind}] ${item.name} (${item.targetField})` : `[${item.kind}] ${item.targetField}`,
    filter: (item, query) => lowerCaseCompare(query, [item.kind, item.name, item.targetField]),
    render: (item) => <div className="flex space-x-2 items-center">
      <Badge variant={enableKeyIsValid(item.enableKey, item) ? 'success' : 'error'}>{item.kind}</Badge>
      <span>{item.name ?? JSON.stringify(item)} ({item.targetField})</span>
      <span>${item.amount}</span>
    </div>
  },
  'GiveCard Mailing': {
    display: (item) => `[${item.kind}] ${item.targetField}`,
    filter: (item, query) => lowerCaseCompare(query, [item.kind, item.targetField]),
    render: (item) => <div className="flex space-x-2 items-center">
      <Badge variant={enableKeyIsValid(item.enableKey, item) ? 'success' : 'error'}>{item.kind}</Badge>
      <span>{item.targetField}</span>
    </div>
  },
  'InlineNotification': {
    display: (item) => item.name ? `[${item.name}] (${item.targetPrefix})` : `[${item.name}] ${item.targetPrefix}`,
    filter: (item, query) => lowerCaseCompare(query, [item.kind, item.name, item.targetPrefix]),
    render: (item) => <div className="flex space-x-2 items-center">
      <div className={classNames("rounded-full size-4", 'bg-green-400')}></div>
      <Badge variant="info">{item.kind}</Badge>
      <span>{item.name} ({item.targetPrefix})</span>
    </div>
  },
  'Notification': {
    display: (item) => item.name ? `[${item.name}] (${item.targetPrefix})` : `[${item.name}] ${item.targetPrefix}`,
    filter: (item, query) => lowerCaseCompare(query, [item.kind, item.name, item.targetPrefix]),
    render: (item) => <div className="flex space-x-2 items-center">
      <Badge variant={enableKeyIsValid(item.enableKey, item, { allowVersions: ['legacy']}) ? 'success' : 'error'}>{item.kind}</Badge>
      <span>{item.name} ({item.targetPrefix})</span>
      <span>({item.followups?.length ?? 0} flup)</span>
    </div>
  },
  'Notification Followup': {
    display: (item) => `[${item.kind}] ${item.suffix}`,
    filter: (item, query) => lowerCaseCompare(query, [item.kind, item.suffix]),
    render: (item) => <div className="flex space-x-2 items-center">
      <Badge variant={enableKeyIsValid(item.enableKey, item) ? 'success' : 'error'}>{item.kind}</Badge>
      <span>{item.suffix} ({item.targetPrefix})</span>
    </div>
  },
  'Queue': {
    display: (item) => `[${item.kind}] ${item.title?.en ?? item.assigneeField}`,
    filter: (item, query) => lowerCaseCompare(query, Object.values(item.title ?? {}).concat(Object.values(item.description ?? {})).concat(item.kind)),
    render: (item) => {
      const { lang } = useContext(InterfaceContext);
      return <div className="space-x-2">
        <Badge variant="info">{item.kind}</Badge> 
        <span>{item.title[lang]} ({item.assigneeField})</span>
      </div>
    }
  }
};

type Evaluation = {
  explain?: BooleanExpr,
  enabled: boolean,
  title?: React.ReactElement,
  extraChecks?: BooleanExpr
};

function extractExpr(query: ClickQuery | SQLQuery | BooleanExpr) {
  switch (query.kind) {
    case 'Click':
      return query.expr;
    case 'SQL':
      return;
    default:
      return query;
  }
}

function evaluate(selected: EligiblityCheckable): Evaluation {

  const enableKey = !(['InlineNotification', 'Queue'] as EligiblityCheckable['kind'][]).includes(selected.kind)
    ? calcEnableKey(selected as HasEnableKey)
    // Inline Notifications and Queues are ALWAYS enabled.
    : '';

  switch (selected.kind) {
    case 'GiveCard Mailing': {
      return {
        enabled: enableKeyIsValid(selected.enableKey, selected),
        explain: extractExpr(selected.condition),
        extraChecks: paymentExtraChecks(selected),
        title: <DetailList details={{
          'Target Field': selected.targetField,
          'Enable Key': enableKey,
          'Payment Method Name/Field': selected.type
        }} />
      };
    }
    case 'Payment': {
      return {
        enabled: enableKeyIsValid(selected.enableKey, selected),
        explain: extractExpr(selected.condition),
        extraChecks: paymentExtraChecks(selected),
        title: <DetailList details={{
          'Name': selected.name,
          'Target Field': selected.targetField,
          'Enable Key': enableKey,
          'Payment Method Name/Field': selected.type
        }} />
      };
    }
    case 'InlineNotification':
    case 'Notification': {
      return {
        enabled: selected.kind === 'Notification' ? enableKeyIsValid(selected.enableKey, selected, { allowVersions: ['legacy']}) : true,
        explain: extractExpr(selected.initial_notification.enabled_when),
        title: <DetailList details={{
          'Name': selected.name,
          'Target Field': selected.targetPrefix,
          'Enable Key': enableKey,
        }} />
      }
    }
    case 'Notification Followup': {
      return {
        enabled: enableKeyIsValid(selected.enableKey, selected),
        explain: extractExpr(selected.send_if),
        title: <DetailList details={{
          'After': `${selected.after.amount} ${selected.after.unit}`,
          'Target Field': selected.targetPrefix + selected.suffix,
          'Enable Key': enableKey,
        }} />
      }
    }
    case 'Queue': {
      return {
        enabled: true,
        explain: extractExpr(selected.condition),
        title: <DetailList details={{
          'Title': selected.title?.en,
          'Starting Section': selected.startSection?.toString(),
          'Assignee Field': `${selected.assigneeField}`,
          'Description': selected.description?.en,
          'Notify On Activity': selected.notifyAboutQueueActivity?.join(', '),
          'Allowed User Tags': selected.allowedUserTags?.join(', ')
        }} />
      }
    }
    default:
      let _: never = selected;
      return {
        enabled: false
      };
  }
}

export function ExplanationSummary({ content }: { content: string | ConditionResultExplanation }) {

  if (typeof content === 'string') {
    return <div>{content}</div>
  } else if (content && (content.passed || content.failed)) {
    return <ol>
      {content.passed?.map((p: any) =>
        <li key={p} className="flex space-x-1 items-center">
          <CheckIcon className="w-3 h-3 bg-green-500 rounded-sm text-white" />
          <span>{p}</span>
        </li>)}
      {content.failed?.map((p: any) =>
        <li key={p} className="flex space-x-1 items-center">
          <XMarkIcon className="w-3 h-3 bg-red-500 rounded-sm text-white" />
          <span>{p}</span>
        </li>)}
    </ol>
  } else {
    return <div>{content ? JSON.stringify(content) : ''}</div>
  }
}

export default function TestEligibility() {
  const explain = usePost('/program/admin/explain_expression');

  const [result, setResult] = useState<Record<string, { fields: Record<string, string>, explanation: ConditionResultExplanation }>>({});
  const [distroKindList, setDistroKindList] = useState<EligiblityCheckable[]>([]);
  const [selected, setSelected] = useState<EligiblityCheckable>();
  const [selectedDetails, setSelectedDetails] = useState<Evaluation>();
  const [filter, setFilter] = useState<Filter>({
    kind: 'Filter',
    filter: {
      kind: 'And',
      clauses: [{ kind: 'Not', clause: { kind: 'Never' } }]
    }
  });
  const [options, setOptions] = useState<{
    ShowFields: boolean,
    GroupByEligibility: boolean,
    OnlyShowFailed: boolean,
    Filter: boolean,
    UsePointInTimeData: boolean,
  }>({
    ShowFields: true,
    GroupByEligibility: false,
    OnlyShowFailed: true,
    Filter: false,
    UsePointInTimeData: false,
  });
  const timezoneId = useProgramTimeZoneId() || useLocalTimeZoneId();
  const debouncedSetTimeBound = useDebouncedCallback((timeBoundMs: number) => setDataTimeBoundMs(timeBoundMs), 350);
  const [dataTimeBoundMs, setDataTimeBoundMs] = useState(Date.now());
  const [uids, setUids] = useState<string[]>([]);
  const [uidTextAreaValue, setUidTextAreaValue] = useState('');

  const records = useMemo(() => {
    let base = Object.entries(result ?? {});
    if (options.OnlyShowFailed) {
      base = base.filter(([, res]) => res.explanation?.failed?.length)
    }

    if (!options.GroupByEligibility) {
      return base.map(([uid, res]) => ({
        uid,
        eligibility: {
          failed: res.explanation.failed,
          passed: res.explanation.passed,
        },
        ...(options.ShowFields ? res.fields : {}),
        ...(options.ShowFields ? res.explanation.relatedTargetFields : {}),
      }))
    } else {
      const groups = base.reduce((prev, [, res]) => {
        for (const test of res.explanation.failed ?? []) {
          if (!prev[test]) {
            prev[test] = { fails: [], passes: [] };
          }
          prev[test].fails.push(res.fields);
        }
        for (const test of res.explanation.passed ?? []) {
          if (!prev[test]) {
            prev[test] = { fails: [], passes: [] };
          }
          prev[test].passes.push(res.fields);
        }
        return prev;
      }, {} as Record<string, { passes: any[], fails: any[] }>);

      return Object.entries(groups).map(([g, { passes, fails }]) => ({
        eligibility: g,
        pass: passes?.length,
        fail: fails?.length
      }));
    }
  }, [result, options]);

  const loadSurvey = usePost('/survey/load_survey');

  const filterEditorRef = useRef<React.ComponentRef<typeof Distro>>(null);
  const distroRef = useRef<React.ComponentRef<typeof Distro>>(null);

  useEffect(() => {
    if (!selected) {
      return;
    }
    setSelectedDetails(evaluate(selected));
  }, [selected]);

  useAsyncEffect(async () => {
    if (!selectedDetails?.explain) {
      return;
    }
    distroRef.current?.initialize(selectedDetails?.explain);
    setResult(await explain({
      filter: uids.length ? uids : filter?.filter,
      expression: {
        kind: 'And',
        clauses: selectedDetails.extraChecks ? [selectedDetails.explain, selectedDetails.extraChecks] : [selectedDetails.explain]
      },
      extraColumns: [
        { kind: 'Field', field: 'legal_name' }
      ],
      ...(options.UsePointInTimeData
        ? { pointInTimeMs: dataTimeBoundMs }
        : {}
      )
    }));
  }, [selectedDetails, filter, uids, dataTimeBoundMs]);

  useAsyncEffect(async () => {
    let { config } = await loadSurvey({ name: 'entireprogram', purpose: "eligibility checker" })

    const expanded = expandGenericTemplates(config);

    const baseTypes = traverseForKinds<Required<EligiblityCheckable>, Field>(expanded, ['Payment', 'GiveCard Mailing', 'Notification', 'InlineNotification', 'Queue'], ['Field']);
    const derivedTypes: EligiblityCheckable[] = baseTypes.flatMap(t => {
      switch (t.kind) {
        case 'Notification':
          return [t, ...(t.followups ?? []).map(f => ({ kind: 'Notification Followup', targetPrefix: t.targetPrefix, ...f }))] as EligiblityCheckable[]
        default:
          return [t];
      }
    });

    setDistroKindList(derivedTypes);
  }, []);

  return <div className="space-y-4">
    <div className="flex">
      <div className="space-y-3 w-full min-w-96">
        <BasicCombobox<EligiblityCheckable>
          onSelectionChanged={setSelected}
          initialSelection={selected}
          filterItem={(item, query) => Renderers[item.kind].filter(item as any, query)}
          displayValue={item => Renderers[item.kind].display(item as any)}
          renderOption={item => Renderers[item.kind].render(item as any)}
          items={distroKindList}
        >
          {selected ?
            <Card className="w-full h-fit space-y-3">
              <CardHeader
                title={selectedDetails?.title}
                badgeText={`${selectedDetails?.enabled ? 'Enabled' : 'Disabled'} ${selected?.kind}`}
                badgeVariant={selectedDetails?.enabled ? 'success' : 'error'} />
              <CardBody>
                <div hidden={!selectedDetails?.explain}>
                  <Suspense>
                    <Distro
                      ref={distroRef}
                      value={selectedDetails?.explain}
                      types="src/survey.ts"
                      name="BooleanExpr"
                    />
                  </Suspense>
                </div>
              </CardBody>
            </Card>
            : <span>Select a Payment or Notification...</span>
          }
        </BasicCombobox>
        <br />
      </div>
    </div>

    <div className='flex'>
      <Fieldset legend="Options" className='font-medium'>
        {Object.entries(options).map(([label, checked]) =>
          <Checkbox
            label={label}
            checked={checked}
            onChange={c => setOptions(prev => ({ ...prev, [label]: c }))}
          />
        )}
      </Fieldset>

      {options.UsePointInTimeData && (
        <div className='mx-4'>
          <div className='font-medium'>{'Test will use applicant data up to this date + time'}</div>
          <CustomDateTimePicker
            id='data-time-bound-picker'
            name='Select data date and time upper bound'
            timeZoneId={timezoneId}
            initialValue={dataTimeBoundMs}
            onChange={debouncedSetTimeBound}
          />
        </div>
      )}
    </div>

    <div hidden={!options.Filter}>
      <Tabs
        onChange={(key) => {
          // Clear out other option on tab switch
          if (key === 'ClickQuery Filter') {
            setUidTextAreaValue('');
            setUids([]);
          } else if (key === 'UID List') {
            setFilter({
              kind: 'Filter',
              filter: {
                kind: 'And',
                clauses: [{ kind: 'Not', clause: { kind: 'Never' } }]
              }
            })
          }
        }}
        tabs={[
          {
            content: <Suspense>
              <Distro
                ref={filterEditorRef}
                onChange={(v) => setFilter(v)}
                value={filter}
                types="src/survey.ts"
                name="Filter"
              />
            </Suspense>,
            key: 'ClickQuery Filter',
            name: 'ClickQuery Filter'
          },
          {
            content: <div className={'mx-4'}>
              <TextArea
                showLabel={true}
                value={uidTextAreaValue}
                className='font-medium'
                label='List of UIDs to check (empty will check all)'
                onChange={(e) => {
                  setUids(mapStrToList(e.target.value || ''))
                  setUidTextAreaValue(e.target.value);
                }}
              />
            </div>,
            count: {
              value: uids.length
            },
            key: 'UID List',
            name: 'UID List'
          }
        ]}
      />
    </div>

    <DistroDashboardTable
      records={records}
      component={{
        kind: 'Table'
      }}
      cellRenderer={(content: string | ConditionResultExplanation) => <ExplanationSummary content={content} />}
    />
  </div>
}
