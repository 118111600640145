"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address = void 0;
console.time("Load Derived: Address");
var runtime_1 = require("@pgtyped/runtime");
var neverthrow_1 = require("neverthrow");
var db_1 = require("../../util/db");
var secrets_1 = require("../../util/secrets");
exports.Address = {
    getSlugs: function (params) {
        var e_1, _a;
        var slugs = [
            '_county',
            '_tract',
            '_lat',
            '_lng',
            '_building',
            '_subpremise',
            '_street',
            '_city',
            '_state',
            '_zip',
            '_dpb',
            '_formatted'
        ];
        var dependencies = [];
        var metadata = JSON.parse(params.infoDefs[params.targetField]['metadata'] || '{}');
        try {
            for (var _b = __values((metadata['lookups'] || [])), _c = _b.next(); !_c.done; _c = _b.next()) {
                var lookup = _c.value;
                slugs.push("_" + lookup);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return (0, neverthrow_1.ok)({ slugs: slugs, dependencies: dependencies });
    },
    compute: function (params) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
        return __awaiter(this, void 0, void 0, function () {
            var countyKey, parentKey, applicantAddress, countyValue, smartyAddressLookup, lookupResults, readOnlyConn, googleMapsFallbackConfigQuery, googleMapsFallbackConfig, googleMapsLookup, lookupResults;
            var _2, _3, _4;
            return __generator(this, function (_5) {
                switch (_5.label) {
                    case 0:
                        countyKey = params.targetField;
                        // Skip all other slugs
                        if (countyKey.indexOf('_county') === -1)
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        parentKey = countyKey.replace('_county', '');
                        applicantAddress = params.newInfoKeys[parentKey];
                        countyValue = params.newInfoKeys[parentKey + '_county'];
                        if ((typeof countyValue === 'string' && countyValue.trim() !== '') || !applicantAddress) {
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        console.debug("Roboscreener:Address: Calculating missing address geocode for address=".concat(applicantAddress, " targetField=").concat(parentKey, " program=").concat(params.deploymentKey));
                        return [4 /*yield*/, getSmartyGeocode({ address: applicantAddress })];
                    case 1:
                        smartyAddressLookup = _5.sent();
                        if (!(smartyAddressLookup.lat && smartyAddressLookup.lng)) return [3 /*break*/, 3];
                        console.debug("Roboscreener:Address: Successfully used smarty to geocode address=".concat(applicantAddress, " targetField=").concat(parentKey, " program=").concat(params.deploymentKey));
                        return [4 /*yield*/, _computeLookups({
                                infoDefs: params.infoDefs,
                                parentKey: parentKey,
                                conn: params.conn,
                                lat: smartyAddressLookup.lat,
                                lng: smartyAddressLookup.lng
                            })];
                    case 2:
                        lookupResults = _5.sent();
                        return [2 /*return*/, (0, neverthrow_1.ok)(__assign(__assign({}, lookupResults), (_2 = {}, _2[parentKey + '_street'] = (_b = (_a = smartyAddressLookup.smartyResponse) === null || _a === void 0 ? void 0 : _a.components.street) !== null && _b !== void 0 ? _b : '', _2[parentKey + '_city'] = (_d = (_c = smartyAddressLookup.smartyResponse) === null || _c === void 0 ? void 0 : _c.components.city_name) !== null && _d !== void 0 ? _d : '', _2[parentKey + '_state'] = (_f = (_e = smartyAddressLookup.smartyResponse) === null || _e === void 0 ? void 0 : _e.components.state_abbreviation) !== null && _f !== void 0 ? _f : '', _2[parentKey + '_zip'] = (_h = (_g = smartyAddressLookup.smartyResponse) === null || _g === void 0 ? void 0 : _g.components.zipcode) !== null && _h !== void 0 ? _h : '', _2[parentKey + '_dpb'] = (_k = (_j = smartyAddressLookup.smartyResponse) === null || _j === void 0 ? void 0 : _j.delivery_point_barcode) !== null && _k !== void 0 ? _k : '', _2[parentKey + '_county'] = smartyAddressLookup.county, _2[parentKey + '_tract'] = smartyAddressLookup.census_tract, _2[parentKey + '_subpremise'] = (_l = smartyAddressLookup.subpremise) !== null && _l !== void 0 ? _l : '', _2[parentKey + '_building'] = (_m = smartyAddressLookup.building) !== null && _m !== void 0 ? _m : '', _2[parentKey + '_lat'] = smartyAddressLookup.lat.toString(), _2[parentKey + '_lng'] = smartyAddressLookup.lng.toString(), _2)))];
                    case 3: return [4 /*yield*/, (0, db_1.getReadOnlyDbConn)(params.deploymentKey)];
                    case 4:
                        readOnlyConn = _5.sent();
                        if (readOnlyConn.isErr()) {
                            console.debug("Roboscreener:Address: Failed to get readonly db conn for address geocoding program=".concat(params.deploymentKey));
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        googleMapsFallbackConfigQuery = (0, runtime_1.sql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["SELECT value FROM configuration WHERE key = 'enable_geocode_google_maps_fallback' LIMIT 1"], ["SELECT value FROM configuration WHERE key = 'enable_geocode_google_maps_fallback' LIMIT 1"])));
                        return [4 /*yield*/, googleMapsFallbackConfigQuery.run({}, readOnlyConn.value)];
                    case 5:
                        googleMapsFallbackConfig = _5.sent();
                        if (!(googleMapsFallbackConfig.length === 1 && googleMapsFallbackConfig[0].value === 'true')) return [3 /*break*/, 8];
                        console.debug("Roboscreener:Address: Using google maps as fallback to geocode address=".concat(applicantAddress, " targetField=").concat(parentKey, " program=").concat(params.deploymentKey));
                        return [4 /*yield*/, getGoogleGeocode({ address: applicantAddress })];
                    case 6:
                        googleMapsLookup = _5.sent();
                        if (!(googleMapsLookup && googleMapsLookup.lat && googleMapsLookup.lng)) return [3 /*break*/, 8];
                        console.debug("Roboscreener:Address: Successfully used google maps to geocode address=".concat(applicantAddress, " targetField=").concat(parentKey, " program=").concat(params.deploymentKey));
                        return [4 /*yield*/, _computeLookups({
                                infoDefs: params.infoDefs,
                                parentKey: parentKey,
                                conn: params.conn,
                                lat: googleMapsLookup.lat,
                                lng: googleMapsLookup.lng
                            })];
                    case 7:
                        lookupResults = _5.sent();
                        return [2 /*return*/, (0, neverthrow_1.ok)(__assign(__assign({}, lookupResults), (_3 = {}, _3[parentKey + '_street'] = "".concat((_o = googleMapsLookup.googleMapsResponse) === null || _o === void 0 ? void 0 : _o.street_number, " ").concat((_p = googleMapsLookup.googleMapsResponse) === null || _p === void 0 ? void 0 : _p.street_name) + (((_q = googleMapsLookup.googleMapsResponse) === null || _q === void 0 ? void 0 : _q.subpremise) ? " ".concat((_r = googleMapsLookup.googleMapsResponse) === null || _r === void 0 ? void 0 : _r.subpremise) : ''), _3[parentKey + '_city'] = (_t = (_s = googleMapsLookup.googleMapsResponse) === null || _s === void 0 ? void 0 : _s.city) !== null && _t !== void 0 ? _t : '', _3[parentKey + '_state'] = (_v = (_u = googleMapsLookup.googleMapsResponse) === null || _u === void 0 ? void 0 : _u.state) !== null && _v !== void 0 ? _v : '', _3[parentKey + '_county'] = (_x = (_w = googleMapsLookup.googleMapsResponse) === null || _w === void 0 ? void 0 : _w.county) !== null && _x !== void 0 ? _x : '', _3[parentKey + '_subpremise'] = (_z = (_y = googleMapsLookup.googleMapsResponse) === null || _y === void 0 ? void 0 : _y.subpremise) !== null && _z !== void 0 ? _z : '', _3[parentKey + '_zip'] = (_1 = (_0 = googleMapsLookup.googleMapsResponse) === null || _0 === void 0 ? void 0 : _0.zipcode) !== null && _1 !== void 0 ? _1 : '', _3[parentKey + '_lat'] = googleMapsLookup.lat.toString(), _3[parentKey + '_lng'] = googleMapsLookup.lng.toString(), _3)))];
                    case 8: 
                    // If we can't find it from smarty or google maps (if enabled), save as Unknown so we don't compute again
                    // in the future
                    return [2 /*return*/, (0, neverthrow_1.ok)((_4 = {}, _4[parentKey + '_county'] = 'Unknown', _4))];
                }
            });
        });
    }
};
// Follows similar logic as packages/aidkit/lib/geo/address
var getGoogleGeocode = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var address, googleKey, e_2, encodedAddress, url, response, data, result, streetNumber, subpremise, streetName, city, state, county, zipcode, countyName;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                address = params.address;
                _g.label = 1;
            case 1:
                _g.trys.push([1, 3, , 4]);
                return [4 /*yield*/, (0, secrets_1.getGlobalConfig)('GOOGLE_KEY')];
            case 2:
                googleKey = _g.sent();
                return [3 /*break*/, 4];
            case 3:
                e_2 = _g.sent();
                console.warn("Roboscreener:Address: Cannot optimistically compute Address derived fields. Failed to fetch Google Maps API key", e_2);
                return [2 /*return*/, null];
            case 4:
                encodedAddress = encodeURIComponent(address);
                url = "https://maps.googleapis.com/maps/api/geocode/json?address=".concat(encodedAddress, "&key=").concat(googleKey);
                return [4 /*yield*/, fetch(url, {
                        method: "GET",
                        headers: { "Content-Type": "application/json" },
                    })];
            case 5:
                response = _g.sent();
                return [4 /*yield*/, response.json()];
            case 6:
                data = _g.sent();
                if (data.results && data.results.length > 0) {
                    result = data.results[0];
                    streetNumber = result.address_components.find(function (component) { return component.types.includes('street_number'); });
                    subpremise = result.address_components.find(function (component) { return component.types.includes('subpremise'); });
                    streetName = result.address_components.find(function (component) { return component.types.includes('route'); });
                    city = result.address_components.find(function (component) { return component.types.includes('locality'); });
                    state = result.address_components.find(function (component) { return component.types.includes('administrative_area_level_1'); });
                    county = result.address_components.find(function (component) { return component.types.includes('administrative_area_level_2'); });
                    zipcode = result.address_components.find(function (component) { return component.types.includes('postal_code'); });
                    if (!result.address_components ||
                        result.partial_match === true ||
                        result.geometry.location_type !== 'ROOFTOP' ||
                        !streetNumber || !streetName || !city || !state || !county || !zipcode) {
                        return [2 /*return*/, null];
                    }
                    countyName = county.long_name.replace(/ County$/, '');
                    return [2 /*return*/, __assign(__assign({}, result.geometry.location), { 'lat': result.geometry.location.lat, 'lng': result.geometry.location.lng, 'county': countyName, 'found_street_number': !!streetNumber, 'census_tract': '', googleMapsResponse: {
                                lat: result.geometry.location.lat,
                                lng: result.geometry.location.lng,
                                formatted_address: result.formatted_address,
                                county: countyName,
                                subpremise: (_a = subpremise === null || subpremise === void 0 ? void 0 : subpremise.short_name) !== null && _a !== void 0 ? _a : '',
                                street_number: (_b = streetNumber.short_name) !== null && _b !== void 0 ? _b : '',
                                street_name: (_c = streetName === null || streetName === void 0 ? void 0 : streetName.short_name) !== null && _c !== void 0 ? _c : '',
                                city: (_d = city === null || city === void 0 ? void 0 : city.long_name) !== null && _d !== void 0 ? _d : '',
                                state: (_e = state === null || state === void 0 ? void 0 : state.short_name) !== null && _e !== void 0 ? _e : '',
                                zipcode: (_f = zipcode === null || zipcode === void 0 ? void 0 : zipcode.short_name) !== null && _f !== void 0 ? _f : '',
                            } })];
                }
                return [2 /*return*/, null];
        }
    });
}); };
// Follows similar logic as packages/aidkit/lib/geo/address
var getSmartyGeocode = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var baseUrl, authId, authToken, encodedAddress, url, data, response, lat, lng, foundLookups;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                baseUrl = "https://us-street.api.smartystreets.com/street-address";
                return [4 /*yield*/, (0, secrets_1.getGlobalConfig)("SMARTY_AUTH_ID")];
            case 1:
                authId = _a.sent();
                return [4 /*yield*/, (0, secrets_1.getGlobalConfig)("SMARTY_AUTH_TOKEN")];
            case 2:
                authToken = _a.sent();
                encodedAddress = encodeURIComponent(params.address);
                url = "".concat(baseUrl, "?license=us-rooftop-geocoding-enterprise-cloud&auth-id=").concat(authId, "&auth-token=").concat(authToken, "&street=").concat(encodedAddress, "&candidates=10");
                return [4 /*yield*/, fetch(url, { method: "GET" })];
            case 3:
                data = _a.sent();
                return [4 /*yield*/, data.json()];
            case 4:
                response = (_a.sent())[0];
                lat = response === null || response === void 0 ? void 0 : response.metadata.latitude;
                lng = response === null || response === void 0 ? void 0 : response.metadata.longitude;
                foundLookups = {};
                return [2 /*return*/, {
                        lat: response === null || response === void 0 ? void 0 : response.metadata.latitude,
                        lng: response === null || response === void 0 ? void 0 : response.metadata.longitude,
                        county: response === null || response === void 0 ? void 0 : response.metadata.county_name,
                        found_street_number: (response === null || response === void 0 ? void 0 : response.analysis.dpv_match_code) !== 'N',
                        census_tract: '',
                        lookups: foundLookups,
                        smartyResponse: {
                            analysis: {
                                dpv_match_code: response === null || response === void 0 ? void 0 : response.analysis.dpv_match_code,
                                dpv_footnotes: response === null || response === void 0 ? void 0 : response.analysis.dpv_footnotes,
                            },
                            components: {
                                street: [
                                    response === null || response === void 0 ? void 0 : response.components.primary_number,
                                    response === null || response === void 0 ? void 0 : response.components.street_predirection,
                                    response === null || response === void 0 ? void 0 : response.components.street_name,
                                    response === null || response === void 0 ? void 0 : response.components.street_suffix
                                ].filter(function (x) { return x; }).join(' '),
                                secondary: [
                                    response === null || response === void 0 ? void 0 : response.components.secondary_designator,
                                    response === null || response === void 0 ? void 0 : response.components.secondary_number,
                                ].filter(function (x) { return x; }).join(' '),
                                city_name: response === null || response === void 0 ? void 0 : response.components.city_name,
                                zipcode: response === null || response === void 0 ? void 0 : response.components.zipcode,
                                state_abbreviation: response === null || response === void 0 ? void 0 : response.components.state_abbreviation
                            },
                            metadata: {
                                latitude: response === null || response === void 0 ? void 0 : response.metadata.latitude,
                                longitude: response === null || response === void 0 ? void 0 : response.metadata.longitude,
                            },
                            delivery_line_1: response === null || response === void 0 ? void 0 : response.delivery_line_1,
                            last_line: response === null || response === void 0 ? void 0 : response.last_line,
                            delivery_point_barcode: response === null || response === void 0 ? void 0 : response.delivery_point_barcode
                        }
                    }];
        }
    });
}); };
// Returns an object with lookup results if we're able to 
// process the lookups successfully with the new geocoded values.
// This follows the updates Object pattern for keys that should 
// be changed as a result of this roboscreener calculation.
var _computeLookups = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var infoDefs, parentKey, conn, lat, lng, lookupUpdates, getLookupValue, metadata, _a, _b, lookup, lookupResult, e_3_1, _c, _d, lookup;
    var e_3, _e, e_4, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                infoDefs = params.infoDefs, parentKey = params.parentKey, conn = params.conn, lat = params.lat, lng = params.lng;
                lookupUpdates = {};
                getLookupValue = (0, runtime_1.sql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      SELECT value \n          FROM geolookup \n          WHERE \n            (\n              ST_CONTAINS(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 4326)) OR\n              (ST_Dimension(shape) = 0 AND ST_DWithin(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 4326), 0))\n            )\n          and kind = $lookup\n          LIMIT 1;\n  "], ["\n      SELECT value \n          FROM geolookup \n          WHERE \n            (\n              ST_CONTAINS(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 4326)) OR\n              (ST_Dimension(shape) = 0 AND ST_DWithin(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 4326), 0))\n            )\n          and kind = $lookup\n          LIMIT 1;\n  "])));
                metadata = JSON.parse(infoDefs[parentKey].metadata || '{}');
                if (!(conn && lat && lng)) return [3 /*break*/, 9];
                _g.label = 1;
            case 1:
                _g.trys.push([1, 6, 7, 8]);
                _a = __values((metadata['lookups'] || [])), _b = _a.next();
                _g.label = 2;
            case 2:
                if (!!_b.done) return [3 /*break*/, 5];
                lookup = _b.value;
                console.log("Checking lookup " + lookup + " for field " + parentKey);
                return [4 /*yield*/, getLookupValue.run({ lng: lng, lat: lat, lookup: lookup }, conn)];
            case 3:
                lookupResult = _g.sent();
                if (lookupResult.length) {
                    lookupUpdates[lookup] = lookupResult[0].value;
                    lookupUpdates[parentKey + '_' + lookup] = lookupResult[0].value;
                }
                else {
                    lookupUpdates[lookup] = '';
                    lookupUpdates[parentKey + '_' + lookup] = '';
                }
                _g.label = 4;
            case 4:
                _b = _a.next();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                e_3_1 = _g.sent();
                e_3 = { error: e_3_1 };
                return [3 /*break*/, 8];
            case 7:
                try {
                    if (_b && !_b.done && (_e = _a.return)) _e.call(_a);
                }
                finally { if (e_3) throw e_3.error; }
                return [7 /*endfinally*/];
            case 8: return [3 /*break*/, 10];
            case 9:
                try {
                    for (_c = __values((metadata['lookups'] || [])), _d = _c.next(); !_d.done; _d = _c.next()) {
                        lookup = _d.value;
                        lookupUpdates[lookup] = '';
                        lookupUpdates[parentKey + '_' + lookup] = '';
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_f = _c.return)) _f.call(_c);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                _g.label = 10;
            case 10: return [2 /*return*/, lookupUpdates];
        }
    });
}); };
console.timeEnd("Load Derived: Address");
var templateObject_1, templateObject_2;
