import { useEffect, useState } from "react";
import { usePost } from "../API";
import { useLocalizedStrings } from "../Localization";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Badge, Dropdown } from "react-bootstrap";
import { SpacedSpinner } from "../Util";

type ReassignProps = { uid: string, applicant: any, applicantInfo: any }

function ReassignDropdown(props: ReassignProps) {
  const L = useLocalizedStrings();
  const [hasRequested, setHasRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [screeners, setScreeners] = useState<Awaited<ReturnType<typeof requestScreeners>>>([]);
  const requestScreeners = usePost("/users/screeners");
  const reassignApplicant = usePost("/applicant/reassign");

  async function doRequestScreeners() {
    setLoading(true);
    setHasRequested(true);
    const response = await requestScreeners({});
    if (!(response as any).error) {
      setScreeners(response);
    }
    setLoading(false);
  }

  useEffect(
    () => {
      if (props.applicant.current_user) {
        doRequestScreeners();
      }
    },
    [props.applicant.current_user]
  )

  async function doReassignApplicant(u: (typeof screeners)[number]) {
    setLoading(true);
    const response = await reassignApplicant({
      applicant: props.uid,
      screener: u.uid
    });

    // reload the page if successful
    if (!(response as any).error) {
      window.location.reload();
    }
    setLoading(false);
  }

  // Only show dropdown with proper permissions.
  if (props.applicant.current_user !== props.applicant.screener_uid && !props.applicant.admin) {
    return <>{props.applicant.screener_name}</>
  }

  if (!screeners.length && !loading && props.applicant.current_user && hasRequested) {
    return <small className="text-xs">No screeners found</small>;
  }

  return (
    <Dropdown style={{ display: "inline-block" }}>
      <Dropdown.Toggle variant="light" size="lg">
        {props.applicant.screener_name}
      </Dropdown.Toggle>
      { loading && <SpacedSpinner /> }
      <Dropdown.Menu>
        <Dropdown.Header>{L.applicant.reassign} ({(screeners && screeners.length > 0 && screeners[0].quotas === true && (
          <>{L.applicant.quotas_enabled}</>
        )) || (<>{L.applicant.no_quotas}</>)})</Dropdown.Header>
        <div style={{"maxHeight": 300, "overflowY": "scroll"}}>
          {screeners.map((u: any, i: number) => {
            return (
              <Dropdown.Item onSelect={() => doReassignApplicant(u)} key={`reassign-${i}`}>
                {u.name} {u.organization && (
                  <Badge key={`badge-${i}`} variant="primary">{u.organization}</Badge>)}
              </Dropdown.Item>
            );  
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )

}

export default ReassignDropdown;
