import React, { useEffect, useState } from 'react';
import { get_deployment, get_rs_host, useAPIPost } from '../API';

/** be sure to pass applicants as a state var otherwise 
 * an endless loop will occur in the useEffect 
*/
export function useRoboScreenerAPI(props: {
  manualOnly?: boolean,
  allApplicants?: boolean, 
  uid?: string, 
  uids?: string[],
  limit?: number,
  debugKeys?: Record<string, string>,
  computeOptions?: {
    forceComputeFields?: string[],
    customClockTimestamp?: string
  }
}) {
    
  const compute = useAPIPost(get_rs_host() + '/compute_and_save', { 
    includeTokenInData: true 
  });

  const getAllApplicants = useAPIPost('/applicants/all');

  const [queuedApplicants, setApplicants] = useState<any[]>([]);
  const [updates, setUpdates] = useState({} as Record<string, Record<string,string>>);
  const [computing, setComputing] = useState(false);
  const [affected, setAffected] = useState<any[]>([]);

  const doCompute = async (overwrite?: boolean, saveUpdates?: boolean, params?: {
    onlySaveAffected?: boolean
  }) => {
    const allUpdates: Record<string, Record<string, string>> = {};
    const localUpdates = localStorage.getItem('rs-dry-updates');

    if (localUpdates && localUpdates.length > 0) {
      Object.assign(allUpdates, JSON.parse(localUpdates));
    }

    setComputing(true);
       
    const applicants: { 
      uid: string, 
      stage?: string,
      screener?: string, 
      hidden?: boolean 
    }[] = [];

    if (!saveUpdates) {
      if (props.allApplicants) {               
        const applicantResponse = await getAllApplicants({});
        applicants.push(...applicantResponse.data);
      } else if (props.uid) {
        applicants.push({ uid: props.uid })
      } else if (props.uids && props.uids.length > 0) {
        applicants.push( ...props.uids.map(uid => ({ uid })) );
        if (!props.limit) props.limit = applicants.length;
      }
      setApplicants(applicants);
    } else {
      if (params?.onlySaveAffected) {
        for (const uid in updates) {
          if (Object.keys(updates[uid] || {}).length > 0) {
            applicants.push({ uid });
          }
        }
      } else {
        applicants.push(...queuedApplicants);
      }
    }

    let index = -1;
    const promises: Promise<any>[] = [];
    for (const applicant of applicants) {
            
      if (applicant.hidden) continue;
            
      if (['Unreachable','Ineligible'].indexOf(applicant.stage || 'asdfsdf') !== -1) continue;

      index++;
      if (props.limit && index >= props.limit) break;
      if (allUpdates[applicant.uid] && !overwrite) continue;

      const computeParams = {
        deploymentKey: get_deployment(),
        uid: applicant.uid,
        debugKeys: (props.debugKeys || {}),
        dryRunOnly: saveUpdates ? false : true, // very important.
        changedKeys: {},
        computeOptions: {
          ...(props.computeOptions?.forceComputeFields && { forceComputeFields: props.computeOptions.forceComputeFields }),
          ...(props.computeOptions?.customClockTimestamp && { customClockTimestamp: props.computeOptions.customClockTimestamp }),
        }
      }

      // stagger updates
      const updates = await compute(computeParams);
      allUpdates[applicant.uid] = updates.value;
    }
    await Promise.all(promises);
    console.debug("Setting local storage to allUpdates for uids: ", Object.keys(allUpdates));
    localStorage.setItem('rs-dry-updates', JSON.stringify(allUpdates));
    setUpdates(allUpdates);
    setComputing(false);
  };

  // Compute once on page load
  useEffect(() => {
    // overwrite if a uid is provided, otherwise don't overwrite local storage.
    if (!props.manualOnly) {
      doCompute(props.uid ? true : false, false);
    }
  }, []);

  return { 
    applicantUpdates: updates, 
    computing,
    refreshRS: async () => { localStorage.removeItem('rs-dry-updates'); await doCompute(true, false) },
    saveUpdates: async (params?: {
      onlySaveAffected?: boolean
    }) => { 
      await doCompute(true, true, params); 
      // Only dry compute again if there is no face being forced
      // Recomputing dry with _face forces the DetectFaces library to delete the face from
      //   Rekognition indexes. So let's not do that!
      if ((props.computeOptions?.forceComputeFields || []).filter(f => f.includes('_face')).length === 0) 
        await doCompute(true, false) 
    },
  }
}
