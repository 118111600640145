import * as v0 from "@aidkitorg/types/lib/survey";
import { DistroQuestionProps, QuestionProps } from "./Props";
import { InlineSignature } from "./InlineSignature";
import React, { useState, useEffect, useCallback, useRef, useContext, ChangeEvent } from 'react';
import { usePost } from "../API";
import { snakeToEnglish } from "../Util";
import w9 from "@aidkitorg/types/lib/documents/w9";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import { useLocalizedStrings } from "../Localization";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import InterfaceContext from "../Context";
import { Button } from "@aidkitorg/component-library";

// This is a switcher component that routes to the correct form
// May move general "form" things here in the future
export function FillableFormQuestion(props: DistroQuestionProps<v0.FillableForm>) {
  switch (props.component.form.kind) {
    case "w9":
      return W9Question(props);
    default:
      return <></>
  }
}


function W9Question(props: DistroQuestionProps<v0.FillableForm>) {
  // For now, just show a signature box with the W9 certify language
  // capture the signature and save it to the signatureStorageField 
  // then generate the form via API, and show the form once finished in a nice PDF Viewer
  const L = useLocalizedStrings();


  // Allow for crossing out line 2 in w9 instructions
  const [instructions, setInstructions] = useState(props.info[props.component.targetField + '_irs_flag'] 
    ? w9.instructions_line2_crossed
    : w9.instructions);

  // Add the checkbox to cross out line 2 the instructions
  const context = useContext(InterfaceContext);
  const questionId = props.questionProps.Question.replace(/[^a-zA-Z0-9]/g, '');
  const elementHtml = `<label for="checkbox-${questionId}" 
            style="display: flex; align-items: center; font-size: 16px; cursor: pointer; margin-top: 0.5em">
            <span style="margin-right: 0.5em">${context.lang === 'es' ? 'Marca esta casilla para tachar la línea 2:' : 'Check this box to cross out line 2:'}</span>
            <input type="checkbox" id="checkbox-${questionId}" data-checkbox-${questionId} 
                style="width: 20px; height: 20px; cursor: pointer;"
                ${props.info[props.component.targetField + '_irs_flag'] ? 'checked' : ''} />
        </label>`;

  const handleCheckboxClick = (e: Event) => { 
    const element = e?.target as HTMLInputElement | null;
    if (element?.matches?.('[data-checkbox-' + questionId + ']')) {
      props.setInfo(props.component.targetField + '_irs_flag', element.checked ? 'yes' : '');
      setInstructions(element.checked ? w9.instructions_line2_crossed : w9.instructions)
    }
  }

  useEffect(() => {
    // Since the content is dynamic, use a listener on ALL 'change' events
    document.addEventListener('change', handleCheckboxClick);
    return () => {
      document.removeEventListener('change', handleCheckboxClick);
    };
  }, []);

  // Add the inline signature box
  const qProps: Parameters<typeof InlineSignature>[0] = {
    Viewer: 'applicant',
    info: props.info,
    setInfoKey: props.setInfo,
    uid: props.uid,
    Question: 'W9 Signature',
    "Field Type": 'Inline Signature',
    "Target Field": props.component.form.signatureStorageField,
    // Remove any whitespace at the start of each line
    // But keep newlines
    "English Content": instructions.en.replace(/^[ \t]+/gm, ''),
    "Spanish Content": instructions.es.replace(/^[ \t]+/gm, ''),
    Metadata: JSON.stringify({ captureRawSignature: true }),
    replaceWithHtml: {
      regex: /#checkbox/,
      html: elementHtml
    }
  }

  const signatureBox = React.createElement(InlineSignature, qProps);

  const [signed, setSigned] = useState('');
  const [rawSignature, setRawSignature] = useState('');
  const container = useRef<HTMLDivElement>(null);

  const fillPdf = usePost("/document/fill_pdf");
  const viewDocument = usePost("/document/view");

  const finishedContent = useModularMarkdown({
    content: props.component.finishedContent || "All done, please download a copy below",
    info: props.info,
    uid: props.uid
  });

  useEffect(() => {
    (async () => {
      if (!props.info[props.component.form.signatureStorageField]) {
        setRawSignature('');
        return;
      }
      const signedUrl = await viewDocument({
        paths: [props.info[props.component.form.signatureStorageField]!]
      });
      setRawSignature(signedUrl.paths[props.info[props.component.form.signatureStorageField]!]);
    })()
  }, [props.info[props.component.form.signatureStorageField]]);

  const signDocument = async () => {
    const mailingAddressField = props.component.form['mailingAddressField'];
    const street = props.info[mailingAddressField + '_street']!;
    const zip = props.info[mailingAddressField + '_zip']!;
    const city = props.info[mailingAddressField + '_city']!;
    const state = props.info[mailingAddressField + '_state']!;
    const response = await fillPdf({ data: {
      kind: "w9",
      legalName: props.info[props.component.form.legalNameField]!,
      businessInfo: {
        businessName: props.info[props.component.form.businessInfo?.businessNameField ?? ''],
        businessType: props.info[props.component.form.businessInfo?.businessTypeField ?? ''] as any,
        otherBusinessTypeText: props.info[props.component.form.businessInfo?.otherBusinessTypeField ?? ''],
        box3b: (props.info[props.component.form.businessInfo?.box3bField ?? ''] as any) || 'no',
        ein: props.info[props.component.form.businessInfo?.einField ?? ''],
      },
      mailingAddress: { street, zip, city, state },
      encryptedSSN: props.info[props.component.form.tinField ?? ''],
      signature: { url: props.info[props.component.form.signatureStorageField]! },
      line2CrossedOut: !!props.info[props.component.targetField + '_irs_flag']
    }});
    if ((response as any).value) {
      props.setInfo(props.component.targetField, (response as any).value.savedPath);
    }
  }

  useEffect(() => {
    (async () => {
      if (!props.info[props.component.targetField]) {
        setSigned('')
        return;
      }
      const signedUrl = await viewDocument({
        paths: [props.info[props.component.targetField]!]
      });
      setSigned(signedUrl.paths[props.info[props.component.targetField]!]);
    })()
  }, [props.info[props.component.targetField]])

  return <div ref={container}>
    {signed
      ? <div className="mb-2">
        {finishedContent}
        <div className="clear-both">
          <div className="bg-white inline-block p-1 rounded-xl">
            <span>{signed.split('/').pop()?.split('?').shift()}</span>
            <button className="cursor-pointer ml-2 text-gray-500 hover:text-blue-500 bg-slate-50 hover:bg-blue-200 border-solid border-2 border-blue-200 hover:border-blue-400 rounded-xl p-2"
              onClick={() => { window.open(signed, '_blank') }}>
              {L.dashboard.download}
              <span className="ml-2">
                <ArrowDownOnSquareIcon width="20" height="20" className="inline"/>
              </span>
            </button>
          </div>
        </div>
      </div> 
      : <div>
        <div className="mb-2">
          {!rawSignature && <span>{signatureBox}</span>}
        </div>
        {rawSignature &&
            <div className="mb-2">
              <p>{L.questions.fillable_form.signature_preview}</p>
              <div className="my-2">
                <img src={rawSignature} alt="Signature unable to load" />
              </div>
              <div className="flex flex-row justify-start space-x-2">
                <Button variant="secondary" onClick={() => props.setInfo(props.component.form.signatureStorageField, '')}>
                  {L.questions.fillable_form.change_signature}
                </Button>
                <Button variant="primary" onClick={() => signDocument()}>
                  {L.questions.fillable_form.sign_w9}
                </Button>
              </div>
            </div>
        }
      </div>
    }
  </div>
}