import React, { useState, useEffect, useContext, Fragment, useRef, MutableRefObject, useMemo, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useMarkdown, languageContent, safeParse, MiniAidKitLogo, AidKitLogo, safeParseValidatedFormula, SpacedSpinner, removeAllButSpecificCookies, removeSpecificCookies } from "./Util";
import { get_deployment, get_rs_host, useAPIPost, usePost } from "./API";
import InterfaceContext, { AuthContext, ConfigurationContext, ForbiddenCopy, PublicConfigurationContext, SupportedLanguage } from "./Context";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon, ArrowLongLeftIcon, ArrowLongRightIcon, CheckCircleIcon, CheckIcon, ExclamationTriangleIcon, Bars3Icon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { QuestionNode, QuestionTypes, SurveyQuestion, TerminalQuestionNode } from "./Applicant";
import { RoboScreener } from "./Components/Roboscreener";
import { useLocalizedStrings } from "./Localization";
import type { GenericMetadata } from "@aidkitorg/airtable/src/types";
import { evalConditional, evalDistroConditional } from "@aidkitorg/roboscreener/lib/compute/util";
import { LanguageDropdown } from "./Components/LanguageDropdown";
import { InfoDict, QuestionOption, ValidatorDict } from "./Questions/Props";
import { BooleanExpr, RichText } from "@aidkitorg/types/lib/survey";
import { useCookies } from "react-cookie";
import * as v0 from "@aidkitorg/types/lib/survey";
import { useModularMarkdown } from "./Hooks/ModularMarkdown";
import { Modal } from "react-bootstrap";
import { isMobile } from "./utils/isMobile";
import { ThreeColumnPage } from "./Components/ThreeColumnPage";
import { NavigationNode } from "@aidkitorg/types/lib/translation/permissions";
import { useAsyncEffect } from "./Hooks/AsyncEffect";
import { HelpButton } from "./HelpButton";
import { AICoachProvider, useAICoach } from "./AICoach/AICoachContext";
import { LoggedInConfigurationContext } from './Context';
import { Icon, SidebarNavTrigger } from "@aidkitorg/component-library";
import { AICoachButton } from './AICoach/AICoachUI';

export function useLinkKey() {
  const sessionStorage = useContext(InterfaceContext).sessionStorage;
  const cookieName = encodeURIComponent('key:' + window.location.pathname);
  const [cookies, setCookie,] = useCookies([cookieName]);
  const authContext = useContext(AuthContext);

  // Look for key in URL param first
  const params = new URLSearchParams(window.location.search);
  const key = params.get("key");
  const localId = params.get("localid");

  if (localId) {
    // for resuming unsubmitted apps from the portal. All the data is stored in the session storage already.
    authContext.setLocalId(localId);
    authContext.setToken(sessionStorage['auth:' + localId]);
    window.history.replaceState({}, document.title, window.location.pathname + localId);
    return sessionStorage['auth:' + localId];
  } else if (key) {
    setCookie(cookieName, key);
    // Remove the key from the URL and history
    const params = new URLSearchParams(window.location.search);
    params.delete("key");
    window.history.replaceState({}, document.title, `${window.location.pathname}?${params.toString()}`);
    return key;
  }

  return cookies[cookieName];
}

type Step = {
  title: string;
  completed?: boolean;
  progressed?: boolean;
  visitable?: boolean;
  visible: boolean;
  children?: JSX.Element;
}

type Steps = {
  branding?: {
    name?: string,
    logo?: string
  },
  steps: Step[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  sequential: boolean;
  languages: SupportedLanguage[],
  noNavBar?: true,
  blockedEditBanner?: RichText | string,
}

function ContinueModal(props: { continue: () => void, restart: () => void }) {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const L = useLocalizedStrings();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all z-50 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {L.apply.application_in_progress_found}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {L.apply.would_you_like_to_continue}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 sm:ml-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => { props.continue(); setOpen(false)}}
                  ref={cancelButtonRef}
                >
                  {L.apply.continue_where_i_left_off}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => { props.restart(); setOpen(false)}}
                >
                  {L.apply.start_new_application}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function SubmittingModal(props: { content: JSX.Element }) {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const L = useLocalizedStrings();

  function close() {
    window.location.reload();
    return false;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={close}>
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all z-50">
              <div className="">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <ArrowPathIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {L.apply.submitting}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {L.apply.submitting_your_information}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function AllDoneComponent(props: { 
  content: JSX.Element,
  customSubmittedModal?: {
    title?: RichText,
    message?: RichText
  },
  info?: InfoDict,
}) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const configuration = useContext(ConfigurationContext);
  const message =
    props.customSubmittedModal?.message?.[context.lang]
    || props.customSubmittedModal?.message?.en
    || configuration.application_submit_message
    || L.apply.your_application_has_been_received;

  // If we have info, allow substitutions
  const marked = (props.info)
    ? useModularMarkdown({ content: message, info: props.info })
    : useMarkdown(message);

  return (
    <div className="max-w-4xl mx-auto applicant-led pb-36 pt-10 sm:pt-20">
      <div className="">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-4 text-center sm:mt-0 sm:text-left px-5">
          <h1 className="text-lg leading-6 font-medium text-gray-900">
            {props.customSubmittedModal?.title 
              ? <span>{(props.customSubmittedModal.title[context.lang as SupportedLanguage] || props.customSubmittedModal.title['en'])}</span> 
              : <span>{L.apply.all_done}</span>}
          </h1>
          <div className="mt-3">
            <fieldset>
              <legend className="text-gray-500">
                {marked}
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  )
}

function LogoutButton(props: {}) {
  const cookieName = encodeURIComponent('key:' + window.location.pathname)
  const [cookies, ,] = useCookies(["auth_token", cookieName]);                      
  const publicConfig = useContext(PublicConfigurationContext);

  const L = useLocalizedStrings();

  return <button type="button" 
    onClick={() => { 
      if (cookies[cookieName]) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      }
      let redirect = publicConfig.application?.login?.logoutRedirectPath;
      if (redirect?.includes('http')) {
        window.location.href = redirect;
      } else if (redirect) {
        window.location.pathname = '/p/' + redirect;
      } else {
        window.location.href = 'https://aidkit.org';
      }
    }} 
    className="rounded-md bg-indigo-50 px-2.5 py-1.5 my-1 h-8 border-0 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
    {L.apply.close}
  </button>
              
}

function NavBar(props: Steps) {
 
  const L = useLocalizedStrings();
  const configuration = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);
  const publicConfig = useContext(PublicConfigurationContext);

  const key = useLinkKey();

  // Prioritize the Distro program config, then the airtable config, otherwise use defaults
  const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configuration.applicant_facing_logo;
  const applicantFacingLogoWidth = publicConfig.interface?.applicantFacingLogo?.width || configuration.applicant_facing_logo_width || '150';
  const fewSteps = props.steps.length >= 9 ? (s: string) => '' : (s: string) => s;

  const programName = publicConfig.name || configuration.program_name || 'AidKit Program';

  const blockedBanner = props.blockedEditBanner 
    ? typeof props.blockedEditBanner === 'object'
      ? props.blockedEditBanner[context.lang] || props.blockedEditBanner['en']
      : props.blockedEditBanner
    : undefined;
  const marked = useMarkdown(blockedBanner);

  const applicantPortalEnabled = publicConfig.experimental?.enableApplicantPortal

  return (
    <>
      {!props.noNavBar && 
    <div className={"sticky top-0 " + ('md:relative') + " w-full z-50"}>
      <Disclosure>
        {(state: {open: boolean}) => (
          <>
            <header className="bg-white">
              <div className="flex max-w-7xl mx-auto py-2 px-2 sm:px-6 lg:px-8 border-t-0 border-l-0 border-r-0 border-b border-solid border-gray-200 items-center justify-between gap-2 sm:gap-4">
                {applicantPortalEnabled && isMobile() && <SidebarNavTrigger className="!bg-gray-50 !text-gray-700 !font-normal"/>}
                <div className={"flex-shrink sm:flex-1 justify-self-start"}>
                  {props.branding?.logo && <img className="h-8 w-auto" src={props.branding.logo} alt={props.branding.name} />}
                  {!props.branding?.logo && 
                  <>{applicantFacingLogo
                    ?
                    <img
                      className="w-auto max-w-[150px] max-h-[125px]"
                      alt={programName}
                      src={applicantFacingLogo}
                      width={applicantFacingLogoWidth}
                    />
                    : (
                      <>
                        <div className="hidden sm:block">
                          <AidKitLogo width={100} height={40} />
                        </div>
                        <div className="block sm:hidden">
                          <MiniAidKitLogo width={50} height={40} />
                        </div>
                      </>
                    )}</>}
                </div>
                <div className={"hidden md:flex gap-2"}>
                  {props.sequential ?
                    <>
                      <div className="inline-block">
                        <div className={"center-hack mx-1 text-md sm:text-xs mt-1 text-gray-500 font-semibold tracking-wide uppercase "}>
                          {L.apply.step_of_steps.replace('$step',(props.steps.filter((s) => s.visible).indexOf(props.steps[props.currentStep]) + 1) + '').replace('$steps', (props.steps.filter((s) => s.visible).length) + '')}
                        </div>
                        <div className={"sm:block center-hack w-full text-sm " + fewSteps("md:text-xl") + " text-black font-medium"}>
                          {props.steps[props.currentStep]?.title}
                        </div>
                      </div>
                      <Disclosure.Button aria-expanded={state.open} aria-label="Application Step Menu" className={"bg-white h-10 flex-none rounded-md border-none " + fewSteps('hidden')}>
                        { state.open ?
                          <Icon name="ChevronUpIcon" role="decorative"/> :
                          <Icon name="ChevronDownIcon" role="decorative"/>
                        } 
                      </Disclosure.Button>
                    </> :
                    <>
                      <div className={"md:block center-hack " + fewSteps("center-hack-left") + " text-xl mt-2 ml-4 text-black font-medium "}>
                        {props.steps[props.currentStep]?.title}
                      </div>
                    </>
                  }
                </div>
                <div className="flex-shrink sm:flex-1 flex justify-content-end gap-1.5 sm:gap-2">
                  {key && <div className="hidden md:inline-block"><LogoutButton /></div>}
                  <LanguageDropdown {...props} />
                </div>
              </div>
              <div className={"border-t-0 border-l-0 border-r-0 border-b border-solid border-gray-200 py-2 md:hidden"}>
                {props.sequential ?
                  <div className="flex items-center justify-center gap-2 text-gray-500 font-semibold">
                    <div className={"mx-1 text-md mt-1 tracking-wide uppercase"}>
                      {L.apply.step_of_steps.replace('$step',(props.steps.filter((s) => s.visible).indexOf(props.steps[props.currentStep]) + 1) + '').replace('$steps', (props.steps.filter((s) => s.visible).length) + '')}
                    </div>
                    <Disclosure.Button aria-expanded={state.open} aria-label="Application Step Menu" className={"bg-white h-10 flex-none rounded-md border-none "}>
                      { state.open ?
                        <Icon name="ChevronUpIcon" role="decorative"/> :
                        <Icon name="ChevronDownIcon" role="decorative"/>
                      } 
                    </Disclosure.Button>
                  </div> :
                  <>
                    <div className={"md:block center-hack text-xl mt-2 ml-4 text-black font-medium " + fewSteps('md:hidden')}>
                      {props.steps[props.currentStep]?.title}
                    </div>
                  </>
                }
              </div>
            </header>
            <Disclosure.Panel className={"bg-white " + fewSteps('md:hidden')}>
              {(cb: { close: () => void }) => (
                <>{props.steps.filter((s) => s.visible).map((step, index) => {
                  if (!step.visitable) return null;
                  return <a
                    key={`step-${index}`}
                    href={window.location.pathname?.startsWith('/config') ? '' : '#'}
                    role="link"
                    aria-disabled={!step.visitable ? 'true' : undefined}
                    onClick={(e) => {
                      e.preventDefault();
                      if (step.visitable) { cb.close(); props.setCurrentStep(props.steps.indexOf(step)); }
                    }}
                    className={"flex-1 group py-2 flex flex-col mb-1 mt-1 border-b-0 border-solid " + (step.progressed ? "border-indigo-600" : "border-gray-100") + " border-t-0 " + (context.textAlign === 'right' ? "hover:border-r-indigo-800 border-r-4 border-l-0 pr-2" : "hover:border-l-indigo-800 border-l-4 border-r-0 pl-2")}
                  >
                    {props.sequential && <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                      {L.apply.step_step.replace('$step',(index + 1) + '')}
                    </span>}
                    <span className={"text-black font-medium " + (props.sequential ? "text-sm": "text-lg")}>{step.title}</span>
                  </a>;
                })}
                </>)}
            </Disclosure.Panel>
            <div className={"flex bg-white " + (state.open ? 'hidden ' + fewSteps('md:flex') : '')}>
              {props.steps.filter((s) => s.visible).map((step, index) => 
                <div
                  key={`div-step-${index}`}
                  className={"flex-1 flex group pl-2 " + fewSteps('md:py-2') + " flex flex-row ml-0.5 mr-0.5 border-b-0 border-r-0 border-solid " + ((props.sequential ? step.progressed : props.currentStep === index) ? "border-indigo-600" : "border-gray-100") + " hover:border-t-indigo-800 border-l-0 border-t-4"}
                >
                  {props.sequential && <span className={"hidden " + fewSteps("md:flex") + " w-6 h-6 mt-1 mr-3 flex items-center justify-center rounded-full " + 
                  (step.completed ? 'bg-indigo-600' : 'text-indigo-600 w-7 h-7 text-xs font-semibold border-solid border-2 border-indigo-600')}>
                    {step.completed === true ? 
                      <CheckIcon className="w-4 h-4 text-white" aria-hidden="true" />
                      : (index + 1)}
                  </span>}
                  <a 
                    href={window.location.pathname?.startsWith('/config') ? undefined : '#'}
                    onClick={(e) => { 
                      e.preventDefault();
                      !props.blockedEditBanner && step.visitable && props.setCurrentStep(props.steps.indexOf(step)); 
                    }}
                    className={props.sequential ? "hidden " + fewSteps('md:block') : "m-auto hidden " + fewSteps('md:block')}>
                    {props.sequential && <span className={"hidden " + fewSteps('md:block') + " text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800"}>
                      {L.apply.step_step.replace('$step', (index + 1) + '')}
                    </span>}
                    <span className={"hidden " + fewSteps('md:block') + " text-black font-medium " + (props.sequential ? "text-sm" : "text-xl")}>{step.title}</span>
                  </a>
                </div>
              )}
            </div>
          </>)}
      </Disclosure>
    </div>}
      <div className={props.noNavBar ? 'pt-4' : 
        (((context.banner && context.banner?.[context.lang]) || blockedBanner) ? 
          "pt-8 md:pt-4" : "pt-8")}>
        {(context.banner && context.banner?.[context.lang]) &&
      <div className={"rounded-md p-4 flex-1 flex flex-col " + context.banner?.['__style']}>
        {context.banner?.[context.lang]}
      </div>}
        {blockedBanner &&
      <div className="flex rounded-md bg-yellow-50 border-2 border-yellow-300 p-2 justify-around">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-7 w-7 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="text-sm text-yellow-700 -mb-4">
              <p>{marked}</p>
            </div>
          </div>
        </div>
      </div>}
        {props.steps[props.currentStep]?.children}
      </div>
    </>
  )
}


type MappedContentTypes = {
  [Language in SupportedLanguage]?: string
}

type Question = {
  'Field Type': string,
  Question: string,
  'English Content': string,
  'Spanish Content': string,
  'Target Field'?: string,
  "Options (if relevant)"?: QuestionOption[],
  'Conditional On'?: string,
  'Conditional On Value'?: string[],
  Metadata?: string,
  'Additional Options'?: string[],
  computeEnabled: (info: InfoDict) => boolean | Promise<boolean>,
  currentlyEnabled?: boolean,
  complete?: boolean,
  lastVisibleQuestion?: boolean
}

export type Sections = {
  info?: InfoDict,
  branding?: {
    name?: string,
    logo?: string,
  },
  options?: Record<string, any>,
  sections?: Array<MappedContentTypes & {
    'English Content': string,
    'Spanish Content': string,
    complete?: boolean,
    currentlyEnabled?: boolean,
    computeVisible: (info: InfoDict) => boolean | Promise<boolean>,
    currentlyVisible?: boolean,
    hideNextButton?: boolean,

    'Conditional On'?: string,
    'Conditional On Value'?: string,

    'Metadata'?: {
      'dashboard'?: boolean
    }
    Questions: Question[]
  }>,
}

function Section(props: { 
  section: Required<Sections>['sections'][number],
  sequential: boolean,
  next?: () => void,
  nextName?: string,
  previous?: () => void,
  previousName?: string,
  submit?: () => Promise<void>,
  saveAuth: (auth: string | null) => void,
  loadInfo?: (info: InfoDict, token: string, submitted?: boolean) => void, 
  refreshInfo?: () => Promise<void>,
  setInfoKey: (
    key: string,
    value: any,
    valid: boolean,
    disqualifies: boolean
  ) => Promise<void>,
  info?: any,
  uid?: string,
  Viewer: 'applicant' | 'screener',
  blockFurtherEdits: boolean,
  disableInputs?: boolean,
  hideNextButton?: boolean,
  hideBottomButtons?: boolean,
  sectionBottomButtons?: { logoutRedirectPath: string }
}) {
  
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const sessionStorage = context.sessionStorage;
  const config = useContext(PublicConfigurationContext);
  const bottomButtons = props.sectionBottomButtons ?? config.interface?.sectionBottomButtons;
  const checkpoint = usePost("/subsurvey/checkpoint");

  const { localid } = useParams<{localid?: string}>();

  // Generate infoValid object by mapping the truthiness of info object properties
  const infoValid = useMemo(() => {
    const infoValid: Record<string, boolean> = {};
    for (const key in props.info) {
      infoValid[key] = !!props.info[key];
    }
    return infoValid;
  }, [props.info]);

  const [unstuckIds, setUnstuckIds] = useState<string[]>([]);
  const clearApplicantSticky = (id: string) => setUnstuckIds(prevIds => [...prevIds, id]);
  const shouldObserveSticky = useCallback((id: string) => !unstuckIds.includes(id), [unstuckIds]);
  const cookieName = encodeURIComponent('key:' + window.location.pathname);
  const [cookies, ] = useCookies([cookieName]);

  if (props.blockFurtherEdits) {
    return <></>;
  }

  return <>
    {props.sequential && props.previous && <QuestionNode
      icon={ArrowLeftCircleIcon} 
      key={props.section["English Content"] + "-question-first"}
    >
      <button
        type="button"
        onClick={() => { props.previous?.(); window.scrollTo(0,0); }}
        className={
          config.experimental?.newNavigationButtonStyle ? 
            "rounded-lg border-black bg-gray-600 px-4 py-2 text-sm font-medium text-gray-50 hover:outline hover:outline-gray-500" :
            "inline-flex items-center -mt-2 px-4 py-2 border border-gray-300  text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        }
      >
        {L.apply.previous_with_name.replace('$previous', props.previousName || '')}
      </button>
    </QuestionNode>}
    {props.section.Questions.map((question, index) => {
      const { keepVisibleForReview }: { keepVisibleForReview?: boolean } = safeParse(question.Metadata || '{}');
      return question.currentlyEnabled ? (
        <div
          className={keepVisibleForReview && shouldObserveSticky(question.Question) ? 'sticky top-0 bg-white z-[9]' : ''}
          key={props.section["English Content"] + "-question-" + index}
        >
          {React.createElement(SurveyQuestion, {
            ...question,
            index,
            uid: props.uid,
            Viewer: props.Viewer || 'applicant',
            setInfoKey: props.setInfoKey,
            info: props.info,
            infoValid,
            Submit: props.submit,
            LoadInfo: props.loadInfo,
            saveAuth: props.saveAuth,
            refreshInfo: props.refreshInfo,
            pageNext: props.next,
            provisional: true,
            selfServe: true,
            blockQuestionEdits: props.disableInputs || props.blockFurtherEdits,
            lastQuestion: (!props.next || !props.sequential || props.hideNextButton) && index === props.section.Questions.length - 1 || question.lastVisibleQuestion,
            clearApplicantSticky,
          })}
        </div>
      ) : null;
    })}
    {props.sequential && props.next && props.section.complete && !bottomButtons && !props.hideNextButton &&
      <TerminalQuestionNode
        icon={ArrowRightCircleIcon} 
        key={props.section["English Content"] + "-question-last"}
      >
        <button
          type="button"
          onClick={() => { props.next && props.next(); window.scrollTo(0,0)}}
          className={
            config.experimental?.newNavigationButtonStyle ? 
              "rounded-lg border-black bg-gray-600 px-4 py-2 text-sm font-medium text-gray-50 hover:outline hover:outline-gray-500" :
              "inline-flex items-center -mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }
        >
          {L.apply.next_with_name.replace('$next', props.nextName || '')}
        </button>
      </TerminalQuestionNode>
    }
    { bottomButtons && !props.hideBottomButtons && (
      <div className={"survey-question relative pb-8 text-clip mt-24"}>
        <div className="flex flex-col space-y-4">
          <div className={'pt-1.5'}>
            <div className='flex justify-between space-x-2 mb-10 max-w-sm'>
              <button type="button"
                onClick={() => { props.previous?.(); window.scrollTo(0, 0); }}
                disabled={!props.previous}
                className={`inline-flex items-center justify-center w-1/2 px-4 py-2 border text-sm font-medium rounded-md ${props.previous ? 'border-gray-300 text-gray-700 bg-white hover:bg-gray-100 hover:outline hover:outline-gray-500' : 'border-gray-200 text-gray-400 bg-gray-100 cursor-not-allowed'}`}
              >
                <ArrowLongLeftIcon className="h-4 w-4 mr-2" />
                {L.applicant.back}
              </button>
              {!props.hideNextButton && <button type="button"
                onClick={() => { props.next && props.next(); window.scrollTo(0, 0) }}
                disabled={!props.next || !props.section.complete}
                className={`inline-flex items-center justify-center w-1/2 px-4 py-2 border text-sm font-medium rounded-md ${(props.next && props.section.complete) ? 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : 'border-gray-200 text-gray-400 bg-gray-100 cursor-not-allowed'}`}
              >
                {L.applicant.next}
                <ArrowLongRightIcon className="h-4 w-4 ml-2" />
              </button>}
            </div>

            { (sessionStorage.getItem('auth:' + localid) || cookies[cookieName]) && (
              <div className="flex flex-justify-center max-w-sm">
                < button type="button"
                  onClick={async () => {
                    if (confirm(L.apply.confirm_logout)) {
                      await checkpoint({
                        form_name: 'apply',
                        info: props.info || {},
                      });
                      // Clear all site cookies except auth_token. Leave that for admins doing testing.
                      removeAllButSpecificCookies(['auth_token']);
                      sessionStorage.clear()
                      window.location.href = '/' + bottomButtons.logoutRedirectPath;
                    }
                  }}
                  className="px-4 py-2 w-100 border border-gray-300 text-sm font-medium rounded-md text-red-500 bg-white hover:bg-gray-100 hover:outline hover:outline-gray-500"
                >
                  { L.apply.save_and_log_out }
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    )}
    <div className="mb-40"></div>
  </>
}

function updateVisibilityForSelect(question: Question, info: InfoDict) {
  // if SelectFromData, get options from source field, else use "Options (if relevant)"
  let options: QuestionOption[] = []
  const metadata = safeParse(question['Metadata'] || '{}');
  const sourceValue = safeParse(info[metadata.source_field] || '""');
  if (metadata.source_field && Array.isArray(sourceValue)) {
    options = sourceValue.map((i: string) => ({'Name': i, "English Text": i} as QuestionOption));
  } else if ( question["Options (if relevant)"] ) {
    options = question["Options (if relevant)"];
  }

  // For single select, return true if the target field is set for a non-other question,
  // or if the target field is set to other and the other field is set.
  if (question['Field Type'] === 'Single Select') {
    for (const option of options) {
      if (option.Name === 'other' || option["Other Field"]) {
        if (info[question["Target Field"] || ''] === option.Name && !!info[question["Target Field"] + "_" + option.Name]) {
          return true;
        }
      } else if (info[question["Target Field"] || ''] === option.Name) {
        return true;
      }
    }
  // For multiple select, return true if all of the selected options are non-other questions,
  // or if all other fields are set.
  } else if (question['Field Type'] === 'Multiple Select') {
    const optionsString = info[question["Target Field"] || ''] || '';
    if (!optionsString) return false;

    const selectedOptions = optionsString.split(',').filter((x) => x).reduce((acc, cur) => { acc[cur] = true; return acc; }, {} as Record<string, boolean>);

    const opts = (options).filter((opt) => selectedOptions[opt.Name]);

    if (!opts.length) return false;

    for (const option of opts) {
      if ((option.Name === 'other' || option["Other Field"]) && !info[question["Target Field"] + "_" + option.Name]) {
        return false;
      }
    }
    return true
  } else {
    throw new Error('updateVisibilityForSelect called on non-select question');
  }
  return false;
}

async function UpdateVisibility(sections: Sections, info: InfoDict) {
  let completeSoFar = true;
  let previousComplete = true;
  for (const section of sections?.sections || []) {
    // console.debug("Updating visibility for section: ", section["English Content"]);
    section.currentlyEnabled = completeSoFar;
    section.currentlyVisible = await section.computeVisible(info);
    // console.debug("Visibility is: ", section.currentlyVisible);
    if (section.currentlyVisible) {
      let complete = true;
      let lastQ = null;
      for (const question of section.Questions) {
        //console.log("Question computeEnabled for question " + question.Question + ": " + question.computeEnabled(info));
        if (await question.computeEnabled(info)) {
          lastQ = question;

          // Mark completeness for a question with sub-questions based on whether all sub-questions are complete
          complete = CheckQuestionComplete(question, info, complete);
          // console.log("Checking question: ", question, complete);
        } else {
          question.currentlyEnabled = false;
        }
      }
      if (complete && lastQ && lastQ !== section.Questions[section.Questions.length - 1]) {
        lastQ.lastVisibleQuestion = true;
      }
      completeSoFar = completeSoFar && complete;
      section.complete = completeSoFar;
    } 
    if (previousComplete) {
      section.currentlyEnabled = true;
    } else {
      section.currentlyEnabled = false;
    }
    previousComplete = completeSoFar;
  }
  // console.log("Visibility", sections)
}

type SubmitInfo = (submit_key?: string, options?: { info: InfoDict }) => Promise<any>;

export function CheckQuestionComplete(question: Question, info: InfoDict, complete: boolean) {
  // Assume things are complete unless found otherwise
  question.currentlyEnabled = complete;
  question.complete = true;
  question.lastVisibleQuestion = false;

  if (question['Field Type'] === 'Likert' || question['Field Type'] === 'Number With Unit') {
    const metadata = safeParse(question['Metadata'] || '{}') as { questions: v0.Likert['questions'], choices: v0.Likert['choices'] };
    if (Array.isArray(metadata?.questions)) {
      const questions = metadata.questions;
      if (
        (question["Additional Options"] || []).indexOf("Optional") !== -1 ||
        questions.every((question: any) => !!info[question.targetField])
      ) {
        question.complete = true;
      } else {
        complete = false;
        question.complete = false;
        question.lastVisibleQuestion = true;
      }
    } else {
      const answerData = question['Target Field'] && info[question['Target Field']] ? info[question['Target Field']] : info[metadata.questions.targetField];
      const answerField = metadata.questions.answerField;
      const possibleAnswers = metadata.choices.map(c => c.value);
      if (answerData) {
        const parsedData = safeParse(answerData);
        if (Array.isArray(parsedData)) {
          const qComplete = parsedData.every(v => {
            return answerField && possibleAnswers.includes(v[answerField])
          });
          question.complete = qComplete;
          complete = qComplete;
        }
      }
    }
  } else if (question['Field Type'] === 'Ranking' && question['Target Field']) {
    const metadata = safeParse(question['Metadata'] || '{}');
    // Verify that the required number of choices in the ranking have been selected
    if (!info[question['Target Field']] || safeParse(info[question['Target Field']]!, []).filter((val: string) => !!val).length < metadata.num_choices) {
      if (!(question['Additional Options'] || []).includes('Optional')) {
        question.complete = false;
        complete = false;
        question.lastVisibleQuestion = true;
      }
    }
  } else if (question['Field Type'] === 'Single Select' || question['Field Type'] === 'Multiple Select') {
    if (updateVisibilityForSelect(question, info)) {
      question.complete = true;
    } else {
      if ((question['Additional Options'] || []).indexOf('Optional') === -1) {
        question.complete = false;
        complete = false;
        question.lastVisibleQuestion = true;
      }
    }
  } else if (question['Field Type'] === 'Liveness Detection' && question['Target Field']) {
    // console.log("Checking liveness", question["Target Field"], info[question["Target Field"]]);
    if (!info[question['Target Field']] || info[question['Target Field']]?.includes('pending')) {
      if (!(question['Additional Options'] || []).includes('Optional')) {
        question.complete = false;
        complete = false;
        question.lastVisibleQuestion = true;
      }
    }
  } else if (question['Target Field']) {
    if (((question['Additional Options'] || []).indexOf('Optional') === -1) &&
      ((question['Additional Options'] || []).indexOf('Advisory') === -1) &&
      ((question['Additional Options'] || []).indexOf('Hidden') === -1) &&
      question['Field Type'] !== 'Show Field' &&
      question['Field Type'] !== 'Show Date' &&
      !info[question['Target Field']]) {
      if (complete) {
        question.lastVisibleQuestion = true;
        complete = false;
      }
      question.complete = false;
    }
  } else if (question['Field Type'] === 'Explanation') {
    if (question['Additional Options']?.includes('Hidden')) {
      question.lastVisibleQuestion = false;
      question.currentlyEnabled = false;
      complete = true;
    }
  }
  return complete;
}

export function ModularQuestionPage(props: { 
  sections: Sections, 
  uid?: string, 
  info: InfoDict | null, 
  viewInfo?: InfoDict,
  setInfo: (info: InfoDict) => void, 
  saveInfo: (info: InfoDict) => Promise<void>,
  saveAuth: (auth: string | null) => void,
  submit?: SubmitInfo,
  loadInfo?: (info: InfoDict, token: string, submitted?: boolean) => void,
  refreshInfo?: () => Promise<void>,
  shouldFastForward?: MutableRefObject<boolean>,
  sequential: boolean,
  noHistory?: true,
  noNavBar?: true,
  branding?: {
    name?: string,
    logo?: string,
  },
  recompute?: number
  Viewer?: 'applicant' | 'screener',
  blockFurtherEdits?: {
    block_when: BooleanExpr,
    blocked_message: RichText
  },
  warnOnIncompleteSurvey?: {
    submissionField?: string,
    warningMessage?: RichText,
  }
}) {
  const authContext = useContext(AuthContext);
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const [roboscreener, setRoboscreener] = useState(null as RoboScreener | null);
  const [refresh, setRefresh] = useState(0);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [disableEdits, setDisableEdits] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const warningDisplayed = useRef<boolean>(false);
  const [visibleSections, setVisibleSections] = useState<Sections['sections']>([]);
  const location = useLocation();
  const AICoach = useAICoach();
  const staticFalseFn = useRef(() => false);

  const params = new URLSearchParams(window.location.search);
  const key = params.get('key');

  // Survey Options: Warn on Incomplete Survey
  // Memoize the specific submission field in the info payload
  // so that we can isolate the useEffect to only run if the
  // specific key changes in info
  const surveySubmitted = useMemo(() => {
    if (props.warnOnIncompleteSurvey?.submissionField) {
      return props.info?.[props.warnOnIncompleteSurvey.submissionField];
    }
    return undefined;
  }, [props.info, props.warnOnIncompleteSurvey]);

  // Survey Options: Warn on Incomplete Survey
  // If enabled, show a warning to user if they leave the 
  // browser and have not yet submitted their survey
  // If on mobile, show it on page load instead
  useEffect(() => {
    const showModal = () =>{
      if (props.warnOnIncompleteSurvey?.submissionField
        && props.Viewer !== 'screener'
        && warningDisplayed.current === false
        && surveySubmitted === undefined) {
        // Only show this warning once to the user
        setShowWarning(true);
        warningDisplayed.current = true;
      }
    };
    
    const handleMouseLeave = (e: MouseEvent) => {
      if (e.clientY <= 0 || e.clientX <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight) {
        showModal();
        // Cleanup event listeners ASAP after the first warning
        document.removeEventListener('mouseleave', handleMouseLeave);
      }
    };

    if (props.warnOnIncompleteSurvey?.submissionField) {
      if (isMobile() ) {
        showModal();
      } else {
        document.addEventListener('mouseleave', handleMouseLeave);
      }
    }

    // Cleanup function to remove the event listener when warnOnIncompleteSurvey is disabled or the component unmounts
    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [props.warnOnIncompleteSurvey, surveySubmitted]);

  useEffect(() => {
    // When the pathname changes because the user is navigating to a different section/subsurvey, 
    // reset the selected tab to be the first tab.
    if (surveyLoaded) {
      setSelectedTab(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    // in screener views update the hash to reflect the current step for sharing links
    if (visibleSections?.length && surveyLoaded && props.noHistory !== true && props.noNavBar !== true) {
      window.history.pushState(
        null,
        "",
        `#step${(selectedTab + 1).toString()}`
      );
    }
  }, [visibleSections?.length, surveyLoaded, selectedTab, props.noHistory, props.noNavBar]);

  useEffect(() => {
    // console.log("Creating rs", props.sections);
    if (!props.sections.sections) return;

    const formulas: Record<string, string> = {};
    for (const section of props.sections.sections || []) {
      if (!section) continue;
      let computeVisible = null;
      const metadata = typeof section.Metadata === 'string' && safeParse(section.Metadata || "{}");
      if (metadata?.hideNextButton) {
        section.hideNextButton = metadata?.hideNextButton;
      }
      if(metadata?.hidden) {
        computeVisible = () => false; 
      }
      else if (metadata?.conditional) {
        computeVisible = async (info: InfoDict) => await evalConditional(metadata.conditional, info, {}, {}, {
          viewInfo: props.viewInfo
        });
      } else if (section['Conditional On']) {
        if (section['Conditional On Value']) {
          if (section['Conditional On Value'][0] === '!') {
            computeVisible = (info: InfoDict) => !info[section['Conditional On']!];
          } else {
            computeVisible = (info: InfoDict) => !!(info[section['Conditional On']!] 
              && (section['Conditional On Value'] || [] as string[]).indexOf(info[section['Conditional On']!] || '') !== -1);
          }
        } else {
          computeVisible = (info: InfoDict) => !!info[section['Conditional On']!];
        }
      } else {
        computeVisible = (info: InfoDict) => true;
      }
      section.computeVisible = computeVisible;

      for (const question of section.Questions) {
        let computeEnabled = null;
        if (question['Field Type'] === "Computed") {
          formulas[question['Target Field']!] = safeParse(question['Metadata'] || '{}').formula;
        }
        if (question['Field Type'] === "Validated") {
          formulas[question['Target Field']!] = '(() => {' + safeParseValidatedFormula(question['Metadata'] || '') + '})()';
        }
        if (question['Field Type'] === "Payment") {
          computeEnabled = (info: InfoDict) => !!info[question['Target Field']!];
        } else if (question['Field Type'] === 'Resume Widget') {
          computeEnabled = (info: InfoDict) => !key;
        } else {
          if (question['Conditional On']) {
            // console.log(question['Conditional On'], question['Conditional On Value']);
            if (question['Conditional On Value']) {
              if (question['Conditional On Value'][0] === '!') {
                computeEnabled = (info: InfoDict) => !info[question['Conditional On']!];
              } else {
                computeEnabled = (info: InfoDict) => {
                  return !!(info[question['Conditional On']!] && (question['Conditional On Value'] || [] as string[]).indexOf(info[question['Conditional On']!] || '') !== -1);
                }
              }
            } else {
              computeEnabled = (info: InfoDict) => !!info[question['Conditional On']!];
            }
          } else {
            // Check if conditional in metadata, ensuring metadata is an object first for legacy Validated type support.
            if (question['Metadata'] && safeParse(question['Metadata'], 'safeReturn') !== 'safeReturn') {
              const metadata = safeParse(question['Metadata'] || '{}') as GenericMetadata;
              if (metadata.conditional) {
                computeEnabled = async (info: InfoDict) => {
                  const result = await evalConditional(metadata.conditional!, info, {}, {}, {
                    viewInfo: props.viewInfo
                  });
                  return !!result;
                }

              } else {
                computeEnabled = (info: InfoDict) => true; 
              }
            } else {
              computeEnabled = (info: InfoDict) => true;
            } 
          }
        }
        question.computeEnabled = computeEnabled;
      }
    }
    if (!roboscreener) {
      const rs = new RoboScreener('', formulas, props.saveInfo,  undefined); //, Object.keys(status.applicants)[0])
      // console.log("Creating rs", rs, props.sections);
      setRoboscreener(rs);
    } else {
      // Reset deps
      roboscreener.formulas = formulas;
      roboscreener.collectedDependencies = false;
      // Kick off a recompute in the usual spot
      props.setInfo({...props.info})
    }
    return () => {
      // cleans up a memory leak
      for (const section of props.sections.sections || []) {
        if (!section) continue;
        section.computeVisible = staticFalseFn.current;
        for (const question of section.Questions) {
          question.computeEnabled = staticFalseFn.current;
        }
      }
    }
  }, [props.sections])

  useEffect(() => {
    if (roboscreener && props.recompute) {
      roboscreener.collectedDependencies = false;
      props.setInfo({...props.info})
    }
  }, [roboscreener, props.recompute]);

  useEffect(() => {
    (async () => {
      if (roboscreener && props.info) {
        roboscreener.info = props.info!;
        roboscreener.computeUpdates();
        props.setInfo(roboscreener.info);

        let localSelectedTab = selectedTab;
        if (props.sections) {
          await UpdateVisibility(props.sections, roboscreener.info);
          const localVisibleSections = (props.sections.sections || []).filter(section => section?.currentlyVisible);
          setVisibleSections(localVisibleSections);
          // console.log("localVisibleSections", localVisibleSections);
          if (props.shouldFastForward && props.shouldFastForward.current) {
            let idx = 0;
            for (const section of localVisibleSections) {
              if ((section.complete && localVisibleSections.indexOf(section) !== localVisibleSections.length - 1)) {
                idx += 1;
              } else {
                break;
              }
            }
            setSelectedTab(idx);
            localSelectedTab = idx;
            props.shouldFastForward.current = false;
          }

          if (!surveyLoaded && localVisibleSections.length && !props.shouldFastForward) {
            // select tab based on window hash for screener view
            setSurveyLoaded(true);
            for (let i = 0; i < localVisibleSections.length; i++) {
              if (
                'step' + (i + 1) === window.location.hash.slice(1)
                && localVisibleSections[i].currentlyEnabled
              ) {
                setSelectedTab(i);
              }
            }
          }

          setRefresh(refresh + 1);
        }
      }
    })();
  }, [props.info, roboscreener, props.sections]);

  if (props.branding?.name) {
    document.title = props.branding.name;
  }

  useEffect(() => {
    (async () => {
      if (props.Viewer !== 'screener' && props.blockFurtherEdits?.block_when) {
        setDisableEdits(await evalDistroConditional(props.blockFurtherEdits.block_when, props.info || {}, false));
      }
    })();
  }, [props.info, props.Viewer, props.blockFurtherEdits]);

  const md = (s: string) => s;

  const L = useLocalizedStrings();
  const warnOnIncompleteMessage = useMarkdown(props.warnOnIncompleteSurvey?.warningMessage?.[context.lang as SupportedLanguage])

  const mainContent = visibleSections?.length ? (
    <div className="max-w-4xl mx-auto applicant-led pb-36">
      <NavBar noNavBar={props.noNavBar} branding={props.branding} steps={
        visibleSections.map((section, index) => {
          const prevIndex = index - 1;
          const nextIndex = index + 1;

          return ({
            title: section[languageContent(context.lang)] || section['English Content'],
            completed: section.complete,
            visitable: section.currentlyEnabled || !props.sequential,
            visible: section.currentlyVisible || false,
            progressed: index < selectedTab + 1,
            children: <div key={'section-' + index} className={md("md:pt-4")}><Section 
              hideBottomButtons={props.sections?.options?.sectionBottomButtons?.hideOnFirstSection && index === 0}
              sectionBottomButtons={props.sections?.options?.sectionBottomButtons}
              sequential={!props.sequential && !section.Metadata?.dashboard ? false : true}
              section={section}
              next={nextIndex <= visibleSections.length - 1 ? () => setSelectedTab(nextIndex) : undefined}
              nextName={visibleSections[nextIndex]?.[languageContent(context.lang)] ||  nextIndex + ''}
              previous={ prevIndex >= 0 ? () => setSelectedTab(prevIndex) : undefined}
              previousName={visibleSections[prevIndex]?.[languageContent(context.lang)] || prevIndex + ''}
              saveAuth={props.saveAuth}

              {...{
                ...(props.submit ? {submit: props.submit} : {}),
                ...(props.loadInfo ? {loadInfo: ((...args) => {
                  if (roboscreener) roboscreener.collectedDependencies = false;
                  props.loadInfo?.(...args);
                })
                } : {}),
                ...(props.refreshInfo ? { 
                  refreshInfo: async () => {
                    if (roboscreener) roboscreener.collectedDependencies = false;
                    props.refreshInfo?.();
                  }} : {})
              }}

              setInfoKey={async (key, value, valid, disqualifies) => {
                roboscreener?.setKey(key, value);
                UpdateVisibility(props.sections, roboscreener?.info || {});
                // console.log("rs info", roboscreener?.info );
                for (const section of props.sections?.sections || []) {
                  for (const question of section.Questions) {
                    const metadata = safeParse(question['Metadata'] || '{}') as GenericMetadata;

                    if (metadata.resetUnreachableFields && question["Target Field"] && await question.computeEnabled(roboscreener?.info || {}) === false) {
                      roboscreener?.setKey(question["Target Field"], "")

                      // Reset all slugs related to this target field
                      metadata.slugs?.forEach((slug) => {
                        roboscreener?.setKey(slug, "")
                      })

                    }
                  }
                }

                props.setInfo(roboscreener?.info || {});

                if (!props.sequential && props.submit && roboscreener?.info) {
                  props.submit(undefined, { info: roboscreener.info })
                } 
              }}
              uid={props.uid}
              info={props.info}
              Viewer={props.Viewer || 'applicant'}
              disableInputs={!!(props.viewInfo?.readonly)}
              blockFurtherEdits={disableEdits}
              hideNextButton={section.hideNextButton}
            /></div>
          })})
      } 
      sequential={true}
      currentStep={selectedTab} 
      setCurrentStep={setSelectedTab}
      blockedEditBanner={disableEdits 
        ? props.blockFurtherEdits?.blocked_message 
        : (props.viewInfo?.readonly)
          ? L.applicant.view_only
          : undefined}
      languages={(config.languages || 'en,es').split(',') as SupportedLanguage[]}
      />
      {props.warnOnIncompleteSurvey && (
        <Modal show={showWarning} onHide={() => setShowWarning(false)}>
          <Modal.Body>
            {warnOnIncompleteMessage}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setShowWarning(false)}
            >
              {L.apply.continue}
            </button>
          </Modal.Footer>
        </Modal>
      )}
      <HelpButton />
      {
        (AICoach.enabled && authContext?.token?.() && !!visibleSections?.length)
          ? <AICoachButton />
          : null
      }
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen w-full">
      <SpacedSpinner className="h-20 w-20 text-gray-400" /> {/* TODO: replace with PageLoading Component */}
    </div>
  );

  return mainContent;
}

type BlockEdits = {
  block_when: BooleanExpr,
  blocked_message: RichText
}
type WarnOnIncompleteSurvey = {
  submissionField?: string,
  warningMessage?: RichText
};

export function SubsectionPage() {
  const params = new URLSearchParams(window.location.search);
  const { path } = useParams() as Record<string, string>;
  const key = useLinkKey();
  const viewInfo = JSON.parse(params.get("view_info") || '{}') as Record<string, string | undefined>;
  const get_survey = usePost("/subsurvey/definition");

  const initialInfo = {} as Record<string, string>;

  const specialKeys = ['key','lang','view_info'];

  for (const paramKey of Array.from(params.keys())) {
    if (paramKey && !specialKeys.includes(paramKey)) {
      initialInfo[paramKey] = params.get(paramKey)!;
    }
  }

  const [info, setInfo] = useState<InfoDict>(initialInfo);
  const [resp, setResp] = useState({} as Sections);
  const saveInfoRS = useAPIPost(get_rs_host() + "/compute_and_save");
  const [showComplete, setShowComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const registeredTimeout = useRef<undefined | ReturnType<typeof setTimeout>>(undefined);
  const context = useContext(InterfaceContext);
  // If we're on /p/apply with no key, redirect to /apply
  const [redirect, setRedirect] = useState(path === 'apply' && !key ? '/apply' : null as null | string);
  const history = useHistory();
  const shouldFastForward = useRef(false);
  const blockFurtherEdits = useRef<BlockEdits>({} as BlockEdits);
  const [infoPendingSave, setInfoPendingSave] = useState<InfoDict | null>(null);  
  const warnOnIncompleteSurvey = useRef<WarnOnIncompleteSurvey>({});
  const publicConfig = useContext(PublicConfigurationContext);
  const [nav, setNav] = useState<NavigationNode[]>([]);
  
  // Have to pass document cookie directly to usePost because it doesn't update when the cookie changes
  // This is a hacky workaround and will only update on rerenders. But react doesn't track cookies.
  const getNav = usePost("/applicant/navigation", { token: () => document.cookie.split("; ").find((c) => c.startsWith("portal="))?.split("=")[1] || '' });

  useAsyncEffect(async (cancel) => {
    // Fetch navigation
    const response = await getNav({ lang: context.lang }, undefined, cancel);
    setNav(response.navigation);
    if (response.loggedIn === false) {
      // remove portal token if it's expired. No need to alert the user here though, just treat them as logged out.
      removeSpecificCookies(['portal']);
    }
  }, [document.cookie, getNav, context.lang]);

  useEffect(() => {
    if (redirect) history.push(redirect);

    return () => {
      if (registeredTimeout.current) {
        clearTimeout(registeredTimeout.current);
      }
    }
  }, [redirect]);

  useEffect(() => {
    (async () => {
      const response = await get_survey({
        form_name: path
      });

      if (response && response.error) {
        if (response.copy) { 
          context.setForbiddenCopy((prevState) => ({
            ...prevState,
            [path]: response.copy as any
          }));
        }
        /** We do this setRedirect to avoid any state leaks because this is in an async useEffect */
        if (response.error === 'subsurvey_expired') {
          setRedirect('/ahh/expired?path=' + path);
        }
        if (response.error === 'subsurvey_part_mismatch' || response.error === 'subsurvey_part_required') {
          setRedirect('/ahh/inaccessible?path=' + path);
        }
        if (response.error === 'subsurvey_not_found') {
          setRedirect('/404');
        }
      }

      if (response && response.sections) {
        // First map the result as the response omitting error after making things required again
        const result = response as Omit<Required<Awaited<ReturnType<typeof get_survey>>>, 'error'>;
        setResp(result as unknown as Sections); // hacky
  
        if (response?.needsKey && !key) {        
          window.location.pathname = '401';
        }
  
        if (response?.initialInfo) {
          setInfo({...response.initialInfo, ...initialInfo});
        }

        if (response.options?.should_fast_forward) {
          shouldFastForward.current = true;
        }

        if (response.options?.block_further_edits) {
          blockFurtherEdits.current = response.options.block_further_edits;
        }

        if (response.options?.warnOnIncompleteSurvey) {
          warnOnIncompleteSurvey.current = {
            submissionField: response.options.warnOnIncompleteSurvey.submissionField,
            warningMessage: response.options.warnOnIncompleteSurvey.warningMessage,
          }
        }
      }
    })();
  }, [key]);

  useEffect(() => {
    // No Saving for now on status pages
    if (path === 'status') return;
    if (!key) return;

    if (registeredTimeout.current) {
      clearTimeout(registeredTimeout.current);
    }
    setInfoPendingSave(info);
    const timeout = setTimeout(async () => {
      registeredTimeout.current = undefined;
      if (redirect) return;
      console.log("Saving keys: ", Object.entries(info || {}).map(([k,v]) => k + ': ' + (v || '').length).join(','));
      if (process.env.NODE_ENV === 'development') {
        console.log("Saving keys", info);
      }
      await saveInfoRS({
        deploymentKey: get_deployment(), 
        changedKeys: info,
        token: key
      });
      setInfoPendingSave(null);
    }, 3000);

    registeredTimeout.current = timeout;
  }, [info]);

  const refreshInfo = useCallback(async () => {
    const response = await get_survey({ form_name: path })
    if (response?.initialInfo) {
      if (process.env.NODE_ENV === 'development') console.log("Setting info", response.initialInfo, "plus", infoPendingSave);
      setInfo({ ...response.initialInfo, ...infoPendingSave });
    }
  }, [path, infoPendingSave]);

  const Submit = async (submit_key?: string, options?: { info: InfoDict }) => {
    if (registeredTimeout.current) clearTimeout(registeredTimeout.current);
    console.log("Saving from submit");
    const resp = await saveInfoRS({
      deploymentKey: get_deployment(), 
      changedKeys: (submit_key ? {...info, [submit_key]: (new Date()).toISOString()} : info) || {},
      token: key,
      ...(submit_key ? { submit_key } : {})
    });
    if (resp.value) {
      setShowComplete(true);
    }
    return resp;
  };

  if (showComplete) {
    const main = <AllDoneComponent
      info={info}
      content={<></>} 
      customSubmittedModal={resp.options?.custom_submitted_modal}
    />;

    return publicConfig.experimental?.enableApplicantPortal ? <ThreeColumnPage main={main} nav={nav} Viewer={'applicant'} applicantPortal /> : main;
  }

  if (submitting) {
    return <SubmittingModal content={<></>} />;
  }

  const main = <ModularQuestionPage 
    sections={resp} 
    info={info} 
    viewInfo={viewInfo}
    setInfo={setInfo} 
    submit={Submit}
    branding={resp.branding}
    refreshInfo={refreshInfo}
    saveInfo={async (info) => {

    }}
    saveAuth={(auth) => {

    }}
    shouldFastForward={shouldFastForward}
    sequential={true}
    blockFurtherEdits={blockFurtherEdits.current}
    warnOnIncompleteSurvey={warnOnIncompleteSurvey.current}  
  />

  return publicConfig.experimental?.enableApplicantPortal ? <ThreeColumnPage main={main} nav={nav} Viewer={'applicant'} applicantPortal/> : main;

}

export function DashboardPage() {
  const params = new URLSearchParams(window.location.search);
  const key = useLinkKey();
  const get_survey = useAPIPost("/applicants/applicant_dashboard");
  const [info, setInfo] = useState<InfoDict>({});
  const [sectionsAndInfo, setSectionsAndInfo] = useState({} as Sections);
  const update_application = useAPIPost("/applicants/applicant_survey_update");
  const [showComplete, setShowComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [uid, setUid] = useState(undefined as string | undefined);

  useEffect(() => {
    (async () => {
      const response = await get_survey({
        status_token: key,
      });

      setSectionsAndInfo(response);
      if (response?.info) {
        setInfo(response.info);
      }
      if (response?.uid) {
        setUid(response.uid);
      }
    })();
  }, [key]);

  const Submit = async (submit_key?: string, options?: { info: InfoDict } ) => {
    const submitInfo = options?.info ? options.info : info;
    console.log("Submitting", submitInfo);
    setSubmitting(true);
    const resp = await update_application({
      info: (submit_key ? {...submitInfo, [submit_key]: (new Date()).toISOString()} : submitInfo),
      auth_code: key
    });
    if (resp.status === 'ok') {
      // setShowComplete(true);
      setSubmitting(false);
    }
  } 

  if (showComplete) {
    return <AllDoneComponent content={<></>} />;
  }

  if (submitting) {
    return <SubmittingModal content={<></>} />;
  }

  return <ModularQuestionPage 
    sections={sectionsAndInfo} 
    info={info}
    uid={uid}
    saveAuth={(auth) => {

    }}
    saveInfo={async (info) => {

    }}
    setInfo={setInfo} 
    submit={Submit}
    sequential={false} />;
}

function ApplicationPage() {
  const { localid } = useParams<{ localid?: string, dynamoAppId?: string }>(); 
  const params = new URLSearchParams(window.location.search);
  const localId = useRef(localid)
  const auth = useRef('');
  const configuration = useContext(ConfigurationContext);
  const marked = useMarkdown(configuration.guard_content || '');
  const publicConfig = useContext(PublicConfigurationContext);
  const programName = publicConfig.name || configuration.program_name;
  const [originalParams, setOriginalParams] = useState<URLSearchParams | null>(null);
  const context = useContext(InterfaceContext);
  const sessionStorage = context.sessionStorage;

  useEffect(() => {
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;
    setOriginalParams(new URLSearchParams(new URL(fullUrl).search));
  }, []);

  if (!programName) {
    return <></>;
  }

  let gparams = (configuration.guard_params || '').split(',').map((p: string) => p.trim());
  const hasGuardParam = gparams.some((p: string) => params.has(p));
  const originallyHadGuardParam = gparams.some((p: string) => originalParams?.has(p));

  if (configuration.guard_params && (!hasGuardParam && !originallyHadGuardParam)) {
    return <div className="m-12 mx-auto w-96 mt-10 bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6 guard">
        {marked}
      </div>
    </div>
  }

  return <AuthContext.Provider value={{
    localId: () => {
      return localId.current!;
    },
    token: () => {
      return auth.current;
    },
    setToken: (token) => {
      sessionStorage['auth:' + localId.current] = token;
      auth.current = token!;
    },
    setLocalId: (id) => {
      localId.current = id;
    }
  }}>
    <AICoachProvider>
      <ApplicationPageInner />
    </AICoachProvider>
  </AuthContext.Provider>;
}

function ApplicationPageInner() {
  const params = new URLSearchParams(window.location.search);
  const key = useLinkKey();
  const get_survey = usePost("/subsurvey/definition");
  const submit = usePost("/subsurvey/submit");
  const getCurrentInfo = usePost("/subsurvey/resume");

  const [applicationSections, setApplicationSections] = useState({} as Sections);
  const urlParams = new URLSearchParams(window.location.hash.slice(1));
  const authContext = useContext(AuthContext);
  const context = useContext(InterfaceContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const sessionStorage = context.sessionStorage;
  const localStorage = context.localStorage;

  const history = useHistory();
  const checkpoint = usePost("/subsurvey/checkpoint");

  const [askToContinue, setAskToContinue] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const shouldFastForward = useRef(false);
  const blockFurtherEdits = useRef<BlockEdits>({} as BlockEdits);
  const warnOnIncompleteSurvey = useRef<WarnOnIncompleteSurvey>({});
  const registeredTimeout = useRef<undefined | ReturnType<typeof setTimeout>>(undefined);

  const toSave = ['ref', 'propel_id', '_local_id'];
  const initialInfo: InfoDict = {};
  const L = useLocalizedStrings();
  for (const key of Array.from(params.keys())) {
    if (toSave.includes(key)) {
      initialInfo[key] = params.get(key)!;
    }
  }

  const logoutAndShowComplete = useCallback(() => {
    // clear session storage for, and log out of, this application
    const localId = authContext.localId?.();
    authContext.setToken(null);
    sessionStorage.removeItem('cache:' + localId);
    sessionStorage.removeItem('rev:' + localId);
    sessionStorage.removeItem('auth:' + localId);
    sessionStorage.removeItem('saved:' + localId);
    // show complete message to the user
    setShowComplete(true);
  }, [authContext.localId, sessionStorage]);

  const [info, setInfo] = useState<InfoDict>(initialInfo);

  const [nav, setNav] = useState<NavigationNode[]>([]);

  // Have to pass document cookie directly to usePost because it doesn't update when the cookie changes
  // This is a hacky workaround and will only update on rerenders. But react doesn't track cookies.
  const getNav = usePost("/applicant/navigation", { token: () => document.cookie.split("; ").find((c) => c.startsWith("portal="))?.split("=")[1] || '' });

  useEffect(() => {
    // Fetch navigation
    (async () => {
      const response = await getNav({ lang: context.lang });
      setNav(response.navigation);
      if (response.loggedIn === false) {
        // remove portal token if it's expired. No need to alert the user here though, just treat them as logged out.
        removeSpecificCookies(['portal']);
      }
    })();
  }, [document.cookie, getNav, context.lang]);

  useEffect(() => {
    (async () => {
      const resp = await get_survey({ form_name: 'apply' });

      setApplicationSections(resp as unknown as Sections);
      if (resp?.options?.block_further_edits) {
        blockFurtherEdits.current = resp.options.block_further_edits;
      }

      if (resp?.options?.warnOnIncompleteSurvey) {
        warnOnIncompleteSurvey.current = resp.options.warnOnIncompleteSurvey;
      }

      if (authContext.localId?.() && sessionStorage['cache:' + authContext.localId()]) {
        if (sessionStorage['auth:' + authContext.localId()]) {
          authContext.setToken(sessionStorage['auth:' + authContext.localId()]);
          // TODO: Check if we should resume
        }
        setInfo(JSON.parse(sessionStorage['cache:' + authContext.localId()]));
        console.log("Fast forwarding");
        shouldFastForward.current = true;
        // TODO: resume on page load (if revision is older) 
      } else {
        setInfo(initialInfo);
      }
    })();
  }, [key]);

  useEffect(() => {
    if (!authContext.localId) return;
    if (registeredTimeout.current) {
      clearTimeout(registeredTimeout.current);
    }
    registeredTimeout.current = setTimeout(async () => {
      registeredTimeout.current = undefined;
      if (authContext.token?.() && authContext.localId?.()) {
        // TODO: This will try to save before we've authed at the moment
        if (sessionStorage['rev:' + authContext.localId()] && sessionStorage['rev:' + authContext.localId()] !== sessionStorage['saved:' + authContext.localId()]) {
          const checkpointResponse = await checkpoint({
            form_name: 'apply',
            info: info || {},
          });
          if (checkpointResponse.status === 'ok') {
            sessionStorage['saved:' + authContext.localId()] = sessionStorage['rev:' + authContext.localId()];
          } else if (checkpointResponse.status === 'already_submitted') {
            logoutAndShowComplete();
          }
        }
      }
      console.log("Saving")
    }, 3000);
  }, [info]);

  const Submit = async (submit_key?: string) => {
    const resp = await submit({
      form_name: 'apply',
      info: (submit_key ? {...info, [submit_key]: (new Date()).toISOString()} : info) || {},
      language: context.lang,
      ...(submit_key ? { submit_key } : {})
    });
    if (resp.status === 'ok') {
      logoutAndShowComplete();
    }
    return resp;
  } 

  if (showComplete) {
    const main = <AllDoneComponent
      content={<></>}
      info={info}
      customSubmittedModal={applicationSections?.options?.custom_submitted_modal}
    />
    return publicConfig.experimental?.enableApplicantPortal ? <ThreeColumnPage main={main} nav={nav} Viewer={'applicant'} applicantPortal /> : main;
  }

  if (askToContinue) {
    return <ContinueModal restart={() => {
      sessionStorage.deleteItem('pendingInfo');
      setInfo({})
      setAskToContinue(false);
    }} continue={() => {
      const pendingInfo = JSON.parse(sessionStorage['pendingInfo']);
      shouldFastForward.current = true;
      setInfo(pendingInfo);
      setAskToContinue(false);
    }}/>
  }

  const main = <ModularQuestionPage 
    sections={applicationSections} 
    info={info} 
    setInfo={(newInfo) => {
      newInfo['_lang'] = context.lang;
      let id = authContext?.localId?.();
      if (!id) {
        id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
        authContext.setLocalId(id);
        const applyLang = params.get("lang") || context.lang;
        history.push('/apply/' + id + '?lang=' + applyLang);
      }

      newInfo._local_id = id;

      const newSerializedInfo = JSON.stringify(newInfo);
      if (sessionStorage['cache:' + id] != newSerializedInfo) {
        sessionStorage['cache:' + id] = newSerializedInfo;
        sessionStorage['rev:' + id] = sessionStorage['rev:' + id] ? parseInt(sessionStorage['rev:' + id]) + 1 : 1; 
      }
      setInfo(newInfo);
    }} 
    saveAuth={(auth) => {
      authContext.setToken(auth!);
    }}
    saveInfo={async (info) => {
      //console.log("SaveInfo");
    }}
    submit={Submit} 
    refreshInfo={async () => {
      shouldFastForward.current = true;
      let curResponse = await getCurrentInfo({ form_name: "apply" })
      if (curResponse.info) setInfo(curResponse.info);
    }}
    loadInfo={(info, token, submitted) => {
      if (submitted) {
        // Once an app is submitted it shouldn't be accessed from this page.
        logoutAndShowComplete();
        return;
      }
      // Redirect to a new local id
      const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
      authContext.setLocalId(id);
      authContext.setToken(token!);
      history.push('/apply/' + id);

      shouldFastForward.current = true;
      setInfo(info);
    }}
    shouldFastForward={shouldFastForward}
    sequential={true} 
    blockFurtherEdits={blockFurtherEdits.current}
    warnOnIncompleteSurvey={warnOnIncompleteSurvey.current}
  />
  if (publicConfig.experimental?.enableApplicantPortal) {
    return (
      <ThreeColumnPage nav={nav} main={main} Viewer={'applicant'} applicantPortal />
    );
  } else {
    return main;
  }
}

export default ApplicationPage;
