
import { Map, useToast } from "@aidkitorg/component-library";
import { SupportedLanguage, Translations } from "@aidkitorg/i18n/lib";
import * as survey from "@aidkitorg/types/lib/survey";
import { ArrowDownOnSquareIcon, ArrowPathIcon, ArrowsPointingOutIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import { TimedQueryResult } from "@aidkitorg/aidkit/lib/common/handler";
import React, { createContext, ReactNode, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { usePost } from "./API";
import { Dropdown } from "./Components/Dropdown";
import { ThreeColumnPage } from "./Components/ThreeColumnPage";
import InterfaceContext, { PublicConfigurationContext } from "./Context";
import { BarChart } from "./DistroDashboard/BarChart";
import { DataDictionary } from "./DistroDashboard/DataDictionary";
import { DistroDashboardTable } from "./DistroDashboard/DistroDashboardTable";
import { DashboardExplanationComponent } from "./DistroDashboard/ExplanationComponent";
import { FullScreenModal } from "./DistroDashboard/FullScreenModal";
import { HeroNumber } from "./DistroDashboard/HeroNumber";
import { MapRegionDensity } from "./DistroDashboard/MapRegionDensity";
import { PieChart } from "./DistroDashboard/PieChart";
import Queue from "./DistroDashboard/Queue";
import { useLocalizedStrings, useLocalizer } from "./Localization";
import { copyToClipboard, RigidSpinner, SpacedSpinner } from "./Util";
import PaymentDashboard from "./Components/PaymentDashboard";


export type TableComponentProps = {
  component: survey.ApplicantTable | survey.Table,
  downloadQualifier?: string,
  sqlButton: ReactNode,
  refreshButton: ReactNode,
  results?: any,
  L: Translations,
  dashboard: string | survey.Dashboard,
  localizedTitle?: string,
  linksToSection?: string | survey.SubsurveyRef,
  queryExecutionTimeMs?: number,
  filterConfig: Record<string, number>,
  lastUpdatedAt?: number,
};

export type DashboardComponentProps = {
  section: survey.DashboardSection['components'][number] & {
    sql?: string, results?: any, groupKeys?: any,
    summaryKey?: string, error?: string, dashboardRef?: string, stackKeys?: any, stack?: boolean,
    dashboardRefs?: Record<string, string>
  },
  updateFilterConfig: (path: string, index: number) => void,
  downloadQualifier?: string,
  publicAccessKey?: string,
  activeFilter?: survey.BooleanExpr,
  isExploreQuery?: boolean,
  linksToSection?: string | survey.SubsurveyRef,
  filterConfig: Record<string, number>,
  dashboard: string | survey.Dashboard,
  queueLoadCall?: (loadDashboardComponent: () => Promise<void>) => void,
  lang: SupportedLanguage,
}

function getCurrentUTCSuffix() {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = ("0" + (currentDate.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + currentDate.getUTCDate()).slice(-2);
  const hours = ("0" + currentDate.getUTCHours()).slice(-2);
  const minutes = ("0" + currentDate.getUTCMinutes()).slice(-2);
  const seconds = ("0" + currentDate.getUTCSeconds()).slice(-2);
  const formattedDate = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}Z`;
  return formattedDate;
}

export function TableComponent(props: TableComponentProps) {
  const [taskId, setTaskId] = useState<string | null>(null);
  const [downloadResponse, setDownloadResponse] = useState<{ status?: string; url?: string; error?: string; } | null>(null);
  const [downloadInProgress, setDownloadInProgress] = useState<'csv' | 'geojson' | null>(null);
  const { toast } = useToast();
  const context = useContext(InterfaceContext);

  const fetchAsyncDownload = usePost('/dashboard/fetch_async_download');
  const downloadData = usePost('/dashboard/download', { textOnly: true });
  const { component, results, sqlButton, refreshButton, L, localizedTitle } = props;

  const downloadAtPath = async (downloadPath: string, downloadName: string, format: 'csv' | 'geojson', asyncDownload?: boolean): Promise<string | null> => {
    if (asyncDownload) {
      const result = await downloadData({ dashboard: props.dashboard, filterIndexes: props.filterConfig, format, downloadPath, asyncDownload: true, lang: context.lang });
      const taskResponse = typeof result === 'string' ? JSON.parse(result) : result;
      return taskResponse.taskId;
    } else {
      const data = await downloadData({ dashboard: props.dashboard, filterIndexes: props.filterConfig, format, downloadPath });
      const dataTypeMap: Record<'csv' | 'geojson', string> = {
        'csv': 'text/csv',
        'geojson': 'application/json'
      };
      const blob = new Blob([data as any], { type: dataTypeMap[format] });
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = downloadName;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      return null;
    }
  };

  const handleDownloadClick = async (asyncDownload: boolean, format: 'csv' | 'geojson') => {
    try {
      setDownloadInProgress(format);
      const downloadTaskId = await downloadAtPath(
        component.download!.downloadPath || '',
        component.download!.filename + (props.downloadQualifier || '') + '_' + getCurrentUTCSuffix() + `.${format}`,
        format,
        asyncDownload
      );
      if (downloadTaskId) { // we only get a task id if it's an async download
        setTaskId(downloadTaskId);
      } else {
        setDownloadInProgress(null);
      }
    } catch (error) {
      toast({
        description: "Error downloading dashboard",
        variant: 'error'
      })
      setDownloadInProgress(null);
    }
  };

  const pollForDownload = useCallback(async () => {
    if (taskId) {
      const readyResponse = await fetchAsyncDownload({ taskId });
      if (readyResponse.url) {
        setDownloadResponse(readyResponse);
        setTaskId(null); // Stop polling
        setDownloadInProgress(null);
      } else if (readyResponse.status === 'FAILED') {
        setTaskId(null); // Stop polling
        setDownloadInProgress(null);
        throw new Error('async_download_failed');
      } else if (readyResponse.error) {
        setTaskId(null); // Stop polling
        setDownloadInProgress(null);
      }
    }
  }, [taskId, fetchAsyncDownload]);

  useEffect(() => {
    if (taskId) {
      const intervalId = setInterval(pollForDownload, 1000);
      return () => clearInterval(intervalId);
    }
  }, [taskId, pollForDownload]);

  useEffect(() => {
    if (downloadResponse?.url) {
      const link = document.createElement('a');
      link.href = downloadResponse.url!;
      link.setAttribute('download', component.download!.filename + (props.downloadQualifier || '') + '_' + getCurrentUTCSuffix() + '.csv'); // Set the filename for the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadResponse]);

  if (!results && !component.download?.downloadOnly) {
    return <div>{localizedTitle}{L.support.loading}</div>;
  }

  return <div className="mt-4 clear-both border-gray-300 border-solid border-l-4 border-r-0 border-y-0 px-4">
    {/* The actual table */}
    {!component.download?.downloadOnly && (
      <DistroDashboardTable records={results}
        component={component}
        extra={<>{sqlButton}{refreshButton}</>}
        title={localizedTitle || undefined}
        count={results.length}
        linksToSection={props.linksToSection}
        queryExecutionTimeMs={props.queryExecutionTimeMs}
        lastUpdatedAt={props.lastUpdatedAt}
      />
    )}
    {/* The download button card */}
    {component.download !== undefined && (
      <div className="my-4 mr-4 py-2 px-3 text-center bg-white overflow-hidden shadow rounded-xl w-fit">
        {component.download?.downloadOnly && (
          <span className="font-semibold flex justify-left items-end">
            {sqlButton}
            <span className="truncate text-gray-600 ml-2">{localizedTitle}</span>
          </span>
        )}
        <div className="clear-both">
          <div className="bg-white inline-block p-1 rounded-xl">{component.download.filename + '.csv'}
            {downloadInProgress === 'csv'
              ? <RigidSpinner className="mx-2" />
              : <button className="cursor-pointer ml-2 text-gray-500 hover:text-blue-500 bg-slate-50 hover:bg-blue-200 border-solid border-2 border-blue-200 hover:border-blue-400 rounded-xl p-2"
                onClick={() => handleDownloadClick(component.download?.asyncDownload ? true : false, 'csv')}>
                {L.dashboard.download}
                <span className="ml-2">
                  <ArrowDownOnSquareIcon width="20" height="20" className="inline"/>
                </span>
              </button>
            }
          </div>
        </div>
      </div>
    )}
    {component.kind === 'Applicant Table' && component.download && component.download.geoJSONDownload && (
      <div className="my-4 mr-4 py-2 px-3 text-center bg-white overflow-hidden shadow rounded-xl w-fit">
        <div className="clear-both">
          <div className="bg-white inline-block p-1 rounded-xl">{component.download.filename + '.geojson'}
            {downloadInProgress === 'geojson'
              ? <RigidSpinner className="mx-2" />
              : <button className="cursor-pointer ml-2 text-gray-500 hover:text-blue-500 bg-slate-50 hover:bg-blue-200 border-solid border-2 border-blue-200 hover:border-blue-400 rounded-xl p-2"
                onClick={() => handleDownloadClick(component.download?.asyncDownload ? true : false, 'geojson')}>
                {L.dashboard.download}
                <span className="ml-2">
                  <ArrowDownOnSquareIcon width="20" height="20" className="inline" />
                </span>
              </button>
            }
          </div>
        </div>
      </div>
    )
    }
  </div>
}

/**
 * An individual chart, table, number etc. on a Dashboard.
 *
 * We memoize the component so that it does not rerender as its parent executes
 * queries and updates its internal state to facilitate that work.
 */
export const DashboardComponent = React.memo((props: DashboardComponentProps) => {
  const { activeComponent } = useContext(ActiveComponentContext);
  const [selectedIdx, setSelectedIdx] = useState(props.section.kind === 'Dashboard Section' ? props.section.activeFilter || 0 : 0);
  const L = useLocalizedStrings();
  const localize = useLocalizer();
  const [componentIsLoading, setComponentIsLoading] = useState(false);
  const publicConfig = useContext(PublicConfigurationContext);
  // We display errors from the API in the dashboard components themselves, so the
  // toasts are redundant and can become overwhelming in certain circumstances 
  const loadDashboard = usePost('/dashboard/load', { handleErrors: () => {} });
  const [results, setResults] = useState<TimedQueryResult | null>(null);
  const [multipleResults, setMultipleResults] = useState<Record<string, TimedQueryResult> | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { toast } = useToast();

  function loadComponent(skipCache?: boolean) {
    // Don't try to load anything if we don't have the config or the experiment is off or the component doesn't have a token
    if (!publicConfig?.experimental?.instantLoadDashboards || (!props.section?.dashboardRef && !props.section?.dashboardRefs)) {
      return;
    }

    setComponentIsLoading(true);

    const loadComponentAsync = async () => {
      try {
        const multipleQueryKinds = ["Map"];

        if (multipleQueryKinds.includes(props.section.kind)) {
          const multiresults: Record<string, TimedQueryResult> | null = {};
          for (const [queryName, queryRef] of Object.entries(props.section.dashboardRefs || {})) {
            multiresults[queryName] = (await loadDashboard({ dashboardRef: queryRef!, publicAccessKey: props.publicAccessKey, skipCache, format: 'geojson' } as { dashboardRef: string, publicAccessKey?: string, skipCache?: boolean, format?: 'json' | 'geojson' })) as TimedQueryResult;
          }
          setMultipleResults(multiresults);
        } else {
          const results = (await loadDashboard({ dashboardRef: props.section.dashboardRef!, publicAccessKey: props.publicAccessKey, skipCache })) as TimedQueryResult;
          setResults(results);
        }
      } catch (e) {
        console.error('Error fetching dashboard: ', e);
        setResults({ rows: [], rowCount: 0, lastUpdated: 0, executionTimeMs: 0, error: 'Something went wrong. Try refreshing the page or coming back a few minutes'});
      } finally {
        setComponentIsLoading(false);
      }
    }

    // For cases where the component is part of a page, we send this callback to the parent
    // component which makes sure we don't try to execute more than some chosen number of queries
    // at a time. For cases like /explore, we just call loadDashboard ourselves immediately.
    if (props.queueLoadCall) {
      props.queueLoadCall(loadComponentAsync);
    } else {
      loadComponentAsync();
    }
  };

  useEffect(() => {
    loadComponent();
  }, [
    publicConfig.experimental?.instantLoadDashboards,
    props.queueLoadCall,
    props.section.dashboardRef,
    props.publicAccessKey,
    props.lang
  ]);

  let sqlButton = <></>;
  if (props.section.sql) {
    sqlButton = <CircleStackIcon className="w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer inline" onClick={() => {
      copyToClipboard(props.section.sql!);
      toast({ description: 'Copied SQL to clipboard', variant: 'success' });
    }} />
  }

  // /explore has its own intuitive way to refresh results (click "Run" button), so reduce
  // redundancy/complexity by not showing refresh button
  let refreshButton = <></>;
  if (!props.isExploreQuery) {
    refreshButton = <ArrowPathIcon className={`w-6 h-6 mr-2 ml-[7px] text-gray-400 hover:text-gray-500 cursor-pointer inline ${componentIsLoading ? 'animate-spin' : ''}`} onClick={() => loadComponent(true)}/>
  }

  const localizedTitle = localize(
    ('title' in props.section && props.section.title)
      ? props.section.title
      : (('visualization' in props.section && props.section.visualization.title)
        ? props.section.visualization.title
        : '')) || '';

  function DashboardSimpleCard(content: string) {
    // Set some reasonable heights/widths for the placeholder cards to minimize
    // screen jumping as components load.
    let height: string;
    let width = '';
    switch (props.section.kind) {
      case 'Bar Chart':
      case 'Pie Chart':
      case 'Map Region Density':
        height = (props.section.height ?? 300) + 132 + 'px';
        width = (props.section.width ?? 300) + 48 + 'px';
        break;
      case 'Applicant Table':
      case 'Queue':
        height = '150px';
        width = '100%';
        break;
      case 'Hero Count':
      case 'Hero Number':
        height = '150px';
        break;
      case 'Custom Query':
        switch (props.section.visualization.kind) {
          case 'Custom Bar Chart':
          case 'Custom Pie Chart':
            height = (props.section.visualization.height ?? 300) + 132 + 'px';
            width = (props.section.visualization.width ?? 300) + 48 + 'px';
            break;
          case 'Table':
            height = '150px';
            width = '100%';
            break;
          case 'Custom Number':
            height = '150px';
            break;
        }
        break;
      default:
        height = '150px';
        width = '150px';
    }

    const kind = props.section.kind === 'Custom Query'
      ? props.section.visualization.kind
      : props.section.kind;
    return (
      <div className="my-4 mr-4 float-left text-center bg-white overflow-hidden shadow rounded-lg relative" style={{height, width}}>
        <div className="px-4 py-4 sm:p-6">
          <dl>
            <dt className="font-semibold text-gray-600 truncate">
              <span>
                <span className="text-gray-400 mr-[7px]">({kind})</span>
                <ComponentHeader
                  sqlButton={sqlButton}
                  refreshButton={refreshButton}
                  localizedTitle={localizedTitle}
                />
              </span>
            </dt>
            <dd className="mt-1 font-medium text-gray-400">
              {content}
            </dd>
          </dl>
        </div>
      </div>
    );
  }

  // It doesn't make sense to expect there to be results if this is an explore query, or a download-only table.
  const useNewLoadStrategy = !props.isExploreQuery && !!publicConfig?.experimental?.instantLoadDashboards;

  // If there was an error with this component's SQL execution, surface it in a card and don't worry about what kind of component it is
  if (results?.error || props.section?.error) {
    return DashboardSimpleCard((results?.error ? results.error : props.section.error) || 'error');
  }

  const isBrf = window.location.hostname.split('.')?.[0] === 'brf';

  // DashboardComponents are recursive in the case of Dashboard Sections,
  // so we only want to display the "loading" card for non-sections.
  // Data Dictionaries don't require any immediate querying and so have no results.
  // Tables and Custom Queries are also not permitted on public dashboards (with the exception of BRF),
  // so we bypass this to remove those components entirely in the switch(kind) below.
  if (useNewLoadStrategy
      && !['Dashboard Section', 'Data Dictionary', 'Dashboard Explanation'].includes(props.section.kind)
            && !(props.section.kind === 'Applicant Table' && (props.publicAccessKey || props.section.download?.downloadOnly))
            && !(props.section.kind === 'Map')
            && !(props.section.kind === 'Custom Query'
              && ((props.publicAccessKey && (!isBrf || (isBrf && props.section.visualization.kind === 'Table')))
                || (props.section.visualization.kind === 'Table' && props.section.visualization.download?.downloadOnly)
              )
            )
            && ((!results) || componentIsLoading)) {
    return DashboardSimpleCard(L.support.loading);
  }

  if (useNewLoadStrategy && props.section.kind === 'Map' && !multipleResults) {
    return DashboardSimpleCard(L.support.loading);
  }

  const queryExecutionTimeSpan = results?.executionTimeMs
    ? <span className="text-gray-400 text-xs">{results.executionTimeMs}ms</span>
    : <></>;

  /**
     * null is returned if the element should not be shown, for example, on public dashboards
     */
  function InnerComponent(): JSX.Element | null {
    switch (props.section.kind) {
      case 'Dashboard Section':
        let suffix: string | undefined;
        let activeFilter: survey.BooleanExpr | undefined;
        if (props.section.filters?.length > 0) {
          suffix = '_' + props.section.filters[selectedIdx].name.replace(' ', '_').toLowerCase();
          props.section.filters[selectedIdx].filter;
        }

        return <div className="mt-4 clear-both border-gray-300 border-solid border-l-4 border-r-0 border-y-0 px-4 relative flow-root">
          <h2>{localizedTitle}</h2>
          {props.section.filters?.length > 0 && (
            <div className="block">
              {/* <Dropdown> uses inline-block for its outermost element, which might be necessary for other
                              * use-cases, but can cause the dropdown to end up in odd places in Dashboards. Hence the surrounding <div>
                              */ }
              <Dropdown
                description="Filter"
                direction="right"
                label={<>{props.section.filters[selectedIdx].name}</>}
                options={
                  props.section.filters.map((f: { name: any; }, i: number) => {
                    return {
                      label: f.name,
                      callback: () => {
                        if (i !== selectedIdx) {
                          setSelectedIdx(i);
                          props.updateFilterConfig((props.section as survey.DashboardSection).filterPath!, i)
                        }
                      }
                    }
                  })}
              />
            </div>
          )}
          {/* Display semi-opaque overlay while component is loading after changing filter */}
          {(activeComponent === (props.section.filterPath ?? '') + selectedIdx) &&
                        <div className="absolute -inset-2 bg-gray-500 opacity-25 flex justify-center z-20">
                          <div className="pt-5 text-white text-6xl"><SpacedSpinner style={{ width: "3rem", height: "3rem" }} />{' ' + L.support.loading}</div>
                        </div>
          }
          {props.section.components?.map((c: any, i: number) => {
            return <DashboardComponent
              key={c.kind + i}
              section={c}
              updateFilterConfig={props.updateFilterConfig}
              downloadQualifier={(props.downloadQualifier || '') + (suffix || '')}
              publicAccessKey={props.publicAccessKey}
              activeFilter={activeFilter}
              filterConfig={props.filterConfig}
              dashboard={props.dashboard}
              queueLoadCall={props.queueLoadCall}
              lang={props.lang}
            />
          })}
        </div>
      case 'Applicant Table':
        if (props.publicAccessKey) {
          return null;
        }
        return <TableComponent
          component={props.section}
          results={useNewLoadStrategy ? results?.rows : props.section.results}
          downloadQualifier={props.downloadQualifier}
          sqlButton={sqlButton}
          refreshButton={refreshButton}
          L={L}
          localizedTitle={localizedTitle}
          linksToSection={props.linksToSection}
          queryExecutionTimeMs={results?.executionTimeMs}
          dashboard={props.dashboard}
          filterConfig={props.filterConfig}
          lastUpdatedAt={results?.lastUpdated}
        />;
      case 'Custom Query':
        if (props.publicAccessKey && (!isBrf || (isBrf && props.section.visualization.kind === 'Table'))) {
          return null;
        }
        switch (props.section.visualization.kind) {
          case 'Table':
            return <TableComponent
              component={props.section.visualization}
              results={useNewLoadStrategy ? results?.rows : props.section.results}
              downloadQualifier={props.downloadQualifier}
              sqlButton={sqlButton}
              refreshButton={refreshButton}
              L={L}
              localizedTitle={localizedTitle}
              linksToSection={props.linksToSection}
              queryExecutionTimeMs={results?.executionTimeMs}
              dashboard={props.dashboard}
              filterConfig={props.filterConfig}
              lastUpdatedAt={results?.lastUpdated}
            />;
          case 'Custom Number':
            let valueToUse = props.section.results;
            if (useNewLoadStrategy) {
              if (results?.rowCount !== 1) {
                // TODO: localize
                return DashboardSimpleCard('Invalid count query. Did not return exactly one row');
              }
              const rowKeys = Object.keys(results.rows[0]);
              if (rowKeys.length !== 1) {
                // TODO: localize
                return DashboardSimpleCard('Invalid count query. Did not return exactly one column');
              }
              valueToUse = results.rows[0][rowKeys[0]];
            }
            return <HeroNumber
              value={valueToUse}
              prefix={props.section.visualization.prefix}
              suffix={props.section.visualization.suffix}
              useCommas={props.section.visualization.useCommas}
              decimalPlaces={props.section.visualization.decimalPlaces}
            />;
          case 'Custom Bar Chart':
            return <BarChart
              data={useNewLoadStrategy ? results?.rows : props.section.results}
              description={props.section?.visualization?.description?.content?.[props.lang]}
              descriptionPlacement={props.section.visualization?.description?.placement}
              groupKeys={props.section.visualization.groupKeys}
              summaryKey={props.section.visualization.summaryKey}
              isCustom={true}
              hideXAxisLabels={props.section.visualization.hideXAxisLabels}
              height={props.section.visualization.height}
              width={props.section.visualization.width}
              stack={props.section.visualization.stack}
              totalKey={props.section.visualization.totalKey}
              hideTotal={props.section.visualization.hideTotal}
              units={props.section.visualization.units}
              isExpanded={isExpanded}
              numberFormat={props.section.visualization.numberFormat}
              showBarLabels={props.section.visualization.showBarLabels}
            />
          case 'Custom Pie Chart':
            return <PieChart
              description={props.section.visualization?.description?.content?.[props.lang]}
              descriptionPlacement={props.section.visualization?.description?.placement}
              data={useNewLoadStrategy ? results?.rows : props.section.results}
              groupKeys={props.section.visualization.groupKeys}
              summaryKey={props.section.visualization.summaryKey}
              totalKey={props.section.visualization.totalKey}
              hideTotal={props.section.visualization.hideTotal}
              isCustom={true}
              height={props.section.visualization.height}
              width={props.section.visualization.width}
              pieStyle={props.section.visualization.pieStyle || publicConfig?.interface?.defaultPieChartStyle}
              isExpanded={isExpanded}
            />
        }
      case 'Dashboard Explanation':
        return <DashboardExplanationComponent explanation={props.section} />
      case 'Data Dictionary':
        return <DataDictionary component={props.section} />;
      case 'Hero Count':
        return <HeroNumber
          value={useNewLoadStrategy ? results?.rowCount : props.section.results}
          valueToCompare={(useNewLoadStrategy ? results : props.section as any).originalResults}
          prefix={props.section.prefix}
          suffix={props.section.suffix}
          useCommas={props.section.useCommas}
          decimalPlaces={props.section.decimalPlaces}
        />;
      case 'Hero Number':
        return <HeroNumber
          value={useNewLoadStrategy
            ? results?.rows && results.rows.length > 0 ? results.rows[0][props.section.summaryKey ?? ''] : ''
            : props.section.results}
          prefix={props.section.prefix}
          suffix={props.section.suffix}
          useCommas={props.section.useCommas}
          decimalPlaces={props.section.decimalPlaces}
        />
      case 'Bar Chart':
        let timeSeriesGroupIndex = props.section.groups.findIndex(group => group.kind === 'Bucket Date');
        return <BarChart
          data={useNewLoadStrategy ? results?.rows : props.section.results}
          description={props.section.description?.content?.[props.lang]}
          descriptionPlacement={props.section.description?.placement}
          groupKeys={props.section.groupKeys}
          summaryKey={props.section.summaryKey!}
          stackKeys={props.section.stackKeys}
          stack={props.section.stack}
          timeSeriesGroupIndex={timeSeriesGroupIndex}
          timeSeriesBucket={(props.section.groups[timeSeriesGroupIndex] as any)?.bucket}
          order={props.section.order}
          hideTotal={props.section.hideTotal}
          height={props.section.height}
          width={props.section.width}
          units={props.section.units}
          isExpanded={isExpanded}
          numberFormat={props.section.numberFormat}
          showBarLabels={props.section.showBarLabels}
        />
      case 'Pie Chart':
        return <PieChart
          description={props.section?.description?.content?.[props.lang]}
          descriptionPlacement={props.section.description?.placement}
          data={useNewLoadStrategy ? results?.rows : props.section.results}
          groupKeys={props.section.groupKeys}
          summaryKey={props.section.summaryKey!}
          height={props.section.height}
          width={props.section.width}
          pieStyle={props.section.pieStyle || publicConfig?.interface?.defaultPieChartStyle}
          isExpanded={isExpanded}
        />
      case 'Queue':
        return <Queue
          component={props.section}
          sqlButton={sqlButton}
          refreshButton={refreshButton}
          numberInQueue={(useNewLoadStrategy ? results?.rowCount : props.section.numberInQueue) ?? 0}
          queryExecutionTimeMs={queryExecutionTimeSpan}
          publicAccessKey={props.publicAccessKey}
          localizedTitle={localizedTitle}
          activeFilter={props.activeFilter}
          lastUpdatedAt={results?.lastUpdated}
        />
      case 'Map Region Density':
        // the query can only return a single row, which will be a FeatureCollection of all the matched boundaries
        const { geojson } = (useNewLoadStrategy ? results?.rows?.[0] : props.section.results?.[0]) || {};
        return <MapRegionDensity {...props.section} geoJSON={geojson} />
      case 'Payment Dashboard':
        return <PaymentDashboard component={props.section} results={useNewLoadStrategy ? results?.rows : props.section.results} />;
      case 'Map':
        const data = useNewLoadStrategy ? multipleResults : props.section.results;

        const sources = props.section.sources.map((source) => {
          // If the data source is GeoJSON (raw or hosted), we will use it as-is and 
          // does not require any additional transformation. 
          if (source.kind !== 'Applicant Data Source') return source;
          if (!data) return source;

          // If the data source is Applicant Data, we need to use 
          // the results from the dashboard db query. The results will have
          // separate data sets (results from multiple db queries; one query per map layer).
          const geojson = data[source.layerName];

          // Create a synthetic RawGeoJSONSource from the Applicant Data Source
          // to translate DB query results to something the <Map /> component can consume
          return {
            ...source,
            kind: 'Raw GeoJSON Source',
            data: JSON.stringify(geojson.rows),
            // Remove Applicant Data Source specific fields
            columns: undefined,
            filter: undefined,
            addressField: undefined,
            includeUrl: undefined,
            includeUid: undefined
          } as survey.RawGeoJSONSource;
        })

        return <Map mapConfig={{
          ...props.section,
          sources: sources
        }} />
      default:
        return <p>{`${props.section.kind} ${L.dashboard.not_supported_yet}`}</p>
    }
  }

  const innerDashboardComponent = InnerComponent();

  const kind = props.section.kind === 'Custom Query'
    ? props.section.visualization.kind
    : props.section.kind;
  if (['Dashboard Section', 'Applicant Table', 'Table', 'Queue', 'Dashboard Explanation', 'Data Dictionary', 'Payment Dashboard'].includes(kind)
            || innerDashboardComponent === null) {
    // Undefined means the element should not be shown, for example, on public dashboards
    return innerDashboardComponent || <></>;
  }
  const expandable = ['Pie Chart', 'Bar Chart', 'Custom Pie Chart', 'Custom Bar Chart', 'Map Region Density'].includes(kind);
  const fullscreen = ['Map'].includes(kind);

  if (fullscreen) {
    return <div className="w-full h-screen">{innerDashboardComponent}</div>
  }

  return (
    <div className="my-4 mr-4 py-2 px-3 float-left text-center bg-white overflow-hidden shadow rounded-xl">
      <ComponentHeader
        sqlButton={sqlButton}
        refreshButton={refreshButton}
        localizedTitle={localizedTitle}
        expandable={expandable ? { expanded: isExpanded, setIsExpanded } : undefined}
      />
      {innerDashboardComponent}
      <div className="flex justify-between pr-2" title="Query execution time">
        {(publicConfig.experimental?.showLastUpdatedOnDashboards && results?.lastUpdated) && (
          <span className="text-gray-400 text-xs">
            {L.dashboard.updated} {new Date(results.lastUpdated).toLocaleTimeString([], { timeZoneName: 'short' })}
          </span>
        )}
        {queryExecutionTimeSpan}
      </div>
      {expandable && (
        <FullScreenModal open={isExpanded} onClose={() => setIsExpanded(false)}>
          <div className="w-full h-full flex flex-col items-center justify-center">
            <dl className="w-full">
              <ComponentHeader
                sqlButton={sqlButton}
                refreshButton={refreshButton}
                localizedTitle={localizedTitle}
              />
            </dl>
            {innerDashboardComponent}
            <div className="flex justify-end pr-2" title="Query execution time">
              {queryExecutionTimeSpan}
            </div>
          </div>
        </FullScreenModal>
      )}
    </div>
  );
});

export function ComponentHeader(props: {
  sqlButton?: ReactNode,
  refreshButton?: ReactNode,
  localizedTitle?: string,
  expandable?: {
    expanded: boolean,
    setIsExpanded: (value: SetStateAction<boolean>) => void
  },
}) {
  return (
    <span className="font-semibold flex justify-between items-end m-2">
      <span>{props.sqlButton}{props.refreshButton}</span>
      <span className="truncate text-gray-600">{props.localizedTitle}</span>
      <span>{props.expandable && <ArrowsPointingOutIcon className="h-6 w-6 text-gray-400 hover:text-gray-500 hover:cursor-pointer" onClick={() => props.expandable!.setIsExpanded(true)} />}</span>
    </span>
  );
}

const ActiveComponentContext = createContext({ activeComponent: null as string | null });

export function DistroDashboard(props: { dashboard?: string, isEmbedded?: boolean }): JSX.Element {
  const params = new URLSearchParams(window.location.search);
  const publicAccessKey = params.get("key") ?? params.get("dashboardKey") ?? undefined;
  const config = useContext(PublicConfigurationContext);
  const context = useContext(InterfaceContext);
  const { path } = useParams() as Record<string, string>
  const runQuery = usePost('/dashboard/view');
  const getDashboard = usePost('/dashboard/getDashboards');
  const L = useLocalizedStrings();
  const localize = useLocalizer();
  const [results, setResults] = useState<survey.Dashboard | null>(null);
  const [filterConfig, setFilterConfig] = useState<Record<string, number>>({});
  const [loadedPath, setLoadedPath] = useState<string | null>(null);
  // This is passed to children to let them know whether to display the "Loading..." overlay
  const [activeComponent, setActiveComponent] = useState<string | null>(null);
  // This is used by the main dashboard to indicate if it is currently executing any queries
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const activeRequestsRef = useRef(0);
  const maxActiveRequestsRef = useRef(10)
  const loadComponentCallbacks = useRef<(() => Promise<void>)[]>([]);
  const [configLoaded, setConfigLoaded] = useState(false);

  // Tracks the number of queries that are currently in progress and prevents more than
  // a chosen number from being executed at once. As queries finish, new ones are pulled
  // off the queue to be run.
  function processQueue() {
    if (activeRequestsRef.current < maxActiveRequestsRef.current && loadComponentCallbacks.current.length > 0) {
      const nextRequest = loadComponentCallbacks.current.shift()!;
      activeRequestsRef.current++;

      nextRequest().finally(() => {
        activeRequestsRef.current--;
        processQueue();
      });
    }
  }

  useEffect(() => {
    // Object.keys check because the config can be {} before it is loaded
    if (config && Object.keys(config).length > 0) {
      setConfigLoaded(true);
    }
  }, [config]);

  useEffect(() => {
    // Don't try to load anything until the config has been loaded so we know which method to use.
    if (!configLoaded) {
      return;
    }

    setIsRunning(true);
    (async () => {
      let results: any;
      if (config?.experimental?.instantLoadDashboards) {
        results = await getDashboard({ dashboard: props.dashboard ?? path, filterIndexes: filterConfig, publicAccessKey, lang: context.lang });
        if (results && 'maxConcurrentQueries' in results && results.maxConcurrentQueries > 0) {
          maxActiveRequestsRef.current = results.maxConcurrentQueries;
        }
      } else {
        results = await runQuery({ dashboard: props.dashboard ?? path, filterIndexes: filterConfig, key: publicAccessKey, lang: context.lang });
      }

      setResults(results);
      setLoadedPath(path);
      setActiveComponent(null);
      setIsRunning(false);
    })();
  }, [filterConfig, path, config?.experimental?.instantLoadDashboards, configLoaded]);

  const updateFilterConfig = useCallback((path: string, idx: number) => {
    setActiveComponent(path + idx);
    setFilterConfig((prev) => ({...prev, [path]: idx}))
  }, []);

  // This function is passed to individual components of this dashboard.
  // Instead of loading their data directly, they pass their load function through it to be added
  // to the queue. This way we can let the individual components define what to call and what to do
  // with the response, but this parent can monitor and limit how many active requests there are to
  // prevent huge dashboards from hammering the DB on page load.
  const enqueueRequest = useCallback((loadComponent: () => Promise<void>) => {
    loadComponentCallbacks.current.push(loadComponent);
    processQueue();
  }, []);

  const isFullscreen = (dashboard: survey.Dashboard): boolean => {
    return 'fullscreen' in dashboard && typeof dashboard.fullscreen === 'boolean' && dashboard.fullscreen;
  };

  return (props.isEmbedded || publicAccessKey)
    ? <div className="h-full p-4 px-8">
      {(results && results.components) && (
        <>
          <div>
            <h1 className="inline">{localize(results.title)}</h1>
            {isRunning && <SpacedSpinner className="ml-3 inline" style={{ width: "2rem", height: "2rem" }} />}
          </div>
          <ActiveComponentContext.Provider value={{activeComponent}}>
            {results.components.map((s, i) => {
              return <DashboardComponent
                key={s.kind + i}
                section={s}
                linksToSection={(results as any).linksToSection}
                updateFilterConfig={updateFilterConfig}
                filterConfig={filterConfig}
                publicAccessKey={publicAccessKey}
                dashboard={ path }
                queueLoadCall={enqueueRequest}
                lang={context.lang}
              />
            })}
          </ActiveComponentContext.Provider>
        </>
      )}
    </div>
    : <ThreeColumnPage main={
      <div>
        {(path === loadedPath && results && results.components) && (
          <div className={isFullscreen(results) ? "" : "bg-gray-100 p-4 px-8 mb-3"}>
            {!isFullscreen && (
              <div>
                <h1 className="inline">{localize(results.title)}</h1>
                {isRunning && <SpacedSpinner className="ml-3 inline" style={{ width: "2rem", height: "2rem" }} />}
              </div>
            )}
            <ActiveComponentContext.Provider value={{activeComponent}}>
              {results.components.map((s, i) => {
                return <DashboardComponent
                  key={s.kind + i}
                  section={s}
                  linksToSection={(results as any).linksToSection}
                  updateFilterConfig={updateFilterConfig}
                  filterConfig={filterConfig}
                  dashboard={ path }
                  queueLoadCall={enqueueRequest}
                  lang={context.lang}
                />
              })}
            </ActiveComponentContext.Provider>
          </div>
        )}
        {(!results || path !== loadedPath) && <><SpacedSpinner /> {L.dashboard.loading_message}</>}
      </div>}
    />;
}
