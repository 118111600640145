import { CompileNudgeExprToSQL, CompileExpressionToSQL, findFields } from "@aidkitorg/types/lib/translation/expr_to_sql";
import { useState, useEffect, useMemo, useCallback } from "react";
import { usePost } from "../API";
import { DistroExprLink, DistroPreviewModal } from "../Components/ExploreLink"
import { ExpandedSurvey, Explore, TrackedLink } from "@aidkitorg/types/lib/survey";
import { Button, Switch } from "@aidkitorg/component-library";
import { Stats, SqlViewer, NotificationSimulation } from "./CommonComponents";
import { EligibilityChecker } from "./EligibilityChecker";
import { Followup } from "./Followup";
import { EnableKey } from "./EnableKey";
import { useDebouncedCallback } from "../Hooks/Debounce";
import { findAndGoToComponent } from "../ConfigSearch";

function NotificationDetail({task, survey, enableEmailMarkdownGlobally}: { task: NonNullable<ExpandedSurvey['notifications']>[number] & { searchTree?: string[] }, survey: ExpandedSurvey['survey'], enableEmailMarkdownGlobally: boolean }) {
  let checkNotification = usePost('/program/admin/test_expression');
  let simulateNotifications = usePost('/program/admin/simulate_notifications');
  let [simulatedCount, setSimulatedCount] = useState<Awaited<ReturnType<typeof checkNotification>> | null>(null);
  let [simulatedMessage, setSimulatedMessage] = useState<Awaited<ReturnType<typeof simulateNotifications>> | null>(null);
  let [liveUpdating, setLiveUpdating] = useState<null | "simulation" | "query">(null);
  let [simulating, setSimulating] = useState(false);
  let [checking, setChecking] = useState(false);
  let [testContactMethod, setTestContactMethod] = useState<string>('sms');
  const [preview, setPreview] = useState(false);
  const [showEligibility, setShowEligibility] = useState(false);

  useEffect(() => {
    if (['email', 'sms', 'whatsapp'].includes(task.contactMethod)) {
      setTestContactMethod(task.contactMethod);
    }
  }, [task.contactMethod]);

  const checkNotificationQuery = useCallback(async () =>{
    setChecking(true);
    const resp = await checkNotification({
      query: task.initial_notification.enabled_when,
      orderBy: task.initial_notification.enabled_when.kind !== 'SQL' ? task.initial_notification.enabled_when.orderBy : undefined,
      sampleCount: 1,
      ...(task.recipient === 'Unsubmitted Applicant' ?
        {
          nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
          targetField: task.targetPrefix + '_' + testContactMethod
        } : {})
      
    });

    setSimulatedCount(resp);

    if (resp && !('error' in resp)) {
      const simulResp = await simulateNotifications({
        uids: resp.sample,
        simulateAllLangs: true,
        content: task.initial_notification.message,
        emailContent: task.initial_notification.email_message,
        emailKey: typeof task.recipient === 'object' ? task.recipient.emailField : 'email',
        phoneKey: typeof task.recipient === 'object' ? task.recipient.phoneField : 'phone_number',
        enableEmailMarkdown: !!task.enableEmailMarkdown || enableEmailMarkdownGlobally,
        links: (task.initial_notification.subsurveys || []).reduce((obj, curr) => {
          obj[curr.variable] = curr.name;
          return obj
        }, {} as Record<string, string | TrackedLink>)
      })
      setSimulatedMessage(simulResp);
    }
    
    setChecking(false);
  },[task, enableEmailMarkdownGlobally])
  const debouncedCheckNotificationQuery = useDebouncedCallback(() => checkNotificationQuery(), 2000, [checkNotificationQuery]);

  const simulate = useCallback(async () => {
    setSimulating(true);
    setSimulatedMessage(await simulateNotifications({
      uids: task.testUIDs || [],
      emailKey: typeof task.recipient === 'object' ? task.recipient.emailField : '',
      phoneKey: typeof task.recipient === 'object' ? task.recipient.phoneField : '',
      content: task.initial_notification.message,
      emailContent: task.initial_notification.email_message,
      enableEmailMarkdown: !!task.enableEmailMarkdown || enableEmailMarkdownGlobally,
      links: (task.initial_notification.subsurveys || []).reduce((obj, curr) => {
        obj[curr.variable] = curr.name;
        return obj
      }, {} as Record<string, string | TrackedLink>)
    }));
    setSimulating(false);
  }, [task, enableEmailMarkdownGlobally])
  const debouncedSimulate = useDebouncedCallback(() => simulate(), 2000, [simulate]);
  useEffect(() => {
    if (liveUpdating === "simulation") {
      setSimulating(true);
      debouncedSimulate();
    }
    if (liveUpdating === "query") {
      setChecking(true);
      debouncedCheckNotificationQuery();
    }
  }, [JSON.stringify(task), enableEmailMarkdownGlobally, liveUpdating])


  const sql = useMemo(() =>
    task.initial_notification.enabled_when.kind === 'SQL' ?
      task.initial_notification.enabled_when.sql :
      task.recipient === 'Unsubmitted Applicant' ?
        CompileNudgeExprToSQL({
          cond: task.initial_notification.enabled_when.expr || task.initial_notification.enabled_when,
          nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
          targetField: task.targetPrefix + '_' + testContactMethod
        }) :
        CompileExpressionToSQL({
          cond: task.initial_notification.enabled_when.expr || task.initial_notification.enabled_when,
          orderBy: task.initial_notification.enabled_when.orderBy
        })
  , [task]);

  const query: Explore = useMemo(() => {
    const name = task.name;
    const expanded = task.initial_notification.enabled_when;
    if (expanded.kind === 'Click') {
      let fields: string[] = findFields(expanded.expr);
      if (!fields.length) {
        fields = ['legal_name'];
      }

      return {
        query: {
          kind: 'Applicant Table',
          filter: expanded,
          columns: fields.map(f => ({ kind: 'Field', field: f })),
          title: { en: name },
          download: {
            filename: name
          }
        }
      };
    } else {
      return {
        query: {
          kind: 'Custom Query',
          sql: expanded.sql,
          visualization: {
            kind: 'Table',
            title: { en: name },
            download: {
              filename: name
            }
          }
        }
      };
    }
  }, [task]);

  const goToNotification = async (notificationName: string) => {
    const tab = document.getElementsByTagName('distro-editor')[0].shadowRoot!.querySelector("[data-tab-name=notifications]");
    if (tab) tab.dispatchEvent(new Event('click', { bubbles: true }));
    await new Promise(r => setTimeout(r, 50));
    document.getElementsByTagName('distro-editor')[0].shadowRoot!
      .querySelector(`[data-name="${notificationName}"]`)?.scrollIntoView({ block: 'center' });
  }

  return <div className="border border-gray-300 p-2 space-y-2">
    <div className="space-x-2 flex">
      <a href="#" onClick={() => {
        if (task.searchTree) findAndGoToComponent(task.searchTree, survey)
        else goToNotification(task.name);
      }}>
        <h3>{task.name} ({task.targetPrefix})</h3>
      </a>
      <DistroExprLink name={task.name} expr={task.initial_notification.enabled_when} />
    </div>
    {task.kind !== 'InlineNotification' ?
      <EnableKey component={task} />
      : <b>Inline Notif</b>}
    <Stats stats={[
      ...(simulatedCount ? [{ name: 'Initially Eligible', stat: simulatedCount.count?.toString() || 'Error' }] : []),
    ]} />
    <ul className="flex space-x-1">
      <li>
        <Button
          variant="primary"
          size="sm"
          onClick={()=>setLiveUpdating(liveUpdating === "query" ? null : "query")}
          loading={checking}
        >
          {checking ? 'Checking...' : liveUpdating === "query" ? 'Watching for changes...' : 'Check Query'}
        </Button>
      </li>
      <li>
        <Button
          variant="secondary"
          onClick={()=>setLiveUpdating(liveUpdating === "simulation" ? null : "simulation")}
          size="sm"
          loading={simulating}
        >
          {simulating ? 'Simulating...' : liveUpdating === "simulation" ? 'Watching for changes...' : 'Simulate Formatted Notification'}
        </Button>
      </li>
      <li>
        <Button variant="secondary" size="sm" onClick={() => setPreview(true)}>
          <span className="size-fit">Preview Explore</span>
        </Button>
      </li>
    </ul>
    {task.recipient === 'Unsubmitted Applicant' && !(['email', 'sms', 'whatsapp'].includes(task.contactMethod)) &&
      <select className="ml-2 inline-flex items-center shadow-sm overflow-hidden bg-white px-2.5 py-1.5 text-black text-xs font-medium text-base text-gray-700 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded"
        onChange={(e) => setTestContactMethod(e.target.value)}>
        <option value="sms">SMS</option>
        <option value="email">Email</option>
        <option value="whatsapp">WhatsApp</option>
      </select>
    }
    <NotificationSimulation contactMethod={task.contactMethod} enableEmailMarkdown={task.enableEmailMarkdown || enableEmailMarkdownGlobally} simulated={simulatedMessage} simulationSource={liveUpdating} />
    <Switch label="Show Eligibility" onCheckedChange={setShowEligibility} />
    <EligibilityChecker cond={task.initial_notification.enabled_when} show={showEligibility} />
    <SqlViewer sql={sql} />
    <DistroPreviewModal query={query} open={preview} onClose={setPreview} />
    {((task.kind !== 'InlineNotification' && task.followups) || []).map((followup) => {
      return <Followup task={task} flup={followup} key={followup.suffix} enableEmailMarkdownGlobally={enableEmailMarkdownGlobally} />
    })}
  </div>
}

export function NotificationsView({tasks, survey, enableEmailMarkdownGlobally}: { tasks: ExpandedSurvey['notifications'], survey: ExpandedSurvey['survey'], enableEmailMarkdownGlobally: boolean | undefined }) {
  if (!tasks?.length) {
    return <div className="mt-4 ml-1">No notifications yet!</div>
  }
  return <>{tasks?.map((t) => <NotificationDetail key={t.name + t.targetPrefix} task={t} survey={survey} enableEmailMarkdownGlobally={!!enableEmailMarkdownGlobally} />)}</>
}
