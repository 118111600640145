import { CommonProperties, Collection, Section, CrossProgramDataExchange, Macro, CollectionComponent, SectionComponent, Persona, Dashboard, DashboardComponent, BooleanExpr, GenericTemplatedBlock, SummaryExpr, ValueExpr, Query, Lookup, Block, ApplicantPortalPage } from "@aidkitorg/types/src/survey";
import { Conditional } from "./translation/expr_to_sql";

// may not contain ALL elements,
// but this is purely for crawling 
export type Countable =
  BooleanExpr
  | Dashboard
  | DashboardComponent
  | Persona
  | CollectionComponent
  | SectionComponent
  | Section
  | Collection
  | CrossProgramDataExchange
  | Macro
  | SummaryExpr
  | ValueExpr
  | Conditional
  | Query
  | Lookup['lookup']
  | GenericTemplatedBlock<any>;

export type PathNode = ArrayNode | RecordNode;

// A node that would be INSIDE an array
export type ArrayNode = {
  kind: 'array';
  matches: {
    [key: string] : string | number
  }
}

// A node that would be a value inside an object
export type RecordNode = {
  kind: 'record';
  name: string;
}

export function traverseForKind<T extends { kind: string }>(node: unknown, kind: T['kind']): (T & CommonProperties)[] {
  return traverseForKinds(node, [kind]);
}

export function traverseForKindsWithPath<T extends { kind: string }, E extends { kind: string } = any>
(node: unknown, kinds: T['kind'][], pathSoFar: PathNode[], ignore?: E['kind'][]): ({item: T & CommonProperties, path: PathNode[]})[] 
{
  let result: {item: T & CommonProperties, path: PathNode[]}[] = [];

  const visitor = (item: any, path?: PathNode[]) => {
    if (kinds.includes(item.kind!)) {
      result.push({ item: item, path: path || [] });
    }
  }
  crawlSurveyKinds(node,
    visitor,
    item => !!ignore?.includes(item.kind!),
    pathSoFar
  );
  return result;
}

export function traverseForKinds<T extends { kind: string }, E extends { kind: string } = any>(node: unknown, kinds: T['kind'][], ignore?: E['kind'][]): (T & CommonProperties)[] {
  let result: T[] = [];
  crawlSurveyKinds(node,
    item => {
      if (kinds.includes(item.kind!)) {
        result.push(item as T);
      }
    },
    item => !!ignore?.includes(item.kind!)
  );
  return result;
}

// Helper function to add new nodes to the path we are building. 
// Added for improved code readability.
function buildNewPath(newNode: PathNode, pathSoFar?: PathNode[]) {
  if (!pathSoFar) return;

  return [...pathSoFar, newNode];
}

export function crawlSurveyKinds(
  elem: any, 
  visitor: (item: Countable, path?: PathNode[]) => void, 
  skip?: (item: Countable) => boolean, 
  pathSoFar?: PathNode[]): void
{
  if (typeof elem !== 'object') {
    return;
  }

  if (Array.isArray(elem)) {
    const filtered = elem.filter(e => !skip?.(e));
    for (let i = 0; i < filtered.length; i++) {
      const newPath = buildNewPath({
        kind: 'array',
        matches: {
          index: i,
        }
      } as ArrayNode, pathSoFar);

      crawlSurveyKinds(filtered[i], visitor, skip, newPath);
    }
  } else {
    for (const k of Object.keys(elem)) {
      const v = elem[k];
      const newObjPath = buildNewPath({
        kind: 'record',
        name: k,
      } as RecordNode, pathSoFar);
  
      if (typeof v === 'object' && !skip?.(v)) {
        if (Array.isArray(v)) {
          const filtered = v.filter(e => !skip?.(e));
          for (let i = 0; i < filtered.length; i++) {
            if (typeof filtered[i] === 'object') {
              const newPath = buildNewPath({
                kind: 'array',
                matches: {
                  index: i,
                }
              } as ArrayNode, newObjPath);
              crawlSurveyKinds(filtered[i], visitor, skip, newPath);
            }
          }
        } else {
          crawlSurveyKinds(v, visitor, skip, newObjPath);
        }
      }
    };

    if (elem.kind && !skip?.(elem)) {
      visitor(elem, pathSoFar);
    }
  }
}

export function transform(elem: Countable, visitor: (item: Countable) => Countable): Countable {

  for (const [k, v] of Object.entries(elem)) {
    if (typeof v === 'object') {
      if (Array.isArray(v)) {
        (elem as any)[k] = v.map(i => transform(i, visitor)).filter(i => i)
      } else {
        (elem as any)[k] = transform(v, visitor);
      }
    }
  }

  if (elem.kind) {
    return visitor(elem);
  } else {
    return elem;
  }
}

/**
 * @param components Block[] | ApplicantPortalPage[] // Can be updated to include other types as needed.
 * @returns set of all target fields that are used in the components
 */
export function extractTargetFieldsFromComponents(components: Block[] | ApplicantPortalPage[]): Set<string> {
  const fields = new Set<string>();

  function traverse(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(traverse);
    } else if (typeof obj === 'object' && obj !== null) {
      if (obj.field) {
        fields.add(obj.field);
      }
      if (obj.targetField) {
        fields.add(obj.targetField);
      }
      Object.values(obj).forEach(value => {
        if (typeof value === 'string') {
          const matches: RegExpMatchArray | null = value.match(/\$[a-zA-Z0-9_]+/g);
          if (matches) {
            matches.forEach(match => fields.add(match.substring(1)));
          }
        } else {
          traverse(value);
        }
      });
    }
  }

  traverse(components);
  return fields;
}
