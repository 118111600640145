import { useToast, Tooltip } from "@aidkitorg/component-library";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useLocalizedStrings } from "../Localization";
import { copyToClipboard } from "../Util";
import { HasEnableKey, calcEnableKey } from "@aidkitorg/types/lib/eval";
import { hash } from "@aidkitorg/types/lib/translation/v0_to_legacy";

export function EnableKey({ component, parts }: { component: HasEnableKey, parts?: string[] }) {
  const expected = useMemo(() => Array.isArray(parts) ? hash(parts.join('')) : calcEnableKey(component), [component, parts]);
  const expectedLegacy = useMemo(() => Array.isArray(parts) ? hash(parts.join('')) : calcEnableKey(component, { version: 'legacy' }), [component, parts]);
  const L = useLocalizedStrings();
  const { toast } = useToast();

  function copy(str: string) {
    const { dismiss } = toast({
      description: `Hash ${L.copied_to_clipboard}`
    });
    copyToClipboard(str);
    setTimeout(() => dismiss(), 1500);
  }

  return <>
    <b>{expected === component.enableKey
      ? 'Enabled'
      : (expectedLegacy === component.enableKey) 
        ? 'Enabled (legacy)' 
        : 'Not Enabled. Key is: ' + expected}</b>
    <Tooltip text="Copy Hash to clipboard">
      <Square2StackIcon className="align-super w-5 h-5 cursor-pointer" onClick={() => copy(expected)} />
    </Tooltip>
  </>
}