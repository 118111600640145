import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
export const OverlayPopup = memo(({ popupInfo }) => {
    const { layers } = popupInfo;
    if (!layers || layers.length === 0) {
        return null;
    }
    return (_jsx("div", { className: "space-y-3", children: layers.map((layerData, i) => {
            if (!layerData.rows.length)
                return null;
            return (_jsxs("div", { children: [_jsx("h2", { className: "font-bold mb-1 text-base", children: layerData.layerName }), _jsx("table", { className: "w-full border-collapse border border-gray-400 mt-1", children: _jsx("tbody", { children: layerData.rows.map(({ displayName, value }, rowIndex) => (_jsxs("tr", { className: `border border-gray-300 ${rowIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}`, children: [_jsx("td", { className: "px-2 py-1 text-sm font-bold border border-gray-300 break-words whitespace-normal", children: displayName }), _jsx("td", { className: "px-2 py-1 text-sm border border-gray-300 break-words whitespace-normal", children: value })] }, rowIndex))) }) })] }, i));
        }) }));
});
