import React, { useState, useEffect, useCallback, useContext } from "react";
import { usePost } from "../API";
import { useHistory } from "react-router-dom";
import { CalendarIcon, ClipboardDocumentIcon, CheckCircleIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import { CustomDateTimePicker } from "../Components/DateTimePicker";
import { useApplicantLocalization, useLocalizedStrings, useLocalTimeZoneId, useProgramTimeZoneId } from "../Localization";
import { RigidSpinner, copyToClipboard } from "../Util";
import { timezones } from '@aidkitorg/types/lib/timezones';
import InterfaceContext, { PublicConfigurationContext } from '../Context';
import { type Meeting, type MeetingRow } from '@aidkitorg/aidkit/lib/application/meeting';
import { LANG_DICTS, VALID_LANGS } from "../Localization";
import { useToast } from "@aidkitorg/component-library";
import { TimeZoneId } from "../utils/time";

type DeliveryMethod = 'sms' | 'email' | 'whatsapp';

/**
 * This converts a MeetingRow -> Meeting, which includes converting date ISO strings to JS Dates
 * @param row 
 * @returns Meeting
 */
export function parseMeetingRow(row:MeetingRow) : Meeting {
  const parsed : any = {};
  for(const dateKey of ['created_at', 'scheduled_start_time', 'started_at', 'completed_at'] as (keyof Meeting)[]){
    if(row[dateKey]){
      parsed[dateKey] = new Date(row[dateKey]! as string) as any;
    }
  }
  if(typeof row.metadata === 'string'){
    parsed.metadata = JSON.parse(row.metadata);
  }
  return {
    ...row,
    ...parsed
  };
}

export function ApplicantMeetings(props: { applicantId: string; info: any;}){  
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [loading, setLoading] = useState(false);
  const listMeetings = usePost('/applicant/meeting/list');
    
  const fetchMeetings = useCallback(
    async function _fetchMeetings(){
      setLoading(true);
      const meetingRows = await listMeetings({
        applicant: props.applicantId
      });    
      setMeetings(meetingRows.map(parseMeetingRow));
      setLoading(false);
    },
    [props.applicantId]
  )

  useEffect(() => {
    (async () => await fetchMeetings())();
  },[])
    
  return <>
    <ApplicantMeetingScheduler {...props} fetchMeetings={fetchMeetings} />
    <ApplicantMeetingsList {...props} fetchMeetings={fetchMeetings} meetings={meetings} loading={loading} />
  </>
}

export function ApplicantMeetingScheduler(props: { applicantId: string; info: any; fetchMeetings: () => any}){
  const L = useLocalizedStrings();
  const timeZoneId = useProgramTimeZoneId() || useLocalTimeZoneId();
  const [title, setTitle] = useState('');
  const [scheduledTime, setScheduledTime] = useState<Date | null>(new Date(Date.now() + (3600 * 1000 - Date.now() % (3600 * 1000))));
  const [shouldSendMessage, setShouldSendMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>('sms');
  const [loading, setLoading] = useState(false);
  const applicantLocalization = useApplicantLocalization(props.info);
  const createMeeting = usePost('/applicant/meeting/create');
          
  let now = new Date();
  const minDate = now;
  const maxDate = new Date(minDate.getTime() + 1000 * 60 * 60 * 24 * 90);

  const hasAllDataForSchedule = scheduledTime && title && (!shouldSendMessage || (message && deliveryMethod));

  const messageComponents : {
    message: string;
    divider: string;
    time: string;
    link: string;
  } = {
    message: message || '<enter message above>',
    divider: '===',
    // localize to the applicant's language/locale
    time: `${LANG_DICTS[applicantLocalization.lang].applicant.meeting.scheduled_time}: ${ scheduledTime ? new Intl.DateTimeFormat(applicantLocalization.locale, { weekday: 'short', hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'short', year: 'numeric', timeZoneName: 'short', timeZone: timeZoneId }).format(scheduledTime) : '' }`,
    link: `${LANG_DICTS[applicantLocalization.lang].applicant.meeting.join_meeting}: $link`
  };

  const messageFull = [
    messageComponents.message,
    messageComponents.divider,
    messageComponents.time,
    messageComponents.link
  ].join('\n');

  const handleMeetNow = async () => {
    const newWindow = window.open;
    setLoading(true);
    const response = await createMeeting({
      applicant: props.applicantId,
      title,
      message: [
        messageComponents.message,
        messageComponents.divider,
        messageComponents.link
      ].join('\n'),
      deliveryMethod
    });
    if ((response as any)?.error) {
      // on error, do nothing, let toast handle it
    }
    else if(response.uid) {
      // on success, navigate to meeting lobby in new tab
      newWindow(`/meeting/${response.uid}`);
    }
    setLoading(false);
  };

  const handleSchedule = async () => {
    setLoading(true);
    const response = await createMeeting({
      applicant: props.applicantId,
      title,
      scheduledTime: scheduledTime!.toISOString(),
      message: messageFull,
      deliveryMethod
    });
    if ((response as any)?.error) {
      // on error, do nothing, let toast handle it
    }
    else {
      // on success, clear values
      setScheduledTime(null);
      setTitle('');
      setMessage('');
      setDeliveryMethod('sms');
      props.fetchMeetings();
    }
    setLoading(false);
  };

  const formInputClasses = "appearance-none mr-2 mt-1 shadow-sm block w-100 overflow-auto pl-3 bg-white pr-2 py-2 text-black text-base border-2 border-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md";

  return <form 
    className="flex flex-col gap-4"
    onSubmit={e => { e.preventDefault(); handleSchedule(); }}>
    <div>
      <label>{L.applicant.meeting.meeting_title}<strong>*</strong></label>
      <textarea 
        className={formInputClasses} 
        value={title}
        placeholder={L.applicant.meeting.meeting_with_name.replace('$name', props.info.legal_name || 'Applicant')} 
        onChange={e => setTitle(e.target.value as any)}
      >
        {title}
      </textarea>
    </div>

    <div>
      <label htmlFor="meetings-datetime">{L.applicant.meeting.meeting_date_and_time}</label>
      <CustomDateTimePicker
        id="meetings-datetime"
        initialValue={scheduledTime?.getTime() ?? (now.getTime() + (3600 * 1000 - now.getTime() % (3600 * 1000)))}
        timeZoneId={timeZoneId as TimeZoneId}
        referenceTimeZoneId={useLocalTimeZoneId() as TimeZoneId}
        after={minDate.getTime()}
        before={maxDate.getTime()}
        onChange={(d) => setScheduledTime(new Date(d))} />
    </div>

    <div className="flex flex-nowrap items-center gap-1 leading-0">
      <label className="m-0 cursor-pointer" htmlFor="should-send-message">{L.applicant.meeting.send_message_to_applicant}</label>
      <input 
        id="should-send-message"
        type="checkbox"
        checked={shouldSendMessage}   
        className="form-checkbox h-4 w-4 cursor-pointer shrink-0"
        onChange={e => setShouldSendMessage(e.target.checked)} />
    </div>

    <div className={"flex flex-col gap-4"}>
      <div>
        <label className={`${!shouldSendMessage ? 'text-gray-500' : ''}`}>{L.applicant.meeting.meeting_message}{shouldSendMessage ? '*' : ''}</label>
        <textarea 
          className={formInputClasses} 
          disabled={!shouldSendMessage}
          value={message}
          placeholder={L.applicant.meeting.meeting_message_description} 
          onChange={e => setMessage(e.target.value as any)}>
          {message}
        </textarea>
      </div>
      <div>
        <select 
          aria-label={L.applicant.meeting.message_type}
          disabled={!shouldSendMessage}
          className={formInputClasses + ` ${!shouldSendMessage ? 'text-gray-500' : ''}`}
          onChange={(e => setDeliveryMethod(e.target.value as DeliveryMethod))}>
          <option value="sms">{L.applicant.comms.send_text}</option>
          <option value="email">{L.applicant.comms.send_email}</option>
          { props.info['whatsapp'] && <option value="whatsapp">{L.applicant.comms.send_whatsapp}</option> }
        </select>
      </div>

      {shouldSendMessage && <div>
        <label htmlFor="message-preview">{L.applicant.meeting.message_preview}</label>
        <textarea id="message-preview" className="bg-gray-200 w-full p-2 ring-2 text-sm h-36" disabled={true} value={messageFull}></textarea>
        <p className="text-xs">{L.applicant.meeting.message_preview_disclaimer.replaceAll('$scheduled_time', L.applicant.meeting.scheduled_time).replaceAll('$meet_now', L.applicant.meeting.meet_now)}</p>
      </div>}
    </div>

    <div className="flex justify-end gap-2">
      <button 
        className="inline-flex px-4 text-sm items-center py-2 text-white font-medium bg-green-700 hover:bg-green-800 rounded-md text-gray-700 disabled:bg-gray-400 disabled:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        disabled={!hasAllDataForSchedule || loading || (!message && shouldSendMessage)}
        onClick={e => { e.preventDefault(); handleMeetNow(); }}>
        {L.applicant.meeting.meet_now} <VideoCameraIcon className="h-6 w-6 ml-1" />
      </button>

      <button type="submit" 
        className="inline-flex px-4 text-sm items-center py-2 text-white font-medium bg-indigo-600 hover:bg-indigo-700 rounded-md text-gray-700 disabled:bg-gray-400 disabled:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        disabled={!hasAllDataForSchedule || loading || (!message && shouldSendMessage)}>
        {L.applicant.meeting.schedule_a_meeting} { loading ? <RigidSpinner className="h-6 w-6 ml-1" /> : <CalendarIcon className="h-6 w-6 ml-1" /> }
      </button>
    </div>

  </form>;
}

export function MeetingRecordingItem(props: { index: number; meeting: Meeting; recording: NonNullable<Meeting['recordings']>[number]; }){    
  const L = useLocalizedStrings();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setURL] = useState<string | null>(null);
  const getRecordingUrl = usePost('/meeting/recording_url');

  async function fetchRecordingURL(){
    setIsLoading(true);
    const response = await getRecordingUrl({
      meetingId: props.meeting.uid,
      key: props.recording.key,
    });
    if(response.url){
      setURL(response.url);
    }
    setIsLoading(false);
  }

  return <div className="meeting-recording-item">
    {
      !!url 
        ? <a 
          href={url}
          target="_blank"
          download
          className="text-white font-medium bg-green-600 hover:bg-green-700 rounded-md disabled:bg-gray-400 disabled:opacity-75 text-xs py-1 pl-2 pr-2">{L.applicant.reports.download}</a>
        : <button 
          disabled={isLoading}
          className="text-white font-medium bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:opacity-75 text-xs py-1 pl-2 pr-2"
          onClick={() => fetchRecordingURL()}>{L.applicant.meeting.get_download_link}</button>

    }        
  </div>
}

export function ApplicantMeetingRow(props: { i: number; meeting: Meeting; fetchMeetings: () => any }){
  const L = useLocalizedStrings();
  const [guestURL, setGuestURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [didCopy, setDidCopy] = useState(false);
  const { toast } = useToast();

  const createGuestLink = usePost('/applicant/meeting/guest_link');
  const endMeeting = usePost('/meeting/end');
  async function handleCreateGuestLink(){
    setLoading(true);
    const { url } = await createGuestLink({
      meetingId: props.meeting.uid
    });
    setDidCopy(false);
    setLoading(false);
    setGuestURL(url);
  }

  const classes = ['applicant-meeting-row flex flex-col gap-1 py-2 border-gray-300 border-solid border-t-0 border-l-0 border-r-0 border-b-0'];
  if(props.i>0){
    classes.push('border-t-2')
  }

  async function handleEndMeeting(){
    await endMeeting({
      meetingId: props.meeting.uid,
      endForAll: true
    });
    props.fetchMeetings();
  }

  async function handleClickClipboard(){
    try {
      copyToClipboard(guestURL); 
      toast({ description: L.copied_to_clipboard, variant: 'success' })
      setDidCopy(true);
    }
    catch(_){}
  }

  return <div key={props.i} className={classes.join(' ')}>
    <div className="flex"><div className="font-bold mr-2">Title:</div> {props.meeting.title}</div>
    {
      props.meeting.scheduled_start_time && <div className="flex">
        <div className="font-bold mr-2">{L.applicant.meeting.scheduled_time}:</div> {new Date(props.meeting.scheduled_start_time.getTime()).toLocaleDateString(navigator.language)} {new Date(props.meeting.scheduled_start_time.getTime()).toLocaleTimeString(navigator.language, { timeZoneName: 'short'})}
      </div>
    }
    {
      props.meeting.started_at && <div className="flex">
        <div className="font-bold mr-2">{L.applicant.meeting.start_time}:</div> {new Date(props.meeting.started_at.getTime()).toLocaleDateString()} {new Date(props.meeting.started_at.getTime()).toLocaleTimeString()}
      </div>
    }

    {
      props.meeting.completed_at && <div className="flex">
        <div className="font-bold mr-2">{L.applicant.meeting.completed_time}:</div> {new Date(props.meeting.completed_at.getTime()).toLocaleDateString()} {new Date(props.meeting.completed_at.getTime()).toLocaleTimeString()}
      </div>
    }

    {
      props.meeting.metadata?.attendees && <div className="flex">
        <div className="font-bold mr-2">{L.applicant.meeting.number_of_attendees}:</div> {Object.values(props.meeting.metadata.attendees).length}
      </div>
    }

    {
      !!props.meeting.recordings?.length && <div>
        <div className="font-bold">{L.applicant.meeting.recordings}:</div>
        <ul className="flex flex-col gap-2 mt-2">
          { 
            props.meeting.recordings!.map(
              (r, i) => <li key={i}><MeetingRecordingItem meeting={props.meeting} index={i} recording={r} /></li>
            ) 
          }
        </ul>    
      </div>
            
    }

    { 
      !props.meeting?.completed_at && <div className="flex gap-2">            
        <a target="_blank" className="text-white bg-green-700 hover:bg-green-800 rounded-md disabled:bg-gray-400 disabled:opacity-75 hover:no-underline text-xs py-1 pl-2 pr-2 text-xs py-1 font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href={`/meeting/${props.meeting.uid}`}>{L.applicant.meeting.join_meeting}</a>
        <button 
          disabled={loading}
          className="text-white bg-indigo-600 hover:bg-indigo-700 rounded-md disabled:bg-gray-400 disabled:opacity-75 text-xs py-1 pl-2 pr-2 text-xs py-1 font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => handleCreateGuestLink()}>
          {L.applicant.meeting.create_guest_link}
        </button>
        <button
          className="text-white bg-red-600 hover:bg-red-700 rounded-md disabled:bg-gray-400 disabled:opacity-75 text-xs py-1 pl-2 pr-2 text-xs py-1 font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => handleEndMeeting()}>
          {L.applicant.meeting.end_meeting}
        </button>
      </div>
    }
        
    { 
      loading && <RigidSpinner className="ml-1 h-2" /> 
    }
        
    {
      (!loading && !!guestURL) && <div className="flex flex-nowrap items-stretch mt-1">
        <div className="rounded-l-md p-1 bg-gray-200 border-gray-400 border cursor-pointer" onClick={() => handleClickClipboard()}>
          {
            didCopy
              ? <CheckCircleIcon className="h-6" />
              : <ClipboardDocumentIcon className="h-6" />
          }
        </div>
        <div className="rounded-r-sm p-1 text-xs bg-gray-100 border-gray-400 border grow nowrap flex items-center">
          <div>{guestURL}</div>
        </div>
      </div>
    }
  </div>
}

export function ApplicantMeetingsList(props: { applicantId: string; info: any; meetings: Meeting[]; fetchMeetings: () => any; loading: boolean}){
  const L = useLocalizedStrings();

  const [completedMeetings, uncompletedMeetings] = props.meetings.reduce(
    (acc, meeting) => {
      acc[meeting.completed_at ? 0 : 1].push(meeting);
      return acc;
    },
    [[],[]] as [Meeting[], Meeting[]]
  );

  if(props.loading){
    return <RigidSpinner />
  }

  return <div className="flex flex-col gap-4 mt-8">
    {
      uncompletedMeetings.length > 0 && <section>
        <h3 className="text-xl font-bold">{L.applicant.meeting.uncompleted_meetings}</h3>
        <div className="flex flex-col">
          { uncompletedMeetings.map((meeting, i) => <ApplicantMeetingRow key={i} i={i} meeting={meeting} {...props} />) }
        </div>
      </section>
    }
    {
      completedMeetings.length > 0 && <section>
        <h3 className="text-xl font-bold">{L.applicant.meeting.completed_meetings}</h3>
        <div className="flex flex-col">
          { completedMeetings.map((meeting, i) => <ApplicantMeetingRow key={i} i={i} meeting={meeting} {...props} />) }
        </div>
      </section>
    }
        
  </div>
}
