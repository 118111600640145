import { useState } from "react";
import { useToast } from "@aidkitorg/component-library";
import { usePost } from "../API";
import { ClickableButton } from "../Components/Button";

export function RunRoboScreenerSection() {
  const [status, setStatus] = useState<string | null>(null);
  const runRoboScreener = usePost("/admin/schedule_one_off_roboscreener_run");
  const { toast } = useToast();

  const handleClick = async () => {
    const response = await runRoboScreener({});

    if ('error' in response) {
      setStatus(response.error);
    } else {
      setStatus(response.status);
      toast({
        title: response.status,
        description: "RoboScreener may take a while, check the applicants page to see when it's done.",
        variant: "success",
        duration: 20000,
      });
    }
  };

  return <div className="mt-4">
    <p className="text-sm">Running RoboScreener from this page will not affect when the cron job runs</p>
    <ClickableButton color={status ? "gray" : "green"} disabled={!!status} onClick={handleClick}>
      {status ?? "Run RoboScreener for this program"}
    </ClickableButton>
  </div>
}
