import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { Button } from "../../Button";
import { Icon } from "../../Icon";
import { LayerPreview } from "./LayerPreview";
import { DEFAULT_ZOOM_INCREMENT, DEFAULT_ZOOM_LEVEL, MAX_ZOOM_LEVEL } from "../data";
import { useLocalizedStrings } from "../../LocalizationProvider";
export const FeatureDetails = memo(({ selectedFeature, mapInstanceRef, previousFeatureRef, setSelectedFeature, styleFunction }) => {
    if (!selectedFeature)
        return null;
    const L = useLocalizedStrings();
    const featureProps = selectedFeature.getProperties();
    const source = selectedFeature.get("sourceData");
    if (!source || !source?.properties) {
        return null;
    }
    const handleZoom = () => {
        if (!mapInstanceRef.current || !selectedFeature)
            return;
        const map = mapInstanceRef.current;
        const view = map.getView();
        const geometry = selectedFeature.getGeometry();
        if (!geometry)
            return;
        const coordinates = geometry.getCoordinates();
        const currentZoom = view.getZoom() ?? DEFAULT_ZOOM_LEVEL;
        const newZoom = Math.min(currentZoom + DEFAULT_ZOOM_INCREMENT, MAX_ZOOM_LEVEL);
        view.animate({
            center: coordinates,
            zoom: newZoom,
            duration: 500,
        });
    };
    const handleClose = () => {
        if (previousFeatureRef.current) {
            const prevFeature = previousFeatureRef.current;
            const source = prevFeature.get("sourceData");
            if (source) {
                prevFeature.setStyle(styleFunction({
                    feature: prevFeature,
                    colorMapping: source.colorMapping,
                    defaultFillColor: source.fillColor,
                    defaultStrokeColor: source.strokeColor,
                    pointConfig: source.pointConfig,
                }));
            }
        }
        setSelectedFeature(null);
        previousFeatureRef.current = null;
    };
    return (_jsxs("div", { className: "\n      bg-white border border-gray-300 shadow-md rounded-md overflow-y-auto break-words space-y-2\n      p-[0.5rem] text-xs m-1\n      sm:p-[0.75rem] sm:text-sm\n      md:max-w-md md:text-base       \n    ", children: [_jsxs("div", { className: "overflow-x-auto", children: [_jsxs("div", { className: "flex justify-between gap-x-8 mb-2", children: [_jsxs("div", { className: "flex items-center flex-1", children: [_jsx(LayerPreview, { source: source, size: "large", feature: selectedFeature, styleFunction: styleFunction }), _jsx("h2", { className: "font-bold break-words text-base m-0", children: source.layerName || "Location Details" })] }), _jsx(Button, { icon: _jsx(Icon, { name: "XMarkIcon", role: "decorative", type: "solid" }), "aria-label": "Close", onClick: handleClose, variant: "secondary", size: "sm" })] }), source.properties
                        .filter((prop) => prop.type === "url")
                        .map(({ key, displayName }) => featureProps[key] ? (_jsx("div", { className: "mb-2", children: _jsx(Button, { variant: "link", openInNewWindow: true, href: featureProps[key], children: displayName }) }, key)) : null), _jsx("table", { className: "w-full border border-gray-300 text-xs sm:text-sm mb-[1rem]", children: _jsx("tbody", { children: source.properties
                                .filter((prop) => prop.type !== "url")
                                .map(({ key, displayName }, index) => featureProps[key] !== undefined ? (_jsxs("tr", { className: `${source.properties?.length === 1
                                    ? "bg-white"
                                    : index % 2 === 0
                                        ? "bg-gray-50"
                                        : "bg-white"} border border-gray-200`, children: [_jsx("td", { className: "px-2 py-1 font-medium text-gray-700 border border-gray-200", children: displayName }), _jsx("td", { className: "px-2 py-1 border border-gray-200", children: featureProps[key] })] }, key)) : null) }) })] }), _jsx("div", { className: "sm:inline-block hidden w-full", children: _jsx(Button, { className: "w-full", variant: "primary", onClick: handleZoom, icon: _jsx(Icon, { name: "MagnifyingGlassPlusIcon", role: 'decorative' }), children: L.component_library.map.zoom }) }), _jsx("div", { className: "sm:hidden", children: _jsx(Button, { className: "w-full", variant: "primary", size: "sm", onClick: handleZoom, icon: _jsx(Icon, { name: "MagnifyingGlassPlusIcon", role: 'decorative' }), children: L.component_library.map.zoom }) })] }));
});
