import { Control } from "ol/control";
export class ZoomControl extends Control {
    constructor(map) {
        const element = document.createElement("div");
        element.className =
            "absolute bottom-12 right-1 flex flex-col space-y-2 bg-white shadow-lg rounded-lg p-1";
        element.innerHTML = `
        <button id="zoom-in" class="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center bg-white hover:bg-gray-200 text-black rounded font-bold text-xl transition duration-200">+</button>
        <button id="zoom-out" class="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center bg-white hover:bg-gray-200 text-black rounded font-bold text-xl transition duration-200">−</button>
    `;
        super({ element });
        element.querySelector("#zoom-in")?.addEventListener("click", () => {
            const view = map.getView();
            const currentZoom = view.getZoom() ?? 4;
            view.animate({
                zoom: currentZoom + 1,
                duration: 200,
            });
        });
        element.querySelector("#zoom-out")?.addEventListener("click", () => {
            const view = map.getView();
            const currentZoom = view.getZoom() ?? 4;
            view.animate({
                zoom: currentZoom - 1,
                duration: 200,
            });
        });
    }
}
