import React, { useEffect, useRef, useContext, ContextType, useCallback, useMemo } from "react";
import { marked } from "marked";
import { Badge, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import InterfaceContext, { SupportedLanguage, OnlineAwarenessContext, OfflineStatus, OfflineSyncContext } from "./Context";
import * as v0 from "@aidkitorg/types/lib/survey";
import type { Option } from "./Questions/Choice";
import { PublicConfig } from "@aidkitorg/types/lib/config";
import { createLogger, DefaultColors, DefaultStyles, offlineLogger } from "./utils";
import { SyncState, SyncStateEvent, SyncStatus, toApplicant } from "./offline/routes";
import { captureException } from "@sentry/react";
import { getOrCreateHttpCache, PermaCache, RequestEntry } from "./offline";
import { ChangeSet } from "@aidkitorg/typesheets/lib/collab";
import { useAsyncEffect } from "./Hooks/AsyncEffect";

export const BUTTON_CLASS = "cursor-pointer shadow mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"

export const mapStrToList = (str: string) => {
  return str.replace(/\n/g, " ").replace(/,/g, " ").split(" ").filter((l: string) => l.length > 0).map(t => t.trim());
}

export default function SlideOut(props: { title: string, content: HTMLDivElement | null, open: boolean, setOpen: (open: boolean) => void }) {
  return (
    <Transition.Root show={props.open} as={React.Fragment}>
      <Dialog as="div" className="z-50 fixed inset-0 overflow-hidden" onClose={props.setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">{props.title}</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white border-0 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => props.setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div ref={(el) => props.content && el?.appendChild(props.content)} className="absolute inset-0 px-4 sm:px-6" />
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function NodeWithSlideout(props: { content: string }) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false);

  function toggle(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    console.log((e.target as HTMLElement).previousSibling?.previousSibling?.nodeName);
    if ((e.target as HTMLElement).previousSibling?.previousSibling?.nodeName === "HR") {
      let toShow = (e.target as HTMLElement).nextSibling;
      const newContent = document.createElement('div');
      while (toShow && toShow.nodeName !== "HR") {
        newContent.appendChild(toShow.cloneNode(true));
        toShow = toShow.nextSibling;
      }
      setContent(newContent);
      setTitle((e.target as HTMLElement).innerText);
      setOpen(true);
    }
  }

  return <>
    <div onClick={toggle} className="markdown" dangerouslySetInnerHTML={{ __html: props.content }}></div>
    <SlideOut title={title} content={content} open={open} setOpen={setOpen} />
  </>;

}

export function nonHookMarkdown(str?: string,
  collapsible?: boolean,
  noParagraphs?: boolean,
  replaceWithHtml?: {
    regex: RegExp, html: string
  },
  textAlign?: string) {

  if (!str) {
    return <div className="group group-hover:bg-blue"></div>;
  }

  str = str.replace(/\*\*([^*\n]+)[\v\f \u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]\*\*/g, '**$1** ');
  str = str.replace(/\*\*[\v\f \u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]([^*\n]+)\*\*/g, ' **$1**');
  str = str.replace(/_([^_\n]+)[\v\f \u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]_/g, '_$1_ ');
  str = str.replace(/_[\v\f \u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]([^_\n]+)_/g, ' _$1_');

  const renderer = new marked.Renderer();
  const linkRenderer = renderer.link;
  renderer.link = (paramhref: string | null, title: any, text: any) => {
    const href = paramhref ? paramhref : '';
    if (href.indexOf('https://www.youtube.com/watch?v=') === 0) {
      const matches = href.match(/https:\/\/www.youtube.com\/watch\?v=([^&]+)/);
      const id = matches ? matches[1] : '';
      if (!id) return `<a href="${href}">${text}</a>`;

      return `<iframe width="100%" height="315"
       src="https://www.youtube.com/embed/${id}"
       title="YouTube video player" frameborder="0"
       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    };

    const AUDIO = ['mp3', 'wav', 'ogg', 'flac', 'aac', 'm4a'];
    for (const ext of AUDIO) {
      if (text === '$audio' || href.toLowerCase().endsWith("." + ext)) {
        return `<audio controls src="${href}" />`;
      }
    }

    if (href.indexOf('https://explain?') === 0 || href.indexOf('https://explain/?') === 0) {
      let div = document.createElement('div');
      div.innerText = text;
      const escaped = div.textContent;
      div.innerText = decodeURIComponent(href.split('?')[1]);
      const escaped2 = div.textContent;
      return `<span tabIndex=0 class="inline-block relative flex-col items-center group">
        <span class="info cursor-pointer border-t-0 border-l-0 border-r-0 border-b-2 border-dashed border-gray-300">${escaped}</span>
        <span class="absolute bottom-0 flex flex-col items-center mb-6 hidden group-focus:flex group-hover:flex z-50">
          <span class="relative z-10 p-2 text-xs text-white w-60 -ml-10 whitespace-no-wrap bg-black shadow-lg">${escaped2}</span>
          <span class="w-3 h-3 -mt-2 -ml-10 transform rotate-45 bg-black"></span>
        </span>
      </span>`;
    }
    let html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `);
  };

  const firstHeader = {
    found: false,
    title: '',
    level: ''
  }

  if (collapsible) {
    const hrenderer = renderer.heading;
    renderer.heading = (text, level, raw, slugger) => {
      let html = hrenderer.call(renderer, text, level, raw, slugger);
      if (firstHeader.found) {
        return html;
      }
      firstHeader.found = true;
      //html =  html.replace(/^<h[1-6] /, `<h${level} class="bg-blue-100" `);
      firstHeader.title = html;
      firstHeader.level = 'h' + level;
      return '';
    }
  }

  let html = marked(str, { renderer, headerIds: false, mangle: false });
  if (firstHeader.found) {
    html = `<button class="border-0 px-0 bg-white align-${textAlign} text-${textAlign}" aria-expanded="false">${firstHeader.title}</button><div>${html}</div>`;
  }
  if (noParagraphs) {
    html = html.replace(/<p>/g, '').replace(/<\/p>/g, '');
  }
  if (replaceWithHtml) {
    html = html.replace(replaceWithHtml.regex, replaceWithHtml.html);
  }
  return <div className="markdown" dangerouslySetInnerHTML={{ __html: html }}></div>
}

export function useMarkdown(str?: string,
  collapsible?: boolean,
  noParagraphs?: boolean,
  replaceWithHtml?: {
    regex: RegExp, html: string
  }
) {
  const context = useContext(InterfaceContext);

  return nonHookMarkdown(str, collapsible, noParagraphs, replaceWithHtml, context.textAlign);
}

export function useInterval(callback: any, time: number) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if ((window as any).hidden) return;
      const memoizedCallback = savedCallback.current as any;
      memoizedCallback();
    }
    if (time !== null) {
      let id = setInterval(tick, time);
      return () => clearInterval(id);
    }
  }, [time]);
}

export function useAsyncInterval<T extends () => Promise<void>>(callback: T, millis: number) {

  const tick = useCallback(async () => {
    if (document.hidden) return;
    await callback();
  }, [callback]);

  // Set up the interval.
  useAsyncEffect(async (cancel) => {
    if (millis !== null) {
      let id = setInterval(tick, millis);
      cancel.onabort = () => clearInterval(id);
    }
  }, [tick, millis]);
}

function copyToClipboardFallback(str: string) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export function copyToClipboard(str: string) {
  // navigator.clipboard is undefined unless inSecureContext (https)
  // so this won't work on dev safari, but will work in prod safari. (crosses fingers)
  if (navigator.clipboard !== undefined) {
    navigator.clipboard.writeText(str).then(() => {
    }, () => {
      copyToClipboardFallback(str);
    });
  } else {
    copyToClipboardFallback(str);
  }
}

export function snakeToEnglish(str: string) {
  return (str || '')
    .replace(/_([-0-9a-z])/g, (c) => " " + c.slice(1).toUpperCase())
    .replace(/^[-a-z]/g, (c) => c.toUpperCase());
}

export type SupportedLanguageContent = "English Content" | "Spanish Content" | SupportedLanguage;
type LegacySupportedLanguage = "english" | "spanish" | "";

export function languageContent(lang?: SupportedLanguage | LegacySupportedLanguage): SupportedLanguageContent {
  if (lang === 'es' || lang === 'spanish') {
    return 'Spanish Content';
  } else if (lang === 'en' || lang === 'english' || !lang) {
    return 'English Content';
  }
  return lang;
}

export function safeParse(json: string, safeReturn?: any) {
  try {
    return JSON.parse(json);
  } catch (e) {
    if (safeReturn) return safeReturn;
    console.warn("Error with safe parse", e, json);
    return {};
  }
}

export function safeParseUrl(maybeUrlString: string, orElse?: URL) {
  try {
    return new URL(maybeUrlString);
  } catch (e) {
    console.warn('safeParseUrl failed with error:', e);
    return orElse;
  }
}

// Get formula from legacy (metadata is plain string) or v0 (metadata is object) Validated types.
export function safeParseValidatedFormula(metadata: string) {
  try {
    if (JSON.parse(metadata).formula) {
      return JSON.parse(metadata).formula;
    } else {
      return ''
    }
  } catch (e) {
    return metadata;
  }
}

export function delimitNumbers(str: string) {
  return (str + "").replace(/\b(\d+)((\.\d+)*)\b/g, function(a, b, c) {
    return (
      (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".")
        ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,")
        : b) + c
    );
  });
}

// Utility function to get a badge based on status
export function getBadgeVariant(status: string, passedString: string, failedString: string) {

  const lcStatus = status.toLowerCase();

  if (lcStatus === "verified" || lcStatus === "passed") {
    return <Badge variant="success">{passedString}</Badge>
  }

  return <Badge variant="warning">{failedString}</Badge>;

}

// Utility function to filter options based on query word array
export function filterByQuery<T extends Option>(
  query: string[] = [],
  allOptions: T[] = [],
  maxOptions = 20,
  selectedOptionId: string
) {
  const options: T[] = [];
  if (query.length > 0) {
    for (let option of allOptions) {
      // if each space-separated query word matches the option, add to options
      const optionText = option.text.toLowerCase();
      if (query.every((word) => optionText.includes(word))) {
        if (option.id === selectedOptionId) {
          options.unshift(option);
        } else {
          options.push(option);
        }
      }
      // stop when the options list is full
      if (options.length >= maxOptions) break;
    }
  }
  return options;
}

export function getApplicantLink(deployment: string, thisDeployment: string, uid: string, label: string) {

  if (deployment !== thisDeployment) {
    return <a href={`https://${deployment}.aidkit.org/applicant/${uid}`} target="_blank" rel="noreferrer noopener nofollow">{label}</a>
  }

  return <Link to={"/applicant/" + uid} target="_blank" rel="noreferrer noopener nofollow">{label}</Link>
}

export function RigidSpinner(props?: any) {
  return <Spinner {...props} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
}

export function SpacedSpinner(props?: any) {
  return <><RigidSpinner {...props} />&nbsp;</>;
}

export function AidKitLogo(props: { width: number, height: number }) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Aidkit Logo"  width={props.width} viewBox="0 0 655 273" height={props.height}>
      <path d="M64.3 193H84.76L92.845 167.26H139.705L147.873 193H168.333L130.878 74.2H101.755L64.3 193ZM98.7025 148.697L116.11 93.4225L133.765 148.697H98.7025ZM186.548 193H206.431V74.2H186.548V193ZM232.793 193H270.661C271.596 193 273.548 192.972 276.518 192.917C279.543 192.862 282.403 192.67 285.098 192.34C294.558 191.13 302.533 187.802 309.023 182.357C315.513 176.857 320.436 169.872 323.791 161.402C327.146 152.932 328.823 143.665 328.823 133.6C328.823 123.535 327.146 114.267 323.791 105.797C320.436 97.3275 315.513 90.3425 309.023 84.8425C302.533 79.3425 294.558 76.015 285.098 74.86C282.348 74.53 279.488 74.3375 276.518 74.2825C273.603 74.2275 271.651 74.2 270.661 74.2H232.793V193ZM253.006 174.272V92.9275H270.661C272.311 92.9275 274.401 92.9825 276.931 93.0925C279.461 93.1475 281.743 93.395 283.778 93.835C289.278 94.88 293.788 97.3275 297.308 101.177C300.828 105.027 303.441 109.785 305.146 115.45C306.906 121.06 307.786 127.11 307.786 133.6C307.786 139.815 306.961 145.755 305.311 151.42C303.661 157.085 301.048 161.897 297.473 165.857C293.953 169.762 289.388 172.265 283.778 173.365C281.743 173.75 279.461 173.997 276.931 174.107C274.401 174.217 272.311 174.272 270.661 174.272H253.006ZM349.976 193H369.858V137.56L419.936 193H445.181L390.648 132.445L441.716 74.2H417.296L369.858 129.31V74.2H349.976V193ZM460.107 193H479.989V74.2H460.107V193ZM534.897 193H554.779V92.845H593.224V74.2H496.452V92.845H534.897V193Z" fill="black" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="655" height="273">
        <rect x="31.2649" y="37" width="21" height="521.082" transform="rotate(-90 31.2649 37)" fill="#A34C4C" />
        <rect x="629.467" y="211" width="40" height="522.124" transform="rotate(90 629.467 211)" fill="#A34C4C" />
        <rect x="619.045" y="130" width="20.8433" height="115" fill="#A34C4C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M529.419 37.2781L568.269 1.43701e-05L583.008 14.1422L544.158 51.4203L529.419 37.2781Z" fill="#A34C4C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M601.412 139.278L640.262 102L655 116.142L616.15 153.42L601.412 139.278Z" fill="#A34C4C" />
        <rect x="16.6746" y="16" width="33.3492" height="112" fill="#A34C4C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.86046e-06 143.21L38.8499 105.932L53.5883 120.074L14.7384 157.352L8.86046e-06 143.21Z" fill="#A34C4C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M146.7 227.262L101.108 272.563L78.1623 251.301L123.754 206L146.7 227.262Z" fill="#A34C4C" />
      </mask>
      <g mask="url(#mask0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M598.202 36H58.3611C46.8497 36 37.5179 44.9543 37.5179 56V208C37.5179 219.046 46.8497 228 58.3612 228H598.202C609.714 228 619.045 219.046 619.045 208V56C619.045 44.9543 609.714 36 598.202 36ZM58.3611 16C35.3383 16 16.6746 33.9086 16.6746 56V208C16.6746 230.091 35.3383 248 58.3612 248H598.202C621.225 248 639.889 230.091 639.889 208V56C639.889 33.9086 621.225 16 598.202 16H58.3611Z" fill="#E33D44" />
      </g>
    </svg>
  );
}

export function MiniAidKitLogo(props: { width: number, height: number, fill?: "white" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-label="Aidkit Logo" width={props.width} height={props.height} viewBox="0 0 243 251" fill="none">
      <path d="M59.1823 194.341L96.6373 75.5412H125.76L163.215 194.341H142.755L108.765 87.7512H113.22L79.6423 194.341H59.1823ZM80.0548 168.601V150.039H142.425V168.601H80.0548Z" fill={props.fill || "black"} />
      <mask id="mask0_910_76" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="593" height="304">
        <rect x="28.3055" y="41.2675" width="23.4221" height="471.758" transform="rotate(-90 28.3055 41.2675)" fill="#A34C4C" />
        <rect x="569.884" y="235.336" width="44.6135" height="472.702" transform="rotate(90 569.884 235.336)" fill="#A34C4C" />
        <rect x="15.0963" y="17.8454" width="30.1925" height="124.918" fill="#A34C4C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.0002626 159.727L35.1728 118.149L48.5161 133.923L13.3436 175.5L0.0002626 159.727Z" fill="#A34C4C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M132.814 253.474L91.5377 304L70.7637 280.285L112.04 229.759L132.814 253.474Z" fill="#A34C4C" />
      </mask>
      <g mask="url(#mask0_910_76)">
        <path fillRule="evenodd" clipRule="evenodd" d="M193.6 40.1522H52.837C42.4152 40.1522 33.9666 50.1392 33.9666 62.4589V110.5V154V231.99C33.9666 244.31 42.4152 276.604 52.837 276.604L193.6 40.1522ZM52.837 17.8454C31.9934 17.8454 15.0963 37.8195 15.0963 62.4589V231.99C15.0963 256.629 31.9934 276.604 52.837 276.604L193.6 40.1522V17.8454C193.6 40.1521 193.6 40.1521 193.6 17.8454L52.837 17.8454Z" fill="#E33D44" />
      </g>
    </svg>);
}

export function AddPhoneDashes(phone: string | undefined): string {
  if (phone === undefined || phone === 'undefined') {
    return phone || '';
  }

  const n = phone.replace('+1', '');

  return n.slice(0, 3) + '-' + n.slice(3, 6) + '-' + n.slice(6, n.length);
}

/** localizedStringTemplate should have a [text](url) in there which is where the url will go */
export function useLinksOnLocalizedStrings(localizedStringTemplate: string, replacementLink: string) {
  return useMarkdown(localizedStringTemplate.replace('%url', replacementLink));
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

export function getDistroBrowserIncompatibility() {
  let supported = true;
  try { new CSSStyleSheet(); } catch (e) { supported = false; }
  if (!supported) {
    // Return a full page element that says this only works in chrome
    return <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-2xl font-bold text-blue-400">This page currently requires Chrome, Firefox, or Safari Version 16.4 or higher.</div>
      <div className="text-xl font-bold text-blue-400">Please use one of these browsers to access this page.</div>
    </div>
  }
  return null;
}

export function saveKeyToCookieAndRelocateToUrl(url: string, options?: {
  removeHistory?: boolean,
  useHistoryToRelocate?: boolean
}) {
  // Remove this from immediate history
  if (options?.removeHistory) {
    history.replaceState({}, '', '/');
  }

  // Save the key to a cookie and remove it from the URL
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;
  if (searchParams.get('key') && !url.includes('card_setup')) {
    const key = searchParams.get('key')!;
    const name = 'key:' + urlObject.pathname;

    // Set the cookie for the root path '/'
    const cookieForRoot = `${encodeURIComponent(name)}=${encodeURIComponent(key)};path=/`;
    document.cookie = cookieForRoot;

    // Set the cookie for the '/p' path
    const cookieForPPath = `${encodeURIComponent(name)}=${encodeURIComponent(key)};path=/p`;
    document.cookie = cookieForPPath;

    searchParams.delete('key');
  }
  url = urlObject.toString();

  // Relocate to the URL
  if (options?.useHistoryToRelocate) {
    history.replaceState({}, '', url);
  }
  else {
    (window as any).location = url;
  }
}

/**
 * Returns JSX
 * Useful for dealing with text that may contain RTL characters.
 */
export function wrapWithBdi(text: string | JSX.Element) {
  return <bdi style={{ whiteSpace: 'break-spaces' }}>{text}</bdi>
}

export function renderLinks(text: string) {
  const urlPattern = /(https:\/\/\S+)/g;

  // Split the text into parts based on the URLs
  const parts = text.split(urlPattern);

  // Generate JSX elements for the replaced URLs
  // We have to do this this way because .replace cannot use a JSX Element as the replacer.
  const replacedParts = parts.map((part, index) => {
    if (urlPattern.test(part)) {
      const url = part.trim();
      // If link is long, we need to Shorten it so it doesn't mess up styles
      const shortenedURL = url.length > 50 ? url.substring(0, 50) + '...' : url;
      // here we just render the url but in a <a> just like it would look like in a real text message.
      return <a title={url} key={index} href={url}>{shortenedURL}</a>;
    } else {
      return part;
    }
  });

  // Concatenate the replaced parts into a final JSX element
  return <>{replacedParts}</>
}

export function blockify(text: string, callback?: (str: string) => JSX.Element) {
  return text.split('\n').map((str, index) => <p key={str + "-" + index} className="mb-0">{str === '' ? <br /> : (callback?.(str) || str)}</p>);
}

export function useOnlineDetection(config?: PublicConfig): ContextType<typeof OnlineAwarenessContext> {
  const [online, setOnline] = useState(true);
  const [inBackground, setInBackground] = useState(false);
  const [status, setStatus] = useState<OfflineStatus>('unloaded');

  useEffect(() => {
    const nowInBackground = () => setInBackground(true);
    const nowInForeground = () => setInBackground(false);
    if (config?.experimental?.enableOfflineMode) {
      window.addEventListener('blur', nowInBackground);
      window.addEventListener('focusout', nowInBackground);
      window.addEventListener('focus', nowInForeground);
      window.addEventListener('focusin', nowInForeground);
    }

    return () => {
      window.removeEventListener('blur', nowInBackground);
      window.removeEventListener('focusout', nowInBackground);
      window.removeEventListener('focus', nowInForeground);
      window.removeEventListener('focusin', nowInForeground);
    }
  }, [config]);

  useEffect(() => {
    if (inBackground) return;

    if (config?.experimental?.enableOfflineMode) {
      // gating this to 30 seconds at a minimum to mitigate accidental abuse of our servers
      const intervalInMS = 30000;
      offlineLogger.debug(`setting up online detection for every ${intervalInMS}ms`);
      const token = setInterval(() =>
        fetch("/favicon.ico", {
          method: 'HEAD',
          keepalive: false,
          cache: 'no-store'
        })
          .then(response => setOnline(response.ok))
          .catch(() => setOnline(false)),
      intervalInMS
      );
      // do an initial fetch on focus to ensure
      // we have the latest state
      fetch("/favicon.ico", {
        method: 'HEAD',
        keepalive: false,
        cache: 'no-store'
      })
        .then(response => setOnline(response.ok))
        .catch(() => setOnline(false))

      const handleOnline = () => setOnline(true);
      const handleOffline = () => setOnline(false);

      // these window events are supported on Chrome, and provide a real-time way
      // to determine the browser's network connectivity.
      // Any server outages will be caught by the preceeding /favicon.ico check.
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
      return () => {
        offlineLogger.debug('disabling online detection');
        clearInterval(token);
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      }
    } else {
      offlineLogger.debug('online detection not enabled');
    }
  }, [inBackground, config]);

  useEffect(() => {
    if (!navigator.serviceWorker || !(config?.experimental?.enableOfflineMode)) {
      return;
    }

    function stateChange(event: any) {
      if (!event.data) {
        return;
      }

      const { data: { type, state, timeTaken, failed } }: { data: { failed: any, type: string, state: string, timeTaken?: number } } = event;

      if (type === 'offline-warm') {
        setStatus(state as OfflineStatus);
        if (failed && failed.length > 0) {
          const msgIds = [];
          for (const failure of failed) {
            const error = new Error(`${failure.path}: ${failure.error?.message ?? failure.error}`)
            msgIds.push(
              captureException(error, { level: 'error', tags: { phase: 'warmup' } })
            );
          }
          offlineLogger.warn('failures during warmup', failed, msgIds);
        }
      } else if (type === 'offline-sync') {
        setStatus(state === 'complete' ? 'synced' : 'syncing');
        if (timeTaken) {
          offlineLogger.debug(`Offline sync took ${timeTaken}ms`);
        }
      }
    };

    navigator.serviceWorker.addEventListener('message', stateChange);
    return () => {
      navigator.serviceWorker.removeEventListener('message', stateChange);
    }
  }, [config?.experimental?.enableOfflineMode]);


  return { online, status };
}

export function useOfflineSync(config?: PublicConfig): ContextType<typeof OfflineSyncContext> {

  const applicantDir = useRef<PermaCache<string, RequestEntry>>();
  const [statuses, setStatuses] = useState<Record<string, SyncState>>({});

  useEffect(() => {
    if (!navigator.serviceWorker || !(config?.experimental?.enableOfflineMode)) {
      return;
    }

    (async () => {

      if (!applicantDir.current) {
        applicantDir.current = await getOrCreateHttpCache('applicants');
      }

      console.debug('useOfflineSync: ', 'getting applicant base states');

      const applicants = await Promise.all((await applicantDir.current.dirs()).map(async d => {
        try {
          const app = await d.as(toApplicant);
          return await app.state();
        } catch {
          return { id: d.name, status: SyncStatus.Unavailable };
        }
      }));

      setStatuses(applicants.reduce((prev, cur) => ({ ...prev, [cur.id!]: cur }), {}));
    })()
  }, [config?.experimental?.enableOfflineMode]);

  useEffect(() => {
    if (!navigator.serviceWorker || !(config?.experimental?.enableOfflineMode)) {
      return;
    }

    function stateChange(event: MessageEvent<SyncStateEvent>) {
      if (!event.data) {
        return;
      }

      const { data } = event;

      if (data.type === 'offline-sync') {
        if (data.id) {
          setStatuses(prev => (
            { ...prev, [data.id!]: data }
          ));
        }
      }
    };

    navigator.serviceWorker.addEventListener('message', stateChange);
    return () => {
      navigator.serviceWorker.removeEventListener('message', stateChange);
    }
  }, [config?.experimental?.enableOfflineMode]);

  return { statuses };
}

export function BarSpinner() {
  return <div className="spinner">
    <div className="rect1"></div>&nbsp;
    <div className="rect2"></div>&nbsp;
    <div className="rect3"></div>&nbsp;
    <div className="rect4"></div>&nbsp;
    <div className="rect5"></div>
  </div>
}

/**
 * A logger that only logs error messages in production,
 * and everything else in dev.
 *
 * @example
 * export function MyButton() {
 *  const logger = useLogger(MyButton);
 *  logger.info('this is a button saying stuff');
 *  return (<button>Wicked Cool™</button>);
 * }
 *
 * outputs to the console:
 * >> (Info-Colored MyButton Badge) this is a button saying stuff
 */
export function useLogger(fn: Function | string, props?: any) {
  return createLogger(
    `${typeof fn === 'function' ? fn.name : fn}${JSON.stringify(props)}`,
    DefaultStyles(DefaultColors), ['debug', 'assert', 'warn', 'info']
  );
}

export function getLocalTimezoneSuffix() {
  const dstStart = new Date(Date.UTC(new Date().getFullYear(), 2, 1)).getUTCDay() === 0 ? 8 : 15; // 2nd Sunday of March
  const dstEnd = new Date(Date.UTC(new Date().getFullYear(), 10, 1)).getUTCDay() === 0 ? 1 : 8; // 1st Sunday of November

  const month = new Date().getMonth();
  const isDST = month >= dstStart && month <= dstEnd;  // Assuming months start from 1 for Jan and end at 12 for Dec

  const offset = new Date().getTimezoneOffset() / 60;

  switch (offset) {
    case 4:
      return isDST ? 'EDT' : 'EDT';
    case 5:
      return isDST ? 'EST' : 'CDT';
    case 6:
      return isDST ? 'CST' : 'MDT';
    case 7:
      return isDST ? 'MST' : 'PDT';
    case 8:
      return isDST ? 'PDT' : 'PDT';
    default:
      return '';
  }
}

export const prettyPhone: (phone: string) => string = (phone: string) => {
  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  if (phone.startsWith('+1')) {
    let phoneNumber = phone.slice(phone.length - 10);
    return phone.slice(0, phone.length - 10) + ' ' + prettyPhone(phoneNumber);
  }
  return phone;
}

/**
 * Sorts records a and b using sortKey in order specified by sortOrder.
 * Importantly, this checks if the values being sorted are numbers, and if so sorts them as such,
 * thus avoiding the issue of numbers as strings being sorted lexographically ("1", "22", "5")
 */
function sortFunc(a: Record<string, any>, b: Record<string, any>, sortKey: string, sortOrder: 'ascending' | 'descending') {
  const aValue = a[sortKey];
  const bValue = b[sortKey];

  const aValueIsNumber = !isNaN(parseFloat(aValue)) && isFinite(aValue);
  const bValueIsNumber = !isNaN(parseFloat(bValue)) && isFinite(bValue);

  if (aValueIsNumber && bValueIsNumber) {
    return sortOrder === 'ascending'
      ? parseFloat(aValue) - parseFloat(bValue)
      : parseFloat(bValue) - parseFloat(aValue);
  }

  if (aValue === null || aValue === undefined) return sortOrder === 'ascending' ? -1 : 1;
  if (bValue === null || bValue === undefined) return sortOrder === 'ascending' ? 1 : -1;

  let normalA = (aValue || '').toString().toLowerCase().trim();
  let normalB = (bValue || '').toString().toLowerCase().trim();

  if (normalA < normalB) {
    return sortOrder === 'ascending' ? -1 : 1;
  }
  if (normalA > normalB) {
    return sortOrder === 'ascending' ? 1 : -1;
  }
  return 0;
}

/**
 * Sorts the given records using the provided key in the array of records.
 */
export function sortRecordsInPlace(records: Record<string, any>[], sortKey: string, sortOrder: 'ascending' | 'descending') {
  records.sort((a, b) => sortFunc(a, b, sortKey, sortOrder));
}

/**
 * Creates a copy of the given records and sorts it using the provided key in the array of records.
 */
export function copyAndSortRecords(records: Record<string, any>[], sortKey: string, sortOrder: 'ascending' | 'descending') {
  return [...records].sort((a, b) => sortFunc(a, b, sortKey, sortOrder));
}

/**
 * Creates a copy of the given records and returns all records r such that for key k,v in matchingRecord, v is included in r[k].
 */
export function filterRecords(records: Record<string, any>[], matchingRecord: Record<string, string>) {
  return records.filter(r => {
    return Object.keys(matchingRecord).every(
      (matchKey) => {
        let recordMatchKey = matchKey;
        if (matchKey === 'full_uid') {
          recordMatchKey = 'uid';
        }
        return r[recordMatchKey] && r[recordMatchKey].toLowerCase().includes((matchingRecord[matchKey]).toLowerCase())
      });
  });
}

export function isPhone() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /iphone|android/i.test(userAgent.toLowerCase());
}

export function classNames(...classes: (string | undefined | null)[]) {
  return classes.filter(Boolean).join(' ')
}

export function generateRandomString(length: number, chars: string): string {
  let result = '';
  const charsLength = chars.length;
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  return result;
}

export function formatTime(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export function removeDuplicateEvents(events: ChangeSet) {
  let eventIDs = new Set<string>();
  let uniqueEvents: ChangeSet = [];
  events?.forEach(e => {
    if (!eventIDs.has(e[2].id)) {
      eventIDs.add(e[2].id);
      uniqueEvents.push(e);
    }
  })

  return uniqueEvents;
}

type ComponentType =
  v0.Block
  | v0.Collection
  | v0.TopLevelTemplatedBlock
  | v0.Payment
  | v0.NotificationGroup
  | v0.Dashboard
  | v0.UsioMailing
  | v0.GiveCardMailing
  | v0.USBankCardMailing
  | v0.ApplicantIdentities
  | v0.Macro
  | v0.ApplicantPortalPage
  | v0.AIAssistantReviewSurvey;

export function enumerateSubsurveys(survey: v0.ExpandedSurvey['survey']) {
  function traverse(component: ComponentType): v0.Subsurvey[] {
    if (component.kind == 'Subsurvey') {
      return [component, ...component.sections.flatMap(traverse)];
    }
    if (component.kind == 'Section') {
      return component.components.flatMap(traverse);
    }
    if (component.kind == 'Collection') {
      return component.components.flatMap(traverse);
    }
    return [];
  }
  const subsurveys = (survey || []).flatMap(traverse);
  return subsurveys;
}

export function memoize<T = any, P extends any[] = any[]>(fn: (...args: P) => T, duration?: number): (...args: P) => T {
  const cache: Record<string, T> = {};
  const timeouts: Record<string, NodeJS.Timeout | undefined> = {};
  return function _memoizedInner(...args: P) {
    const key = JSON.stringify(args);
    if (!cache[key]) {
      cache[key] = fn(...args);
      if (duration) {
        if (timeouts[key]) {
          timeouts[key] = clearTimeout(timeouts[key]) as undefined;
        }
        timeouts[key] = setTimeout(
          () => {
            delete cache[key];
            if (timeouts[key]) {
              timeouts[key] = clearTimeout(timeouts[key]) as undefined;
            }
          },
          duration
        );
      }
    }
    return cache[key];
  }
}

export function lowerCaseCompare(item: string, comparisons: string[]) {
  return comparisons.some(c => c.toLowerCase().includes(item.toLowerCase()));
}

/**
 * Utility function to iterate over cookies and decide which to remove.
 * NOTE: we remove all cookies using / as path. We can update this to be more specific if needed.
 * @param {(name: string) => boolean} shouldRemove - A callback to determine if a cookie should be removed.
 */
function handleCookies(shouldRemove: (name: string) => boolean) {
  document.cookie.split(';').forEach(cookie => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();

    if (shouldRemove(name)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    }
  });
}

/**
 * Remove specific cookies by name.
 * @param {string[]} toRemove - List of cookie names to remove.
 */
export function removeSpecificCookies(toRemove: string[]) {
  handleCookies(name => toRemove.includes(name));
}

/**
 * Remove all cookies except those specified.
 * @param {string[]} toKeep - List of cookie names to keep.
 */
export function removeAllButSpecificCookies(toKeep: string[]) {
  handleCookies(name => !toKeep.includes(name));
}

/**
 * Remove all cookies.
 */
export function removeAllCookies() {
  handleCookies(() => true);
}
