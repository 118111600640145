import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { Checkbox } from "../../Checkbox";
import { Button } from "../../Button";
import { LayerPreview } from "./LayerPreview";
import { useLocalizedStrings } from "../../LocalizationProvider";
export const MapControls = memo(({ handleLayerToggle, handleOverlayToggle, handleDescriptionToggle, handleSelectAll, handleSelectNone, mapTitle = "Map Layers", mapDescription, sources, isOverlayEnabled, isDescriptionEnabled, layerCounts, legend, visibleLayers, isVisible, }) => {
    const L = useLocalizedStrings();
    return (_jsx("nav", { className: `absolute bottom-16 left-4 transition-opacity duration-200 opacity-0 ${isVisible ? "opacity-100" : "opacity-0 pointer-events-none"}`, children: _jsxs("div", { className: "flex flex-col gap-1 max-w-xs max-h-[calc(100vh-120px)] overflow-y-auto", children: [legend && legend.showLayerCount.some(layer => Object.keys(layerCounts).includes(layer.layerName)) && (_jsxs("div", { className: "bg-white rounded-md shadow-md border border-gray-300 p-[0.75rem]", children: [_jsx("h2", { className: "font-bold mb-1 inline-block text-base", children: legend.title }), _jsx("table", { className: "w-full border-collapse border border-gray-400 mt-1", children: _jsx("tbody", { children: legend.showLayerCount.map((layer, index) => {
                                    if (Object.keys(layerCounts).includes(layer.layerName)) {
                                        const countDisplay = layerCounts[layer.layerName]?.[layer.featureToCount];
                                        const isSingleRow = legend.showLayerCount.filter(l => Object.keys(layerCounts).includes(l.layerName)).length === 1;
                                        return (_jsxs("tr", { className: `border border-gray-300 ${isSingleRow ? "bg-white" : index % 2 === 0 ? "bg-gray-100" : "bg-white"}`, children: [_jsx("td", { className: "px-2 py-1 text-sm border border-gray-300 break-words whitespace-normal", children: layer.label }), _jsx("td", { className: "px-2 py-1 text-sm border border-gray-300 break-words whitespace-normal", children: _jsx("span", { className: "", children: countDisplay }) })] }, layer.layerName));
                                    }
                                    return null;
                                }) }) })] })), _jsxs("div", { className: "bg-white p-[0.75rem] rounded-md shadow-md border border-gray-300", children: [_jsx("span", { className: "font-bold mb-1 inline-block text-base", children: mapTitle || L.component_library.map.map_layers }), _jsxs("div", { className: "flex gap-2 mb-2 space-x-2", children: [_jsx(Button, { variant: "link", onClick: handleSelectAll, children: L.component_library.map.select_all }), Object.values(visibleLayers).includes(true) && (_jsx(Button, { variant: "link", onClick: handleSelectNone, children: L.component_library.map.clear_selection }))] }), _jsx("div", { className: "space-y-1.5", children: [...sources].reverse().map((source) => (_jsxs("div", { className: "flex items-baseline", children: [_jsx("div", { className: "flex-none w-7", children: _jsx(LayerPreview, { source: source }) }), _jsx(Checkbox, { label: source.layerName, checked: visibleLayers[source.layerName], onChange: () => handleLayerToggle(source.layerName) })] }, source.layerName))) })] }), _jsxs("div", { className: "bg-white p-[0.75rem] rounded-md shadow-md border border-gray-300 space-y-2", children: [_jsx(Checkbox, { label: "Show Region Overlay", checked: isOverlayEnabled, onChange: handleOverlayToggle }), mapDescription && _jsx(Checkbox, { label: "Show Description", checked: isDescriptionEnabled, onChange: handleDescriptionToggle })] })] }) }));
});
