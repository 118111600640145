import { Paragraph, useToast } from "@aidkitorg/component-library";
import { RealtimeServerEvent, ResponseCreateEvent } from "openai/resources/beta/realtime/realtime";
import { SessionCreateResponse } from "openai/resources/beta/realtime/sessions";
import React, { PropsWithChildren, useContext, useEffect, useReducer, useState } from "react";
import { usePost } from "../API";
import { LoggedInConfigurationContext } from "../Context";
import { safeParse } from "../Util";

type AICoachState = {
  enabled: boolean,
  modality: "text" | "audio",
  setModality: (v: AICoachState["modality"]) => void,
  audioElement: HTMLAudioElement | null,
  setAudioElement: (v:AICoachState["audioElement"]) => void,
  peerConnection: RTCPeerConnection | null,
  setPeerConnection: (v:AICoachState["peerConnection"]) => void,
  dataChannel: RTCDataChannel | null,
  setDataChannel: (v:AICoachState["dataChannel"]) => void,
  channelIsOpen: () => boolean,
  startSession: () => Promise<void>,
  closeSession: () => void,
  sendMessage: (msg:string) => void
};

const defaults : AICoachState = {
  enabled: false,
  modality: "text",
  setModality: () => {},
  audioElement: null,
  setAudioElement: () => {},
  peerConnection: null,
  setPeerConnection: () => {},
  dataChannel: null,
  setDataChannel: () => {},
  channelIsOpen: () => false,
  startSession: async () => {},
  closeSession: () => {},
  sendMessage: () => {}
}

export const AICoachContext = React.createContext<AICoachState>(defaults);

async function initializeRTC(ephemeralToken:SessionCreateResponse.ClientSecret, handlers?: {
  onReady?: () => void,
  onConnectionError?: (errMsg: string) => void
}) {
  const pc = new RTCPeerConnection();

  // create an empty media stream to satisfy OpenAI
  // that there is an audio input on the client side
  // this is required even when only use text modality
  const destination = new (window.AudioContext || (window as any).webkitAudioContext)().createMediaStreamDestination();
  const emptyMediaStream = destination.stream;
  pc.addTrack(emptyMediaStream.getTracks()[0]);

  const dataChannel = pc.createDataChannel("oai-events");
  const offer = await pc.createOffer();
  await pc.setLocalDescription(offer);
  
  const baseUrl = "https://api.openai.com/v1/realtime";
  const sdpResponse = await fetch(`${baseUrl}`, {
    method: "POST",
    body: offer.sdp,
    headers: {
      Authorization: `Bearer ${ephemeralToken.value}`,
      "Content-Type": "application/sdp"
    },
  });

  const sdp = await sdpResponse.text();
  const responseJSON = safeParse(sdp);
  if (responseJSON?.error) {
    handlers?.onConnectionError?.(responseJSON.error?.message || "Failed to initialize WebRTC chat session");
  } else {
    const answer = {
      type: "answer",
      sdp
    } as const;
    await pc.setRemoteDescription(answer);
  }
  if (handlers?.onReady) {
    dataChannel.onopen = handlers.onReady;
  }
  return {pc, dataChannel};
}

export function AICoachProvider({ children }: PropsWithChildren ) {
  const createCoachSession = usePost("/coach/conversation");
  const { toast } = useToast();
  const config = useContext(LoggedInConfigurationContext);
  const enabled = config?.experimental?.enableAICoach!!
  const [modality, setModality] = useState<AICoachState["modality"]>("text");
  const [peerConnection, setPeerConnection] = useState<AICoachState["peerConnection"]>(null);
  const [dataChannel, setDataChannel] = useState<AICoachState["dataChannel"]>(null);
  const [audioElement, setAudioElement] = useState<AICoachState["audioElement"]>(null);

  // if we have both an audio element and a peer connection
  // bind the audio to the peer connection
  useEffect(
    () => {
      if (audioElement && peerConnection) {
        peerConnection.ontrack = e => {
          if (audioElement) {
            audioElement.srcObject = e.streams[0];
          }
        }
      }
    },
    [audioElement, peerConnection]
  );

  async function startSession() {
    const { token } = await createCoachSession({ modality, persistConversation: true });
    if (token) {
      const { pc, dataChannel } = await initializeRTC(token, {
        onReady: function onReady() {
          // only set channel to be available to consumers once it's ready
          setDataChannel(dataChannel);
          dataChannel.addEventListener("message", function onChannelMessage(msg){
            const event = JSON.parse(msg.data) as RealtimeServerEvent;
          });
        },
        onConnectionError: function onConnectionError(errMsg:string) {
          toast({
            title: "Connection error",
            variant: "error",
            description: <Paragraph>{errMsg}</Paragraph>,
          });
        }
      });
      setPeerConnection(pc);
    }  
  }

  function closeSession() { 
    // TODO, also close microphone if open
    peerConnection?.close();
    dataChannel?.close();    
    if (audioElement) {
      audioElement.pause();
      audioElement.srcObject = null;
    }

    setPeerConnection(null);
    setDataChannel(null);
  }

  function channelIsOpen(){
    return dataChannel?.readyState === "open";
  }

  function sendMessage(text:string) {    
    if (!dataChannel || !channelIsOpen()) {
      console.error("Failed to send message - RTCDataChannel is not open - current state:", dataChannel?.readyState ?? "Unknown");
      return;
    }
    dataChannel.send(JSON.stringify({
      type: "response.create",
      response: {
        input: [{
          type: "message",
          role: "user",
          content: [{
            type: "input_text",
            text
          }]
        }]
      }
    } as ResponseCreateEvent));
  }  

  const stateValue : AICoachState = {
    enabled,
    modality, setModality,
    peerConnection, setPeerConnection,
    dataChannel, setDataChannel,
    audioElement, setAudioElement,
    channelIsOpen,
    startSession,
    closeSession,
    sendMessage
  };

  return <AICoachContext.Provider value={stateValue}>
    {children}
  </AICoachContext.Provider>;
}

// sugar
export function useAICoach() : AICoachState {
  const AICoach = useContext(AICoachContext);
  return AICoach;
}