import React, { useContext, useEffect, useState } from "react";
import { useAPIPost, usePost } from "./API";
import { ClickableButton } from "./Components/Button";
import InterfaceContext from "./Context";
import { useLocalizedStrings } from "./Localization";
import { sanitizeDangerousText } from "@aidkitorg/types/lib/utils/textSanitizer";

function TranslateWrapper(props: { 
  desired_lang: string,
  metadata?: Record<string, any>,
  body: React.ReactNode,
  translateText: string,
  id: string | number,
  applicant_lang?: string,
}) {
  const L = useLocalizedStrings();
  const lang = props.desired_lang;
  const metadata = props.metadata;
  const body = props.body;
  const key = props.id;
  const context = useContext(InterfaceContext);

  const [translated, setTranslated] = useState('');
  const translate = usePost("/translate");

  // const detected_lang = googleApiDetectLanguage(body);
  const [langMatches, setLangMatches] = useState(true);
  const languageNames = new Intl.DisplayNames([context.lang.replace('_','-')], { type: 'language' });
  const fixedLang = (metadata?.language || props.applicant_lang || 'en').replace(/_/g, '-');
  let sourceLanguage;
  try {
    sourceLanguage = languageNames.of(fixedLang) || (metadata?.language || props.applicant_lang);
  } catch (e) {
    sourceLanguage = (metadata?.language || props.applicant_lang);
  }

  async function doTranslate() {
    const result = await translate({ q: props.translateText, target: lang })

    if (!('error' in result)) {
      setTranslated(result.translatedText);
    }
  }

  useEffect(() => {
    async function doDetect() {
      if (metadata && metadata['language']) {
        setLangMatches(metadata['language'] === lang);
        return <></>;
      }
      if (!metadata) {
        if (props.applicant_lang && props.applicant_lang === lang) {
          setLangMatches(true);
        } else {
          setLangMatches(false);
        }
      }
    }

    doDetect();
  }, [props.applicant_lang, lang, metadata, setLangMatches]);

  return (
    <div className="block text-start" dir="auto">
      <span key={`untranslated-body-${key}`}>{body}</span>
      <div className="flex justify-start mt-2">
        {!langMatches && !translated && <ClickableButton color="blue" key={`translate-button-${key}`}  
          onClick={() => doTranslate()}>
          {L.language.translate_to} (&larr; {sourceLanguage})
        </ClickableButton>}
      </div>
      {translated && (
        <div className="flex-col flex">
          <br />
          <em className="text-sm font-light">{L.language.translated_from.replace('$lang', sourceLanguage)}</em>
          <span key={`translated-${key}`}>{sanitizeDangerousText(translated)}</span>
        </div>)}
    </div>
  );
}

export default TranslateWrapper;
