// Applicant Support component for the frontend
// Author: robwalls3
// Objectives:
//   1. Provide a dashboard of unhandled comms from applicants who have not yet submitted
//      - dashboard should be a simple list of applicants (Dynamo Dashboards)
//         - each applicant should have their Name, Email, and Phone Number with 
//           a link to their profile (see objective 2)
//   2. Provide a way to click into each of those applicants and Provide the info
//      - the info can be formatted in two ways:
//      - a) a list
//      - b) formatted like the survey
//      - a is easier so we will start there and expand if needed.
//   3. Provide a way to Communicate with the apps (Use Comms)
//   4. Provide a way to take Notes on the apps (i.e., I tried contacting, but they didn't respond. I'll try again later.)
//      - there should be a way to change the state of the app in such a way that it can be reflected on the dashboard
//         -- i.e. add Note "Contact Attempted: 7/27/2022, 12:05 PM MDT"
//         -- to do this we can display the notes in sequential order for each 'contact' on the dashboard?

import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, FunnelIcon } from "@heroicons/react/24/outline";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { get_host, usePost } from "./API";
import InterfaceContext, { ConfigurationContext, SupportedLanguage, UserInfoContext } from "./Context";
import { Tab } from "@headlessui/react";
import { ClickableButton } from "./Components/Button";
import { AdminOnlyBadge } from "./Components/Badge";
import { langToWord, useLocalizedStrings } from "./Localization";
import { Dropdown } from "./Components/Dropdown";
import { snakeToEnglish, SpacedSpinner, useInterval } from "./Util";
import { SupportCaseBadge } from "./SupportCase";

import type { SupportRequest } from '@aidkitorg/aidkit/lib/application/support';
import { caseStatuses, fields, getStageFromRequest, stages, SupportApplicantStage, TableKey } from "./SupportUtil";
import { LanguageIcon } from "./Components/LanguageDropdown";
import { hash } from "@aidkitorg/types/lib/translation/v0_to_legacy";
import { Translations } from "@aidkitorg/i18n/lib";
import { Button, Icon } from "@aidkitorg/component-library";

const localizeStageFn = (L: Translations) => (stage: string) => {
  switch (stage) {
    case "No Application":
      return L.application_stage.pre_application;
    case "In Progress":
      return L.application_stage.in_progress;
    case "Applied":
      return L.application_stage.applied;
    case "All Stages":
      return L.support.all_stages;
    default:
      return stage;
  }
}

type FieldColumn = {
  kind: 'field';
  key: TableKey;
  sortable: boolean;
};

type ExtraColumn = {
  kind: 'extraColumn';
  key: string;
  label: string;
  filterable?: boolean;
};

type Column = FieldColumn | ExtraColumn;

function langForDate(lang: SupportedLanguage) {
  return lang.replaceAll('_', '-');
}

export function SupportPageInner(props: any) {

  const L = useLocalizedStrings();
  const localizeStage = localizeStageFn(L);
  const { customId, channel } = useParams() as any;
  const [mainFilter, setMainFilter] = useState(customId);
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);

  const [targetFieldFilterOptions, setTargetFieldFilterOptions] = useState({} as Record<string, string[]>);
  const [targetFieldFilters, setTargetFieldFilters] = useState({} as Record<string, string>);
  const [allColumns, setAllColumns] = useState<Column[]>([]);

  // load sortKey, sortOrder, filteredStage, and filteredLanguage from search params
  const params = new URLSearchParams(window.location.search);

  const fetchSupport = usePost("/applicants/support");

  const [supportRequests, setSupportRequests] = useState([] as SupportRequest[]);
  const [sortedRequests, setSortedRequests] = useState(null as null | SupportRequest[]);
  const [fetchTime, setFetchTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadedOnce, setLoadedOnce] = useState(false);

  const [sortKey, setSortKey] = useState<TableKey>(params.get("sortKey") as TableKey ?? "min");

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">(params.get("sortOrder") as "desc" ?? "asc");

  const [filteredStage, setFilteredStage] = useState<SupportApplicantStage | null>(params.get("filteredStage") as SupportApplicantStage ?? null);

  const acceptableRates = [30, 60, 180, 300, -1];
  const queryRate = parseInt(params.get("refreshRate") || '60');
  const [refreshRate, setRefreshRate] = useState(acceptableRates.includes(queryRate) ? queryRate : 60); // Refresh every 60s by default

  // Language filter
  const [filteredLanguage, setFilteredLanguage] = useState<SupportedLanguage | null>(params.get("filteredLanguage") as SupportedLanguage ?? null);
  const [requestLanguages, setRequestLanguages] = useState([] as (SupportedLanguage)[]);

  const [rows, setRows] = useState([] as (JSX.Element | null)[]);

  const None = 'No Filter';

  // Prevent horizontal scrolling of the entire page when a table extends beyond the viewport
  useEffect(() => {
    document.body.classList.add('overflow-x-hidden');
    return () => {
      document.body.classList.remove('overflow-x-hidden');
    };
  }, []);

  function sortRequests(requests: SupportRequest[], sortKey: TableKey, sortOrder: "asc" | "desc") {
    const sorted = requests.sort((a, b) => {
      let neg = sortOrder === 'desc' ? 1 : -1;
      let pos = sortOrder === 'desc' ? -1 : 1;
      if (!a || !b) {
        return 0;
      }

      if (sortKey === 'unhandled_messages') {
        return (a.row.messages || []).length < (b.row.messages || []).length ? neg : pos;
      }

      if (sortKey === 'applicant_stage') {
        return getStageFromRequest(a) < getStageFromRequest(b) ? neg : pos;
      } else {
        if (!a.row[sortKey]) return neg;
        if (!b.row[sortKey]) return pos;
        return a.row[sortKey]! < b.row[sortKey]! ? neg : pos;
      }
    });
    return sorted;
  }

  const handleSort = useCallback((key: TableKey, switchOrder: boolean, dataSet?: SupportRequest[]) => {
    const newSortOrder = switchOrder ? (sortOrder === 'asc' ? 'desc' : 'asc') : sortOrder;
    const toSort = dataSet || supportRequests;
    const sorted = sortRequests([...toSort], key, newSortOrder);
    setSortedRequests(sorted.slice(0));
    setSortOrder(newSortOrder);
    setSortKey(key);

    setRows([...sorted].map((request, idx: number) => {
      const app = request.row.source;
      if (!app) return null;
      if (filteredStage && getStageFromRequest(request) !== filteredStage) return null;
      if (filteredLanguage && request.row.language !== filteredLanguage) return null;
      for (const [field, filterValue] of Object.entries(targetFieldFilters)) {
        if (filterValue) {
          let matchFound = false;
          for (const applicantInfo of Object.values(request.applicantInfos)) {
            if (applicantInfo[field] === filterValue) {
              matchFound = true;
              break;
            }
          }
          if (!matchFound) return null;
        }
      }

      return <tr id={"approw-" + app} key={hash(JSON.stringify(request.row))} className="border border-b-4 border-gray-600">
        <MappedSupportRequest idx={idx} request={request} app={app} allColumns={allColumns} channel={channel} />
      </tr>
    }));
  }, [filteredStage, filteredLanguage, supportRequests, targetFieldFilters, allColumns, sortOrder]);

  const cb = useCallback(() => {
    (async () => {
      setLoading(true);
      const dashboard = await fetchSupport({
        channel: channel || "default",
        ...(mainFilter === 'mine' ? { mine: true } : {}),
        ...(mainFilter && caseStatuses.indexOf(mainFilter as typeof caseStatuses[number]) !== -1 ?
          { status: mainFilter } : {})
      });
      setLoading(false);
      if (!dashboard || (dashboard as any).error) return;

      if (!loadedOnce) setLoadedOnce(true);

      setFetchTime(dashboard.fetchTime!);

      if (!dashboard.supportRequests) {
        console.log("No support requests found");
        return;
      }

      setSupportRequests(dashboard.supportRequests);
      handleSort(sortKey, false, dashboard.supportRequests);
      // Set unique languages 
      const languages = new Set(dashboard.supportRequests.map(r => r.row.language).filter(l => l));
      setRequestLanguages(Array.from(languages) as SupportedLanguage[]);
      setTargetFieldFilterOptions(dashboard.targetFieldFilterOptions);
      setAllColumns(() => {
        const columns: Column[] = [];
        fields.forEach((field) => {
          columns.push({kind: 'field', key: field.key, sortable: field.sortable})
        });
        for (const c of dashboard.targetFieldConfigs) {
          // insert the column at the index specified by the columnIndex or at the end if undefined or out of range
          columns.splice(c.columnIndex ?? columns.length, 0, { kind: 'extraColumn', key: c.targetField, label: snakeToEnglish(c.targetField), filterable: c.filterable });
        }
        return columns;
      });
    })();
  }, [mainFilter, channel]);

  const updateURL = (options: {
    path?: 'mine' | 'all_unhandled' | string,
    params?: Record<string, string | number | null>
  }) => {
    let { params } = options;
    let newPath = new URLSearchParams(window.location.search);
    for (const param of ["filteredStage", "filteredLanguage", "sortKey", "sortOrder", "refreshRate"]) {
      // If params are not passed, we will just keep the existing ones.
      if (params) {
        if (params[param]) {
          newPath.set(param, params[param] + "");
        } else {
          newPath.delete(param);
        }
      }
    }
    window.history.replaceState({}, '', `${options.path || window.location.pathname}?${newPath}`);
  }

  // This use effect sorts when we update stage or language
  // Because those are filtered in the handleSort method.
  useEffect(() => {
    handleSort(sortKey, false);
  }, [filteredStage, filteredLanguage, targetFieldFilters, allColumns]);

  // This use effect updates search params when any of the filters/sorts changes so that
  // The link can be copied and the exact same view can be shared.
  useEffect(() => {
    updateURL({ params: { filteredStage, filteredLanguage, sortKey, sortOrder, refreshRate } });
  }, [filteredStage, filteredLanguage, sortKey, sortOrder, refreshRate]);

  useInterval(() => { if (refreshRate > 0 && !document.hidden) cb() }, refreshRate * 1000);
  useEffect(() => {
    cb();
  }, [mainFilter, channel]);

  const localizeStatusOrFilter = (status: string) => {
    switch (status) {
      case "mine":
        return L.support.mine;
      case "all_unhandled":
        return L.support.all_unhandled;
      case "open":
        return L.support.open;
      case "escalated":
        return L.support.escalated;
      case "resolved":
        return L.support.resolved;
      case "viewed":
        return L.support.viewed;
      default:
        return snakeToEnglish(status);
    }
  }

  return <div className="py-10 mb-20">
    <header>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between">
          <div>
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              {mainFilter === 'mine'
                ? L.support.my_support_requests
                : mainFilter
                  ? L.support.filtered_support_requests.replace('{}', snakeToEnglish(mainFilter))
                  : L.support.all_support_requests}
            </h1>
            {(config.roles || '').indexOf('admin') !== -1 && <small title="Only visible to admin" className="text-gray-500 text-xs font-normal">{L.support.fetched_in_ms.replace('{}', fetchTime.toString())}</small>}
          </div>
          <div className="flex">
            {loading && loadedOnce && <SpacedSpinner />}
            <Dropdown label={<div className="p-0 flex items-center">
              <LanguageIcon />&nbsp;{(filteredLanguage ? langToWord(filteredLanguage!, "Name") : '')}
            </div>}
            description="Language"
            options={
              [null, ...requestLanguages].map(l => ({
                label: l ? langToWord(l, "Name") : None,
                callback: () => {
                  setFilteredLanguage(l)
                }
              }))
            } />&nbsp;
            <Dropdown usePopper={true} label={localizeStage(filteredStage || 'All Stages')} options={
              [null, ...stages].map(stage => ({
                label: localizeStage(stage || 'All Stages'),
                callback: () => {
                  setFilteredStage(stage);
                }
              }))
            } />&nbsp;
            <Dropdown usePopper={true} label={localizeStatusOrFilter(mainFilter)} options={
              ['mine', 'all_unhandled', ...caseStatuses].map(status => ({
                label: localizeStatusOrFilter(status),
                callback: () => {
                  // window.location.pathname = '/supportrequests/' + status;
                  setMainFilter(status);
                  updateURL({ path: status });
                }
              }))
            } />&nbsp;
            <Dropdown usePopper={true} label={refreshRate === -1 ? L.support.never : `${L.support.every_x_seconds.replace('{}', refreshRate.toString())}`} options={[
              { label: "30s", callback: () => setRefreshRate(30) },
              { label: L.stats.one_minute, callback: () => setRefreshRate(60) },
              { label: "3 " + L.stats.minutes, callback: () => setRefreshRate(180) },
              { label: "5 " + L.stats.minutes, callback: () => setRefreshRate(300) },
              { label: L.support.never, callback: () => setRefreshRate(-1) }
            ]} />
          </div>
        </div>
      </div>
    </header>
    <main className="px-4">
      <span>{loading && !loadedOnce ? <>
        <SpacedSpinner /><span>{L.selfie.loading}</span>
      </> : null}</span>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 h-100">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {(!sortedRequests || sortedRequests.length === 0) && (
                <div className="text-center text-gray-500">{L.support.no_requests_found}</div>
              )}
              {sortedRequests && sortedRequests.length > 0 &&
                <table className="table-auto min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr >
                      {allColumns.map((column) => {
                        return <th scope="col" key={column.key}
                          className="py-2.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          {column.kind === 'field' ? <div className="flex space-x-2 justify-between items-center h-10">
                            <div className="">
                              {L.support.fields[column.key]}
                            </div>
                            {column.sortable && (
                              <div className="flex-col">
                                {sortKey !== column.key ?
                                  <Button
                                    icon={<Icon
                                      name="ChevronRightIcon"
                                      role="decorative"
                                    />}
                                    size="sm"
                                    onClick={() => {
                                      handleSort(column.key, false);
                                    }}
                                    variant="unstyled"
                                    aria-label="Sort items by column"
                                  /> :
                                  <Button
                                    icon={<Icon
                                      name={sortOrder === 'asc' ? "ChevronUpIcon" : "ChevronDownIcon"}
                                      role="decorative"
                                    />}
                                    size="sm"
                                    aria-label="Sort items by column"
                                    onClick={() => {
                                      handleSort(column.key, true);
                                    }}
                                    variant="unstyled"
                                  /> 
                                }
                              </div>)}
                          </div> : <div className="flex space-x-2 justify-between items-center h-10">
                            <div className="">
                              {column.label}
                            </div>
                            {column.filterable && (
                              <div className="flex items-center space-x-2">
                                <Dropdown
                                  usePopper={true}
                                  label={targetFieldFilters[column.key] || <FunnelIcon className="cursor-pointer h-4 w-4 text-gray-400" />}
                                  options={[
                                    { label: L.admin.survey_design.clear, callback: () => { setTargetFieldFilters((prev) => ({ ...prev, [column.key]: '' })) } },
                                    ...(targetFieldFilterOptions[column.key] && targetFieldFilterOptions[column.key].map((option) => ({
                                      label: option, // Display the option as the label in the dropdown
                                      callback: () => {
                                        // Call setExtraColumnFilters when this option is selected
                                        setTargetFieldFilters((prev) => ({ ...prev, [column.key]: option }));
                                      }
                                    })))]
                                  }
                                />
                              </div>
                            )}
                          </div>
                          }
                        </th>
                      })}
                      <th scope="col" className="flex-1 py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Open Case</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody id="support-tbody" className="divide-y divide-gray-200 bg-white">
                    {rows.filter(r => r).map(r => r)}
                  </tbody>
                </table>}
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
}

function MappedSupportRequest(props: {
  app: string,
  channel: string,
  request: SupportRequest,
  idx: number,
  allColumns: Array<{ kind: 'field', key: string, sortable: boolean } | { kind: 'extraColumn', key: string, label: string, filterable?: boolean }>
}) {
  const [app, setApp] = useState(props.app);
  const [request, setRequest] = useState(props.request);
  const config = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);
  const history = useHistory();
  const user = useContext(UserInfoContext);

  const L = useLocalizedStrings();
  const localizeStage = localizeStageFn(L);

  const createSupportCaseRequest = usePost("/support/create_case");
  const reassignSupportRequest = usePost("/support/reassign_case");

  async function getOrCreateSupportCase(leaveUnassigned?: boolean): Promise<string> {
    // we've already got a case, no need to create one.
    let supportCase = request.row.support_case;
    // Handle support cases that the frontend knows about
    if (supportCase) {
      // allow cases to be claimed in the event the system has them.
      // this assignee indicates "unassigned"
      if (!leaveUnassigned && request.row.assigned_to?.toLowerCase() === 'system') {
        await reassignSupportRequest({ caseId: supportCase, reassignTo: user.uid! });
        setRequest({
          ...request,
          row: {
            ...request.row,
            assigned_to: user.uid!,
            assigned_to_name: config.user?.name || 'Me',
            support_case_status: "open"
          }
        });
      }
    } else {
      const result = await createSupportCaseRequest({
        leaveUnassigned,
        channel: props.channel || "default",
        ...(request.row.source ? { contact: request.row.source } : {}),
        ...(Object.keys(request.applicantInfos).length > 0 ? { uid: Object.keys(request.applicantInfos)[0] } : {})
      });
      if (result.supportCase) {
        supportCase = result.supportCase.uid;
        setRequest({
          ...request,
          row: {
            ...request.row,
            ...result.supportCase,
            assigned_to: result.supportCase.assigned_to,
            assigned_to_name: (leaveUnassigned ? 'system' : config.user?.name || 'Me'),
            support_case: result.supportCase.uid,
            support_case_status: "open"
          }
        });
      }
    }

    if (!supportCase) {
      throw new Error('unable to create support case at this time');
    }

    return supportCase;
  }

  const makeSupportCaseUrl = (supportCaseId: string) => `/supportcasev2/${props.channel ?? "default"}/${supportCaseId}`;

  const maskSystemUser = (row: any, field: { key: string }) => {
    const value = row[field.key] || '';
    if(value?.toLowerCase?.() === 'system' && field.key === 'assigned_to_name') {
      return '';
    }
    return value;
  };

  return <>
    {props.allColumns.map((column, idx) => {
      if (column.kind === 'field') {
        return <td key={app + "-" + column.key + "-" + idx} className={`whitespace-wrap py-4 px-2 text-sm text-gray-900 ${column.key === 'unhandled_messages' && 'text-right'}`}>
          {column.key === 'support_case_status' && request.row.support_case_status && <SupportCaseBadge status={request.row.support_case_status as typeof caseStatuses[number]} />}
          {column.key === 'unhandled_messages' && <>{(request.row.messages || []).length}</>}
          {(column.key === 'min' || column.key === 'max' || column.key === 'case_created') && <>{request.row[column.key] && new Date(request.row[column.key]!).toLocaleString(langForDate(context.lang))}</>}
          {column.key === 'applicant_stage' ? <>{localizeStage(getStageFromRequest(request))}</> :
            <>{!['support_case_status',
              'unhandled_messages', 'min', 'max', 'case_created'].includes(column.key)
              ? (maskSystemUser(request.row as any, column) || '').toLocaleString(langForDate(context.lang))
              : <></>}</>}
        </td> 
      } else {
        return <td key={app + "-" + column.key + "-" + idx} className="whitespace-wrap py-4 px-2 text-sm text-gray-900">
          {Object.values(request.applicantInfos).map(info => info[column.key]).filter(Boolean).join(', \n')}
        </td>
      }
    })}

    <td key={app + "-action-case"}>
      <ul className="relative flex gap-2 items-center mt-3 whitespace-nowrap text-right text-sm font-medium sm:pr-6">
        <li>
          <Button 
            onClick={async () => {
              let caseId = await getOrCreateSupportCase(true);
              history.push(makeSupportCaseUrl(caseId!));
            }}
            variant="secondary"
          >
            {L.support.view_case}
          </Button>
        </li>
        <li hidden={!!request.row.support_case && request.row.assigned_to?.toLowerCase() !== 'system'}>
          <Button
            onClick={async () => await getOrCreateSupportCase(false)}
            variant="primary"
          >
            {L.dashboard.claim}
          </Button>
        </li>
      </ul>
    </td>
  </>
}
