import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button, Checkbox, Switch, TextArea, useToast } from "@aidkitorg/component-library";
import * as v0 from '@aidkitorg/types/lib/survey';
import { CollectScopeForUser, PermissionScope } from '@aidkitorg/types/lib/translation/permissions';
import { expandTemplates } from '@aidkitorg/types/lib/translation/v0_to_legacy';
import { Author, ChangeSet, generateUserLocation, getCompressedEvents, mergeStateFromEvents, Patch, reconstructStateFromEvents, State, updateUserActivity, UserActivity } from '@aidkitorg/typesheets/lib/collab';
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { get_deployment, get_rs_host, useAPIPost, usePost } from "./API";
import { QuestionTypes } from "./Applicant";
import { useCollabActions } from './CollabWrapper';
import { ExcludableType, EXCLUDABLE_TYPES_ARRAY, QuestionNodeModal } from "./CondensedSurveyView";
import { LoggedInConfigurationContext, SupportedLanguage, ConfigurationContext } from './Context';
import { useAsyncEffect } from "./Hooks/AsyncEffect";
import { langToWord, supportedLanguages } from './Localization';
import { FacePile, stringToColor } from "./Realtime";
import { generateRandomString, languageContent, nonHookMarkdown, removeDuplicateEvents, SpacedSpinner } from './Util';
import { mapSubsurveyPaths, PathNode, QuestionNodeType, SubsurveyNode, TemplatedBlockNodeType } from "./utils/mapSubsurveyPaths";
import { QuestionProps } from "./Questions/Props";
import { TranslatorMap, TRANSLATORS } from "@aidkitorg/types/lib/translation/questions";
import { AirtableSurveyQuestion } from "@aidkitorg/types/lib/legacy/airtable";

type CopyEdits = { edits: Record<string, Record<SupportedLanguage, { value: string, prev_gen: number }>>, latestEditedObjectId?: string }

const QuestionNode = React.memo(function QuestionNode(props: {
  questionNode: QuestionNodeType,
  previewLang: SupportedLanguage,
  editorLang: SupportedLanguage,
  displayIndex: string,
  addChange: (textChange: string, lang: SupportedLanguage, trackedObjInfo: v0.TrackedObjectInfo) => void
  isSubQuestion?: boolean,
}) {

  // Same function used for editor and preview to determine if disabled
  function getElementDisabledAndEmptyText(lang: SupportedLanguage): [boolean, string] {
    const disabled = props.questionNode.trackedObjInfo?.[lang] === undefined;
    let potentialConfigurationIssueWarning = '';
    if (lang !== 'en' && disabled && props.questionNode.trackedObjInfo?.['en'] !== undefined) {
      potentialConfigurationIssueWarning = '⚠️ ';
    }
    return [disabled, `[[${potentialConfigurationIssueWarning}Not configured for this language]]`];
  }

  const [previewDisabled, previewNotConfiguredText] = getElementDisabledAndEmptyText(props.previewLang);
  const [editorDisabled, editorNotConfiguredText] = getElementDisabledAndEmptyText(props.editorLang);

  const [isModalOpen, setIsModalOpen] = useState(false);
  // This is to prevent lag between local edits and updates to overall collab state.
  // TODO: seeing if this will alleviate cursor jumping: https://react.dev/reference/react-dom/components/input#my-input-caret-jumps-to-the-beginning-on-every-keystroke
  // See also: https://react.dev/reference/react/useCallback (Should you add useCallback everywhere? halfway down the page)
  // See also: https://giacomocerquone.com/blog/keep-input-cursor-still/
  const [localEditorText, setLocalEditorText] =
    useState(
      editorDisabled
        ? editorNotConfiguredText
        : props.questionNode.content?.[props.editorLang] ?? ''
    )

  const otherEditors = (props.questionNode.trackedObjInfo?.[props.editorLang]?.userActivity || [])
    .filter(editor => (editor.uid !== props.questionNode.trackedObjInfo?.[props.editorLang]?.currentAuthor?.uid || editor.tabId !== props.questionNode.trackedObjInfo?.[props.editorLang]?.currentAuthor?.tabId))
    .map((userActivity) => {
      return {
        ...userActivity,
        color: stringToColor(userActivity.name + '/' + userActivity.tabId)
      }
    });

  // localEditorText is intentionally omitted from dependencies, because we don't want this
  // being called for edits we make ourselves. React advises against this, so it could be worth
  // investigating if there's a more canonical solution to this.
  useEffect(() => {
    const viewingAuthor = props.questionNode.trackedObjInfo?.[props.editorLang]?.currentAuthor;
    const editingAuthorsNotMe = (props.questionNode.trackedObjInfo?.[props.editorLang]?.userActivity || [])
      .some((editingAuthor) => (editingAuthor.uid !== viewingAuthor?.uid || editingAuthor.tabId !== viewingAuthor?.tabId));

    // this can cause cursor jumping issues if we are the one editing, and then our own changes get propogated through.
    // we only really care for this useEffect when someone else is also editing the text field.
    if (editingAuthorsNotMe) {
      if (localEditorText !== props.questionNode.content?.[props.editorLang]) {
        setLocalEditorText(props.questionNode.content?.[props.editorLang] ?? '')
      }
    }
  }, [props.questionNode.trackedObjInfo, props.questionNode.content, props.editorLang]);

  const onChangeHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (!editorDisabled) {
      setLocalEditorText(e.target.value);
      // Okay to assert trackedObjInfo not undefined because if it were, editorDisabled would be true
      // and this would be unreachable.
      props.addChange(e.target.value, props.editorLang, props.questionNode.trackedObjInfo!);
    }
  }, [props.addChange, editorDisabled]);

  return (
    <div className='flex justify-between pb-4 pr-4'>
      <div className='w-1/5 cursor-pointer text-black hover:underline' onClick={() => setIsModalOpen(true)}>
        <span style={{ background: stringToColor(props.questionNode.questionType), borderColor: stringToColor(props.questionNode.questionType, true) }} className='mr-1 rounded-xl px-1 py-[1px] border-[1px] text-xs'>{props.questionNode.questionType}</span>
        <span>{props.displayIndex}</span>
        {props.questionNode.optional && <span className='bg-amber-100 mx-1 rounded-xl px-1 py-[1px] border-[1px] border-amber-200 text-xs'>Optional</span>}
        {props.questionNode.hidden && <span className='bg-amber-100 mx-1 rounded-xl px-1 py-[1px] border-[1px] border-amber-200 text-xs'>Hidden</span>}
        {props.questionNode.targetField && <span> - {props.questionNode.targetField}</span>}
      </div>
      {(props.questionNode.content?.[props.previewLang] || localEditorText) && (
        <>
          <div className={`rounded-md w-[526px] ${previewDisabled ? 'bg-gray-100' : 'bg-white'} border p-2 ${props.isSubQuestion ? 'ml-16 mr-1 my-1' : 'm-1'}`}>
            {nonHookMarkdown(previewDisabled ? previewNotConfiguredText : (props.previewLang === props.editorLang ? localEditorText : props.questionNode.content?.[props.previewLang] ?? ''))}
          </div>
          <div className='w-1/3'>
            {/* DEBUG */}
            {/* <div>{props.questionNode.trackedObjInfo?.[props.editorLang]?.id}</div> */}

            {/* The color box that surrounds an editor when another user is working there */}
            <div className='flex flex-row'>
              {otherEditors.map(ec => {
                return <div key={ec.uid + ec.tabId}
                  style={{
                    position: 'relative',
                    backgroundColor: ec.color,
                    paddingLeft: '2px',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: '500',
                    border: `3px solid ${ec.color}`,
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    height: '20px',
                    width: `${100 / otherEditors.length}%`
                  }}>
                  {ec.name}
                </div>
              })}
            </div>
            {/* The editor component */}
            <div className='h-full' style={(otherEditors.length ? { border: `3px solid ${otherEditors[0].color}`, borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' } : {})}>
              <TextArea
                label={`Editable text area for question ${props.questionNode.targetField + props.displayIndex}`}
                disabled={editorDisabled}
                className='rounded-md disabled:bg-gray-200 h-full'
                rows={1}
                value={localEditorText}
                onChange={onChangeHandler}
              />
            </div>
          </div>
        </>
      )}
      <QuestionNodeModal
        questionType={props.questionNode.questionType}
        displayIndex={props.displayIndex}
        targetField={props.questionNode.targetField}
        choices={props.questionNode.choices}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        content={props.questionNode.content?.[props.previewLang]}
      />
    </div>
  );
});

function TemplatedBlockNode(props: {
  templatedBlockNode: TemplatedBlockNodeType,
  previewLang: SupportedLanguage,
  editorLang: SupportedLanguage,
  excludedTypes: Record<ExcludableType, boolean>,
  addChange: (textChange: string, lang: SupportedLanguage, trackedObjInfo: v0.TrackedObjectInfo) => void,
  isPreviewMode: boolean,
  displayIndex: string,
  indexForKey: number,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className='bg-yellow-100 border-t-2 border-l-2 border-slate-300 border-dashed rounded-t-lg'>
      <div className='pl-1 cursor-pointer text-black font-medium hover:underline' onClick={() => setIsModalOpen(true)}>Templated Block</div>
      <Modal size="lg" show={isModalOpen} onHide={() => setIsModalOpen(false)} className='relative'>
        <Modal.Header closeButton={true}>
          <Modal.Title>Templated Block Substitutions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.templatedBlockNode.iterations.map((iteration, i) => {
            return (
              <Accordion type='multiple' key={'iteration' + i + '.'}>
                <AccordionItem value={'item' + i}>
                  <AccordionTrigger className='text-base'>{'Substitution ' + i}</AccordionTrigger>
                  <AccordionContent>
                    {iteration.substitutions.map((substitution, j) =>
                      <div key={'substitution ' + i + '.' + j}>
                        <span>{substitution.key} : </span>
                        <span>{substitution.value}</span>
                      </div>
                    )}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          })}
        </Modal.Body>
      </Modal>
      <div className='pl-4 relative bg-yellow-50'>
        <Node
          nodes={(props.templatedBlockNode.subNodes || [])}
          previewLang={props.previewLang}
          editorLang={props.editorLang}
          excludedTypes={props.excludedTypes}
          addChange={props.addChange}
          prevIndex={props.displayIndex}
          indexForKey={props.indexForKey}
          isPreviewMode={props.isPreviewMode}
        />
      </div>
    </div>
  );
}

// TODO(Riley): PreviewMode should likely be supported for non-collab programs... figure that out
/**
 * This component is used to display each node/question in a survey in a manner very similar to what
 * a participant would see when filling out a survey with a few additional features such as:
 *  - All Likerts and Explanations starting out fully expanded
 *  - All paths through conditional branches are shown, and their condition logic is displayed
 *  - All questions are visible simultaneously without needing to be "answered"
 * 
 * The original motivation for this view is to provide a means to create a PDF of an entire survey
 * in a way that is human-readable and requires minimal manual work
 */
function PreviewNode(props: {
  subNode: QuestionNodeType,
  previewLang: SupportedLanguage,
  excludedTypes: Record<ExcludableType, boolean>,
}) {
  const translatedQuestions = [] as AirtableSurveyQuestion[];
  if (props.subNode.kind) {
    // Special handling for preview of different components

    // Expand all Explanations
    if (props.subNode.kind === 'Explanation') {
      props.subNode.node.collapsible = false;
    }
    // This ensures that all likert subquestions are visible,
    // without having to actually answer them
    if (props.subNode.kind === 'Likert') {
      props.subNode.node.showAll = true;
    }

    // The code that makes this work in normal flows is in questions.ts and v0_to_legacy.ts
    TRANSLATORS[props.subNode.kind as keyof TranslatorMap]?.addFunc(props.subNode.node,
      {
        Question: (q) => { translatedQuestions.push(q) },
        Translation: (q) => '',
        Choice: (q) => {
          // This is a hack, but we need the value to be an object like { en: string } not string like the function wants
          (q.label as any)['Name'] = q.label?.en;
          return q.label as unknown as string
        },
        Role: (q) => '',
        Section: (q) => '',
        Depth: 0,
      });
  }

  return <>
    {!props.excludedTypes[props.subNode.questionType as ExcludableType] && (
      <div className='flex justify-between pb-4'>
        <div className={'rounded-md w-[1000px] border p-2 m-1 bg-white'}>
          {
            /* This is a little hacky. We render just a simple markdown div for subcontent (which is ConditionalContent text),
             * otherwise, render the real component preview.
             */
          }
          {props.subNode.questionType === 'SubContent'
            ? <div>{nonHookMarkdown(props.subNode.content?.[props.previewLang])}</div>
            // 
            : translatedQuestions.map((question, i) => {
              // Likerts are strange and have multiple subquestions that we don't want to render here.
              // Other question types like Liveness also have subquestions and those we *do* want to render.
              if (translatedQuestions[0].fields["Field Type"] === 'Likert' && i > 0) return <></>;

              // Some question kinds like "Milestone" don't have a corresponding QuestionType, so skip them.
              return (question?.fields?.["Field Type"] && question.fields["Field Type"] in QuestionTypes) && (
                // This should end up looking very close to what a particpant would see in a live survey
                React.createElement(
                  QuestionTypes[question.fields["Field Type"]],
                  {
                    key: question.id,
                    Viewer: 'applicant',
                    Question: question.id,
                    ...question.fields,
                    [languageContent(props.previewLang)]: (props.subNode as QuestionNodeType)?.content?.[props.previewLang],
                    setInfoKey: (key: string, value: any, valid: boolean, disqualifies: boolean) => { },
                    // TODO(Riley): we have two options here:
                    // - we could supply a test UID and load their data to be passed into this which would be
                    //   most realistic but would cause certain components to render in unhelpful ways (think encrypted text)
                    // - we could create a single dummy dictionary of components for which having some data is
                    //   necessary in order to render them realistically (think LikertDataSource)
                    //
                    // This does seem to work for some components (TextEntry), but not others (Select)...
                    info: {},
                  } as QuestionProps
                )
              );
            }
            )}
        </div>
      </div>
    )}
  </>
}

function SubNode(props: {
  subNode: PathNode,
  previewLang: SupportedLanguage,
  editorLang: SupportedLanguage,
  excludedTypes: Record<ExcludableType, boolean>,
  addChange: (textChange: string, lang: SupportedLanguage, trackedObjInfo: v0.TrackedObjectInfo) => void,
  isPreviewMode: boolean,
  prevIndex?: string,
  indexForKey: number,
  conditionForKey?: string,
  outerCount: number,
}) {
  let mutableCount = props.outerCount;
  const displayIndex = (props.prevIndex ? props.prevIndex + '.' + mutableCount : mutableCount) + ''; // cast to string

  function ConditionNode(conditionProps: { condition: string, conditionDisplayIndex: string, subNodesTrue: PathNode[], subNodesFalse?: PathNode[] }) {
    return (
      <div className='bg-blue-100 border-t-2 border-l-2 border-slate-300 border-dashed rounded-t-lg'>
        <div className='pl-1 flex justify-between'>{conditionProps.condition}</div>
        <div className='bg-teal-50 pl-4 relative'>
          <Node
            previewLang={props.previewLang}
            editorLang={props.editorLang}
            excludedTypes={props.excludedTypes}
            addChange={props.addChange}
            nodes={(conditionProps.subNodesTrue || [])}
            prevIndex={conditionProps.conditionDisplayIndex}
            indexForKey={props.indexForKey}
            conditionForKey={conditionProps.condition}
            isPreviewMode={props.isPreviewMode}
          />
        </div>
        {conditionProps.subNodesFalse !== undefined && (
          <div className='bg-purple-100 pl-4 relative'>
            <Node
              previewLang={props.previewLang}
              editorLang={props.editorLang}
              excludedTypes={props.excludedTypes}
              addChange={props.addChange}
              nodes={(conditionProps.subNodesFalse || [])}
              prevIndex={conditionProps.conditionDisplayIndex}
              indexForKey={props.indexForKey}
              conditionForKey={conditionProps.condition}
              isPreviewMode={props.isPreviewMode}
            />
          </div>
        )}
      </div>
    )
  }

  switch (props.subNode.type) {
    case 'Question':
      mutableCount++
      if (props.isPreviewMode) {
        return <>
          {
            /* Below is needed to preview conditional content correctly.
            * First we look for whether there are any Conditions in the subContent of this node,
            * and if we find any, we split out the subcontent into PreviewNodes and ConditionNodes.
            */
          }
          {((props.subNode.subContent || []).some((subNode) => subNode.type === 'Condition')) 
            ? props.subNode.subContent?.map((subQuestion, subQIndex) => {
              if (subQuestion.type === 'Question') {
                return <PreviewNode subNode={subQuestion} previewLang={props.previewLang} excludedTypes={props.excludedTypes} />
              } else if (subQuestion.type === 'Condition') {
                return <ConditionNode
                  key={subQuestion.condition + displayIndex + props.indexForKey + props.conditionForKey}
                  condition={subQuestion.condition}
                  conditionDisplayIndex={displayIndex + '→' + subQIndex}
                  subNodesTrue={(subQuestion.subNodesTrue || [])}
                  subNodesFalse={(subQuestion.subNodesFalse || [])}
                />
              }
            })
            : <PreviewNode subNode={props.subNode} previewLang={props.previewLang} excludedTypes={props.excludedTypes} />
          }
        </>
      }
      return (
        <>
          {!props.excludedTypes[props.subNode.questionType as ExcludableType] && (
            <>
              {/* We include editorLang and previewLang in the key to make React reset the component when those change */}
              <QuestionNode
                key={(props.subNode.targetField || props.subNode.questionType) + displayIndex + props.indexForKey + props.conditionForKey + props.editorLang + props.previewLang}
                questionNode={props.subNode}
                previewLang={props.previewLang}
                editorLang={props.editorLang}
                displayIndex={displayIndex}
                addChange={props.addChange}
              />
              {(props.subNode.subContent ?? []).map((subQuestion, subQIndex) => {
                if (subQuestion.type === 'Question') {
                  return <QuestionNode
                    key={((props.subNode as QuestionNodeType).targetField || (props.subNode as QuestionNodeType).questionType) + displayIndex + props.indexForKey + props.conditionForKey + subQIndex + props.editorLang + props.previewLang}
                    questionNode={subQuestion}
                    previewLang={props.previewLang}
                    editorLang={props.editorLang}
                    displayIndex={displayIndex + '→' + subQIndex}
                    addChange={props.addChange}
                    isSubQuestion={true}
                  />
                } else if (subQuestion.type === 'Condition') {
                  return <ConditionNode
                    key={subQuestion.condition + displayIndex + props.indexForKey + props.conditionForKey}
                    condition={subQuestion.condition}
                    conditionDisplayIndex={displayIndex + '→' + subQIndex}
                    subNodesTrue={(subQuestion.subNodesTrue || [])}
                    subNodesFalse={(subQuestion.subNodesFalse || [])}
                  />
                }
              }
              )}
            </>
          )}
        </>
      )
    case 'Section':
      return <h4 key={props.subNode.name + displayIndex + props.indexForKey + props.conditionForKey} className='mt-2'>
        {props.subNode.name}
      </h4>;
    case 'Condition':
      // It is possible to have multiple conditions with the same displayIndex and condition, hence the other pieces of the key
      return <ConditionNode
        key={props.subNode.condition + displayIndex + props.indexForKey + props.conditionForKey}
        condition={props.subNode.condition}
        conditionDisplayIndex={displayIndex}
        subNodesTrue={(props.subNode.subNodesTrue || [])}
        subNodesFalse={(props.subNode.subNodesFalse || [])}
      />
    case 'Templated Block':
      // TODO: maybe we should hash the iterations part of the templated block to assign it a unique ID/key?
      return <TemplatedBlockNode
        key={props.subNode.iterations.length + displayIndex + props.indexForKey + props.conditionForKey}
        displayIndex={displayIndex}
        indexForKey={props.indexForKey}
        excludedTypes={props.excludedTypes}
        templatedBlockNode={props.subNode}
        previewLang={props.previewLang}
        editorLang={props.editorLang}
        addChange={props.addChange}
        isPreviewMode={props.isPreviewMode}
      />
    default:
      return <div key={displayIndex + props.indexForKey}>Unknown node type: {props.subNode.type}</div>;
  }
}

const Node = React.memo(function SubNodeOuter(props: {
  nodes: PathNode[] | undefined,
  previewLang: SupportedLanguage,
  editorLang: SupportedLanguage,
  excludedTypes: Record<ExcludableType, boolean>,
  addChange: (textChange: string, lang: SupportedLanguage, trackedObjInfo: v0.TrackedObjectInfo) => void,
  isPreviewMode: boolean,
  prevIndex?: string,
  indexForKey?: number,
  conditionForKey?: string,
}) {

  return <>
    {(props.nodes || []).map((subNode, i) =>
      <SubNode
        // TODO: Can we instead use a hash of like... kind + content + ? instead of indexes 
        // Alternatively, can we just use TrackedObjectInfo
        // This could be important in cases where someone in Distro adds/removes/moves stuff and the
        // indexes change
        key={(i + 1) + '.' + (props.indexForKey ?? 1)}
        subNode={subNode}
        previewLang={props.previewLang}
        editorLang={props.editorLang}
        excludedTypes={props.excludedTypes}
        addChange={props.addChange}
        prevIndex={props.prevIndex}
        indexForKey={i + 1}
        conditionForKey={props.conditionForKey}
        outerCount={i + 1}
        isPreviewMode={props.isPreviewMode}
      />
    )}
  </>
});

export function CopyEditor() {
  const getUserInfo = usePost('/user/all_info');
  const [currentUser, setCurrentUser] = useState({} as Awaited<ReturnType<typeof getUserInfo>>);
  const config = useContext(ConfigurationContext);
  const languages: SupportedLanguage[] = (config['languages'] ?? 'en').split(',').map((s: string) => s.trim());
  const loggedInConfig = useContext(LoggedInConfigurationContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subsurveyPaths, setSubsurveyPaths] = useState<SubsurveyNode[] | null>(null);
  const [excludedTypes, setExcludedTypes] = useState<Record<ExcludableType, boolean>>({ 'Show Field': true, 'Computed': true, 'Validated': true });
  const [selectedSubsurvey, setSelectedSubsurvey] = useState<SubsurveyNode | null>(null);
  const [editorLang, setEditorLang] = useState<SupportedLanguage>('en');
  const [previewLang, setPreviewLang] = useState<SupportedLanguage>('en');
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  // This is needed because otherwise the closure of mergeEvents has an unfilled version of the userContext,
  // which results in an empty scope being used/returned when the survey needs to update.
  const thisUserRef = useRef<string>();

  // Collab stuff
  const tabId = useRef(generateRandomString(4, 'abcdefghjkmnpqrstuvwxyz123456789'));
  const [author, setAuthor] = useState<Author>();
  const stateRef = useRef<State>();
  const convertEventsTimer = useRef<NodeJS.Timeout>();
  const [changeInProgress, setChangeInProgress] = useState(false);

  // Publishing
  const [survey, setSurvey] = useState<v0.Root>({ survey: [], notifications: [], personas: [] });
  const loadSurvey = usePost('/survey/load_survey');
  const saveSurvey = usePost('/survey/save_survey', { compressRequestPayload: true });
  const [publishing, setPublishing] = useState(false);
  const [lastPublished, setLastPublished] = useState(null as null | string);
  const [surveyValidationErrors, setSurveyValidationErrors] = useState<(string | { hint: string, error: string })[]>();
  const validateSurveyRS = useAPIPost(
    get_rs_host() + "/check_survey_validity",
    {
      includeTokenInData: true,
      includeDeploymentKeyInData: true,
      compressRequestPayload: true
    }
  );

  const { toast } = useToast();

  // State management & actions for Collab Mode 
  const {
    // if collab is not enables, this hook doesn't do much
    collabEnabled,
    // false if collab is actively receiving and merging events, otherwise true
    canPublish,
    // a work in progress. right now, a number indicating how far we've changed from 
    // the last published version
    changesSinceLastPublish,
    // tell listening clients that you just published
    alertJustPublished,
    // refresh collab events and settings
    getCollabState,
    // to be called whenever you make a distro change - this handles sending events in realtime 
    // and also persisting new events to dynamo / s3
    handleNewCollabEvents,
    // updates state var and sets up base events to begin using collab mode.
    // also triggers a refresh on other clients listening browsers.
    enableCollab,
    // updates state var and persisted collab settings.
    // also triggers a refresh on other clients listening browsers.
    disableCollab,
    // uploads large amounts of events and saves a new version, with those events 
    // merged into the exising ops.
    writeNewCollabVersion
  } = useCollabActions(
    'entireprogram',
    tabId.current,
    (events: ChangeSet, userActivity?: UserActivity[]) => {
      mergeEvents(events, userActivity);
    }
  );

  useAsyncEffect(async () => {
    setCurrentUser(await getUserInfo({}));
  }, []);

  // Copied from Config.tsx
  const publish = useCallback(async () => {
    if (!canPublish) return;

    setPublishing(true);
    try {
      const res = (await validateSurveyRS({ survey: JSON.stringify(survey) }))?.value;

      if (res?.errors) {
        // TODO: expose this somewhere
        setSurveyValidationErrors(res.errors);
        setPublishing(false);
        return;
      }
    } catch (e) {
      console.log(e);
      setPublishing(false);
      return;
    }

    let surveyResp;
    try {
      surveyResp = await loadSurvey({ name: 'entireprogram', purpose: "checking survey has not changed" });
    } catch (e) {
      // It's only best effort;
    }

    // Check for updates before saving to reduce clutter in versions page
    if (JSON.stringify(surveyResp?.config) !== JSON.stringify(survey)) {
      const response = await saveSurvey({
        name: 'entireprogram',
        content: JSON.stringify(survey),
        browserTab: tabId.current,
      });

      if (response.status === 'ok') {
        // after publishing, make sure the collab source of truth is aligned with what we just published.
        const toSend = JSON.stringify(getCompressedEvents(stateRef.current!));
        await writeNewCollabVersion({ toSend });

        const collabResp = await getCollabState();
        alertJustPublished(collabResp.changesSinceLastPublish);
      }

      if (response.status === 'ok') {
        toast({
          description: 'Successfully saved survey',
          variant: 'success'
        })
      } else {
        toast({
          description: JSON.stringify(response),
        })
      }
    } else {
      toast({
        description: <span>Everything's up to date!<br /><br />No changes to save.</span>,
      })
    }

    surveyResp = await loadSurvey({ name: 'entireprogram', purpose: "final load after save" });
    setPublishing(false);
    setLastPublished(surveyResp.lastModified);
  }, [survey, tabId, canPublish]);

  const mergeEvents = useCallback(async (events: ChangeSet, userActivity?: UserActivity[]) => {
    if (!events.length) {
      return;
    }
    let latestRootTrackedObject;
    if (!stateRef.current) {
      [latestRootTrackedObject, stateRef.current] = reconstructStateFromEvents(events);
    } else {
      // This function and updateUserActivity update the State that is passed into them, so we shouldn't need to reassign
      // stateRef.current here.
      latestRootTrackedObject = mergeStateFromEvents(stateRef.current, events)[0];
      if (userActivity) {
        updateUserActivity(stateRef.current, userActivity);
      }
    }

    // Note: If we called save_survey with this freshestSurvey object, that would be
    // equivalent to the "Real" save that happens upon publishing in Distro
    const freshestSurvey = latestRootTrackedObject.read(true);
    const updatedSubsurveyPaths = updateSubsurveyPaths(freshestSurvey);
    setSurvey(freshestSurvey);

    const selectedSubsurveyName = selectedSubsurvey?.name || window.location.hash.slice(1) || '';
    const prechosenSubSurvey = updatedSubsurveyPaths.find((sp: SubsurveyNode) => sp.name === selectedSubsurveyName)
    setSelectedSubsurvey(prechosenSubSurvey ?? null);

    setChangeInProgress(false);
  }, []);

  function convertCopyEditsToCollabEvents(copyEdits: CopyEdits): ChangeSet {
    let events: ChangeSet = [];
    for (const id of Object.keys(copyEdits.edits || {})) {
      for (const lang of Object.keys((copyEdits.edits || {})[id] || {})) {
        // Create Collab "patch" operation
        const edit = copyEdits.edits[id]![(lang as SupportedLanguage)];
        const op: Patch = {
          op: 'patch',
          id: Math.random().toString(), // TODO: should we be using uuids here? Collab doesn't but that doesn't mean no.
          value: edit.value,
          prev_gen: Math.max(0, edit.prev_gen),
          author
        };
        events.push([id, 'primitive', op]);
      }
    }
    events = removeDuplicateEvents(events);
    return events;
  }

  /**
   * Traverses an entire progrogram config and constructs (via mapSubsurveyPaths()) the paths
   * one could take while completing it, branching at conditionals, as a tree of renderable nodes
   */
  function updateSubsurveyPaths(survey: v0.Root): SubsurveyNode[] {
    const expandedSurvey = expandTemplates(survey) as v0.ExpandedSurvey;
    let scope: PermissionScope | undefined;
    if ('users' in expandedSurvey) {
      // We do not use expandedSurvey.survey here because we want the unexpanded templates
      // so that we can correctly scope fields like q_ITER inside TemplatedBlocks.
      // NOTE: This may not function as expected if tags are assigned as part of iterations/substitutions
      // inside TemplatedBlocks themselves, but... that's whack anyway.
      scope = CollectScopeForUser(thisUserRef.current!, (survey as v0.ExpandedSurvey).survey, expandedSurvey.users);
    }
    const subSurveyPaths = mapSubsurveyPaths(survey, scope);
    setSubsurveyPaths(subSurveyPaths);
    return subSurveyPaths;
  }

  // General Initialization
  useEffect(() => {
    if (subsurveyPaths !== null || !currentUser.uid) {
      return;
    }

    thisUserRef.current = currentUser.uid;

    if ((currentUser?.tags ?? []).some((tag) => ['copy_edit', 'admin'].includes(tag))) {
      if (!author) {
        setAuthor({ uid: currentUser.uid, tags: currentUser.tags, tabId: tabId.current });
      }
      setIsLoading(true);
      (async () => {
        const resp = await getCollabState();
        const [rootTrackedObject, state] = reconstructStateFromEvents(resp.events);
        stateRef.current = state;
        stateRef.current.currentAuthor = { uid: currentUser.uid, tags: currentUser.tags, tabId: tabId.current }
        const surveyFromCollabEvents = rootTrackedObject.read(true);
        updateSubsurveyPaths(surveyFromCollabEvents);
        setIsLoading(false);
      })();
    }

  }, [subsurveyPaths, currentUser]);

  // TODO: Double check that this even needs to be a useEffect
  useEffect(() => {
    const surveyFromSearchParams = window.location.hash.slice(1) || '';
    if (subsurveyPaths && subsurveyPaths.length > 0) {
      if (surveyFromSearchParams) {
        const prechosenSubSurvey = subsurveyPaths.find((sp: SubsurveyNode) => sp.name === surveyFromSearchParams)
        setSelectedSubsurvey(prechosenSubSurvey ?? null);
      } else {
        window.location.hash = '#' + subsurveyPaths[0].name;
        setSelectedSubsurvey(subsurveyPaths[0]);
      }
    }
  }, [subsurveyPaths]);

  // Somewhat analogous to Config.tsx's handleUpdatesInner(), i.e. a function
  // that is called whenever there is an update, in this case to "edits"
  // This is how the various children update the collab state.
  // TODO: Could we move all of this down to the leaf nodes themselves so that the parent doesn't need
  // to manage so much state, and these functions don't need to get passed up/down the tree?
  const addChange = useCallback((textChange: string, lang: SupportedLanguage, trackedObjInfo: v0.TrackedObjectInfo) => {
    if (!trackedObjInfo[lang]) {
      // TODO: this may not be possible, but might be worth keeping just in case
      console.error('TrackedObject edit with language not in TrackedObject', trackedObjInfo, lang);
      return;
    }
    const { id, prev_gen } = trackedObjInfo[lang]!;

    if (collabEnabled) {
      const changesToEdits = {
        edits: {
          [id]: {
            [lang]: { value: textChange, prev_gen: prev_gen }
          },
        },
        latestEditedObjectId: id
      } as CopyEdits;

      if (convertEventsTimer.current) {
        clearTimeout(convertEventsTimer.current);
      }

      // When we convert events to collab, they count as the same generation. We thus have to only convert them once, 
      // so we only have the latest version of that generation (most recent edit)
      convertEventsTimer.current = setTimeout(() => {
        setChangeInProgress(true);
        if (stateRef.current && author && changesToEdits.latestEditedObjectId) {
          stateRef.current.myActivity = generateUserLocation(author, changesToEdits.latestEditedObjectId);
        }
        const copyEditsAsCollabChangeSet = convertCopyEditsToCollabEvents(changesToEdits);
        handleNewCollabEvents(copyEditsAsCollabChangeSet, stateRef.current?.myActivity, stateRef.current);
      }, 750);
    }
  }, [handleNewCollabEvents]);

  // Rendering options split out into if/else to avoid nested ternaries
  if (isLoading || subsurveyPaths === null) {
    return (
      <div className='m-2'>
        <span className='text-[2rem] font-medium text-gray-900 py-3'>{'Copy Editor is loading... '}</span><SpacedSpinner className='text-sm p-2 mb-[2px] ml-2' />
      </div>
    );
  } else if (collabEnabled === false) {
    return (
      <div className='m-2 text-sm font-bold text-gray-900'>
        {'Copy Editor requires Collab to be enabled. Enable it, or ask and Admin to do so'}
      </div>
    )
  }

  // The editor, once loaded and confirmed collab is enabled
  return (
    <div className='w-max'>

      {/* Header bar */}
      {/* z-index here to ensure dropdown stays on top */}
      <div className='z-10 sticky top-0 left-0 bg-white w-screen noprint'>
        <div className='flex py-2 shadow-md'>
          <h3 className='mx-2 my-2'>Copy Editor</h3>
          {/* Dropdown for selecting Survey to edit */}
          <div className='ml-2 mx-3 min-w-[200px]'>
            <label htmlFor="subsurvey-select" className="block mb-2 text-sm font-bold text-gray-900">Select survey</label>
            <select
              id="subsurvey-select"
              value={selectedSubsurvey?.name || subsurveyPaths?.[0]?.name || ''}
              onChange={(e) => {
                setSelectedSubsurvey(subsurveyPaths.find((subsurvey) => subsurvey.name === e.target.value) ?? null);
                window.location.hash = '#' + e.target.value;
              }}
              className="mt-1 block px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 w-[200px]"
              aria-label="Select subsurvey to edit"
            >
              {subsurveyPaths.map((subsurvey) => (
                <option key={subsurvey.name} value={subsurvey.name}>
                  {subsurvey.name}
                </option>
              ))}
            </select>
          </div>
          {/* Toggles for showing/hiding certain Question Types */}
          <div className='mx-3 min-w-fit'>
            <fieldset className='grid grid-cols-2 grid-rows-2'>
              <legend className='text-sm font-bold text-gray-900'>
                Excluded Question Types
              </legend>
              {EXCLUDABLE_TYPES_ARRAY.map((t) =>
                <Checkbox
                  key={t}
                  checked={excludedTypes[t]}
                  onChange={(e) => {
                    setExcludedTypes(prevState => {
                      const newExcludedTypes = { ...prevState }
                      newExcludedTypes[t] = !newExcludedTypes[t];
                      return newExcludedTypes;
                    });
                  }}
                  label={t}
                />
              )}
            </fieldset>
          </div>
          {/* Preview column language selector */}
          <div className='mx-3 w-[200px]'>
            <label htmlFor="language-select" className="block mb-2 text-sm font-bold text-gray-900">{'Select preview language'}</label>
            <select
              id="preview-language-select"
              value={previewLang}
              onChange={(e) => {
                setPreviewLang(e.target.value as SupportedLanguage);
              }}
              className="mt-1 block px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 w-[200px]"
              aria-label="Select preview language"
            >
              {languages.map((lang) => (
                <option key={lang} value={lang}>
                  {supportedLanguages[lang]} {lang !== 'en' ? '(' + langToWord(lang as SupportedLanguage, 'Name') + ')' : null}
                </option>
              ))}
            </select>
          </div>
          {/* Editor column language selector */}
          <div className='mx-3 w-[200px]'>
            <label htmlFor="language-select" className="block mb-2 text-sm font-bold text-gray-900">{'Select editor language'}</label>
            <select
              id="editor-language-select"
              value={editorLang}
              onChange={(e) => {
                setEditorLang(e.target.value as SupportedLanguage);
              }}
              className="mt-1 block px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 w-[200px]"
              aria-label="Select editor language"
            >
              {languages.map((lang) => (
                <option key={lang} value={lang}>
                  {supportedLanguages[lang]} {lang !== 'en' ? '(' + langToWord(lang as SupportedLanguage, 'Name') + ')' : null}
                </option>
              ))}
            </select>
          </div>
          <div className='mx-3 block'>
            {loggedInConfig?.capabilities?.copyEditPublish
              ? <>
                <div className='text-sm font-bold text-gray-900'>Publish changes</div>
                <button aria-label="Publish Changes" onClick={publish} disabled={publishing || !canPublish}
                  className={'border-0 rounded-md mx-3 my-1.5 h-9 bg-blue-100 p-1.5 ' + (canPublish ? 'text-blue-600 hover:text-blue-800 hover:bg-blue-300' : 'text-gray-400')}>
                  {publishing
                    ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    : <div className="relative inline-block">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>
                    </div>
                  }
                </button>
              </>
              : <div className='text-sm text-gray-700'>
                Publishing disabled. Changes will be saved automatically, but will not become live until an admin publishes
              </div>
            }
          </div>
          <div className='block mx-3 text-sm font-normal text-gray-700'>
            <div>Users here</div>
            <FacePile name={currentUser.name || currentUser.uid || ''} channel={get_deployment() + ':entireprogram'} browserTab={tabId.current} />
          </div>
          <div className='block mx-3 text-sm font-normal text-gray-700'>
            {changeInProgress ? 'Saving changes...' : 'Changes saved ✅'}
          </div>
          <div className='block mx-3 text-sm font-normal text-gray-700'>
            <div className='mb-1.5'>Preview mode</div>
            <Switch label="Preview mode" hideLabel={true} checked={isPreviewMode} onCheckedChange={(checked) => setIsPreviewMode(checked)} />
          </div>
        </div>
      </div>

      {/* This is where the survey/editor is rendered */}
      {selectedSubsurvey && (
        <div className='flex p-2 bg-gray-100'>
          <div className={`w-64 my-2 ${isPreviewMode ? 'ml-[200px]' : 'ml-2'} h-max p-2`} style={{ minWidth: '1600px' }}>
            <h2>{selectedSubsurvey.name}</h2>
            <Node
              nodes={selectedSubsurvey.subNodes}
              excludedTypes={excludedTypes}
              addChange={addChange}
              previewLang={previewLang}
              editorLang={editorLang}
              isPreviewMode={isPreviewMode}
            />
          </div>
        </div>
      )}
    </div>
  );
}
