import { useEffect, useState } from "react";
import { usePost } from "./API";
import { Button, DateDisplay, Loading, Modal, Paragraph, useToast } from "@aidkitorg/component-library";

interface Job {
  name: string;
  lastRunTime: number | null;
  nextRunTime: number | null;
  nextRunTimeIfRunNow: number | null;
}

export default function CronPage() {
  const getJobs = usePost('/dev/cron/enumerate');
  const triggerJob = usePost('/admin/cron/schedule');
  const { toast } = useToast();

  const [jobs, setJobs] = useState<Job[] | null>(null);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [searchText, setSearchText] = useState<string>(''); 

  const refreshJobs = async () => {
    setJobs(null);
    const response = await getJobs({});
    setJobs(response.jobs.sort((a, b) => a.name.localeCompare(b.name)));
  };

  useEffect(() => {
    refreshJobs();
  }, [])

  const submitJob = async (job: Job) => {
    const response = await triggerJob({ name: job.name });

    const description = 'error' in response
      ? response.error as string
      : 'message' in response
        ? response.message
        : undefined;

    if (response.status === 'ok') {
      setSelectedJob(null);
      refreshJobs();
      toast({ title: 'Cron job submitted', description, variant: 'success' })
    } else {
      toast({ title: 'An error occurred', description, variant: 'error' })
    } 
  };

  if (jobs === null) {
    return <Loading />
  } else {
    return (
      <>
        {selectedJob && (
          <Modal 
            open 
            onClose={() => setSelectedJob(null)} 
            title={`Run ${selectedJob.name}`}
          >
            <Paragraph measure='wide'>
              Running this job manually may change its schedule. 
              <br /> <br />
              It's currently set to run at:
              <br />
              <DateOrFallbackText date={selectedJob.nextRunTime} fallbackText='an unknown time' />
              <br /> <br />
              If you run it manually it will instead next run at:
              <br />
              <DateOrFallbackText date={selectedJob.nextRunTimeIfRunNow} fallbackText='an unknown time' />
              <br /> <br />
              Are you sure you want to run it now?
            </Paragraph>
            <div className="flex flex-row justify-end gap-x-3">
              <Button variant='secondary' onClick={() => setSelectedJob(null)}>Cancel</Button>
              <Button variant='danger' onClick={() => submitJob(selectedJob)}>Run now</Button>
            </div>
          </Modal>
        )}

        <input
          value={searchText}
          placeholder='Search for a job'
          onChange={(e) => {
            setSearchText(e.currentTarget.value);
          }}
          className="block rounded-md bg-white mt-10 ml-4 px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
          autoFocus
        />

        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <Header content='Job Name' />
                      <Header content='Last Run' />
                      <Header content='Next Run' />
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {jobs.filter((job) => job.name.includes(searchText)).map((job) =>
                      <tr key={job.name}>
                        <DataCell content={job.name} />
                        <DataCell content={<DateOrFallbackText date={job.lastRunTime} fallbackText='Never run' />} />
                        <DataCell content={<DateOrFallbackText date={job.nextRunTime} fallbackText='Unknown' />} />
                        <DataCell content={<Button variant='secondary' onClick={() => setSelectedJob(job)}>Run job now</Button>} />
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

function Header(props: { content: string }) {
  return (
    <th scope="col" className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-1">
      {props.content}
    </th>
  );
}

function DataCell(props: { content: React.ReactNode }) {
  return (
    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-1">
      {props.content}
    </td>
  );
}

function DateOrFallbackText(props: { date: number | null, fallbackText: string }) {
  if (props.date) {
    return <DateDisplay className="text-[length:inherit]" utcValue={props.date} displayInUTC={false} optionOverrides={{ second: undefined }} />;
  } else {
    return <>{props.fallbackText}</>;
  }
}
