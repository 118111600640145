import type { Schedule } from '@aidkitorg/aidkit/lib/common/cron';
import { useLocalizedStrings } from '../Localization';
import type { Translations } from "@aidkitorg/i18n/lib";

export const prettifySchedule = (schedule: Schedule, L: Translations) => {
  if (schedule === 'Immediate') return 'Immediate';
  let scheduleString = '';
  if ( schedule.day === 'All' || !schedule.day ) {
    scheduleString += L.applicant.check_status.every_day;
  } else if ( schedule.day.kind === 'Weekdays' ) {
    const weekdays = Object.entries(schedule.day).map(([weekday, value]) => {
      if (value) return weekday
    });
    scheduleString += L.applicant.check_status.every_X.replace('$X', weekdays.join(', '));
  } else if ( schedule.day.kind === 'DateSpecific') {
    scheduleString += L.applicant.check_status.every_X.replace('$X', schedule.day.dates.map(date => {
      return `${date.slice(0, 2)}/${date.slice(2, 4)}`
    }).join(', '));
  }

  if (schedule.hour === 'All' || !schedule.hour ) {
    // do nothing. it will just run at the first minute of the day
  } else if ( schedule.hour.kind === 'Specific' ) {
    scheduleString += " " + L.applicant.check_status.at_hours_X.replace('$X', schedule.hour.specific.join(', '));
  } else if ( schedule.hour.kind === 'Interval' ) {
    scheduleString += L.applicant.check_status.every_X_hours.replace('$X', schedule.hour.interval.toString());
  }

  if (schedule.minute === 'All' || !schedule.minute ) {
    // do nothing. it will just run at the first second of the hour
  } else if ( schedule.minute.kind === 'Specific' ) {
    scheduleString += " " + L.applicant.check_status.at_minutes_X.replace('$X', schedule.minute.specific.join(', '));
  } else if ( schedule.minute.kind === 'Interval' ) {
    scheduleString += L.applicant.check_status.every_X_minutes.replace('$X', schedule.minute.interval.toString());
  }
  return scheduleString;
}
