import { GeoJSON } from "ol/format";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { styleFunction } from "../utils";
import { DEFAULT_PROJECTION } from "./constants";
export const processGeoJSONSources = async ({ map, sources, layersRef, setLayerCounts, visibleLayers, }) => {
    for (const { url, data, layerName, minZoomLevel, colorMapping, fillColor, strokeColor, pointConfig, properties } of sources) {
        if (layersRef.current[layerName])
            continue;
        let vectorSource;
        if (data) {
            const geojsonData = JSON.parse(data);
            let features = [];
            if (geojsonData && Array.isArray(geojsonData.features)) {
                try {
                    features = new GeoJSON().readFeatures(data, {
                        featureProjection: DEFAULT_PROJECTION,
                    });
                }
                catch (error) {
                    console.warn('Error parsing GeoJSON features:', error);
                }
            }
            vectorSource = new VectorSource({
                format: new GeoJSON(),
                features: features,
            });
            vectorSource.getFeatures().forEach((feature) => {
                feature.set("sourceData", { layerName, fillColor, strokeColor, colorMapping, pointConfig, properties });
            });
        }
        else if (url) {
            try {
                const response = await fetch(url);
                const geojsonData = await response.json();
                let features = [];
                if (geojsonData && Array.isArray(geojsonData.features)) {
                    try {
                        features = new GeoJSON().readFeatures(geojsonData, {
                            featureProjection: DEFAULT_PROJECTION,
                        });
                    }
                    catch (error) {
                        console.warn('Error parsing GeoJSON features:', error);
                    }
                }
                features.forEach((feature) => {
                    if (!feature.getGeometry()) {
                        console.warn("Feature missing geometry:", feature);
                    }
                    feature.set("sourceData", { layerName, fillColor, strokeColor, colorMapping, pointConfig, properties });
                });
                vectorSource = new VectorSource({
                    format: new GeoJSON(),
                    features,
                });
            }
            catch (error) {
                console.error(`Error loading GeoJSON for ${layerName}:`, error);
            }
        }
        if (vectorSource) {
            const pointCount = vectorSource.getFeatures().filter(f => f.getGeometry()?.getType() === "Point").length;
            const polygonCount = vectorSource.getFeatures().filter(f => {
                const geometryType = f.getGeometry()?.getType();
                return geometryType === 'Polygon' || geometryType === 'MultiPolygon';
            }).length;
            setLayerCounts(prevCounts => ({
                ...prevCounts,
                [layerName]: { points: pointCount, polygons: polygonCount }
            }));
            const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: (feature) => styleFunction({ feature, colorMapping, defaultFillColor: fillColor, defaultStrokeColor: strokeColor, pointConfig }),
                minZoom: minZoomLevel,
                visible: visibleLayers[layerName],
                renderBuffer: 50,
                updateWhileAnimating: false,
                updateWhileInteracting: false,
            });
            vectorLayer.setVisible(visibleLayers[layerName]);
            map.addLayer(vectorLayer);
            layersRef.current[layerName] = vectorLayer;
        }
    }
};
