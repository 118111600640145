import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button, useToast } from '@aidkitorg/component-library';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { usePost } from '../API';
import type { RealtimeServerEvent } from 'openai/resources/beta/realtime/realtime'
import { LoggedInConfigurationContext } from '../Context';

export default function CoachEval(props: {}) {
  const config = useContext(LoggedInConfigurationContext);

  if(config?.experimental?.enableAICoach) {
    return <ActualCoachEval />;
  } else {
    return <></>;
  }
}

function ActualCoachEval(props: {}) {

  const session = usePost('/coach/conversation');
  const [rtc, setRTC] = useState<RTCPeerConnection>();
  const [mic, setMic] = useState<MediaStream>();
  const audioRef = useRef<HTMLAudioElement>(null);
  const rateLimits = useRef({ token: 'inf', requests: 'inf' } as { token: number | 'inf', requests: number | 'inf' });
  const chat = useRef<string[]>([]);
  const [events, setEvents] = useState<string[]>([]);

  async function init(clientSecret: string, audioElem: HTMLAudioElement, mic: MediaStream) {

    // Create a peer connection
    const pc = new RTCPeerConnection();

    // Set up to play remote audio from the model
    pc.ontrack = e => audioElem.srcObject = e.streams[0];

    pc.addTrack(mic.getTracks()[0]);

    const channel = pc.createDataChannel('oai-events');

    channel.addEventListener('message', msg => {
      const event = JSON.parse(msg.data) as RealtimeServerEvent;
      setEvents(prev => prev.concat(event.type));

      switch (event.type) {
        case 'response.audio_transcript.delta':
          if (chat.current.length === 0) chat.current.push('');
          chat.current[chat.current.length - 1] += event.delta;
          break;
        case 'response.output_item.added':
          chat.current.push('');
          break;
        case 'rate_limits.updated':
          rateLimits.current = {
            token: event.rate_limits.find(r => r.name === 'tokens')?.remaining || 0,
            requests: event.rate_limits.find(r => r.name === 'requests')?.remaining || 0
          };
          break;
        default:
          break;
      }
    });

    // Set up data channel for sending and receiving events
    // const dc = pc.createDataChannel("oai-events", { protocol: 'binary' });

    // Start the session using the Session Description Protocol (SDP)
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    const baseUrl = "https://api.openai.com/v1/realtime";
    const sdpResponse = await fetch(`${baseUrl}`, {
      method: "POST",
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${clientSecret}`,
        "Content-Type": "application/sdp"
      },
    });

    const answer = {
      type: "answer",
      sdp: await sdpResponse.text(),
    } as const;

    await pc.setRemoteDescription(answer);

    return pc;
  }


  async function start() {
    const { token } = await session({ modality: 'audio' }) as any;

    if(!token) {
      return;
    }

    // Add local audio track for microphone input in the browser
    const newMic = await navigator.mediaDevices.getUserMedia({
      audio: true
    });

    setMic(newMic);

    const newRTC = await init(token!.value, audioRef.current!, newMic);
    setRTC(newRTC);
  }

  async function end() {
    mic?.getTracks().forEach(t => t.stop());
    rtc?.close();
  }

  return <div className="space-x-4 space-y-4">
    <Button onClick={start}>Start</Button>
    <Button onClick={end}>End</Button>
    <table className="table">
      <thead>
        <tr>
          <th>Limit</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(rateLimits.current).map(([name, value], i) =>
          <tr key={name}>
            <td>{name}</td>
            <td>{value}</td>
          </tr>
        )}
      </tbody>
    </table>
    <Accordion type="multiple">
      <AccordionItem value="chat">
        <AccordionTrigger>
          <h3>Chat</h3>
        </AccordionTrigger>
        <AccordionContent>
          {chat.current.map((c, i) => <p key={i}>{c}</p>)}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="events">
        <AccordionTrigger>
          <h3>Events</h3>
        </AccordionTrigger>
        <AccordionContent>
          <ul>
            {events.map((c, i) => <li key={i}>
              {c}
            </li>)}
          </ul>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
    <audio id="playback" hidden autoPlay ref={audioRef} />
  </div>
}
