import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ClickableButton } from '../Components/Button';
import { useLocalizedStrings } from '../Localization';
import { SpacedSpinner } from '../Util';
import { useRoboScreenerAPI } from './useRoboScreenerAPI';

const labelStyling = "block text-sm font-medium text-gray-700";
const inputStyling = "w-full block mb-8 rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6";

/** This page runs a dry RS run on all non-hidden applicants and
 *  lets the user view any deltas.
 */
export function ApplicantUpdatesSection(props: any) {

  const L = useLocalizedStrings();
    
  const [showUpdatesWithField, setShowUpdatesWithField] = useState('');
  const [limit, setLimit] = useState(50 as number | undefined);
  const [uids, setUIDs] = useState('');
  const [debugKeys, setDebugKeys] = useState({} as Record<string, string>);
  const [forceComputeFields, setForceCompute] = useState(null as string[] | null);

  const { applicantUpdates, computing, refreshRS, saveUpdates } = useRoboScreenerAPI({ 
    uids: uids && uids.length > 0 ? uids.replace(/\n/g, " ").replace(/,/g, " ").split(" ").filter((l: string) => l.length > 0).map(t => t.trim()) : [], 
    allApplicants: !uids || uids.length === 0, 
    manualOnly: true,
    limit,
    debugKeys,
    computeOptions: {
      ...(forceComputeFields ? { forceComputeFields } : {}),
    }
  });

  return <>
    <div>
      <label htmlFor="uids" className={labelStyling}>Enter UIDs separated by commas, spaces, or lines</label>
    </div>
    <textarea id="uids" className={inputStyling} value={uids} 
      onChange={(e) => setUIDs(e.target.value) } />
    <div>
      <label htmlFor="account-number" className={labelStyling}>
        Limit
      </label>
      <input
        type="text"
        value={limit}
        onChange={(e) => setLimit(parseInt((e.target?.value || '').replace(/[^0-9]/g, '') || '0'))}
        className={inputStyling}
        placeholder="50"
      />
    </div>
    <div className="my-2">
      <label htmlFor="account-number" className={labelStyling}>
        Debug Keys (Separate with commas)
      </label>
      <input
        type="text"
        value={Object.keys(debugKeys || {}).join(',')}
        onChange={(e) => {
          let value = e.target.value || '';
          let parsed = value.split(',');
          setDebugKeys(parsed.reduce((acc, cur) => { acc[cur] = cur; return acc; }, {} as Record<string, string>));
        }}
        className={inputStyling}
      />
    </div>
    <div className="my-2">
      <label htmlFor="account-number" className={labelStyling}>
        Force Compute Target Fields (Separate with commas)
      </label>
      <input
        type="text"
        value={(forceComputeFields || []).join(',')}
        onChange={(e) => {
          let value = e.target.value || '';
          let parsed = value.split(',');
          setForceCompute(parsed.map(v => v.trim()));
        }}
        className={inputStyling}
      />
    </div>
    <ClickableButton color="green" onClick={() => refreshRS()}>
      {computing && <SpacedSpinner />}{L.admin.survey_design.refresh_applicants} ({L.admin.survey_design.limit} {limit})
    </ClickableButton>&nbsp;
    {Object.keys(applicantUpdates || {}).length > 0 ? 
      <>
        <ClickableButton color="blue" onClick={() => saveUpdates()}>
          {computing && <SpacedSpinner />}{L.admin.survey_design.save_this_batch} ({L.admin.survey_design.affects} {Object.keys(applicantUpdates || {}).length})
        </ClickableButton>&nbsp;
        <ClickableButton color="blue" onClick={() => saveUpdates({ onlySaveAffected: true })}>
          {computing && <SpacedSpinner />}Save Affected ({L.admin.survey_design.affects} {Object.keys(applicantUpdates || {}).filter(k => Object.keys(applicantUpdates[k] || {}).length > 0).length})
        </ClickableButton>
      </> : <></>}
    <br/>
    {showUpdatesWithField && <>
      <small><em>{L.admin.survey_design.showing_applicants_with_update} </em>{showUpdatesWithField}</small>&nbsp;
      <ClickableButton color="gray" onClick={() => setShowUpdatesWithField('')}>{L.admin.survey_design.clear}</ClickableButton>
    </>}
    <br/>
    {(Object.keys(applicantUpdates || {}) || []).map((uid: string) => {
      if (!applicantUpdates[uid]) return <></>;
      if (showUpdatesWithField && applicantUpdates[uid][showUpdatesWithField] === undefined) return <></>;

      return <div className="my-1" key={`top-div-uid-${uid}`}>
        <div className="flex-1 pt-1.5">
          <Link to={`/applicant/${uid}`} target="_blank">{uid}</Link>
        </div>
        <div className="flex-1 pt-1.5 m-2 flex flex-wrap">
          {(Object.keys(applicantUpdates[uid] || {}) || []).map((key: string) => {
            if (showUpdatesWithField && key !== showUpdatesWithField) return <></>
            return <div className="m-1" key={`rs-page-${uid}-${key}`}>
              <ClickableButton color="gray" 
                onClick={() => setShowUpdatesWithField(key)}>
                {key}: {applicantUpdates[uid][key]}
              </ClickableButton>
            </div>
          })}
        </div>
      </div>
    })}
  </>
}
