import {v4 as uuidv4} from "uuid";
import * as v0 from "../survey"
import { AirtableSurvey, AirtableSurveyChoice, AirtableSurveyQuestion, AirtableTranslations, PopulatedQuestion, Section } from "../legacy/airtable"
import { CompileExpressionToSQL, CompileNudgeExprToSQL, findFields } from "./expr_to_sql";
import { CompileExpressionToJS } from "./expr_to_js";
import { pruneForUserTags } from "./permissions";
import { addGeneratedSubsurveys, expandGenericTemplates, jsonReplace } from "./templates";
import { Add, TRANSLATORS } from "./questions";
import { calcEnableKey, enableKeyIsValid } from "../eval";
// hack to support cross-package mocking in tests
export let v4 = uuidv4;

function compileConditional(conditional: v0.Code | v0.BooleanExpr | undefined) {
  if (!conditional) return undefined;
  if (typeof conditional === 'string') {
    return conditional;
  } else {
    const toReturn = CompileExpressionToJS(conditional as v0.BooleanExpr);
    return toReturn;
  }
}

// FNV-1a hash
export function hash(str?: string) {
  /*jshint bitwise:false */
  var i, l, hval = 0x811c9dc5;
  for (i = 0, l = (str || '').length; i < l; i++) {
    hval ^= (str || '').charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  return (hval >>> 0).toString(16).substring(-8);
}

export function expandDataExchanges(groups: v0.CrossProgramDataExchange[]): AirtableSurveyQuestion[] {
  const RentalSurvey: AirtableSurvey['Rental Survey'] = [];

  const fieldInfoDefsAdditions = groups.flatMap(g => 
    (g.import?.postProcessing?.map(p => p.targetField) ?? [])
      .concat((g.import?.fields.flatMap(findFields) ?? []))
  );

  function addRole(name: string, properties: string[]) {
    return hash(name + JSON.stringify(properties));
  }

  // this is purely to register these target fields in info_defs
  for(const field of fieldInfoDefsAdditions) {
    RentalSurvey.push({
      id: v4(),
      createdTime: '',
      fields: {
        Question: "--",
        "Field Type": "Single Line Text Entry",
        "Who Can Edit": [addRole('Screener', ['Screener'])],
        "Target Field": field,
        "Additional Options": ["Hidden","Optional"],
      }
    } as typeof RentalSurvey[number]);
  }

  return RentalSurvey;
}

export function expandPayments(groups: v0.Payment[]): AirtableSurveyQuestion[] {
  const RentalSurvey: AirtableSurvey['Rental Survey'] = [];

  for (const group of groups) {
    if (!enableKeyIsValid(group.enableKey, group) || !group.targetField) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Skipping payment "${group.name}" for invalid enableKey or missing target field`);
      }
      continue;
    }

    RentalSurvey.push({
      id: v4(),
      createdTime: '',
      fields: {
        Question: group.name + ' Payment',
        "Field Type": 'Payment',
        "Target Field": group.targetField,
        "English Content": '--',
        "Spanish Content": '--',
        "Metadata": JSON.stringify({ 
          amount: group.amount,
          type: group.type,
          ledger: group.ledger,
          card_id: group.cardIdField,
          sql: group.condition.kind === 'SQL' ? 
            group.condition.sql : 
            CompileExpressionToSQL({ cond: group.condition.expr, orderBy: group.condition.orderBy }),
          auto_associate: !!group.autoAssociate,
          higher_spending_limit: group.higherSpendingLimit,
          address: (group as any).mailingAddress,
          physical_address: (group as any).physicalAddress,
          ssn: (group as any).ssnField,
          birth_date: (group as any).birthDateField,
          auto_retry: group.autoRetry,
          contact: (group as any).contactField
        }),
      }
    })
  }
  return RentalSurvey
}

export function expandNotifications(groups: v0.Notification[]): [AirtableSurveyQuestion[], AirtableTranslations[]] {
  groups = groups || [];
  const Translations: AirtableSurvey['Translations'] = [];
  const RentalSurvey: AirtableSurvey['Rental Survey'] = [];

  function addTranslation(content: v0.Content) {
    let text = Array.isArray(content) ? { 'en': JSON.stringify(content) } : content;
    const id = v4();
    Translations.push({
      id,
      createdTime: '',
      fields: text || { 'en': '--', 'es': '--' }
    })
    return id;
  }

  function addRole(name: string, properties: string[]) {
    return hash(name + JSON.stringify(properties));
  }

  function fieldNotSetMoreThanADayAgo(field: string): v0.BooleanExpr {
    return {
      kind: 'Or',
      clauses: [
        {
          kind: 'And',
          clauses: [{
            field,
            kind: 'Exists'
          }, {
            kind: 'Not',
            clause: {
              field,
              kind: 'Last Modified',
              ago: {
                amount: 1,
                unit: 'days'
              }
            }
          }]
        },
        {
          field,
          kind: 'DoesntExist'
        }
      ]
    };
  }

  for (const group of groups) {		
    if ((group.kind === 'InlineNotification' || !enableKeyIsValid(group.enableKey, group, { allowVersions: ['legacy']}))) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Skipping notification "${group.name}" for invalid enableKey or because it is an InlineNotification`);
      }
      continue;
    }

    let contentIsArray = {
      'message': Array.isArray(group.initial_notification.message),
      'email_message': Array.isArray(group.initial_notification.email_message)
    }

    let timezone: v0.Timezone | undefined = group.scheduleSettings?.timezone;

    // A note for this function:
    // the pattern ...(foo ? { bar: ... } : {}) is a way to optionally add a parameter to an object at creation.
    // For example, if we have:
    // x = {
    //   y: 'yes',
    //   ...(foo ? { bar: 'baz' } : {})
    // }
    // then if foo is truthy, x looks like: { y: 'yes', bar: 'baz' }
    // else x looks like: { y: 'yes' }

    // For SMS
    if (group.contactMethod !== 'email' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
      id: v4(),
      createdTime: '',
      fields: {
        Question: group.name + ' Initial SMS',
        "Field Type": 'Notification',
        "Target Field": group.targetPrefix + '_sms',
        "Translation": [addTranslation(group.initial_notification.message)],
        "English Content": '--',
        "Spanish Content": '--',
        "Who Can Edit": [addRole('Screener', ['Screener'])],
        "Metadata": JSON.stringify({
          component: group,
          links: (group.initial_notification.subsurveys || []).reduce((o, s) => {
            o[s.variable] = s.name;
            return o;
          }, {} as Record<string, string | v0.TrackedLink>),
          contact_method: group.contactMethod,
          notify_applicant_phone: true,
          notify_applicant_phone_key: (group.recipient as v0.CustomContact)?.phoneField,
          notify_applicant_email_key: (group.recipient as v0.CustomContact)?.emailField,
          service_sid: group.smsService,
          channel: group.channel,
          timezone,
          ...(contentIsArray['message'] 
            ? { messageBlocks: group.initial_notification.message } 
            : {}),
					
          sql: group.initial_notification.enabled_when.kind === 'SQL' 
            ? group.initial_notification.enabled_when.sql 
            : group.recipient === 'Unsubmitted Applicant' 
              ? CompileNudgeExprToSQL({
                cond: group.initial_notification.enabled_when.expr,
                nudgeContact: 'phone_number',
                targetField: group.targetPrefix + '_sms',
              }) 
              : CompileExpressionToSQL({
                cond: {
                  kind: 'And',
                  clauses: [
                    {
                      field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
                      kind: 'Exists'
                    },
                    fieldNotSetMoreThanADayAgo(group.targetPrefix + '_email'),
                    fieldNotSetMoreThanADayAgo(group.targetPrefix + '_whatsapp'),
                    group.initial_notification.enabled_when.expr
                  ]},
                orderBy: group.initial_notification.enabled_when.orderBy
              }),
          js: group.initial_notification.enabled_when.kind === 'Click' ? 
            CompileExpressionToJS(group.initial_notification.enabled_when.expr) :
            '',
          is_nudge: group.recipient === 'Unsubmitted Applicant'
        }),
      }
    })

    // For Email
    if (group.contactMethod !== 'sms' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
      id: v4(),
      createdTime: '',
      fields: {
        Question: group.name + ' Initial Email',
        "Field Type": 'Notification',
        "Target Field": group.targetPrefix + '_email',
        "Translation": [addTranslation((group.initial_notification.email_message || group.initial_notification.message))],
        "English Content": '--',
        "Spanish Content": '--',
        "Who Can Edit": [addRole('Screener', ['Screener'])],
        "Metadata": JSON.stringify({
          component: group,
          links: (group.initial_notification.subsurveys || []).reduce((o, s) => {
            o[s.variable] = s.name;
            return o;
          }, {} as Record<string, string | v0.TrackedLink>),
          contact_method: group.contactMethod,
          notify_applicant_email: true,
          notify_applicant_phone_key: (group.recipient as v0.CustomContact)?.phoneField,
          notify_applicant_email_key: (group.recipient as v0.CustomContact)?.emailField,
          subject: group.initial_notification.email_subject,
          service_sid: group.smsService,
          channel: group.channel,
          timezone,
          ...(contentIsArray['email_message'] 
            ? { messageBlocks: group.initial_notification.email_message } 
            : contentIsArray['message'] 
              ? { messageBlocks: group.initial_notification.message } 
              : {}),
          sql: group.initial_notification.enabled_when.kind === 'SQL' 
            ? group.initial_notification.enabled_when.sql 
            : group.recipient === 'Unsubmitted Applicant' 
              ? CompileNudgeExprToSQL({
                cond: group.initial_notification.enabled_when.expr,
                nudgeContact: 'email',
                targetField: group.targetPrefix + '_email'
              }) 
              : CompileExpressionToSQL({
                cond: {
                  kind: 'And',
                  clauses: [
                    {
                      field: (group.recipient as v0.CustomContact)?.emailField || 'email',
                      kind: 'Exists'
                    },
                    fieldNotSetMoreThanADayAgo(group.targetPrefix + '_sms'),
                    fieldNotSetMoreThanADayAgo(group.targetPrefix + '_whatsapp'),
                    group.initial_notification.enabled_when.expr
                  ]},
                orderBy: group.initial_notification.enabled_when.orderBy
              }),
          from: group.emailSender,
          js: group.initial_notification.enabled_when.kind === 'Click' ? 
            CompileExpressionToJS(group.initial_notification.enabled_when.expr) :
            '',
          is_nudge: group.recipient === 'Unsubmitted Applicant'
        }),
      }
    })

    // For WhatsApp
    if (group.contactMethod !== 'email' && group.contactMethod !== 'sms') RentalSurvey.push({
      id: v4(),
      createdTime: '',
      fields: {
        Question: group.name + ' Initial WhatsApp',
        "Field Type": 'Notification',
        "Target Field": group.targetPrefix + '_whatsapp',
        "Translation": [addTranslation(group.initial_notification.message)],
        "English Content": '--',
        "Spanish Content": '--',
        "Who Can Edit": [addRole('Screener', ['Screener'])],
        "Metadata": JSON.stringify({
          component: group,
          links: (group.initial_notification.subsurveys || []).reduce((o, s) => {
            o[s.variable] = s.name;
            return o;
          }, {} as Record<string, string | v0.TrackedLink>),
          contact_method: group.contactMethod,
          notify_applicant_whatsapp: true,
          content_template: group.initial_notification.content_template,
          notify_applicant_phone_key: (group.recipient as v0.CustomContact)?.phoneField,
          notify_applicant_email_key: (group.recipient as v0.CustomContact)?.emailField,
          // TODO: if we ever want a particular WhatsApp service SID for notifications only, 
          // we'll need to update this.
          service_sid: undefined,
          timezone,
          ...(contentIsArray['message'] 
            ? { messageBlocks: group.initial_notification.message } 
            : {}),
          sql: group.initial_notification.enabled_when.kind === 'SQL' 
            ? group.initial_notification.enabled_when.sql 
            : group.recipient === 'Unsubmitted Applicant' 
              ? CompileNudgeExprToSQL({
                cond: group.initial_notification.enabled_when.expr,
                nudgeContact: 'phone_number',
                targetField: group.targetPrefix + '_whatsapp',
              })
              : CompileExpressionToSQL({
                cond: {
                  kind: 'And',
                  clauses: [
                    {
                      field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
                      kind: 'Exists'
                    },
                    fieldNotSetMoreThanADayAgo(group.targetPrefix + '_email'),
                    fieldNotSetMoreThanADayAgo(group.targetPrefix + '_sms'),
                    group.initial_notification.enabled_when.expr
                  ]}
              }),
          js: group.initial_notification.enabled_when.kind === 'Click' ? 
            CompileExpressionToJS(group.initial_notification.enabled_when.expr) :
            '',
          is_nudge: group.recipient === 'Unsubmitted Applicant'
        }),
      }
    })

    for (const followup of group.followups || []) {
      const thisHash = hash(group.targetPrefix + 
				followup.suffix + 
				JSON.stringify(followup.send_if));
      if (thisHash !== followup.enableKey) {
        continue;
      }

      let recipient = (followup.recipient || group.recipient) as v0.CustomContact;

      let followupIsArray = {
        'message': Array.isArray(followup.message),
        'email_message': Array.isArray(followup.email_message)
      }

      // For SMS
      if (group.contactMethod !== 'email' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
        id: v4(),
        createdTime: '',
        fields: {
          Question: group.name + ' Followup SMS ' + followup.suffix,
          "Field Type": 'Notification',
          "Target Field": group.targetPrefix + '_' + followup.suffix + '_sms',
          "Translation": [addTranslation(followup.message as v0.Text)],
          "English Content": '--',
          "Spanish Content": '--',
          "Who Can Edit": [addRole('Screener', ['Screener'])],
          "Metadata": JSON.stringify({
            component: group,
            links: (followup.subsurveys || []).reduce((o, s) => {
              o[s.variable] = s.name;
              return o;
            }, {} as Record<string, string | v0.TrackedLink>),
            contact_method: group.contactMethod,
            notify_applicant_phone: true,
            notify_applicant_phone_key: recipient?.phoneField,
            notify_applicant_email_key: recipient?.emailField,
            service_sid: group.smsService,
            channel: group.channel,
            timezone,
            ...(followupIsArray['message'] ? { messageBlocks: followup.message } : {}),
            sql: followup.send_if.kind === 'SQL' 
              ? followup.send_if.sql 
              : (group.recipient === 'Unsubmitted Applicant' 
                ? CompileNudgeExprToSQL({
                  cond: followup.send_if.expr,
                  nudgeContact: 'phone_number',
                  followupAfter: followup.after,
                  followupTargetField: group.targetPrefix + '_' + followup.suffix + '_sms',
                  targetField: group.targetPrefix + '_sms',
                }) 
                : CompileExpressionToSQL({
                  cond: {
                    kind: 'And',
                    clauses: [
                      {
                        field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
                        kind: 'Exists'
                      },
                      {
                        field: group.targetPrefix + '_sms',
                        kind: 'Exists'
                      },
                      {
                        field: group.targetPrefix + '_sms',
                        kind: 'Last Modified',
                        ago: followup.after
                      },
                      fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_email'),
                      followup.send_if.expr
                    ]},
                  orderBy: followup.send_if.orderBy
                })),
            is_nudge: group.recipient === 'Unsubmitted Applicant'
          }),
        }
      })

      // For Email
      if (group.contactMethod !== 'sms' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
        id: v4(),
        createdTime: '',
        fields: {
          Question: group.name + ' Followup Email ' + followup.suffix,
          "Field Type": 'Notification',
          "Target Field": group.targetPrefix + '_' + followup.suffix + '_email',
          "Translation": [addTranslation((followup.email_message || followup.message) as v0.Content)],
          "English Content": '--',
          "Spanish Content": '--',
          "Who Can Edit": [addRole('Screener', ['Screener'])],
          "Metadata": JSON.stringify({
            component: group,
            links: (followup.subsurveys || []).reduce((o, s) => {
              o[s.variable] = s.name;
              return o;
            }, {} as Record<string, string | v0.TrackedLink>),
            contact_method: group.contactMethod,
            notify_applicant_email: true,
            notify_applicant_phone_key: recipient?.phoneField,
            notify_applicant_email_key: recipient?.emailField,
            subject: followup.email_subject,
            service_sid: group.smsService,
            channel: group.channel,
            timezone,
            ...(followupIsArray['email_message'] 
              ? { messageBlocks: followup.email_message } 
              : followupIsArray['message'] ? { messageBlocks: followup.message } : {}),
            sql: followup.send_if.kind === 'SQL' 
              ? followup.send_if.sql 
              : group.recipient === 'Unsubmitted Applicant' 
                ? CompileNudgeExprToSQL({
                  cond: followup.send_if.expr,
                  nudgeContact: 'email',
                  followupAfter: followup.after,
                  followupTargetField: group.targetPrefix + '_' + followup.suffix + '_email',
                  targetField: group.targetPrefix + '_email',
                }) 
                : CompileExpressionToSQL({
                  cond: {
                    kind: 'And',
                    clauses: [
                      {
                        field: (group.recipient as v0.CustomContact)?.emailField || 'email',
                        kind: 'Exists'
                      },
                      {
                        field: group.targetPrefix + '_email',
                        kind: 'Exists'
                      },
                      {
                        field: group.targetPrefix + '_email',
                        kind: 'Last Modified',
                        ago: followup.after
                      },
                      fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_sms'),
                      followup.send_if.expr
                    ]
                  },
                  orderBy: followup.send_if.orderBy
                }),
            from: group.emailSender,
            is_nudge: group.recipient === 'Unsubmitted Applicant'
          }),
        }
      })

      // For WhatsApp
      if (group.contactMethod !== 'email' && group.contactMethod !== 'sms') RentalSurvey.push({
        id: v4(),
        createdTime: '',
        fields: {
          Question: group.name + ' Followup WhatsApp ' + followup.suffix,
          "Field Type": 'Notification',
          "Target Field": group.targetPrefix + '_' + followup.suffix + '_whatsapp',
          "Translation": [addTranslation(followup.message as v0.Text)],
          "English Content": '--',
          "Spanish Content": '--',
          "Who Can Edit": [addRole('Screener', ['Screener'])],
          "Metadata": JSON.stringify({
            component: group,
            links: (followup.subsurveys || []).reduce((o, s) => {
              o[s.variable] = s.name;
              return o;
            }, {} as Record<string, string | v0.TrackedLink>),
            contact_method: group.contactMethod,
            notify_applicant_whatsapp: true,
            content_template: followup.content_template,
            notify_applicant_phone_key: recipient?.phoneField,
            notify_applicant_email_key: recipient?.emailField,
            service_sid: undefined,
            timezone,
            ...(followupIsArray['message'] ? { messageBlocks: followup.message } : {}),
            sql: followup.send_if.kind === 'SQL' 
              ? followup.send_if.sql 
              : group.recipient === 'Unsubmitted Applicant' 
                ? CompileNudgeExprToSQL({
                  cond: followup.send_if.expr,
                  nudgeContact: 'phone_number',
                  followupAfter: followup.after,
                  followupTargetField: group.targetPrefix + '_' + followup.suffix + '_whatsapp',
                  targetField: group.targetPrefix + '_whatsapp',
                }) 
                : CompileExpressionToSQL({
                  cond: {
                    kind: 'And',
                    clauses: [
                      {
                        field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
                        kind: 'Exists'
                      },
                      {
                        field: group.targetPrefix + '_whatsapp',
                        kind: 'Exists'
                      },
                      {
                        field: group.targetPrefix + '_whatsapp',
                        kind: 'Last Modified',
                        ago: followup.after
                      },
                      fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_email'),
                      fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_sms'),
                      followup.send_if.expr
                    ]
                  }
                })
          }),
        }
      })
    }
  }

  return [RentalSurvey, Translations]
}

export function deepCopy(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((o: any) => deepCopy(o));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((o: any, k: string) => {
      o[k] = deepCopy(obj[k]);
      return o;
    }, {});
  } 	
  return obj;
}

/**
 * @deprecated
 * A weakly-typed but null-safe version of expandTemplatesStrict
 * @param root 
 * @returns 
 */
export function expandTemplates(root: v0.Root): v0.Root {
  if (!root) return [];
  const expanded = expandGenericTemplates(root);
  return addGeneratedSubsurveys(expanded);
}
export function expandTemplatesStrict<T extends v0.Root>(root: T): T {
  if (!root) throw new Error("Cannot expand nullish root");
  const expanded = expandGenericTemplates(root);
  return addGeneratedSubsurveys(expanded);
}

export function extractNotificationsAndPayments (root: v0.Survey, scope: v0.BooleanExpr[], options?: {
  email?: string,
  smsService?: string,
}): [v0.Notification[], v0.Payment[]] {
  let notifs: v0.Notification[] = [];
  let payments: v0.Payment[] = [];
  for (const section of root) {
    if (section.kind === 'Collection') {
      const [n, p] = extractNotificationsAndPayments(section.components, [...scope, section.options.limitedToApplicants].filter((a) => a) as v0.BooleanExpr[])
      notifs = notifs.concat(n);
      payments = payments.concat(p);
    }
    if (section.kind === 'Subsurvey') {
      const [n, p] = extractNotificationsAndPayments(section.sections, scope.filter((a) => a) as v0.BooleanExpr[])
      notifs = notifs.concat(n);
      payments = payments.concat(p);
    }
    if (section.kind === 'Section') {
      section.components.forEach((component) => {
        if (component.kind === 'InlineNotification') {
          notifs.push(component as v0.InlineNotification);
        }
      });
    }
    if (section.kind === 'Notification') {
      notifs.push(section as v0.NotificationGroup);
    }
    else if ((section as any).amount !== undefined) {
      payments.push(section as v0.Payment);
    }
    else if (section.kind === 'GiveCard Mailing') {
      payments.push({
        name: section.kind,
        type: 'givecard_mail',
        ...section,
        kind: 'Payment',
        amount: '0',
      });
    } else if (section.kind === 'USBank Card Mailing') {
      payments.push({
        name: section.kind,
        type: 'usbank_card_mail',
        ...section,
        kind: 'Payment',
        amount: '0',
      });
    }
  }
  return [notifs, payments];
}

export function extractNotificationsAndPaymentsWithSearchTrees (root: v0.Survey, scope: v0.BooleanExpr[],
/** for recursion, should not be set by original callers */
  parentTree?: string[]
): [(v0.Notification & {searchTree: string[]})[], (v0.Payment & {searchTree: string[]})[]] {
  let notifs: (v0.Notification & {searchTree: string[]})[] = [];
  let payments: (v0.Payment & {searchTree: string[]})[] = [];
  for (const section of root) {
    if (section.kind === 'Collection') {
      let blockName = typeof (section as any).name === 'object' ? ((section as any).name?.en || '') : ((section as any).name || '');
      const pathName = blockName || (section as any).targetField;
      const searchTree = [...(parentTree??[]), section.kind + (pathName ? ': ' + pathName : '')];
      const [n, p] = extractNotificationsAndPaymentsWithSearchTrees(section.components, [...scope, section.options.limitedToApplicants].filter((a) => a) as v0.BooleanExpr[], searchTree)
      notifs = notifs.concat(n);
      payments = payments.concat(p);
    }
    if (section.kind === 'Subsurvey') {
      const blockName = typeof section.title === 'object' ? (section.title?.en || '') : (section.title || '');
      const pathName = blockName || section.path;
      const searchTree = [...(parentTree??[]), section.kind + (pathName ? ': ' + pathName : '')];
      const [n, p] = extractNotificationsAndPaymentsWithSearchTrees(section.sections, scope.filter((a) => a) as v0.BooleanExpr[], searchTree)
      notifs = notifs.concat(n);
      payments = payments.concat(p);
    }
    if (section.kind === 'Section') {
      section.components.forEach((component) => {
        if (component.kind === 'InlineNotification') {
          let blockName = typeof section.title === 'object' ? (section.title?.en || '') : (section.title || '');
          let pathName = blockName || (section as any).targetField;
          const sectionSearchTree = [...(parentTree??[]), section.kind + (pathName ? ': ' + pathName : '')];
          blockName = typeof (component as any).name === 'object' ? ((component as any).name?.en || '') : ((component as any).name || '');
          pathName = blockName || (component as any).targetField;
          const searchTree = [...(sectionSearchTree??[]),  component.kind + (pathName ? ': ' + pathName : '')];
          notifs.push({...(component as v0.InlineNotification), searchTree});
        }
      });
    }
    if (section.kind === 'Notification') {
      let blockName = typeof (section as any).name === 'object' ? ((section as any).name?.en || '') : ((section as any).name || '');
      const pathName = blockName || (section as any).targetField;
      const searchTree = [...(parentTree??[]), section.kind + (pathName ? ': ' + pathName : '')];
      notifs.push({...(section as v0.NotificationGroup), searchTree});
    }
    else if ((section as any).amount !== undefined) {
      let blockName = typeof (section as any).name === 'object' ? ((section as any).name?.en || '') : ((section as any).name || '');
      const pathName = blockName || (section as any).targetField;
      const searchTree = [...(parentTree??[]), section.kind + (pathName ? ': ' + pathName : '')];
      payments.push({...(section as v0.Payment), searchTree});
    }
    else if (section.kind === 'GiveCard Mailing') {
      let blockName = typeof (section as any).name === 'object' ? ((section as any).name?.en || '') : ((section as any).name || '');
      const pathName = blockName || (section as any).targetField;
      const searchTree = [...(parentTree??[]), section.kind + (pathName ? ': ' + pathName : '')];
      payments.push({
        name: section.kind,
        type: 'givecard_mail',
        ...section,
        kind: 'Payment',
        amount: '0',
        searchTree,
      });
    } else if (section.kind === 'USBank Card Mailing') {
      let blockName = typeof (section as any).name === 'object' ? ((section as any).name?.en || '') : ((section as any).name || '');
      const pathName = blockName || (section as any).targetField;
      const searchTree = [...(parentTree??[]), section.kind + (pathName ? ': ' + pathName : '')];
      payments.push({
        name: section.kind,
        type: 'usbank_card_mail',
        ...section,
        kind: 'Payment',
        amount: '0',
        searchTree,
      });
    }
  }
  return [notifs, payments];
}

export function condenseConfig(config?: v0.ProgramConfig): AirtableSurvey['Configuration'] {
  if (!config) return [];
  let result: AirtableSurvey['Configuration'] = [];
	
  // Map each config key to a result row

  let add = (key: string, value: string) => {
    result.push({
      id: v4(),
      createdTime: '',
      fields: {
        Key: key,
        Value: value
      }
    });
  }
	

  add('comms', JSON.stringify(config.comms || {}));
  if (config.comms?.alternateContacts) add('alternate_contact', JSON.stringify(config.comms.alternateContacts));

  // Application
  add('application', JSON.stringify(config.application || {}));

  // Fraud
  add('fraud', JSON.stringify(config.fraud || {}));

  // Experimental Feature Flags
  add('enable_clean_uploads', config.experimental?.enableCleanUploads ? 'true' : '');
  add('enable_roboscreener_validate_clean_uploads', config.experimental?.enableRoboscreenerValidateCleanUploads ? 'true' : '');
  add('lateral_unnesting_in_dashboards', config.experimental?.lateralUnnestingInDashboards ? 'true' : '');
  add('experimental_enableQuickJS', config.experimental?.enableQuickJS ? 'true' : '');
  add('enable_custom_query_filtering', config.experimental?.enableCustomQueryFiltering ? 'true' : '');
  add('enable_applicant_portal', config.experimental?.enableApplicantPortal ? 'true' : '');
  add('enable_geocode_google_maps_fallback', config.experimental?.geocodeGoogleMapsFallback ? 'true' : '');

  // Capabilities
  add('import_key_id', config.capabilities?.importKeyId || '');
  add('encrypted_report_configs', JSON.stringify(config.capabilities?.encryptedReportConfigs || []));

  for (let row of config.legacyAirtable?.configuration || []) {
    if (row.key === 'alternate_contact' && config.comms?.alternateContacts) {
      continue; // skip, this was already added.
    }
    if (row.key) add(row.key, row.value);
  }

  return result;
}

export function v0ToLegacy (root: v0.Root, options?: {
  includeConfig?: boolean,
  roles?: string[],
}): AirtableSurvey {
  root = expandTemplates(deepCopy(root));
	
  if (options?.roles) {
    if (Array.isArray(root)) {
      root = pruneForUserTags(root, options?.roles || []);
    } else {
      root.survey = pruneForUserTags(root.survey, options?.roles || []);
    }
  }

  const v0: v0.Survey = Array.isArray(root) ? root : root.survey;

  let RentalSurvey: AirtableSurvey['Rental Survey'] = [];
  const Choices: AirtableSurvey['Choices'] = [];
  const Configuration: AirtableSurvey['Configuration'] = [];
  let Translations: AirtableSurvey['Translations'] = [];
  const Roles: AirtableSurvey['Roles'] = [];

  let RentalSurveyToAppend: AirtableSurvey['Rental Survey'] = [];

  const slugsAtTheBottom: Record<string, boolean> = {};
  const addTargetFieldsToSurvey = (slugs: string[]) => {
    for (const slug of slugs) {
      slugsAtTheBottom[slug] = true;
    }
  }

  if (!Array.isArray(root)) {
    const [n, p] = extractNotificationsAndPayments(root.survey, []);

    const notifs = expandNotifications([...root.notifications || [], ...n]);
    RentalSurveyToAppend = notifs[0];
    Translations = notifs[1];

    const payments = expandPayments([...root.payments || [], ...p]);
    RentalSurveyToAppend = RentalSurveyToAppend.concat(payments);

    const dataExchanges = expandDataExchanges([...root.crossProgramDataExchange || []]);
    RentalSurveyToAppend = RentalSurveyToAppend.concat(dataExchanges);

    if (options?.includeConfig) {
      Configuration.push(...condenseConfig(root.config));
      
      // Add target fields from custom Actions to the survey so RS can set them
      if (root.config?.payments && typeof root.config.payments.failure_handling === 'object' && 
	    root.config.payments.failure_handling.customActionButtons) {
        const uniqueKeys = Array.from(new Set(
          root.config.payments.failure_handling.customActionButtons.flatMap(button => button.newInfo.map(info => info.key))));
        addTargetFieldsToSurvey(uniqueKeys);
      }

      // Add target fields for auto retry to the survey so RS can set them
      for (const payment of p) {
        if (payment.autoRetry) {
          const tfs = payment.autoRetry.onFailure.map(tf => tf.key);
          addTargetFieldsToSurvey(tfs);
        }
      }
    }
  }

  function addTranslation(content: v0.Content) {
    let text = Array.isArray(content) ? { en: JSON.stringify(content) } : content;
    const id = hash(JSON.stringify(text));
    Translations.push({
      id,
      createdTime: '',
      fields: text || { 'en': '--', 'es': '--' }
    })
    return id;
  }

  function addChoice(choice: v0.Select['choices'][number]) {
    const id = hash(JSON.stringify(choice));
    Choices.push({
      id,
      createdTime: '',
      fields: {
        Name: choice.value,
        Translation: [addTranslation(choice.label)],
        "English Content": '--',
        "Spanish Content": '--',
        'Exclusive Option': choice.exclusive,
        'Select All': choice.selectAll,
        'Other Field': choice.otherField
      }
    })
    return id;
  }

  function addRole(name: string, properties: string[]) {
    const id = hash(name + JSON.stringify(properties));
    Roles.push({
      id,
      createdTime: '',
      fields: {
        Name: name,
        'Additional Properties': properties
      }
    })
    return id;
  }

  function combineConditionals(component: any, condition?: string): string | undefined {
    if ((component as any).conditionalOn && condition) {
      return '(' + condition + ')' + ' && ' + compileConditional((component as any).conditionalOn);
    }
    if ((component as any).conditionalOn) {
      return compileConditional((component as any).conditionalOn);
    }
    if (condition) {
      return condition;
    }
  }

  let pushSection = (section: v0.CollectionComponent | v0.ConditionalBlock, depth: number, condition?: string, parentResetUnreachableFields?: boolean): string => {
    const id = v4();
    if (section.kind === 'Section') {
      RentalSurvey.push({
        id,
        createdTime: '',
        fields: {
          Question: section.title.en,
          "Field Type": 'Section',
          "Translation": [addTranslation(section.title)],
          "English Content": '--',
          "Spanish Content": '--',
          "Metadata": JSON.stringify({ 
            sectionId: section.sectionId,
            conditional: combineConditionals(section, condition),
            screenerMode: section.screenerMode || 'All At Once',
            depth,
            hideNextButton: section.hideNextButton, 
            hidden: !!section.hidden,
          }),
        }
      })
    }

		type HasComponents<T> = T extends { components: any } ? T : never;
		type TypeHasProp<T, Prop extends string> = T extends { [key in Prop]?: any } ? T : never;
		for (const component of ((section as HasComponents<v0.CollectionComponent | v0.ConditionalBlock>).components || [section])) {
		  let Metadata = {} as any;
		  Metadata.conditional = combineConditionals(component, condition);
		  if (!component.kind) {
		    // For Payment and some legacy types, kind is optional. just go next.
		    continue;
		  }
		  if (component.kind === 'Conditional Block') {
		    const resetUnreachableFields = !!component.resetUnreachableFields || parentResetUnreachableFields;

		    // If either the parent or the component itself should reset unreachable fields, make sure to let all subsequent children know
		    pushSection(component, depth, Metadata.conditional, resetUnreachableFields)

		    // We also want to propagate any clearing to corresponding otherwise blocks, but with the inverse conditional
		    if (component.otherwise) {
		      pushSection({
		        kind: 'Conditional Block',
		        components: component.otherwise!,
		        // Note: this is technically incorrect but conditionalOn is not used when
		        // called recursively like this
		        conditionalOn: '' as unknown as v0.Code,
		      }, depth, '(' + (condition === undefined ? 'true' : condition) + ' && !(' + Metadata.conditional + '))', resetUnreachableFields)

		    }
		  } else {
		    const kind = component.kind as keyof typeof TRANSLATORS;
		    const Translator = TRANSLATORS[kind];

		    if (!Translator || !Translator.addFunc) { 
		      continue;
		    }
		    Translator.addFunc(component as any, {
		      Question: (q) => {
		        if (component.kind === 'Section') {
		          // Calculate depth based on any sections above this one
		          return pushSection(component, depth + 1, Metadata.conditional, !!parentResetUnreachableFields);
		        }
            
		        let metadata = JSON.parse(q.fields.Metadata || '{}') as {
		          textToSpeech?: v0.CommonProperties['textToSpeech'],
		          [key: string]: any;
		        };

		        if ((component as any).textToSpeech) {
		          metadata.textToSpeech = (component as TypeHasProp<v0.Block, 'textToSpeech'>).textToSpeech!
		        } 

		        if ((component as any).customAudio) {
		          metadata.customAudio = (component as TypeHasProp<v0.Block, 'customAudio'>).customAudio!
		        }
		
		        // TODO: Stack this with any section conditionals etc
		        if (Metadata.conditional) {
		          metadata.conditional = Metadata.conditional;
		        }
            
		        // Ensure that each question knows if it should clear based on the conditional
		        // except for Computed, Validated, and Lookup fields, we should not clear those manually and let computations run as expected
		        if (parentResetUnreachableFields && kind !== 'Computed' && kind !== 'Validated' && kind !== 'Lookup') {
		          metadata.resetUnreachableFields = true;
		        }

		        //console.log(metadata) - this is extremely noisy in RS
		        if ('optional' in component && component.optional) {
		          q.fields['Additional Options'] = [...(q.fields['Additional Options'] || []), 'Optional'];
		        }
		        if ('hidden' in component && component.hidden) {
		          q.fields['Additional Options'] = [...(q.fields['Additional Options'] || []), 'Hidden'];
		        }

		        q['id'] = '';
		        q.fields['id'] = '';
		        if (q.fields['Field Type'] !== 'Subsurvey') q.fields['Question'] = '';
		        const newId = hash(JSON.stringify(q));
		        q['id'] = newId;
		        q.fields['id'] = newId;
		        if (q.fields['Field Type'] !== 'Subsurvey') q.fields['Question'] = newId;

		        q.fields['Kind'] = component.kind;

		        RentalSurvey.push(q)

		        // Get any slugs and mark those to be added
		        // Do this in a safe way so it doesn't crash the whole thing
		        try {
		          let slugs = TRANSLATORS[kind].getSlugs(component as any)?.slugs;

		          const metadataSlugs: string[] = []
		          slugs.forEach((slug) => {
		            metadataSlugs.push(slug.startsWith('_') ? (component as any).targetField + slug : slug)
		          })

		          metadata.slugs = metadataSlugs

		          addTargetFieldsToSurvey(slugs.map(slug => slug.startsWith('_') ? (component as any).targetField + slug : slug));
		        } catch (e) { console.warn("Error adding slugs to question:", q, e) }

		        q.fields.Metadata = JSON.stringify(metadata);
		      },
		      Translation: addTranslation,
		      Choice: addChoice,
		      Role: addRole,
		      Section: pushSection,
		      Depth: depth
		    })
		  }
		}
		return id;
  }

  for (const section of v0) {
    pushSection(section, 0);
  }

  const slugSurvey: AirtableSurvey['Rental Survey'] = [];
  for (const key in slugsAtTheBottom) {
    slugSurvey.push({
      id: v4(),
      createdTime: '',
      fields: {
        Question: key,
        "Field Type": "Single Line Text Entry",
        "Additional Options": ["Hidden","Optional"],
        "Translation": [addTranslation({ en: key })],
        "English Content": '--',
        "Spanish Content": '--',
        "Target Field": key,
        "Who Can Edit": [addRole('Screener', ['Screener'])]
      }
    })
  }

  return {
    'Rental Survey': [...RentalSurvey, ...RentalSurveyToAppend, ...slugSurvey],
    Roles,
    Choices,
    Configuration,
    Translations
  }
}

export class SimulatedSurvey {
  airtableSurvey: AirtableSurvey;
  choicesById: Record<string, AirtableSurveyChoice['fields']> = {};
  questionsById: Record<string, AirtableSurveyQuestion['fields']> = {};
  translationsById: Record<string, AirtableTranslations['fields']> = {};

  private constructor() {
    this.airtableSurvey = {} as AirtableSurvey;
  }
  public static load(airtable: AirtableSurvey, params?: {
    includeAudit?: boolean
  }) {
    const survey = new SimulatedSurvey();

    // Do some computations that are commonly required for most other functions
    survey.airtableSurvey = airtable;

    let afterImport = [] as typeof survey.airtableSurvey['Rental Survey']
    for (const question of survey.airtableSurvey['Rental Survey']) {
      if (question.fields['Field Type'] === 'Import') {
        /*
				const metadata = question.fields['Metadata'] || '{}';
				const unpackedMetadata = JSON.parse(metadata);
				if (unpackedMetadata.url && !unpackedMetadata.url.includes('-audit')) {
						const toMerge = v0ToLegacy(JSON.parse(await getSurveyDefinition(unpackedMetadata.url)));
						for (const question of toMerge['Rental Survey']) {
								afterImport.push(question);
						}
						survey.airtableSurvey['Choices'] = [...survey.airtableSurvey['Choices'], 
								...toMerge['Choices']];
						survey.airtableSurvey['Translations'] = [...survey.airtableSurvey['Translations'], 
								...toMerge['Translations']];
				}
				*/
      } else {
        afterImport.push(question);
      }
    }
    survey.airtableSurvey['Rental Survey'] = afterImport;

    const expanded: typeof survey.airtableSurvey['Rental Survey'] = [];
    for (const question of survey.airtableSurvey['Rental Survey']) {
      expanded.push(question);
      if (question.fields['Field Type'] === 'Contract') {
        if ((question.fields['Additional Options'] || []).includes('No Signatures')) {
          continue;
        }

        const metadata = JSON.parse(question.fields.Metadata || '{}') as {
          'signers': Record<string, string>
        };

        const signers = Object.keys(metadata['signers'] || {});
        for (const signer of signers) {
          expanded.push({
            id: v4(),
            createdTime: '',
            fields: {
              'Question': question.fields.Question + ' - ' + signer,
              'Field Type': 'Contract Signer',
              'English Content': signer,
              'Target Field': question.fields['Target Field'] + '_' + signer,
              'Additional Options': question.fields['Additional Options'],
              'Conditional On': question.fields['Conditional On'],
              'Conditional On Value': question.fields['Conditional On Value'],
            }
          });
        }

        expanded.push({
          id: v4(),
          createdTime: '',
          fields: {
            'Question': question.fields.Question + ' - Stale',
            'Field Type': 'Contract Stale',
            'Target Field': question.fields['Target Field'] + '_stale',
            'Additional Options': question.fields['Additional Options'],
            'Conditional On': question.fields['Conditional On'],
            'Conditional On Value': question.fields['Conditional On Value'],
          }
        });
      }
    }
    survey.airtableSurvey['Rental Survey'] = expanded;
    survey.airtableSurvey['Choices'].forEach((c) => survey.choicesById[c.id] = c.fields)
    survey.airtableSurvey['Rental Survey'].forEach((c) => survey.questionsById[c.id] = c.fields);
    (survey.airtableSurvey['Translations'] || []).forEach((c) => survey.translationsById[c.id] = c.fields)
    return survey;
  }
  getSurvey(): { 
    sections: Section[],
    viewableFields: string[]
  } {
    // Utility for populating translation
    const getExtraTranslations = <T extends { Translation?: string[] }>(item: T, text?: boolean) => {
      let extraTranslations = {} as AirtableTranslations['fields'];
      if (item['Translation']) {
        extraTranslations = item['Translation'].map(id => this.translationsById[id])[0];
        if (extraTranslations.en) {
          extraTranslations[text ? 'English Text' : 'English Content'] = extraTranslations.en;
        }
        if (extraTranslations.es) {
          extraTranslations[text ? 'Spanish Text' : 'Spanish Content'] = extraTranslations.es;
        }
      }
      return extraTranslations;
    }

    const populatedQuestions: PopulatedQuestion[] = this.airtableSurvey['Rental Survey'].filter((q: any) => {
      return (q.fields.Question || q.fields.modern) && q.fields['Field Type'] !== 'Stage'
    }).map((q: any) => {
      let id = q.id;
      q = q.fields;

      // The code in this conditional makes all questions optional, and treats all questions
      // as having their conditions met so everything is displayed.
      // if (bool) {
      //   if (q['Additional Options']) {
      //     if (!q['Additional Options'].includes('Optional')) {
      //       q['Additional Options'].push('Optional');
      //     }
      //   } else {
      //     q['Additional Options'] = ['Optional']
      //   }
      //   if (q.Metadata) {
      //     const qMeta = JSON.parse(q.Metadata);
      //     delete (qMeta as any)['conditional'];
      //     q.Metadata = JSON.stringify(qMeta);
      //   }
      // }

      const extraTranslations = getExtraTranslations(q);
      return {
        ...q,
        'Options (if relevant)': (q['Options (if relevant)'] || []).map((id: any) => {
          const choice = this.choicesById[id];
          const extraTranslations = getExtraTranslations(choice, true);
          return {
            ...choice,
            ...extraTranslations
          };
        }),
        'Conditional On Value': q['Conditional On Value'] ? q['Conditional On Value'].map((v: any) => this.choicesById[v].Name) : undefined,
        ...extraTranslations,
        id
      };
    }) as unknown as PopulatedQuestion[];

    // Group questions in a section into actual sections rather than 
    // just using them as markers.
    return populatedQuestions.reduce<{ 
      sections: Section[],
      viewableFields: string[]
    }>(
      (soFar, q) => {
        if (q['Field Type'] === 'Section') {
          soFar.sections.push({...q,
            Questions: []
          })
        } else {
          if (soFar.sections.length > 0) {
            soFar.sections[soFar.sections.length - 1].Questions.push(q)
          }
        }
        return soFar;
      },
      { sections: [], viewableFields: [] }
    );
  }
}

export type v0SurveyImport = {v0: v0.ExpandedSurvey, importMetadata: {url?: string, kind?: string, path?: string, autofill_sections?: boolean}};

/**
 * Fetches a v0 survey from a remote source and hydrates it by:
 * - converting v0.Survey to v0.ExpandedSurvey if needed
 * - expanding templates
 * - pruning based on any given userTags
 * 
 * @param filename name of the file, without an extension
 * @param getSurveyDefinition presumably an S3 wrapper
 * @param userTags for pruning
 * @returns 
 */
export async function loadV0SurveyFromFileName(filename: string, getSurveyDefinition: (url: string) => Promise<string>, userTags?: string[]): Promise<v0SurveyImport> {
  return new Promise(async (resolve, reject) => {
    try {
      const v0 = JSON.parse(await getSurveyDefinition(filename)) as v0.Root
      const expandedSurvey: v0.ExpandedSurvey = expandTemplatesStrict(Array.isArray(v0) ?  ({survey: v0, personas: []} satisfies v0.ExpandedSurvey) : v0);
      if (userTags) {
        expandedSurvey.survey = pruneForUserTags(expandedSurvey.survey, userTags || []);
      }
      resolve({v0: expandedSurvey, importMetadata: {}});

    } catch (e) {
      console.log("loadDistroSurveysFromImports Failed", filename, e);
      reject(e);
    }
  });
}
/**
 * Fetches, parses, and prunes the v0 Surveys described by the importQuestions.
 * 
 * @param imports A list of Import type Legacy "questions", presumably from the {programName}.json file.
 * @param programId 
 * @param getSurveyDefinition A fetching interface, presumably backed by S3.
 * @param userTags Tags of the requesting user, for pruning elements that the user is not authorized to access.
 * @returns List of completed survey imports
 */
export async function loadV0SurveysFromImports(imports: v0SurveyImport['importMetadata'][], programId: string, getSurveyDefinition: (url: string) => Promise<string>, userTags?: string[]): Promise<v0SurveyImport[]> {
  let imported: string[] = [];
  const surveyPromises = imports
    .map((importMetadata: v0SurveyImport['importMetadata']): Promise<v0SurveyImport | null> => {
      return new Promise(async (resolve, reject) => {
        if (
          ((userTags || []).includes('no_airtable') && !importMetadata.url?.includes("entireprogram"))
          || !importMetadata.url
          || importMetadata.url.includes("-audit")
        ) {
          resolve(null);
          return;
        }

        // When we use the generic Airtable base we need to replace [handle] with the program name
        importMetadata.url = importMetadata.url.replace('[handle]', programId);//programName);

        try {
          const t0 = Date.now();
          const v0 = JSON.parse(await getSurveyDefinition(importMetadata.url)) as v0.Root
          const t1 = Date.now();
          console.log("[loadDistroSurveysFromImports] Loading", importMetadata.url, "took", t1 - t0, "ms");
          const expandedSurvey: v0.ExpandedSurvey = expandTemplatesStrict(Array.isArray(v0) ?  ({survey: v0, personas: []} satisfies v0.ExpandedSurvey) : v0);
          if (userTags) {
            expandedSurvey.survey = pruneForUserTags(expandedSurvey.survey, userTags || []);
          }
          const t2 = Date.now();
          console.log("[loadDistroSurveysFromImports] Pruning and Expanding", importMetadata.url, "took", t2 - t1, "ms");

          imported.push(importMetadata.url);
          resolve({v0: expandedSurvey, importMetadata});

        } catch (e) {
          console.log("loadDistroSurveysFromImports Failed", importMetadata.url, e);
          reject(e);
        }
      });
    });

  return await Promise.all(surveyPromises)
    .then((results) => {
      console.log("Loaded Distro Surveys: ", imported.join(', '));
      const nonNullResults: v0SurveyImport[] = [];
      for (const result of results) {
        if (result) nonNullResults.push(result);
      }
      return nonNullResults;
    })
    .catch((e) => {
      console.log('[loadDistroSurveysFromImports] Promise.all failed', e);
      return [];
    });
}

/**
 * Converts the given v0Surveys into Legacy (airtable) format and merges them into the originalSurvey,
 * modifying the originalSurvey in place.
 * 
 * @param originalSurvey 
 * @param v0Surveys 
 * @param programId 
 */
export function mergeV0SurveysIntoAirtableSurvey(originalSurvey: AirtableSurvey, v0Surveys: v0SurveyImport[], programId: string) {
  // For some configuration things, we need the existing configuration to Add to it
  let airtableConfig = originalSurvey['Configuration'];

  // Map the key of each existingConfiguration row to a new dict so we can easily add to the right spot
  let existingConfig = airtableConfig.reduce((acc, cur) => {
    if (!cur.fields.Key) return acc;
    acc[cur.fields.Key] = cur.fields.Value || '';
    return acc;
  }, {} as Record<string,string>);

  const airtableQuestions = v0Surveys.map(({v0, importMetadata}) => {
    const afterImport: AirtableSurveyQuestion[] = [];

    let toMerge: AirtableSurvey;
    try {
      const t2 = Date.now();
      toMerge = v0ToLegacy(v0, {
        includeConfig: importMetadata.url?.includes('-entireprogram'),
      });
      const t3 = Date.now();
      console.log("[mergeV0SurveysIntoAirtableSurvey] v0ToLegacy", importMetadata.url, "took", t3 - t2, "ms");

      for (const question of toMerge["Rental Survey"]) {
        afterImport.push(question);
      }
      for (const config of toMerge["Configuration"]) {
        if (config.fields.Key) existingConfig[config.fields.Key] = config.fields.Value || '';
      }
      originalSurvey["Choices"] = [...originalSurvey["Choices"], ...toMerge["Choices"]];
      originalSurvey["Roles"] = [...originalSurvey["Roles"], ...toMerge["Roles"]];
      originalSurvey["Translations"] = [
        ...originalSurvey["Translations"],
        ...toMerge["Translations"],
      ];
      if (importMetadata.kind === "subsurvey") {
        const path = importMetadata.path || "";
        afterImport.push({
          id: v4(),
          createdTime: "",
          fields: {
            Question: "Subsurvey Config - " + path,
            "Field Type": "Section",
            "English Content": "Subsurvey Config - " + path,
            "Spanish Content": "Subsurvey Config - " + path,
          },
        });
        // Keep the metadata that was specified for this Distro Import, and add autofill_sections to it.
        // TODO: Do we need to do this for kind === 'inline' as well?
        importMetadata["autofill_sections"] = true;
        afterImport.push({
          id: v4(),
          createdTime: "",
          fields: {
            Question: path,
            "Field Type": "Subsurvey",
            "English Content": "--",
            "Spanish Content": "--",
            Subquestions: toMerge["Rental Survey"]
              .filter((q) => q.fields["Field Type"] === "Section")
              .map((q) => q.id),
            Metadata: JSON.stringify(importMetadata),
          },
        });
      }
    } catch (e) {
      console.log("Merge Failed", importMetadata?.url, e);
    }
    return afterImport;
  })

  originalSurvey["Rental Survey"].push(...airtableQuestions.flat());
  originalSurvey.Configuration = Object.keys(existingConfig).map((key) => {
    return {
      id: v4(),
      createdTime: "",
      fields: {
        Key: key,
        Value: existingConfig[key],
      },
    };
  })
}

/**
 * Given an AirtableSurvey, performing the following operations
 * for each Import question specified:
 * 1. Fetch and parse the v0 data from S3
 * 2. Prune the v0 data base on userTags
 * 3. Convert the v0 data to Airtable-style data
 * 4. Modify the original AirtableSurvey in place, inserting and overwriting as applicable
 * 
 * @param survey AirtableSurvey, will be modified in place
 * @param programId Program name/id
 * @param getSurveyDefinition typically a wrapper around an S3 getObject call
 * @param userTags Used to prune v0 components with `limitedToTags` configured
 */
export async function handleImports(survey: AirtableSurvey, programId: string, getSurveyDefinition: (url: string) => Promise<string>, userTags?: string[]) {
  const importQuestions: AirtableSurveyQuestion[] = []
  survey["Rental Survey"] = survey["Rental Survey"].filter(question => {
    if (question.fields["Field Type"] === "Import") {
      importQuestions.push(question)
      return false
    }
    return true
  })
  const v0Surveys = await loadV0SurveysFromImports(
    importQuestions.map(q => JSON.parse(q.fields['Metadata'] || '{}')),
    programId, 
    getSurveyDefinition, 
    userTags
  );
  mergeV0SurveysIntoAirtableSurvey(survey, v0Surveys, programId);
}

export const AirtableSurveyToSurveyDefinition = (airtableSurvey: AirtableSurvey) => {
  return SimulatedSurvey.load(airtableSurvey).getSurvey();
}
