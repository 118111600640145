import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Icon } from "../../Icon";
import { Button } from "../../Button";
import { cn } from "../../utils/tailwind";
import { useLocalizedStrings } from "../../LocalizationProvider";
export const MapDescription = ({ title = "Map Details", description, isVisible, handleToggle }) => {
    const L = useLocalizedStrings();
    const parseMarkdown = (text) => {
        return text.split("\n").map((line, index, arr) => (_jsxs(React.Fragment, { children: [parseLine(line), index < arr.length - 1 && _jsx("br", {})] }, index)));
    };
    const parseLine = (line) => {
        const regex = /(\*\*([^*]+)\*\*|\*([^*]+)\*)/g;
        const elements = [];
        let lastIndex = 0;
        let match;
        let key = 0;
        while ((match = regex.exec(line)) !== null) {
            if (match.index > lastIndex) {
                elements.push(line.slice(lastIndex, match.index));
            }
            if (match[2]) {
                elements.push(_jsx("span", { className: "font-semibold", children: match[2] }, key++));
            }
            else if (match[3]) {
                elements.push(_jsx("span", { className: "italic", children: match[3] }, key++));
            }
            lastIndex = regex.lastIndex;
        }
        if (lastIndex < line.length) {
            elements.push(line.slice(lastIndex));
        }
        return elements;
    };
    return (_jsxs("div", { className: cn("bg-white border border-gray-300 rounded-md shadow-md text-sm p-[1rem] m-1", "transition-opacity duration-200 overflow-y-auto break-words", "sm:p-[0.75rem] sm:text-sm", isVisible && "opacity-100", !isVisible && "opacity-0 pointer-events-none"), children: [_jsxs("div", { className: "flex justify-between gap-x-8 mb-2", children: [_jsx("div", { className: "flex items-center flex-1", children: _jsx("h2", { className: "font-bold break-words text-base m-0", children: title }) }), _jsx(Button, { icon: _jsx(Icon, { name: "XMarkIcon", role: "decorative", type: "solid" }), "aria-label": L.component_library.map.close, onClick: handleToggle, variant: "secondary", size: "sm" })] }), _jsx("div", { className: "", children: _jsx("p", { children: parseMarkdown(description) }) })] }));
};
