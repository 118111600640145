import { useToast } from "@aidkitorg/component-library";
import { SupportedLanguage } from "@aidkitorg/i18n/lib";
import { ExclamationCircleIcon, LanguageIcon } from "@heroicons/react/24/outline";
import { useState, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { get_deployment, handleDataError, usePost, useToken } from "../../API";
import { useLinkKey } from "../../Apply";
import InterfaceContext, { ConfigurationContext } from "../../Context";
import { useLocalizedStrings, langToWord } from "../../Localization";
import { LIVENESS_REQUEST_EVENT_TYPE } from "../../Questions/LivenessDetection";
import { AidKitLogo, saveKeyToCookieAndRelocateToUrl, SpacedSpinner, useMarkdown } from "../../Util";
import * as Session from "./Session";
import { Button } from "./Buttons";
import { CardFront, CardBack, DebitCardFront, Selfie } from "./CoverImages";
import { LivenessDetection } from "./SessionUIController/types";
import { Dropdown } from "../../Components/Dropdown";
import { handleErrors } from "./LivenessErrors";
import { getBrowserDetails } from "./BrowserDetails";
import { usePing } from "../../utils/useMultipartUpload";
import { LivenessDetectionSessionUIContextProvider } from "./SessionUIController";

export function LivenessDetectionPage(props: any) {
  const linkKey = useLinkKey();

  const [ready, setReady] = useState(false);
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const { toast } = useToast();

  const { livenessId } = useParams<{ livenessId: string }>();
  const getLivenessSession = usePost("/applicant/get_liveness_session", { handleErrors: (data) => {handleErrors(data, (payload) => toast(payload))} });
  const [ errored, setErrored ] = useState(null as null | string);
  const [, auth_token] = useToken();
  const [livenessConfig, setLivenessConfig] = useState(null as null | LivenessDetection);

  const ping = usePing();
  const backContent = useMarkdown(
    livenessConfig?.identification?.back?.content?.[context.lang] 
    || L.questions.identity.take_a_photo_of_back);

  function sendLivenessRefreshRequest(){
    if(window.opener){
      // auth needs to be refreshed
      // notify the parent window that we need a new liveness link
      window.opener.postMessage(JSON.stringify({
        type: LIVENESS_REQUEST_EVENT_TYPE
      }));
    }
  }

  useEffect(() => {
    if (!livenessId) return;
    if (!auth_token){
      sendLivenessRefreshRequest();
      return;
    }

    // Fetch the liveness config
    (async () => {
      const resp = await getLivenessSession({ livenessId });
      // get some details about their browser
      const browserDetails = getBrowserDetails();

      if (resp.error) {
        const pingPromise = ping("Received error from getLivenessSession", { livenessId, ...resp, browserDetails })
        setErrored(resp.error);
        try {
          switch (resp.error){
            case "ld2_session_empty_please_start_over":
            case "ld2_link_expired":
            case "ld2_session_expired":
            case "ld2_not_authorized":
              return sendLivenessRefreshRequest();
            default:
                  // do nothing with other errors
          }
        } finally {
          await pingPromise;
        }
      } else if (resp.livenessConfig) {
        setLivenessConfig(resp.livenessConfig);
      } else {
        await ping("Unable to get liveness config", { livenessId, ...(resp || {}), browserDetails })
        setErrored("ld2_unknown_error");
      }
    })();
  }, [livenessId, auth_token]);

  if (window.location.search.includes("key")) {
    saveKeyToCookieAndRelocateToUrl(window.location.href, { removeHistory: true, useHistoryToRelocate: true });
    return <></>;
  } 

  const errorContent = (function getErrorContent(){
    const sessionEmptyContent = <div>{L.selfie.session_expired_message}:
      <ol className="list-decimal mb-0 mt-2 list-inside">
        <li>{L.selfie.session_expired_close}</li>
        <li>{L.selfie.session_expired_restart}</li>
      </ol>
    </div>;

    if(!auth_token && !window.location.search.includes("key")){
      return sessionEmptyContent;
    }

    switch (errored) {
      case "ld2_session_empty_please_start_over":
        return sessionEmptyContent;
      case "ld2_liveness_not_defined":
        return <div>There was a Problem getting the configuration</div>
      case "ld2_link_expired":
      case "ld2_session_expired":
      case "ld2_not_authorized":
        return  <div>This session has expired. Please go back to your application and start a new session.</div>
      default:
        return null;
    }
  })();
  
  if(errorContent){
    return <div className="p-2 m-2 flex gap-2 bg-red-200 ring-2 ring-red-400 rounded-md items-start">
      <ExclamationCircleIcon className="w-8 shrink-0" />
      <div className="grow">{errorContent}</div>
    </div>
  }

  if (!ready) {
    return <div className="h-full w-full bg-gray-100 items-stretch flex-col flex">
      <div className="flex-shrink flex-1" />
      <div className="flex-grow items-center flex flex-col py-2">
        <div className="px-10 py-10 text-xl m-0 bg-white w-full mb-4 flex items-center flex-col">
          <div className="max-w-lg flex-1">
            <div className="flex flex-row mb-2">
              <div className="flex-grow text-left"></div>
              <div className="flex-1 text-right">
                <Dropdown description="Language" 
                  label={<><LanguageIcon />
                    <span className="ml-1 md:block">
                      {langToWord(context.lang as SupportedLanguage, 'Name')}
                    </span>
                  </>} 
                  options={
                    (config.languages?.split(',') || []).map((l: any) => {
                      return { label: langToWord(l as any, 'Name'), callback: () => {
                        context.setLanguage(l as any);
                      }}
                    })} />
              </div>
            </div>
            <div className="max-width-100">
              <AidKitLogo width={100} height={50} />
              <h1>{L.questions.identity.title}</h1>
              {L.questions.identity.comfort}
            </div>
          </div>
        </div>
        {livenessConfig ? <div className="flex-grow items-center flex flex-col px-10 py-10 text-xl m-0 w-full mb-4">
          <div className="max-w-lg flex-1">
            <h2 className="text-lg">{L.questions.identity.preview_instructions}</h2>
          </div>
          {[
            {
              step: 'front',
              title: L.questions.identity.take_a_photo_of_front,
              icon: <CardFront style={{width: '100%', height: 'auto'}} />,
            },
            {
              step: 'back',
              title: backContent,
              icon: livenessConfig.identification?.back.alternativeDocument?.kind === 'Debit Card' 
                ? <DebitCardFront style={{width: '100%', height: 'auto'}} />
                : <CardBack style={{width: '100%', height: 'auto'}} />,
            },
            {
              step: 'selfie',
              title: L.questions.identity.take_a_photo_of_face,
              icon: <Selfie style={{width: '100%', height: 'auto'}} />,
            },
          ].filter(f => livenessConfig.identification ? !!f : f.step === 'selfie').map((step, i) => {
            return <button key={i} className="max-w-lg relative p-2 m-4 rounded-md bg-white" onClick={() => setReady(true)} 
              style={{"border": '2px solid black'}}>
              <div className="absolute top-0 text-center left-0 w-full" style={{top: '-22px'}}>
                <div className="inline-block text-center align-center bg-white rounded-full bold" style={{
                  width: '40px',
                  height: '40px',
                  lineHeight: '40px',
                  border: '2px solid black'
                }}>
                  {i + 1}
                </div>
              </div>
              <div className="text-center align-center mx-4 mt-8 mb-2">
                {step.icon}
              </div>
              <div className="m-4 mt-0">{step.title}</div>
            </button>
          })}
          <div className="text-center mt-6 mb-20 w-100">
            <Button onClick={() => { setReady(true) }}>{L.questions.identity.begin_identity_verification}</Button>
          </div>
        </div> : <SpacedSpinner />}
      </div>
      <div className="flex-shrink" /> 
    </div>;
  }

  return <LivenessDetectionSessionUIContextProvider>
    <Session.LivenessDetectionSessionPage {...props} />
  </LivenessDetectionSessionUIContextProvider>
}