import { Explore, Query } from "@aidkitorg/types/lib/survey";
import { CompileExpressionToSQL, findFields, simulateDate } from "@aidkitorg/types/lib/translation/expr_to_sql";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useState, useMemo } from "react";
import { usePost } from "../API";
import ExploreLink, { DistroPreviewModal } from "../Components/ExploreLink";
import { classNames } from "../Util";
import { SqlViewer } from "./CommonComponents";
import { EligibilityChecker } from "./EligibilityChecker";
import { Button, Switch, Tooltip, Badge } from "@aidkitorg/component-library";
import { EnableKey } from "./EnableKey";
import { HasEnableKey } from "@aidkitorg/types/lib/eval";

type QueryCheckerProps = {
  name: string,
  onNameClick?: ()=>void,
  testName: string,
  expr: Query,
  targetField: string,
  component: HasEnableKey
};

export function QueryChecker(props: QueryCheckerProps) {
  const { name, onNameClick, testName, expr, targetField, component } = props;
  const testExpression = usePost('/program/admin/test_expression');
  const [count, setCount] = useState<number | undefined | null>();
  const [error, setError] = useState<string | undefined | null>();
  const [checking, setChecking] = useState(false);
  const [preview, setPreview] = useState(false);
  const [simulationDate, setSimulationDate] = useState<Date>();
  const [modifiedExpr, setModifiedExpr] = useState<Query>(expr);
  const [showEligibility, setShowEligibility] = useState(false);

  const sql = useMemo(() =>
    modifiedExpr.kind === 'SQL' ? modifiedExpr.sql : CompileExpressionToSQL({
      cond: modifiedExpr.expr,
      orderBy: modifiedExpr.orderBy
    }),
  [modifiedExpr]
  );

  const query: Explore = useMemo(() => {
    if (modifiedExpr.kind === 'Click') {
      let fields: string[] = findFields(modifiedExpr.expr);
      if (!fields.length) {
        fields = ['legal_name'];
      }
      fields.push(targetField);

      return {
        query: {
          kind: 'Applicant Table',
          filter: modifiedExpr,
          columns: fields.map(f => ({ kind: 'Field', field: f })),
          title: { en: name },
          download: {
            filename: name
          }
        }
      };
    } else {
      return {
        query: {
          kind: 'Custom Query',
          sql: modifiedExpr.sql,
          visualization: {
            kind: 'Table',
            title: { en: name },
            download: {
              filename: name
            }
          }
        }
      };
    }
  }, [modifiedExpr]);

  async function simulate(date?: Date) {
    setChecking(true);
    let newExpr = expr;
    switch (expr.kind) {
      case 'Click':
        if (date) {
          newExpr = { ...expr, expr: simulateDate(expr.expr, date) }
        }
        break;
      default:
        break;
    }
    const simulatedTest = await testExpression({
      query: newExpr
    });
    setModifiedExpr(newExpr);
    setSimulationDate(date);
    setCount(simulatedTest.count);
    setError((simulatedTest as any).error);
    setChecking(false);
  }

  return <ul className="border border-gray-300 p-2 space-x-3 space-y-2">
    <li className="flex space-x-2">
      {onNameClick ? <a href="#" onClick={onNameClick}><h4>{name} ({targetField})</h4></a> : <h4>{name} ({targetField})</h4>}
      <DistroPreviewModal
        query={query}
        open={preview}
        onClose={setPreview}
      />
      <ExploreLink query={query} />
    </li>
    <li className="flex space-x-1">
      <EnableKey component={component} />
    </li>
    <li>
      <fieldset className="justify-items-center space-x-2 p-1 items-center flex">
        <Tooltip text="choose a time for the check. hover each option to see the exact date!">
          <span className="font-bold w-auto cursor-help">Check:</span>
        </Tooltip>
        <Button variant="secondary" size="md" onClick={() => simulate()}>Now</Button>
        <input type="date"
          value={moment(simulationDate).format('YYYY-MM-DD')}
          onChange={(e) => simulate(moment(e.target.value).toDate())}
          className={classNames(
            'relative flex cursor-pointer items-center justify-center rounded-md p-1 aria-checked:bg-indigo-300 aria-checked:border-none border border-black',
          )}
        />
      </fieldset>
    </li>
    <li hidden={count === undefined || count === null} onClick={() => simulate(simulationDate)} className="cursor-pointer">
      <Badge variant="info" className="text-lg px-2 py-1 space-x-2">
        <span>{count}&nbsp;{testName} {simulationDate ? 'on ' + simulationDate?.toLocaleDateString() : 'Now'}</span>
        <ArrowPathIcon className={classNames("h-5 w-5", checking ? "animate-spin" : "hidden")} />
      </Badge>
      <span className="text-sm bg-red-300 text-wrap" hidden={!error}>{error}</span>
    </li>
    <li className="flex space-x-2 justify-end">
      <Button variant="secondary" size="md" onClick={() => setPreview(true)}>
        <span className="size-fit">Preview Explore</span>
      </Button>
    </li>
    <li className="space-y-2">
      <Switch label="Show Eligibility" defaultChecked={showEligibility} onCheckedChange={setShowEligibility} />
      <EligibilityChecker cond={modifiedExpr} show={showEligibility} />
      <SqlViewer sql={sql} />
    </li>
  </ul >
}
