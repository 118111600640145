import { RadioGroup, Switch } from "@aidkitorg/component-library";
import { RadioGroupOption } from "@aidkitorg/component-library/src/RadioGroup";
import { Block, ConditionalContentList, ExpandedSurvey, RichText, Select } from "@aidkitorg/types/lib/survey";
import { deepCopy, expandTemplates } from "@aidkitorg/types/lib/translation/v0_to_legacy";
import { toName } from '@aidkitorg/typesheets/lib/utils';
import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { captureException } from "@sentry/react";
import { Fragment, useEffect, useState } from "react";
import { SearchableComponent } from "./Config";
import { BUTTON_CLASS } from "./Util";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type MatchType = "Name/Title/Path" | "Target Field" | "Content" | "Formula" | "Kind" | "Other";

type TreeBlock = {
  thing: SearchableComponent,
  tree: string[],
  matchTypes: MatchType[]
};

type SearchOptions = {
  matchCase: boolean,
  wholeMatchOnly: boolean,
  includeDashboards: boolean,
}

export function highlightSearchTerm(jsonString: string, searchTerm: string | string[], searchOptions: SearchOptions) {
  let segments: string[] = [];
  const isArrayOfTerms = Array.isArray(searchTerm);
  try {
    let searchTermRegex;
    if (searchOptions.wholeMatchOnly) {
      // Escape special characters in the searchTerm to be used in a regular expression
      if (isArrayOfTerms) {
        const escapedSearchTerms = searchTerm.map(term => term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));
        // Create a regular expression with word boundaries to look for instances of the searchTerm token
        searchTermRegex = new RegExp(`\\b(${escapedSearchTerms.join('|')})\\b`, 'gi');
      } else {
        const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        // Create a regular expression with word boundaries to look for instances of the searchTerm token
        searchTermRegex = new RegExp(`\\b(${escapedSearchTerm})\\b`, 'gi');
      }
    } else {
      searchTermRegex = new RegExp(`(${isArrayOfTerms ? searchTerm.join('|') : searchTerm})`, 'gi');
    }
    segments = jsonString.split(searchTermRegex);
  } catch (_) {
    // Do nothing for now
  }
  return segments.map((segment, index) => {
    if ((searchOptions.matchCase && (isArrayOfTerms ? searchTerm.some(term => term === segment) : segment === searchTerm))
      || (!searchOptions.matchCase && (isArrayOfTerms ? searchTerm.some(term => term.toLowerCase().includes(segment.toLowerCase())) : segment.toLowerCase() === searchTerm.toLowerCase()))) {
      return (
        <span key={index} style={{ backgroundColor: 'moccasin', fontWeight: 'bold' }}>
          {segment}
        </span>
      );
    }
    return <span key={index}>{segment}</span>;
  });
}

async function findComponent(survey: ExpandedSurvey['survey'], searchTerm: string, options: SearchOptions): Promise<TreeBlock[] | null> {
  if (!survey || !searchTerm) return null;

  if (!options.matchCase) searchTerm = searchTerm.toLowerCase();

  // Escape special characters in the searchTerm to be used in a regular expression
  const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  // Create a regular expression with word boundaries to look for instances of the searchTerm token
  const tokenMatchRegex = new RegExp(`\\b${escapedSearchTerm}\\b`);

  survey = expandTemplates(deepCopy(survey)) as ExpandedSurvey['survey'];

  // This is a set of JSON Stringified TreeBlocks
  const foundThings = new Set<string>();

  function traverse(thing: SearchableComponent, parentTree: string[], isDashboardComponent?: boolean): Block[] {
    if (isDashboardComponent && !options.includeDashboards) return [];

    let blockName = typeof (thing as any).name === 'object' ? ((thing as any).name?.en || '') : ((thing as any).name || '');
    if (!blockName) blockName = typeof (thing as any).title === 'object' ? ((thing as any).title?.en || '') : ((thing as any).title || '');
    if (!blockName) blockName = ((thing as any).path) || '';

    // pathName is used to describe the path, blockName is used to search
    const pathName = blockName || (thing as any).targetField;

    const tree = [...parentTree, thing.kind + (pathName ? ': ' + pathName : '')];
    const thingToAdd: TreeBlock = { thing, tree, matchTypes: [] };

    const maybeAdd = (possibleMatch: string, matchType: TreeBlock['matchTypes'][number]) => {
      if (!options.matchCase) possibleMatch = possibleMatch.toLowerCase();

      if ((options.wholeMatchOnly && tokenMatchRegex.test(possibleMatch)) || (!options.wholeMatchOnly && possibleMatch.includes(searchTerm))) {
        thingToAdd.matchTypes.push(matchType);
      }
    }

    maybeAdd(blockName, "Name/Title/Path");

    const blockKey = ((thing as any).targetField || '');
    maybeAdd(blockKey, "Target Field");

    const content = (thing as any).content?.en || '';
    maybeAdd(content, "Content");

    const choices = JSON.stringify((thing as Select).choices || []);
    maybeAdd(choices, "Content");

    ['formula', 'condition', 'filter', 'sql', 'groups', 'columns'].forEach(key => {
      const unparsedFormula = (thing as any)[key] || '';
      const formula = typeof unparsedFormula === 'object' ? JSON.stringify(unparsedFormula) : unparsedFormula;
      maybeAdd(formula, "Formula");
    });

    const kind = ((thing as any).kind || '');
    maybeAdd(kind, "Kind");

    if (thing.kind === 'Action') {
      if (thing.fields) {
        for (let field of thing.fields) {
          maybeAdd(field, "Target Field");
        }
      }

      if (thing.action && typeof thing.action !== 'string' && (thing.action as any).targetField) {
        maybeAdd((thing.action as any).targetField, "Target Field");
      }
      if (thing.explanation) {
        maybeAdd(thing.explanation.en || '', "Content");
      }
    }

    if (thing.kind === 'Payment') {
      maybeAdd(thing.cardIdField || '', 'Target Field');
      maybeAdd((typeof thing.ledger === 'string' ? thing.ledger : thing.ledger?.field) || '', 'Content');
      maybeAdd(thing.targetField || '', 'Target Field');
      maybeAdd(thing.amount || '', 'Content');
      maybeAdd(thing.type || '', 'Content');
    }

    function traverseTextOrConditionalContentList(textOrConditionalContentList: RichText | ConditionalContentList) {
      function traverseConditionalContent(textOrConditionalContent: ConditionalContentList) {
        textOrConditionalContent.forEach(textOrContent => {
          if ('en' in textOrContent) {
            maybeAdd(textOrContent.en || '', 'Content');
          } else {
            traverseConditionalContent(textOrContent.components);
            if (textOrContent.otherwise) {
              traverseConditionalContent(textOrContent.otherwise);
            }
          }
        })
      }

      if ('en' in textOrConditionalContentList) {
        maybeAdd(textOrConditionalContentList.en, 'Content');
      } else {
        traverseConditionalContent(textOrConditionalContentList);
      }
    }

    if (thing.kind === 'Notification' || thing.kind === 'InlineNotification') {
      maybeAdd(thing.targetPrefix || '', 'Target Field');
      maybeAdd(JSON.stringify(thing.initial_notification.enabled_when || ''), "Formula");

      traverseTextOrConditionalContentList(thing.initial_notification.message);

      maybeAdd(thing.initial_notification.email_subject.en || '', 'Content');

      if (thing.initial_notification.email_message) {
        traverseTextOrConditionalContentList(thing.initial_notification.email_message);
      }

      if (thing.kind === 'Notification' && thing.followups) {
        thing.followups.forEach((followup) => {
          maybeAdd(followup.suffix || '', 'Target Field');
          maybeAdd(followup.email_subject.en || '', 'Content');
          maybeAdd(JSON.stringify(followup.send_if || ''), "Formula");
          traverseTextOrConditionalContentList(followup.message);
          if (followup.email_message) {
            traverseTextOrConditionalContentList(followup.email_message);
          }
        });
      }
    }

    if (thingToAdd.matchTypes.length) {
      const toAdd = JSON.stringify(thingToAdd);
      foundThings.add(toAdd);
    }

    if (thing.kind === 'Custom Query') {
      traverse(thing.visualization, tree, true);
    }

    if (thing.kind === 'Subsurvey') {
      thing.sections.forEach((section) => {
        traverse(section, tree);
      })
    }

    if (thing.kind === 'Section') {
      thing.components.forEach(block => {
        traverse(block, tree);
      });
    }
    if (thing.kind === 'Collection') {
      thing.components.forEach(component => {
        traverse(component, tree);
      });
    }

    if (thing.kind === 'Dashboard' || thing.kind === 'Dashboard Section') {
      thing.components.forEach(component => {
        traverse(component, tree, true);
      });
    }

    if (thing.kind === 'Conditional Block') {
      thing.components.forEach(component => {
        traverse(component, tree);
      });
      if (thing.otherwise) {
        thing.otherwise.forEach(component => {
          traverse(component, tree);
        })
      }
    }
    return [];
  }
  (survey || []).forEach(component => {
    traverse(component, []);
  });

  let things: TreeBlock[] = [];
  foundThings.forEach(thing => { things.push(JSON.parse(thing) as TreeBlock); });

  // Rank the components by how close they are to the search term
  things.sort((a, b) => {
    let aName = ((a.thing as any).name?.en || '').toLowerCase();
    let bName = ((b.thing as any).name?.en || '').toLowerCase();
    let aKey = ((a.thing as any).targetField || '').toLowerCase();
    let bKey = ((b.thing as any).targetField || '').toLowerCase();

    // console.log("Comparing", aName, bName, aKey, bKey, name);

    if (!aName && !aKey) return 1;
    if (!bName && !bKey) return -1;

    // Rank ascending top score = BOTH Name and Key include
    // Rank descending top score = EITHER Name or Key include
    if (aKey.startsWith(searchTerm) && bKey.startsWith(searchTerm)) return 0;
    if (aName === searchTerm || aKey === searchTerm || aKey.startsWith(searchTerm)) return -1;
    if (bName === searchTerm || bKey === searchTerm || bKey.startsWith(searchTerm)) return 1;
    if (aName.includes(searchTerm) && aKey.includes(searchTerm) && bName.includes(searchTerm) && bKey.includes(searchTerm)) return 0;
    if (aName.includes(searchTerm) && aKey.includes(searchTerm)) return -1;
    if (bName.includes(searchTerm) && bKey.includes(searchTerm)) return 1;
    if (aName.includes(searchTerm) || aKey.includes(searchTerm)) return -1;
    if (bName.includes(searchTerm) || bKey.includes(searchTerm)) return 1;
    return 0;
  });
  return things;
}

function getComponentsInPath(survey: SearchableComponent[], path: string[]): SearchableComponent[] {

  let depth = 0;
  const foundComponents: SearchableComponent[] = [];
  while (depth < path.length) {
    for (const thing of survey) {
      let blockName = typeof (thing as any).name === 'object' ? ((thing as any).name?.en || '') : ((thing as any).name || '');
      if (!blockName) blockName = typeof (thing as any).title === 'object' ? ((thing as any).title?.en || '') : ((thing as any).title || '');
      if (!blockName) blockName = ((thing as any).path) || '';

      // pathName is used to describe the path, blockName is used to search
      const pathName = blockName || (thing as any).targetField;
      const componentName = thing.kind + (pathName ? ': ' + pathName : '')

      if (componentName === path[depth]) {
        foundComponents.push(thing);
        switch (thing.kind) {
          case 'Collection':
            survey = thing.components;
            break;
          case 'Section':
            survey = thing.components;
            break;
          case 'Subsurvey':
            survey = thing.sections;
            break;
          case 'Dashboard':
            survey = thing.components;
            break;
          default:
            // we have reached a component
            // that does not have components...
            return foundComponents;
        }
        break;
      }
    }
    depth++;
    if (foundComponents.length < depth) {
      console.error('[finder] couldnt resolve components', foundComponents, 'depth', depth);
      return [];
    }
  }

  return foundComponents;
}

/**
     * Locates the desired leaf node within the Distro Configuration.
     * Starts with the topmost node in the path, and proceeds one level of
     * depth at a time, expanding nodes using the [show] buttons until the leaf is reached.
     * it then scrolls to the leaf!
     */
export async function findAndGoToComponent(tree: string[], survey: ExpandedSurvey['survey']) {
  console.log('[scroll-to] expanding survey');
  const surveyCopy = expandTemplates(deepCopy(survey)) as ExpandedSurvey['survey'];
  console.log('[scroll-to] locating component details', tree);
  const components = getComponentsInPath(surveyCopy, tree);
  console.log('[scroll-to] components found', components);
  try {
    const editor = document.getElementsByTagName('distro-editor')[0];
    const root = editor.shadowRoot!;
    const surveyTab = root.querySelector("[data-tab-name=survey]");
    if (surveyTab) surveyTab.dispatchEvent(new Event('click', { bubbles: true }));
    await new Promise(r => setTimeout(r, 50));
    
    let depth = 0;
    let elem: Element | null = null;

    while (depth < tree.length) {
      const comp = Object.entries(components[depth])
        .filter(([_, v]) => (typeof v === 'object' && !Array.isArray(v) && 'en' in v) || typeof v === 'string');

      // we don't know what nodes of the tree have been expanded yet,
      // so we have to start the pruning process at the root node.
      // This selector string finds all the parent nodes that
      // contain children which account for every field of the current SearchableComponent
      const selector = '[data-tracker-type=object]:has' + comp
        .map(([k, v]) => {
          // any Text or RichText kind winds up having a
          // "data-en" element instead of its actual name (i.e. content, title)
          k = typeof v === 'object' ? 'en' : k;
          return `([${toName(k)}="${(v.en ?? v).replaceAll('"', '\\"')}"])`
        }).join(':has');

      // escaping newlines so the selector string is both valid and still finds the right thing
      const trackedObjs = root.querySelectorAll(selector.replaceAll('\n', '\\\\n'));

      // TODO: may be able to eliminate the below loop entirely
      // by just going w/ the last element in the array,
      // not sure about the stability of the query selector though

      // find the lowest level node that still contains all child elements
      for (let t = 0; t < trackedObjs.length; t++) {
        const candidate = trackedObjs[t];
        elem = !elem || elem.contains(candidate) ? candidate : elem;
      }

      // we want to exclude 'options[show]' buttons
      // because they will not lead us to further sections
      // of the tree, instead they would mess with the node expansion process
      const showBtn = elem?.querySelector(
        '#expander:not([data-btn-type=options])'
      );

      if (showBtn?.textContent?.includes('show')) {
        showBtn.dispatchEvent(new Event('click', { bubbles: true }));
      }
      await new Promise(res => setTimeout(res, 50));

      depth++;
    }

    elem?.scrollIntoView({block: 'center'});
  } catch (e) {
    captureException(new Error('scroll-to failed'), { extra: { cause: e } });
  }
}

export default function ConfigSearch(props: {
  survey: ExpandedSurvey['survey'],
  close: () => void
}) {
  const [query, setQuery] = useState('')

  const [open, setOpen] = useState(true)

  const [results, setResults] = useState(null as TreeBlock[] | null);
  const [resultTypes, setResultTypes] = useState<Record<MatchType, TreeBlock[]> | null>(null)
  const [selectedTypes, setSelectedTypes] = useState<Record<MatchType, boolean>>({ "Name/Title/Path": true, "Target Field": true, "Content": true, "Formula": true, "Kind": true, "Other": true });
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({ matchCase: false, wholeMatchOnly: false, includeDashboards: true });
  const [searching, setSearching] = useState(false);
  const [showLength, setShowLength] = useState(0);
  const [showThing, setShowThing] = useState({} as Record<string, boolean>); // Used to hide large objects

  async function doSearch() {
    // Using a callback here guarantees that multiple clicks will not cause multiple API calls
    // because React automatically queues the set requests and executes them sequentially.
    setSearching((currentSearching) => {
      if (currentSearching) {
        console.log("Already clicked");
        return currentSearching;
      }
      (async () => {
        if (!query || query.length < 2) {
          setResults(null);
          setResultTypes(null);
          return;
        }

        const components = await findComponent(props.survey, query, searchOptions);

        // Group components by type for easy toggling
        const componentTypes = (components || []).reduce<Record<MatchType, TreeBlock[]>>(
          (acc, cur) => {
            for (let matchType of cur.matchTypes) {
              acc[matchType] ||= [];
              acc[matchType].push(cur);
            }
            return acc;
          }, { "Name/Title/Path": [], "Target Field": [], "Content": [], "Formula": [], "Kind": [], "Other": [] });

        setResults(components || null);
        setShowLength((components?.length || 0) > 20 ? 20 : components?.length || 0);
        setResultTypes(componentTypes);
        setSelectedTypes((Object.keys(componentTypes) as Array<keyof typeof componentTypes>).reduce<Record<MatchType, boolean>>((acc, cur) => {
          acc[cur] = selectedTypes[cur];
          return acc;
        }, { "Name/Title/Path": false, "Target Field": false, "Content": false, "Formula": false, "Kind": false, "Other": false }));
        setSearching(false);
      })();

      return true;
    });
  }

  useEffect(() => {
    if (!query || query.length < 2) return;

    const timer = setTimeout(() => {
      doSearch();
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [query, searchOptions]);


  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => {
      setQuery('');
      props.close();
    }} appear>
      <Dialog as="div" className="relative mt-20" style={{ zIndex: 100 }} onClose={() => {
        setOpen(false);
        props.close();
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mt-20 mx-auto max-w-4xl transform divide-y bg-white divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    {/** svg magnifying glass */}
                    <svg className="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7">
                      <title id="title">Search Icon</title>
                      <desc id="desc">A magnifying glass icon.</desc>
                      <g className="search-path" fill="none" stroke="#848F91">
                        <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                        <circle cx="8" cy="8" r="7" />
                      </g>
                    </svg>
                  </div>
                  <input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {(query?.length < 2) && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <p className="mt-4 font-semibold text-gray-900">Search term must be 2 or more characters</p>
                  </div>
                )}

                {searching ? (
                  <div className="p-6 text-center text-sm sm:px-14">
                    <p className="mt-4 font-semibold text-gray-900">Searching...</p>
                  </div>
                ) : (
                  (query?.length >= 2) && (
                    <div className="px-6 pt-2 text-center text-sm sm:px-14">
                      <h3>Search Options</h3>
                      <div className="mt-4 grid grid-cols-2 gap-y-2 mb-2 justify-items-start items-start">
                        <Switch
                          checked={searchOptions.matchCase}
                          onCheckedChange={() => setSearchOptions(prev => ({ ...prev, matchCase: !searchOptions.matchCase }))}
                          label={'Match Case'} />
                        <Switch
                          checked={searchOptions.includeDashboards}
                          onCheckedChange={() => setSearchOptions(prev => ({ ...prev, includeDashboards: !searchOptions.includeDashboards }))}
                          label={'Include dashboards'} />
                        <Switch
                          checked={searchOptions.wholeMatchOnly}
                          onCheckedChange={() => setSearchOptions(prev => ({ ...prev, wholeMatchOnly: !searchOptions.wholeMatchOnly }))}
                          label={'Whole Match Only'} />
                        {resultTypes && (Object.keys(resultTypes) as Array<keyof typeof resultTypes>).map(t => {
                          return <div key={t}>
                            <Switch key={'switch-' + t}
                              checked={selectedTypes[t]}
                              onCheckedChange={() => setSelectedTypes(prev => ({ ...prev, [t]: !prev[t] }))}
                              label={`${t}: ${resultTypes[t].length}`} />
                          </div>
                        })}
                      </div>
                      <h3>Search results</h3>
                      {results?.length ? (
                        <div>
                          <p className="mt-2 text-gray-500">
                            Found {results.length} results for {query}
                          </p>
                          <p className="mt-0 text-gray-400">
                            {/* TODO: we currently show the same result multiple times if it has mutiple result types,
                            for example, if the search term appears in both the targetfield and content for a component
                            this may or may not be desirable, but it can cause this count to say things like: "Showing 12 of 8 results" */}
                            (Showing {(Object.keys(selectedTypes || {}) as Array<keyof typeof resultTypes>).reduce((acc, cur) => {
                              const resultTypeLength = selectedTypes[cur] ? Object.keys(resultTypes?.[cur] || {}).length : 0;
                              return acc + (resultTypeLength < showLength ? resultTypeLength : showLength);
                            }, 0)} of {results.length} results)
                          </p>
                          <div className="mt-6">
                            {resultTypes && (Object.keys(resultTypes) as Array<keyof typeof resultTypes>).map((t, tIdx) => {
                              return <ul className="mt-6" hidden={!selectedTypes[t]} key={t}>
                                {resultTypes[t] && Object.values(resultTypes[t]).slice(0, showLength).map((result, idx) => {
                                  const content = JSON.stringify(result.thing, null, 2);
                                  const uuid = content.replace(/[^a-zA-Z0-9]/g, '') + idx + t + tIdx;

                                  return (
                                    <li key={uuid} className="mt-4 p-4 text-left border-2 border-b-2 border-black">
                                      <span className="inline-flex flex-wrap items-center">{result.tree.map((v, index) => <>
                                        <button
                                          className={classNames(index === (result.tree.length - 1) ? "font-semibold bg-green-300 rounded p-1 hover:animate-pulse" : "font-semibold")}
                                          disabled={index !== (result.tree.length - 1)}
                                          onClick={() => findAndGoToComponent(result.tree, props.survey)}>
                                          {v}
                                        </button>
                                        &nbsp;{index !== (result.tree.length - 1) && <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}&nbsp;
                                      </>)}</span>
                                      {/** This can get really long so if it's really long let's collapse by default */}
                                      {content.length > 500 && <button onClick={() => setShowThing(prev => ({ ...prev, [uuid]: !prev[uuid] }))} className="float-right px-2 py-1 bg-gray-200 hover:bg-gray-300">{showThing[uuid] ? 'Hide' : 'Show'}</button>}
                                      <pre className={classNames("bg-gray-50 my-3 p-2", (content.length > 500 && !showThing[uuid]) ? 'hidden' : '')} style={{ whiteSpace: 'pre-wrap' }}>
                                        {highlightSearchTerm(content, query, searchOptions)}
                                      </pre>
                                      <hr />
                                      {(showLength < Object.values(resultTypes[t]).length) && (idx === showLength - 1) && <button className={BUTTON_CLASS} onClick={() => setShowLength(showLength + 20)}>Show More</button>}
                                    </li>
                                  )
                                })}
                              </ul>
                            })}
                          </div>
                        </div>
                      ) : (
                        <div className="py-14 px-6 text-center text-sm sm:px-14">
                          <p className="mt-4 font-semibold text-gray-900">No results found</p>
                          <p className="mt-2 text-gray-500">
                            We couldnt find anything with that term. Please try again.
                          </p>
                        </div>
                      )}
                    </div>
                  )
                )}
              </>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
