import * as React from "react";
import { useParams } from "react-router-dom";
import { LivenessDetection, LivenessStage } from "./types";
import { usePost, useAPIUpload } from "../../../API";
import { useMultipartUpload, usePing } from "../../../utils/useMultipartUpload";
import { handleErrors } from "../LivenessErrors";
import { useToast } from "@aidkitorg/component-library";
import { ImageAndVideoURLObject } from "@aidkitorg/aidkit/lib/application/liveness";
import { useInterval } from "../../../Util";

type CheckRealtimeResultsResolve = Record<"front" | "back" | "selfie", { state?: "passed" | "failed" | "pending" | "max_attempts_reached"; hash?: string }>

//
// The unchanging data held by the controller
//
type LivenessDetectionSessionUIContextStaticData = {
  livenessId: string;
}

//
// The dynamic state that the controller manages internally through dispatched actions
// processed by the reducer
//
type LivenessDetectionSessionUIContextDynamicData = {
  livenessConfig?: LivenessDetection;
  actionState?: LivenessStage;
  appId: string | null;
  realtimeResults: null | CheckRealtimeResultsResolve;
}

//
// A discriminated union type for all actions that can be dispatched internally to update
// the dynamic state of the controller.
//
type LivenessDetectionSessionUIContextReducerAction = {
  type: 'no-op';
} | {
  type: 'setLivenessConfig';
  config: LivenessDetection;
} | {
  type: 'setActionState';
  state: LivenessStage | null | ((curr: LivenessStage | null) => LivenessStage | null);
} | {
  type: 'setAppId';
  appId: string | null;
} | {
  type: 'setRealtimeResults';
  realtimeResults: CheckRealtimeResultsResolve | null | ((curr: CheckRealtimeResultsResolve | null) => CheckRealtimeResultsResolve | null);
} | {
  type: 'incomingRealtimeResults';
  results: CheckRealtimeResultsResolve;
}

//
// The callbacks that we provide in the context, to kick off actions and processes in
// the controller
//
type LivenessDetectionSessionUIContextCallbacks = {
  setLivenessConfig: (config: LivenessDetection) => void;
  setActionState: (state: LivenessStage | null | ((curr: LivenessStage | null) => LivenessStage | null)) => void;
  setRealtimeResults: (realtimeResults: CheckRealtimeResultsResolve | null | ((curr: CheckRealtimeResultsResolve | null) => CheckRealtimeResultsResolve | null)) => void;
  getLivenessSession: (params: { livenessId: string }, pathOverride?: string, signal?: AbortSignal) => Promise<{ error: string } | { currentAttempt: any; livenessConfig: LivenessDetection }>;
  updateLivenessAttempt: (
    params: { _apiVer?: "2"; livenessId: string; stage: LivenessStage; parts?: { front?: ImageAndVideoURLObject; back?: ImageAndVideoURLObject; selfie?: ImageAndVideoURLObject } },
    pathOverride?: string,
    signal?: AbortSignal
  ) => Promise<"ok" | "already_submitted" | { status: string }>;
  getUploadURL: (
    params: { path: string; length: number; phone?: string; publicFile?: boolean; livenessId?: string },
    pathOverride?: string,
    signal?: AbortSignal
  ) => Promise<{ error: string } | { uploadURL: string; savedPath: string; }>;
  checkRealtimeResults: (
    params: { livenessId: string },
    pathOverride?: string,
    signal?: AbortSignal
  ) => Promise<CheckRealtimeResultsResolve>;
  upload: (files: any[], params?: any, actualPath?: string, method?: "POST" | "PUT", localProgressCallback?: (progress: number) => void) => Promise<any>;
  ping: (message: string, extra?: any)=> Promise<void>;
  multipartUpload: {
    isUploading: boolean;
    progress: number;
    error: string | null;
    startUpload: (
      file: File,
      params: {
        pathParams: {
          kind: "liveness",
          livenessId: string;
          part: "front" | "back" | "selfie"
          videoIndex: number;
        } | {
          kind: "collab",
          survey: string
        };
        contentType: "video/mp4" | "video/webm" | "text/plain";
        length: number;  
      }
    ) => Promise<{ location: any } | undefined>;
  }
}

//
// Here we declare globally the React context variable that defines the structure of
// this Provider/Consumer context tooling.
//
export const LivenessDetectionSessionUIContext = React.createContext<LivenessDetectionSessionUIContextStaticData & LivenessDetectionSessionUIContextDynamicData & LivenessDetectionSessionUIContextCallbacks>({
  livenessId: '',
  appId: null,
  setLivenessConfig: () => {},
  setActionState: () => {},
  setRealtimeResults: () => {},
  getLivenessSession: () => (Promise.resolve({ error: 'Unimplemented' })),
  updateLivenessAttempt: () => (Promise.resolve({ status: 'Unimplemented' })),
  getUploadURL: () => (Promise.resolve({ error: 'Unimplemented' })),
  checkRealtimeResults: () => (Promise.resolve({ front: {}, back: {}, selfie: {} })),
  upload: () => (Promise.resolve()),
  ping: () => (Promise.resolve()),
  multipartUpload: {
    isUploading: false,
    progress: 0,
    error: null,
    startUpload: () => (Promise.resolve(undefined))
  },
  realtimeResults: null
});

//
// This is the heart of the Controller's internal data handling: This reducer parses
// dispatched action calls and applies appropriate changes to the state of the data.
//
// NOTE: A very common pattern, given our dependence on asynchronous callbacks, is to
// respond to a user action by (a) dispatching an action to update an in-process flag,
// (b) dispatching an asynchronous action that will in turn dispatch a *second* action
// to clear that in-process flag and update data with the results of the call (or
// with error messages).
//
export const livenessDetectionDataContextReducer = (state: LivenessDetectionSessionUIContextDynamicData, action: LivenessDetectionSessionUIContextReducerAction): LivenessDetectionSessionUIContextDynamicData => {
  if (action.type === 'no-op') {
    return state;
  }
  if (action.type === 'setLivenessConfig') {
    return {
      ...state,
      livenessConfig: action.config
    }
  }
  if (action.type === 'setActionState') {
    if (action.state) {
      if (typeof action.state === 'function') {
        const newState = action.state(state.actionState ?? null)
        return {
          ...state,
          actionState: newState || 'front'
        }
      }
      return {
        ...state,
        actionState: action.state
      }  
    }
    else {
      const { actionState, ...rest } = state
      return rest
    }
  }
  if (action.type === 'setAppId') {
    if (action.appId) {
      return {
        ...state,
        appId: action.appId
      }
    }
  }
  if (action.type === 'setRealtimeResults') {
    if (typeof action.realtimeResults === 'function') {
      const realtimeResults = action.realtimeResults(state.realtimeResults)
      return {
        ...state,
        realtimeResults
      }
    }
    else {
      return {
        ...state,
        realtimeResults: action.realtimeResults
      }
    }
  }
  if (action.type === 'incomingRealtimeResults') {
    // If something was just submitted, make sure not to overwrite it with old data
    // To do this, we have a hash for the 'pending' object, and if it doesn't match what's returned,
    // it's still pending.
    const nextState = JSON.parse(JSON.stringify(state.realtimeResults));
    for (const key of ['front','back','selfie'] as const) {
      if (nextState?.[key] && nextState[key]!.hash === action.results?.[key]?.hash) {
        nextState[key]!.state = action.results[key].state;
      }
    };
    return {
      ...state,
      realtimeResults: nextState
    };
  }
  throw new Error('Invalid action in livenessDetectionDataContextReducer');
};

//
// This provider component wraps around a liveness session page component, and handles
// all the asynchronous tooling. The goal is to provide an abstraction layer so that
// the session page component can simply call actions, and rely upon the data it is
// *currently receiving* to be an accurate representation of the state of the complex
// asynchronous system.
//
type LivenessDetectionSessionUIContextProviderProps = {
  children: React.ReactNode;
};

export const LivenessDetectionSessionUIContextProvider: React.FunctionComponent<LivenessDetectionSessionUIContextProviderProps> = (props) => {
  const { livenessId } = useParams() as { livenessId: string };
  const [dynamicData, dispatch] = React.useReducer(livenessDetectionDataContextReducer, { appId: null, realtimeResults: null });
  const setLivenessConfig = (config: LivenessDetection) => {
    dispatch({ type: 'setLivenessConfig', config });
  };
  const setActionState = (state: LivenessStage | null | ((curr: LivenessStage | null) => LivenessStage | null)) => {
    dispatch({ type: 'setActionState', state })
  }
  const setRealtimeResults = (state: CheckRealtimeResultsResolve | null | ((curr: CheckRealtimeResultsResolve | null) => CheckRealtimeResultsResolve | null)) => {
    dispatch({ type: 'setRealtimeResults', realtimeResults: state })
  }
  const { toast } = useToast();

  const getLivenessSession = usePost("/applicant/get_liveness_session", { handleErrors: (data) => { handleErrors(data, (payload) => toast(payload)) } });
  const updateLivenessAttempt = usePost("/applicant/update_liveness_attempt");
  const getUploadURL = usePost("/document/upload_url");
  const checkRealtimeResults = usePost("/applicant/check_realtime_results_v2");
  const upload = useAPIUpload("/upload");
  const ping = usePing();
  const multipartUpload = useMultipartUpload();

  // On load, get information about the liveness session
  React.useEffect(() => {
    // Fetch the liveness config
    (async () => {
      //
      // Fetch both currentLiveness information, and realtimeResults (to
      // determine whether the user is reloading a session mid-way through)
      // in parallel.
      //
      const [currentLiveness, realtimeResults] = await Promise.all([
        getLivenessSession({ livenessId }),
        checkRealtimeResults({ livenessId })
      ]);
      if ('error' in currentLiveness) {
        return
      }
      dispatch({ type: 'setAppId', appId: currentLiveness?.currentAttempt?.appId });
      if (currentLiveness.livenessConfig) {
        const completedPhase = realtimeResults?.front?.hash
          ? realtimeResults?.back?.hash
            ? realtimeResults?.selfie?.hash
              ? "done"
              : "selfie"
            : "back"
          : currentLiveness.livenessConfig && !currentLiveness.livenessConfig.identification ? 'selfie' : 'front'
        setLivenessConfig(currentLiveness.livenessConfig);
        setActionState(completedPhase);
      }
    })();
  }, [])

  // Check the feedback
  useInterval(() => {
    if (dynamicData.actionState === 'done' || !livenessId) return;
    checkRealtimeResults({ livenessId })
      .then((results) => { dispatch({ type: 'incomingRealtimeResults', results }); })
  }, 3000);
  
  return <LivenessDetectionSessionUIContext.Provider value={{
    livenessId,
    ...dynamicData,
    setLivenessConfig,
    setActionState,
    getLivenessSession,
    setRealtimeResults,
    updateLivenessAttempt,
    getUploadURL,
    checkRealtimeResults,
    upload,
    ping,
    multipartUpload
  }}>
    { props.children }
  </LivenessDetectionSessionUIContext.Provider>
}

type LivenessDetectionSessionUIContextOutput = {
  livenessId: string;
  appId: string | null;
  livenessConfig?: LivenessDetection;
  actionState: LivenessStage;
  realtimeResults: CheckRealtimeResultsResolve | null;
} & LivenessDetectionSessionUIContextCallbacks;

//
// The hook that the wrapped liveness session page component uses to receive data and action
// callbacks.
//
export const useLivenessDetectionSessionUIContext = (): LivenessDetectionSessionUIContextOutput => {
  const {
    livenessId,
    appId,
    livenessConfig,
    setLivenessConfig,
    actionState,
    setActionState,
    realtimeResults,
    setRealtimeResults,
    getLivenessSession,
    updateLivenessAttempt,
    getUploadURL,
    checkRealtimeResults,
    upload,
    ping,
    multipartUpload
  } = React.useContext(LivenessDetectionSessionUIContext);
  return {
    livenessId,
    appId,
    livenessConfig,
    setLivenessConfig,
    actionState: actionState || 'front',
    setActionState,
    realtimeResults,
    setRealtimeResults,
    getLivenessSession,
    updateLivenessAttempt,
    getUploadURL,
    checkRealtimeResults,
    upload,
    ping,
    multipartUpload
  };
};