import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useParams } from 'react-router';
import { usePost } from '../API';
import { LinkSender } from '../Components/LinkSender';
import InterfaceContext, { ConfigurationContext } from '../Context';
import { LANG_DICTS, useLocalizedStrings } from '../Localization';
import { snakeToEnglish } from '../Util';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from './Props';

export function SubsurveyQuestion(props: QuestionProps) {
  const getSubsurveyLink = usePost("/shortlink/create");
  const { uid } = useParams() as Record<string, string>;
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const host = window.location.protocol + "//" + window.location.host;

  const [iterations, setIterations] = useState([] as string[]);

  async function getLink(lang: string) {
    const resp = await getSubsurveyLink({ applicant: uid, part: props["Question"], host, lang });
    return resp.short_link!;
  }
    
  async function awaitLinkAndRedirect(iteration: string) {
    const resp = await getSubsurveyLink({ applicant: props.uid!, part: props['Question'], iteration, host, lang: context.lang, no_short_link: true });
    const link = resp.link;

    window.open(link, "_blank");
  }

  useEffect(() => {
    const fieldIters: { [field: string]: string[] } = {};
    if (!props['Subquestions']) return;
        
    Object.keys(props.info).filter(key => key.includes(':')).map(
      (key) => {
        const [iter, field]: string[] = key.split(':');
        if (fieldIters[field]){
          fieldIters[field].push(iter);
        }else{
          fieldIters[field] = [iter];
        }
        return key;
      }
    );

    props['Subquestions'].forEach(
      (q) => {
        if (!q['Target Field']) return;
                
        if (!fieldIters[q['Target Field']]) return;

        const iters = new Set(fieldIters[q['Target Field']]);

        iters.forEach((iteration: string) => {
          if(iterations.includes(iteration)) return;

          setIterations((prevState) => {
            const nextState = prevState;
            nextState.push(iteration);
            return nextState;
          });
        });
      }
    );
  }, [props.info, props.Subquestions]);

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <></>
  }

  const spanishMessage = props['Spanish Content'] || LANG_DICTS.es.subsurvey.default_message;

  // console.log(iterations);

  // If in applicant-facing view, don't show anything
  if (props.Viewer === 'applicant') {
    return <></>
  }

  return (
    <div>
      <LinkSender message={{'en': props['English Content'], es: spanishMessage}} 
        toastMessage={L.applicant.link_sent} 
        defaultValue={props.info['phone_number']}
        linkCreator={getLink}></LinkSender>
      {iterations.length > 0 && (config.roles?.includes('reviewer') || config.roles?.includes('admin')) && (
        <Card className="mt-4">
          <Card.Header>{props.Question}</Card.Header>
          <Card.Body>
            <p className="p-2">{L.subsurvey.iterations_found}</p>
            <ul>
              {iterations.sort((a,b) => a > b ? 1 : -1).map((iter) => {
                return (
                  <li key={props.Question + '-iter-' + iter}>
                    <Button variant="link" target="_blank" onClick={() => awaitLinkAndRedirect(iter)}>{snakeToEnglish(iter)}</Button>
                  </li>
                )
              })}
            </ul>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
