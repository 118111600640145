import { ExpandedSurvey, NotificationGroup, TrackedLink, Explore, Query } from "@aidkitorg/types/lib/survey";
import { CompileNudgeExprToSQL, CompileExpressionToSQL, findFields } from "@aidkitorg/types/lib/translation/expr_to_sql";
import { useState, useEffect, useMemo, useCallback } from "react";
import { usePost } from "../API";
import { DistroExprLink, DistroPreviewModal } from "../Components/ExploreLink";
import { Button, Switch } from "@aidkitorg/component-library";
import { Stats, SqlViewer, NotificationSimulation } from "./CommonComponents";
import { EligibilityChecker } from "./EligibilityChecker";
import { EnableKey } from "./EnableKey";
import { HasEnableKey } from "@aidkitorg/types/lib/eval";
import { useDebouncedCallback } from "../Hooks/Debounce";

export function Followup(props: {
  task: NonNullable<ExpandedSurvey['notifications']>[number],
  flup: Exclude<NonNullable<NotificationGroup>['followups'], undefined>[number],
  enableEmailMarkdownGlobally: boolean
}) {
  let testExpression = usePost('/program/admin/test_expression');
  let simulateNotifications = usePost('/program/admin/simulate_notifications');
  let [eligible, setEligible] = useState<Awaited<ReturnType<typeof testExpression>> | null>(null);
  let [simulated, setSimulated] = useState<Awaited<ReturnType<typeof simulateNotifications>> | null>(null);
  let [liveUpdating, setLiveUpdating] = useState<null | "simulation" | "query">(null);
  let [simulating, setSimulating] = useState(false);
  let [checking, setChecking] = useState(false);
  let [testContactMethod, setTestContactMethod] = useState<string>('sms');
  const [preview, setPreview] = useState(false);
  const [showEligibility, setShowEligibility] = useState(false);

  useEffect(() => {
    if (['email', 'sms', 'whatsapp'].includes(props.task.contactMethod)) {
      setTestContactMethod(props.task.contactMethod);
    }
  }, [props.task.contactMethod]);

  let expanded: Query = useMemo(() => props.flup.send_if.kind === 'SQL' ?
    props.flup.send_if :
    (props.task.recipient !== 'Unsubmitted Applicant' ? {
      kind: 'Click', expr: {
        kind: 'And',
        clauses: [
          {
            kind: 'Or',
            clauses: [
              {
                kind: 'And',
                clauses: [{
                  field: props.task.targetPrefix + '_sms' as any,
                  kind: 'Exists'
                },
                {
                  field: props.task.targetPrefix + '_sms' as any,
                  kind: 'Last Modified',
                  ago: props.flup.after
                }
                ]
              },
              {
                kind: 'And',
                clauses: [{
                  field: props.task.targetPrefix + '_email',
                  kind: 'Exists'
                },
                {
                  field: props.task.targetPrefix + '_email',
                  kind: 'Last Modified',
                  ago: props.flup.after
                }
                ]
              },
            ]
          },
          props.flup.send_if.expr
        ]
      }
    } : props.flup.send_if), [props.flup, props.task]);

  const checkNotificationQuery = useCallback(async () => {
    setChecking(true);
    const resp = await testExpression({
      query: expanded,
      orderBy: props.flup.send_if.kind !== 'SQL' ? props.flup.send_if.orderBy : undefined,
      sampleCount: 1,
      ...(props.task.recipient === 'Unsubmitted Applicant' ?
        {
          nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
          targetField: props.task.targetPrefix + '_' + testContactMethod,
          followupTargetField: props.task.targetPrefix + '_' + props.flup.suffix + '_' + testContactMethod,
          followupAfter: { ...props.flup.after }
        }
        : {})
    });
    setEligible(resp);

    if (resp && !('error' in resp)) {
      const simulResp = await simulateNotifications({
        uids: resp.sample,
        simulateAllLangs: true,
        content: props.task.initial_notification.message,
        emailContent: props.task.initial_notification.email_message,
        emailKey: typeof props.task.recipient === 'object' ? props.task.recipient.emailField : 'email',
        phoneKey: typeof props.task.recipient === 'object' ? props.task.recipient.phoneField : 'phone_number',
        enableEmailMarkdown: !!props.task.enableEmailMarkdown || !!props.enableEmailMarkdownGlobally,
        links: (props.task.initial_notification.subsurveys || []).reduce((obj, curr) => {
          obj[curr.variable] = curr.name;
          return obj
        }, {} as Record<string, string | TrackedLink>)
      })
      setSimulated(simulResp);
    }
    setChecking(false);
  }, [props.flup, props.task, props.enableEmailMarkdownGlobally])
  const debouncedCheckNotificationQuery = useDebouncedCallback(() => checkNotificationQuery(), 2000, [checkNotificationQuery]);

  const simulate = useCallback(async () => {
    setSimulating(true);
    setSimulated(await simulateNotifications({
      uids: props.task.testUIDs || [],
      emailKey: typeof props.task.recipient === 'object' ? props.task.recipient.emailField : '',
      phoneKey: typeof props.task.recipient === 'object' ? props.task.recipient.phoneField : '',
      content: props.flup.message as Record<string, string>,
      emailContent: props.flup.email_message,
      enableEmailMarkdown: props.task.enableEmailMarkdown || props.enableEmailMarkdownGlobally,
      links: (props.flup.subsurveys || []).reduce((obj, curr) => {
        obj[curr.variable] = curr.name;
        return obj
      }, {} as Record<string, string | TrackedLink>),
      ...(Array.isArray(props.flup.message) ? {
        messageBlocks: props.flup.message as any
      } : {})
    }));
    setSimulating(false);
  }, [props.flup, props.task, props.enableEmailMarkdownGlobally])
  const debouncedSimulate = useDebouncedCallback(() => simulate(), 2000, [simulate]);

  useEffect(() => {
    if (liveUpdating === "simulation") {
      setSimulating(true);
      debouncedSimulate();
    }
    if (liveUpdating === "query") {
      setChecking(true);
      debouncedCheckNotificationQuery();
    }
  }, [JSON.stringify(props), props.enableEmailMarkdownGlobally, liveUpdating])

  const sql = useMemo(() =>
    expanded.kind === 'SQL' ?
      expanded.sql :
      props.task.recipient === 'Unsubmitted Applicant' ?
        CompileNudgeExprToSQL({
          cond: expanded.expr,
          nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
          targetField: props.task.targetPrefix + '_' + testContactMethod,
          followupTargetField: props.task.targetPrefix + '_' + props.flup.suffix + '_' + testContactMethod,
          followupAfter: { ...props.flup.after }
        }) :
        CompileExpressionToSQL({
          cond: expanded.expr,
          orderBy: props.flup.send_if.kind !== 'SQL' ? props.flup.send_if.orderBy : undefined
        })
  , [expanded, props.task, props.flup]);

  const query: Explore = useMemo(() => {
    const name = props.flup.suffix;
    if (expanded.kind === 'Click') {
      let fields: string[] = findFields(expanded.expr);
      if (!fields.length) {
        fields = ['legal_name'];
      }

      let orderBy = {};
      if (props.task.recipient !== 'Unsubmitted Applicant') {
        if (props.flup.send_if.kind !== 'SQL' && props.flup.send_if.orderBy) {
          orderBy = { orderBy: props.flup.send_if.orderBy };
        } else if (expanded.orderBy) {
          orderBy = { orderBy: expanded.orderBy };
        }
      }

      return {
        query: {
          kind: 'Applicant Table',
          filter: {
            kind: 'Click',
            expr: expanded.expr,
            ...orderBy
          },
          columns: fields.map(f => ({ kind: 'Field', field: f })),
          title: { en: name },
          download: {
            filename: name
          }
        }
      };
    } else {
      return {
        query: {
          kind: 'Custom Query',
          sql: expanded.sql,
          visualization: {
            kind: 'Table',
            title: { en: name },
            download: {
              filename: name
            }
          }
        }
      };
    }
  }, [expanded, props.task, props.flup]);

  return <div className="border border-gray-300 p-2 bg-gray-100 space-y-2">
    <div className="flex space-x-2">
      <b>Followup:&nbsp;{props.flup.suffix}</b>
      <DistroExprLink name={props.flup.suffix} expr={props.flup.send_if} />
    </div>
    <EnableKey component={props.flup as HasEnableKey} parts={[props.task.targetPrefix, props.flup.suffix, JSON.stringify(props.flup.send_if)]} />
    <Stats stats={[
      ...(eligible ? [{ name: 'Initially Eligible', stat: eligible.count?.toString() || 'Error' }] : [])
    ]} />
    <ul className="flex space-x-1">
      <li>
        <Button
          size="sm"
          variant="primary"
          onClick={()=>setLiveUpdating(liveUpdating === "query" ? null : "query")}
          loading={checking}
        >
          {checking ? 'Checking...' : liveUpdating === "query" ? 'Watching for changes...' : 'Check Query'}
        </Button>
      </li>
      <li>
        <Button
          size="sm"
          variant="secondary"
          onClick={()=>setLiveUpdating(liveUpdating === "simulation" ? null : "simulation")}
          loading={simulating}
        >
          {simulating ? 'Simulating...' : liveUpdating === "query" ? 'Watching for changes...' : 'Simulate Formatted Notification'}
        </Button>
      </li>
      <li>
        <Button variant="secondary" size="sm" onClick={() => setPreview(true)}>
          <span className="size-fit">Preview Explore</span>
        </Button>
      </li>
    </ul>
    {props.task.recipient === 'Unsubmitted Applicant' && !(['email', 'sms', 'whatsapp'].includes(props.task.contactMethod)) &&
      <select className="ml-2 inline-flex items-center shadow-sm overflow-hidden bg-white px-2.5 py-1.5 text-black text-xs font-medium text-base text-gray-700 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded"
        onChange={(e) => setTestContactMethod(e.target.value)}>
        <option value="sms">SMS</option>
        <option value="email">Email</option>
        <option value="whatsapp">WhatsApp</option>
      </select>
    }
    <NotificationSimulation contactMethod={props.task.contactMethod} enableEmailMarkdown={props.task.enableEmailMarkdown || props.enableEmailMarkdownGlobally} simulated={simulated} simulationSource={liveUpdating} />
    <DistroPreviewModal open={preview} onClose={setPreview} query={query} />
    <Switch label="Show Eligibility" onCheckedChange={setShowEligibility} />
    <EligibilityChecker cond={expanded} show={showEligibility} />
    <SqlViewer sql={sql} />
  </div>
}
