import { Icon, Tooltip, Modal, Button, Tabs, Skeleton, Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@aidkitorg/component-library";
import { useCallback, useContext, useState } from "react";
import InterfaceContext, { PublicConfigurationContext } from "./Context";
import { useLocalizedStrings } from "./Localization";
import { usePost } from "./API";
import { Section } from "@aidkitorg/types/lib/survey";
import { ModularQuestionPage, type Sections } from "./Apply";

export function HelpButton() {
  const L = useLocalizedStrings();
  const publicConfig = useContext(PublicConfigurationContext);
  const [isOpen, setIsOpen] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const getHelpButtonSubsurveys = usePost("/help_button/get_subsurveys");

  const [helpSubsurveys, setHelpSubsurveys] = useState<{ path: string, branding?: { name?: string }, sections: Sections['sections'] }[]>([]);

  const handleClick = async () => {
    setIsOpen(true);
    try {
      setIsLoading(true);
      const result = await getHelpButtonSubsurveys({});
      setHelpSubsurveys(result.subsurveys as unknown as { path: string, branding?: { name?: string }, sections: Sections['sections'] }[]);
    } catch (error) {
      console.error('Failed to get subsurveys:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const helpButton = publicConfig.experimental?.helpButton;
  if (!helpButton?.enabled) {
    return null;
  }

  return (
    <>
      <div className="fixed bottom-4 right-4 z-50">
        <Tooltip text={"Help & FAQs"}>
          <button
            onClick={handleClick}
            className="w-12 h-12 rounded-full bg-indigo-600 text-white shadow-lg hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            aria-label={"help"}
          >
            <Icon name="QuestionMarkCircleIcon" className="w-8 h-8 mx-auto" aria-hidden="true" />
          </button>
        </Tooltip>
      </div>

      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={"Help and FAQs"}
        size="fullscreen"
        className="max-h-[90vh] overflow-y-auto"
        icon={<Icon name="QuestionMarkCircleIcon" className="w-6 h-6" />}
      >
        <div className="space-y-4">
          {isLoading ? (
            <div className="space-y-2">
              <Skeleton variant="card" size="auto" />
            </div>
          ) : (
            <HelpSubsurveyContent subsurveys={helpSubsurveys} />
          )}
        </div>
        <div className="mt-6 flex justify-end border-t pt-4">
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

const HelpSubsurveyContent = ({ subsurveys }: { subsurveys: { path: string, branding?: { name?: string }, sections: Sections['sections'] }[] }) => {

  const context = useContext(InterfaceContext);
  
  return <div className="flex flex-col h-full">
    {subsurveys.length > 0 ? (
      <Accordion type="single" className="w-full" defaultValue={subsurveys[0].path}>
        {subsurveys.map((survey) => (
          <AccordionItem key={survey.path} value={survey.path}>
            <AccordionTrigger>
              {/** Use the section title as the trigger text */}
              {survey.sections?.[0]?.[context.lang]}
            </AccordionTrigger>
            <AccordionContent>
              <ModularQuestionPage 
                shouldFastForward={{ current: true }}
                sections={{ sections: survey.sections }} 
                noNavBar={true}
                noHistory={true}
                info={{}}
                setInfo={() => {}}
                saveInfo={async () => {}}
                saveAuth={() => {}}
                sequential={false}
                warnOnIncompleteSurvey={{}}
              />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    ) : (
      <>Theres nothing here</>
    )}
  </div>
}
