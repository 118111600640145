type Distinct<T> = T; // & { __distro_tagged: T }; (Parser adds this)

export type WithLegacy<Modern, Legacy> = Modern | Legacy;

type AtLeast = {
  0: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
  1: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
  2: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
  3: 3 | 4 | 5 | 6 | 7 | 8 | 9,
  4: 4 | 5 | 6 | 7 | 8 | 9,
  5: 5 | 6 | 7 | 8 | 9,
  6: 6 | 7 | 8 | 9,
  7: 7 | 8 | 9,
  8: 8 | 9,
  9: 9
}

export function atLeastVersion<V extends number, A = unknown, B = unknown>(a: WithLegacy<A, B>): a is A & { version: V extends keyof AtLeast ? AtLeast[V] : never } {
  return true;
}

export type UserActivity = {
  lastEditedObject: string,
  uid: string,
  name: string,
  tabId: string
}

export type Author = {
  uid?: string,
  name?: string,
  tabId?: string,
  tags?: string[]
};

export type TrackedObjectInfo = {
  [key in keyof Text]: { id: string, prev_gen: number, currentAuthor?: Author, userActivity?: UserActivity[] }
}

export type Slug<T> = string & { __slug: T };

export type SuffixSlug = Slug<'suffix'>;
export type StandaloneSlug = Slug<'standalone'>;

export type SlugsAndDependencies = {
  slugs?: (SuffixSlug | StandaloneSlug)[],
  dependencies?: StandaloneSlug[],
};

/**
 * @display tabs
 * @validateInput Text
 */
export type Text = {
  /**
     * @name English
     */
  'en': string,
  /**
     * @name Spanish
     */
  'es'?: string,
  /**
     * @name French 
     */
  'fr'?: string,
  /**
     * @name Amharic
     */
  'am'?: string;
  /**
     * @name Chinese (Simplified)
     */
  'zh_CN'?: string,
  /**
     * @name Chinese (Traditional)
     */
  'yue'?: string,
  /**
     * @name Arabic 
     * @direction rtl
     */
  'ar'?: string,
  /**
     * @name Tagalog 
     */
  'tl'?: string,
  /**
     * @name Polish 
     */
  'pl'?: string,
  /**
     * @name Pashto
     * @direction rtl
     */
  'ps_AF'?: string,
  /**
     * @name Dari
     * @direction rtl
     */
  'fa_AF'?: string,
  /**
     * @name Korean
     */
  'ko'?: string,
  /**
     * @name Somali
     */
  'so'?: string,
  /**
     * @name Vietnamese
     */
  'vi'?: string,
  /**
     * @name Persian
     * @direction rtl
     */
  'fa'?: string,
  /**
     * @name Portuguese
     */
  'pt'?: string,
  /**
     * @name Bengali
     */
  'bn'?: string,
  /**
     * @name Haitian Creole
     */
  'ht'?: string,
  /**
     * @name Asháninka
     */
  'cni'?: string,
  /**
     * @name Purépecha
     */
  'pua'?: string,
  /**
     * @name Marshallese
     */
  'mh'?: string,
  /**
     * @name Hausa
     */
  'ha'?: string,
  /**
     * @name Nepali
     */
  'ne'?: string,
  /**
     * @name Russian
     */
  'ru'?: string,
  /**
     * @name Oromo
     */
  'om'?: string,
  /**
     * @name Hmong
     */
  'hmn'?: string,
  /**
     * @name Ukrainian
     */
  'ua'?: string,
  /**
     * @name ID!
     * @hidden yes
     */
  '_id'?: string
}

/**
 * @display tabs
 * @validateInput Text
 */
export type RichText = {
  /**
     * @name English
     */
  'en': Markdown,
  /**
     * @name Spanish
     */
  'es'?: Markdown,
  /**
     * @name French 
     */
  'fr'?: Markdown,
  /**
     * @name Amharic
     */
  'am'?: Markdown;
  /**
     * @name Chinese (Simplified)
     */
  'zh_CN'?: Markdown,
  /**
     * @name Chinese (Traditional)
     */
  'yue'?: Markdown,
  /**
     * @name Arabic 
     * @direction rtl
     */
  'ar'?: Markdown,
  /**
     * @name Tagalog 
     */
  'tl'?: Markdown,
  /**
     * @name Polish 
     */
  'pl'?: Markdown,
  /**
     * @name Pashto
     * @direction rtl
     */
  'ps_AF'?: Markdown,
  /**
     * @name Dari
     * @direction rtl
     */
  'fa_AF'?: Markdown,
  /**
     * @name Korean
     */
  'ko'?: Markdown,
  /**
     * @name Somali
     */
  'so'?: Markdown,
  /**
     * @name Vietnamese
     */
  'vi'?: Markdown,
  /**
     * @name Persian
     */
  'fa'?: Markdown,
  /**
     * @name Portuguese
     */
  'pt'?: Markdown,
  /**
     * @name Bengali
     */
  'bn'?: Markdown,
  /**
     * @name Haitian Creole
     */
  'ht'?: Markdown,
  /**
     * @name Asháninka
     */
  'cni'?: Markdown,
  /**
     * @name Purépecha
     */
  'pua'?: Markdown,
  /**
     * @name Marshallese
     */
  'mh'?: Markdown,
  /**
     * @name Hausa
     */
  'ha'?: Markdown,
  /**
     * @name Nepali
     */
  'ne'?: Markdown,
  /**
     * @name Russian
     */
  'ru'?: Markdown,
  /**
     * @name Oromo
     */
  'om'?: Markdown,
  /**
     * @name Hmong
     */
  'hmn'?: Markdown,
  /**
     * @name Ukrainian
     */
  'ua'?: Markdown,
  /**
     * @name ID!
     * @hidden yes
     */
  '_id'?: string
}

/**
 * @component TextArea 
 */
export type Markdown = Distinct<string>;

/**
 * @component TextArea
 */
export type Code = Distinct<string>;

/**
 * @referencedIn fields
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage TargetFields must start with a letter or _ and contain no spaces
 */
export type TargetField = Distinct<string>

/**
 * @references fields
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage TargetFieldRefs must start with a letter or _ and contain no spaces
 */
export type TargetFieldRef = Distinct<string>

export type TargetFieldObj = {
  kind: 'Target Field',
  /**
   * @width 4
   */
  field: TargetFieldRef
};

/**
 * @referencedIn fields
 * @references fields
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage Target Fields must start with a letter or _ and contain no spaces
 */
export type SelfReferencingTargetField = Distinct<string>

/**
 * @referencedIn sections
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage Section IDs must start with a letter or _ and contain no spaces
 */
export type SectionId = Distinct<string>

/**
 * @references sections
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage Section Refs must start with a letter or _ and contain no spaces
 */
export type SectionRef = Distinct<string>

/**
 * @display stacked
 */
export type Config = {
  /**
     * The subdomain prefix used to reach this program.
     */
  deployment_id: string,
  /**
     * The full (english) name for this program.
     * @name Program Name
     */
  program_name: string
  /**
     * A URL that links to the logo used on applicant facing surveys. 
     */
  applicant_facing_logo?: string,
  /**
     * Content to show on the /apply page if the application isn't open yet.
     */
  guard?: Markdown
}


export type TopLevelTemplatedBlock = {
  kind: 'Templated Block',
  iterations: {
    /**
         * @width 4
         */
    substitutions: { key: string, value: string }[]
  }[]
  /**
     * @name _ 
     */
  components: Survey
}

export type GenericTemplatedBlock<Component extends any[]> = {
  kind: 'Templated Block',
  iterations: {
    /**
         * @width 4
         */
    substitutions: { key: string, value: string }[]
  }[],
  /**
     * @name _ 
     */
  components: Component
}

export type Collection = {
  kind: 'Collection',
  /**
     * @width 3
     */
  name: Text,
  /**
     * @width 4
     * @collapsible starts-collapse
     */
  options: {
    /**
         * @name Limited To Users Tagged
         */
    limitedToTags?: UserTagRef[],
    /**
         * @width 4
         */
    limitedToApplicants?: BooleanExpr,

    notificationEmail?: string,
    notificationService?: string,
    /**
         * @width 2
         */
    subsurveyBranding?: {
      /**
             * @width 4
             */
      name: string,
      /**
             * @width 4
             */
      logo: string
    },
    /**
         * Disables all payments and notifications in a section
         */
    archived?: true
  }
  /**
     * @name _
     * @collapsible starts-collapse
     * @macros PaymentModule
     */
  components: CollectionComponent[]
}


export type SectionComponent = Block;

export type CollectionComponent = (
  (Section & CommonProperties)
  | Collection
  | Subsurvey
  | TopLevelTemplatedBlock
  | Payment
  | NotificationGroup
  | UsioMailing
  | GiveCardMailing
  | USBankCardMailing
  | Dashboard
  | ApplicantIdentities
  | ApplicantPortalPage
  | AIAssistantReviewSurvey
) // & MacroBlockProperties // for CollectionComponents. This gets added by the parser in macroEditor mode
  | Macro;

export type SurveyComponent = SectionComponent | CollectionComponent;

export type Survey = CollectionComponent[];

export type UsioMailing = {
  kind: 'Usio Mailing',
  targetField: TargetField,
  mailingAddress: TargetFieldRef,
  /**
     * @width 4
     */
  condition: ClickQuery,
  /**
     * if the payment kind is USIO Mailed Grant (usio_mailed_grant),
     * this field will cause the card mailed to the applicant to be
     * pre-associated.
     */
  autoAssociate?: boolean,
}

export type GiveCardMailing = {
  kind: 'GiveCard Mailing',
  targetField: TargetField,
  /**
     * Only required if overriding the default, "usio_mailing_address".
     *
     * Sets the mailing address field to be used with givecard_mail card types.
     *
     * @name Address Field
     */
  mailingAddress?: TargetFieldRef,

  /**
     * Enables this mailing component.
     * If this is not set properly (see payment tab in Distro),
     * no cards will be mailed.
     *
     * @name Enable Key
     * @width 2
     * @validateInput ConditionEnableKey
     */
  enableKey: string,

  /**
     * The criteria that determines the eligible applicants to process.
     *
     * @name Eligibility Criteria
     * @width 4
     */
  condition: ClickQuery | SQLQuery,

  /**
     * Automatically sets the applicant's Card ID target field (usually card_id) to the ordered card's id.
     *
     * If this is false (or not set at all), the applicant will need to associate it manually
     * using a CardManagement kind in a Subsurvey.
     *
     * @name Auto Associate
     * @width 1
     */
  autoAssociate?: boolean,

  /**
     * GiveCard Only Field
     *
     * Cards that are ordered as a result of this Payment kind
     * will be allowed a higher limit (up to 8k).
     *
     * The default target_field is givecard_higher_spending_limit
     *
     * @name Higher Spending Limit
     * @width 2
     */
  higherSpendingLimit?: TargetFieldRef,

  /**
     * Indicates that a card is either virtual or physical.
     * Must be one of these values: givecard_mail, givecard_virtual, givecard_rtp
     *
     * (for backward compatibility reasons) IF THIS IS NOT INCLUDED, IT IS ASSUMED TO ALWAYS BE givecard_mail.
     *
     * @name Card Type
     * @width 2
     */
  type?: TargetFieldRef,

  /**
   * The contact (can be either a Phone or Email) to
   * associate the card with.
   *
   * @name Contact Field
   * @width 2
   */
  contactField?: TargetFieldRef,

  /**
     * DEPRECATED. This does not do anything.
     * @name (Do Not Use) Card Field
     * @width 1
     */
  cardField?: TargetField,

  /**
     * DEPRECATED. This does not do anything.
     * @name (Do Not Use) Mailing Name Field
     * @width 1
     */
  mailingName?: TargetFieldRef,

}

export type USBankCardMailing = {
  kind: 'USBank Card Mailing',
  targetField: TargetField,
  /**
      * Only required if overriding the default, "usio_mailing_address".
      *
      * Sets the mailing address field to be used with usbank_card_mail card types.
      *
      * @name Address Field
      */
  mailingAddress?: TargetFieldRef,

  /**
      * Only required if the physical address is different than the mailing address. 
      *
      * Sets the physical address field to be used with usbank_card_mail card types.
      *
      * @name Physical Address Field
      */
  physicalAddress?: TargetFieldRef,

  /**
      * Enables this mailing component.
      * If this is not set properly (see payment tab in Distro),
      * no cards will be mailed.
      *
      * @name Enable Key
      * @width 2
      * @validateInput ConditionEnableKey
      */
  enableKey: string,

  /**
      * The criteria that determines the eligible applicants to process.
      *
      * @name Eligibility Criteria
      * @width 4
      */
  condition: ClickQuery | SQLQuery,

  /**
      * Automatically sets the applicant's Card ID target field (usually card_id) to the ordered card's id.
      *
      * If this is false (or not set at all), the applicant will need to associate it manually
      * using a CardManagement kind in a Subsurvey.
      *
      * @name Auto Associate
      * @width 1
      */
  autoAssociate?: boolean,

  /**
    * If not provided, 'email' will be used.
    *
    * @name Email Field
    * @width 2
    */
  contactField?: TargetFieldRef,

  /**
      * If not provided, 'ssn' will be used. This should be an encrypted value.
      * 
      * @name  Encrypted SSN Field
      * @width 1
      */
  ssnField?: TargetField,

  /**
      * If not provided, 'birth_date' will be used.
      * 
      * @name Birth Date field
      * @width 1
      */
  birthDateField?: TargetFieldRef,
}

/**
 * @display stacked
 */
export type Section = {
  kind: 'Section',
  /**
     * @width 3
     */
  title: Text,

  /**
     * @collapsible starts-collapsed 
     * @width 4
     */
  components: Block[],
  /**
     * @width 2
     */
  screenerMode?: 'All At Once' | 'Progressive',
  /**
     * @name Limited To Users Tagged
     */
  limitedToTags?: UserTagRef[],
  /**
     * @name Hide Next Button
     * @width 2
     */
  hideNextButton?: boolean,

  /**
   * A unique identifier for the section so it can be referenced elsewhere
   * @width 2
   */
  sectionId?: SectionId
}

export type CommonProperties = {
  /**
     * @width 4
     * @validateJS (() => expr )
     * @language js
     */
  conditionalOn?: Code | BooleanExpr,
  optional?: boolean,
  hidden?: boolean,
  /**
    * Optional text read by audio reader but not visible in survey.
    * @width 4
    * @name Text-to-Speech 
    */
  textToSpeech?: {
    mode: "append" | "replace",
    /**
        * @width 4
        */
    content: RichText
  },
  /**
    * Audio file to replace the TTS reader. Upload file and generate URL here: https://[program].aidkit.org/publicS3
    * @width 4
    * @name Custom-Audio-File
    */
  customAudio?: {
    /**
        * @width 4
        */
    audio_link: Text
  }
}

/**
 * @intersection-naming first-only
 */
export type Block = (
  (
    (
      | Action
      | Address
      | ApplicantCreator
      | ArcGISAttachmentViewer
      | Assignee
      | Attachment
      | BankRoutingNumber
      | BenefitsCalculator
      | CardManagement
      | Consent
      | Computed
      | Confirmation
      | ContactConfirmation
      | Date
      | EncryptedValueVerification
      | Explanation
      | HouseholdCalculator
      | FillableForm
      | Flag
      | DuplicateReview
      | IncomeCalculator
      | InlineLanguageSelector
      | InlineNotification
      | InlineSignature
      | Likert
      | LivenessDetection
      | LoginWidget
      | Lookup
      | Number
      | NumberWithUnit
      | PlaidBankAccount
      | PlaidIncome
      | RankingQuestion
      | ResumeWidget
      | SearchSelect
      | Section
      | Select
      | SelectFromData
      | ShowField
      | SimilarDocumentCheck
      | SimilarDocumentReview
      | SubmitButton
      | Subsurvey
      | SupportButton
      | TextEntry
      | ThirdPartyCheck
      | Validated
      | Milestone
      | RelatedApplicants
      | ImagePreview
    )
    & CommonProperties
  )
  | TemplatedBlock
  | ConditionalBlock
); // & MacroBlockProperties // For Blocks. This gets added by the parser in macroEditor mode

export type ConditionalBlock = {
  kind: 'Conditional Block',
  /**
      * @width 4
      * @validateJS (() => expr )
      * @language js
      */
  conditionalOn: Code | BooleanExpr,
  /**
      * @name _
      * @collapsible starts-shown
      */
  components: Block[],
  /**
      * @name Otherwise
      * @collapsible starts-shown
      */
  otherwise?: Block[],
  /**
   * Option to reset unreachable fields when conditional switches from T to F.
   * When condition is true, fields inside Otherwise block will be reset.
   * When condition is false, fields inside normal components block will be reset.
   * This does not include Computed, Lookup, and Validated kinds.
   * @name Reset Unreachable Fields
   */
  resetUnreachableFields?: boolean,
}

export type TemplatedBlock = {
  kind: 'Templated Block',
  iterations: {
    /**
         * @width 4
         */
    substitutions: { key: string, value: string }[]
  }[]
  /**
     * @name _ 
     */
  components: Block[],
}

export type Explanation = {
  kind: 'Explanation',
  /**
     * @width 4
     */
  content: RichText | ConditionalContentList,
  collapsible?: boolean
}

export type USBankCard = {
  kind: 'USBank',
  /**
     * Target Field that stores the applicant's address to be associated with this card. 
     * Default is address.
     * @name Address Field
     * @width 4
     */
  addressField?: string,
  /**
     * Target Field that stores the applicant's email to be associated with this card. 
     * Default is email.
     * @name Email Field
     * @width 4
     */
  emailField?: string,
  /**
     * Target Field that stores the applicant's SSN to be associated with this card. 
     * This target field should be encrypted. Default is ssn.
     * @name SSN Field
     * @width 4
     */
  ssnField?: string,
  /**
     * Target Field that stores the applicant's date of birth to be associated with this card. 
     * Default is birth_date.
     * @name Date of Birth Field
     * @width 4
     */
  dobField?: string
}

export type CardManagement = {
  kind: 'Card Management',
  /**
     * @width 3
     */
  targetField: TargetField,
  viewOnly?: boolean,
  /**
     * @width 4
     */
  cardType?: 'GiveCard' | 'USIO' | USBankCard,
}


/**
 * @referencedIn PersonaName
 */
type PersonaName = Distinct<string>;

/**
 * @references PersonaName
 * @name Persona
 */
type PersonaNameRef = Distinct<string>;

/**
 * @display stacked
 */
export type Persona = {
  kind: 'Persona',
  name: PersonaName,
  /**
     * @collapsible starts-collapse
     */
  attrs: {
    field: TargetFieldRef,
    /**
         * @width 3
         */
    value: string,
  }[],
}

/**
 * @migration PlaidBankLegacyV0
 */
type PlaidBankLegacyV0 = {
  /**
     * @width 2
     */
  kind: 'Plaid Bank Account'
  /**
     * @width 2
     */
  targetField: TargetField,
  version?: 0
}

export type PlaidBankAccount = WithLegacy<{
  /**
     * @width 2
     */
  kind: 'Plaid Bank Account'
  /**
     * @width 2
     */
  targetField: TargetField
  /**
     * @width 4
     */
  content: RichText
  version?: 1
  /**
     * Uses Assets by default. For Dev Use Only.
     * @name Product
     */
  product?: 'transactions' | 'income_verification' | 'assets',
}, PlaidBankLegacyV0>

export type PlaidIncome = {
  /**
     * @width 2
     */
  kind: 'Plaid Income',
  /**
     * @width 2
     */
  targetField: TargetField
}

export type Connection = PlaidBankAccount | PlaidIncome;

export type Milestone = {
  kind: 'Milestone'
  name: string
}

export type TextEntry = {
  kind: 'TextEntry',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText
  multiline?: boolean,
  format?: 'phone' | 'email' | 'legalName',
  minLength?: number,
  maxLength?: number,
  confirmationField?: TargetFieldRef,
}

export type Address = {
  kind: 'Address',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  lookups?: string[]
}

export type ContactConfirmation = {
  kind: 'ContactConfirmation',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  contactField?: string,
  resume?: boolean,
  /**
     * @width 2
     */
  ingestedResumesToSubsurvey?: SubsurveyPathRef
  /**
     * Appears directly above the "Send Confirmation Code" button.
     * @width 4
     */
  customSMSConsentText?: RichText,
  /**
     * Appears directly above the button.
     * @width 4
     */
  customEmailConsentText?: RichText
  /**
     * default: Send Confirmation Code.
     * @width 4
     */
  customSendButtonText?: Text
  /**
     * default: Resume.
     * @width 4
     */
  customResumeButtonText?: RichText
}

export type Confirmation = {
  kind: 'Confirmation',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText
}

export type ViewOnly = {
  /**
     * @width 2
     */
  modeType: 'view_only'
}
export type SendOnly = {
  /**
     * @width 2
     */
  modeType: 'send_only',
  /**
     * @width 4
     */
  sendLink: RichText
}
export type SendAndView = {
  /**
     * @width 2
     */
  modeType: 'send_and_view',
  /**
     * @width 4
     */
  sendLink: RichText
}

/**
 * @referencedIn subsurveyPaths
 * @format ^[a-z0-9_-]+$
 * @formatMessage Lowercase characters, - , and _ only. No spaces.
 */
type SubsurveyPath = Distinct<string>;
/**
 * @references subsurveyPaths
 * @format ^[a-z0-9_-]+$
 * @formatMessage Lowercase characters, - , and _ only. No spaces.
 */
export type SubsurveyPathRef = Distinct<string>;

export type SubsurveyRef = {
  kind: "Subsurvey",
  path: SubsurveyPathRef
}

/** 
 * @display stacked
 */
export type ApplicantIdentities = {
  kind: 'Applicant Identities',
  /**
     * @name Dashboard title
     */
  title: Text,
  /** 
     * @format ^[a-zA-Z_][a-zA-Z0-9_]*$
     * @formatMessage Can only contain alphanumeric characters and underscores.
     */
  path: string,
  /**
     * Specify fields here to see their values and if they are shared across the applicants on this dashboard.
     * @name Fields to watch. 
     */
  info: (ValueExpr | NamedExpression)[],
  /**
     * @name Shared Key
     */
  sharedKey?: string
}

/** 
 * @display stacked
 */
export type Subsurvey = {
  kind: 'Subsurvey',
  /**
     * @name URL Path (/p/<path>)
     */
  path: SubsurveyPath,

  /**
     * The title that will appear if this subsurvey is linked. i.e. "Application" or "Appeal"
     * @width 2
     */
  title?: Text

  /**
     * Which applicants can access this subsurvey. 
     * If an applicant matches the requirements here, they will be able to access the subsurvey.
     * @width 4
     * @name Conditional Access
     */
  accessConditional?: BooleanExpr,

  /**
     * @name Protected Search Config
     * @width 4
     */
  protectedSearch?: {
    /**
         * @width 4
         */
    condition: ClickQuery,
    /**
         * @width 4
         * @name Allowed User Tags
         */
    roles: UserTagRef[],
    /**
         * Content uses $link for URL. This will replace the "View" button with two Send Link options to send via SMS or via Email.
         * @width 4
         * @name Send Link (With Content)
         */
    sendLink?: RichText,
    /**
         * @width 4
         * @name Assignee Field
         */
    assigns?: string,
    /**
         * The key=value pairs will be passed into the subsurvey url in the view_info url param.
         * @width 4
         * @name View Info (Custom Views)
         */
    viewInfo?: {
      /**
             * @width 2
             */
      key: string,
      /**
             * @width 2
             */
      value: string
    }[],
    /**
         * @width 4
         * @name Mode
         */
    mode?: ViewOnly | SendOnly | SendAndView,
    /**
         * If your search may return more than one applicant (common in multiplexing), you can provide
         * target field values here to help the searcher determine which applicant they are looking for.
         * @width 4
         * @name Applicant display fields
         */
    displayFields?: TargetFieldRef[],
  }
  /**
     * @name Allow Anonymous viewing
     */
  anonymous?: boolean,
  /**
     * @name Custom popup text on submit
     * @display stacked
     */
  customSubmittedModal?: {
    title?: RichText,
    message?: RichText,
  },
  /**
     * @name Redirect to subsurvey on submit
     */
  redirectToSubsurvey?: SubsurveyPathRef,
  /**
     * @name Load to first incomplete section
     */
  shouldFastForward?: boolean,
  /**
     * @collapsible starts-collapse
     */
  sections: (Section & CommonProperties)[], // & MacroBlockProperties // for Subsurvey Sections. This gets added by the parser in macroEditor mode
  defaultExpiration?: {
    days: number,
    weeks: number,
    months: number
  },
  branding?: {
    name: string,
    logo: string
  },
  hideFromNavigation?: boolean,
  /**
     * @name Block further edits
     */
  blockFurtherEdits?: {
    /**
         * @width 4
         */
    block_when: BooleanExpr,
    /**
         * @width 4
         */
    blocked_message: RichText
  },
  /**
     * @width 4
     * @name Bottom Buttons
     */
  sectionBottomButtons?: {

    /**
         * @width 4
         * @name Logout Redirects To
         */
    logoutRedirectPath: SubsurveyPathRef,

    /**
         * @width 4
         * @name Hide in First Section
         */
    hideOnFirstSection?: boolean,
  },
  /**
    * @width
    * @name Warn on Incomplete Survey
    */
  warnOnIncompleteSurvey?: {
    /**
    * Warn on incomplete survey will display a modal to the user if they leave the browser window before
    * submitting the subsurvey. It will check if the user's mouse leaves the viewport and display the modal
    * if the targetField defined in submissionField is not populated.
     */
    submissionField: TargetFieldRef,
    /** 
     * @width 4
     * @name Warning Message
     */
    warningMessage: RichText,
  },
  copyEditing?: {
    /**
     * @name Limited To Users Tagged
     */
    allowedUserTags: UserTagRef[],
  }
}

type SelectChoice = {
  /**
       * @width 4
       * @format ^[a-zA-Z0-9_]+$
       * @formatMessage Alphanumeric characters and underscores only
       */
  value: string,
  /**
       * @width 4
       */
  label: Text,
  exclusive?: boolean,
  selectAll?: boolean,
  otherField?: boolean,
}; // & MacroBlockProperties // for SelectChoices. This gets added by the parser in macroEditor mode

export type Select = {
  kind: 'Select',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  multiple?: boolean,
  maxAllowedSelections?: number,
  randomizeOrder?: boolean,
  choices: SelectChoice[]
}

export type SelectFromData = {
  kind: 'SelectFromData',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  multiple?: boolean,
  maxAllowedSelections?: number,
  /**
     * @name sourceField (json stringified array of string values)
     * @width 4
     */
  sourceField: TargetFieldRef
}

/**
 * @migration SearchSelectV1
 */
export type LegacySearchSelectV0 = {
  kind: 'SearchSelect',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  optionsFileUrl: Text,
  maxOptions?: number,
};

type ClientSide = {
  kind: 'ClientSide';
  /**
   * Url for a JSON file (probably uploaded via /publics3) that contains the options for the dropdown. Each option needs "id" and "text" properties.
   * @width 4
    */
  optionsFileUrl: Text;
};

export type ServerSide = {
  kind: 'ServerSide';
  /**
     * @width 3
     */
  baseTableName: string;
  /**
     * @width 4
     */
  loc: {
    /**
         * @width 2
         */
    bucket: string,
    /**
         * @width 2
         */
    key: string,
  },
  /**
     * @name csv columns
     * @width 4
     */
  columns: {
    /**
         * Column that contains the text to search
         * @width 2
         */
    searchColumn: string,
    /**
         * Column that contains unique values for each row, used to differentiate any duplicate search column values
         * @width 2
         */
    uniqueColumn?: string,
    /**
         * @width 2
         */
    idColumn?: string,
  },
  /**
     * @name check for updates every
     * @width 4
     */
  updateInterval?: {
    /**
         * @width 2
         */
    amount: number,
    /**
         * @width 2
         */
    unit: 'hours' | 'days' | 'weeks' | 'months',
  },
  /**
     * @width 4
     */
  allowNotFound?: {
    /**
         * @width 4
         */
    customOptionLabel?: Text,
  }
};

export type SearchSelect = WithLegacy<{
  kind: 'SearchSelect';
  /**
     * @width 3
     */
  targetField: TargetField;
  /**
    * @width 4
    */
  content: RichText,
  /**
   * Maximum number of options shown in the dropdown at a time, regardless of the number available.
   * The default is 20. We recommend not exceeding 100, but up to 500 should work okay (might be slow on slower machines).
   */
  maxOptions?: number,
  /**
     * @width 4
     */
  searchType: ClientSide | ServerSide,
  /**
    * @hidden yes
    */
  version: 1,
}, LegacySearchSelectV0>;

export type ShowField = {
  kind: 'Show Field',
  /**
     * @width 3
     */
  targetField: TargetFieldRef,
  /**
     * @width 4
     */
  headerText: Text,
  showsVersions?: boolean,
  allowDecrypting?: boolean
  keepVisibleForReview?: boolean,
}

export type Date = {
  kind: 'Date',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  /** 
   * If this is true, no day field will be shown
   * and the day will always be considered the first of the month.
   * @name Month and Year Only
   */
  monthAndYearOnly?: boolean,
}

export type Number = {
  kind: 'Number',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  encrypted?: boolean,
  min?: number | string,
  max?: number | string,
  min_length?: number,
  max_length?: number,
  /**
    * By default, leading zeros are removed. To preserve them, set preserveLeadingZeros to true.
    */
  integers_only?: boolean,
  format?: 'usd',
  startsWith?: string,
  doesNotStartWith?: string,
  /**
    * For use with integers_only.
    * If this is set, any zeros at the beginning of the number will be preserved.
    * Example use cases: social security numbers, id numbers, zip codes
    */
  preserveLeadingZeros?: boolean,
}

export type EncryptedValueVerification = {
  kind: 'Encrypted Value Verification',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  /**
     * @width 4
     */
  sourceField: TargetFieldRef,
  lastFourOnly?: boolean
}

export type BankRoutingNumber = {
  kind: 'BankRoutingNumber',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
}

export type VerificationRequirements = {
  facePresent?: boolean,
  /**
     * @width 4
     */
  textPresent?: {
    kind: 'Any Text'
  } | {
    anyOf?: (string | Field)[],
    allOf?: (string | Field)[],
  },
  advisoryOnly?: boolean,
  /**
     * @width 4
     */
  errorMessage: Text
}

export type Attachment = {
  kind: 'Attachment',
  /**
     * @width 3
    */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  limitOne?: boolean,
  detectFaces?: boolean,
  extractText?: boolean,
  directUploadOnly?: boolean,
  allowSendingLinkToUpload?: boolean,
  enableCamera?: boolean,

  /**
     * @width 4
     */
  realtimeVerifications?: VerificationRequirements[]
}

export type IPCheck = {
  /**
     * @width 4
     */
  kind: 'IP VPN Check',
  /**
     * @width 4
     */
  ipField?: '_ip' | string
}

export type AnalyzeID = {
  kind: 'Analyze ID',
  idField: TargetFieldRef
}

export type SharedKeyComputation = {
  /**
     * @width 2
     */
  kind: "Shared Key Computation",
  /**
     * All applicants with the same value for this field will be included in the computation specified in propagateUpdates
     * @width 2
     * @name Shared Key
     */
  sharedKey: "program_identity" | string,
  /**
     * All fields that are referenced in propagateUpdates. Any field referenced there without
     * being listed here will not evaluate correctly
     * @width 4
     * @name Depends On
     */
  dependsOn: Field[] // example: program_identity, duplicate_review_1, metastate
  /**
     * Code that will be executed for each of the applicants with matching values for the sharedKey specified,
     * generally used to set/update multiple other fields.
     * Propagate Updates should be a Record<uid, Record<infoKey, value>>
     * We will run RoboScreener after propagating these updates.
     * @width 4
     * @validateJS ((shared_apps) => { const newInfo = expr; return newInfo; })
     * @language js
     */
  propagateUpdates: Code
}

export type ITINCheck = {
  /**
     * @width 4
     */
  kind: 'ITIN Check',
  /**
     * @width 4
     */
  itinField: TargetFieldRef
  nameField?: 'legal_name' | TargetFieldRef;
}

export type EmailDomainCheck = {
  /**
     * @width 4
     */
  kind: 'Email Domain Check'
  /**
     * @width 4
     */
  emailField: TargetFieldRef
}

export type IDCheck = {
  /**
     * @width 4
     */
  kind: 'ID Check',
  /**
     * @width 4
     */
  detailsField: TargetFieldRef
}

export type ProgramSpecificCheck = {
  /**
     * @width 4
     */
  kind: 'Program Specific',
  /**
     * @width 2
     */
  name: string,
  dependencies?: TargetFieldRef[],
}

export type ThirdPartyCheck = {
  /**
     * @width 4
     */
  kind: "Third Party Check"
  /**
     * @width 4
     */
  condition: BooleanExpr, // Who should be included
  /**
     * @width 4
     */
  type: AnalyzeID | IPCheck | EmailDomainCheck | IDCheck | ITINCheck | ProgramSpecificCheck | SharedKeyComputation,
  /**
     * @width 2
     */
  targetField: TargetField,
  /**
     * @width 2
     */
  statusField?: TargetField // defaults to targetField_status. Stores 'pending' | 'done'
}

export type LivenessDetection = {
  /**
     * @width 2
     */
  kind: 'Liveness Detection',
  /**
     * The content that appears in the survey, above the start button
     * @width 4
     * @name Survey Content
     */
  content: RichText
  /**
    * This is the target field the entire liveness video will be stored in.
    * @width 2
    * @name Target Field
    */
  targetField: TargetField,
  /**
     * @width 4
     */
  selfie: {
    /**
         * We will automatically Detect Faces in the selfie capture
         * @width 2
         * @name Capture Target Field
         */
    targetField: TargetField,
    /**
         * Optional content to display above the capture button
         * @width 4
         */
    content?: RichText
  },
  /**
     * @width 4
     */
  identification?: {
    /**
         * @width 4
         */
    front: {
      /**
             * @width 2
             */
      targetField: TargetField,
      /**
             * @width 4
             */
      content: RichText,
      /**
             * Adds a button to allow the user to take a "tall" picture of their ID,
             * useful for Paper IDs or other non-standard ID types.
             * @width 2
             */
      allowTallImages?: boolean
    },
    /**
         * @width 4
         */
    back: {
      /**
             * @width 2
             */
      targetField: TargetField,
      /**
             * @width 4
             */
      content: RichText,
      /**
             * By default, we assume this is back of id.
             * But liveness can be used to capture all sorts of images!
             * This lets you specify an alternative document kind in place of the "Back of ID"
             * @name Alternative Document
             * @width 4
             */
      alternativeDocument?: {
        /**
                 * @width 2
                 */
        kind: "Debit Card"
      }
    }
  },

  /**
     * What the button to start the liveness detection process should say.
     * Default is "I'm ready, send me the text!"
     * @width 4
     */
  startButtonText?: Text,

  /**
     * What to show after the process is done.
     * @width 4
     */
  completionContent?: RichText,

  /**
     * Which contacts to send the link to. 
     * We will attempt these in order, the first field with a valid contact value will be sent the link.
     * @name Contact Fields
     * @width 4
     */
  contactFields?: TargetFieldRef[],

  /**
     * This option will result in lower quality images 
     * because webcams are generally way lower MP than phone cameras.
     * However, it provides a more seamless UX for applicants.
     * @name Allow Desktop Webcam
     * @width 2
     */
  allowDesktopWebcam?: boolean

  /**
     * @uuid hidden
     * @hidden yes
     */
  id: string,
}

export type InlineSignature = {
  /**
     * @width 2
     */
  kind: 'InlineSignature',
  /** 
     * @width 2
     */
  targetField: TargetField,
  /** 
     * @width 4
     */
  content: RichText | ConditionalContentList,
  /**
     * @width 2
     */
  signerNameTargetField?: string | {
    special: 'Viewer Name'
  }
  /**
     * If this flag is set, then this signature will not include contact confirmation, and will not be available via a public URL
     */
  anonymous?: boolean;
  /**
     * For use with Fillable pdf forms
     * @width 2
     */
  captureRawSignature?: boolean
  /**
     * If this flag is set, an additional Edit / Confirm step will be added, to allow users to edit their signature before saving.
     * @width 2
     */
  confirmButton?: boolean
}

export type FillableForm = {
  /**
     * @width 2
     */
  kind: "Fillable Form",
  /**
     * @width 2
     */
  targetField: TargetField,
  /**
     * @display stacked
     * @width 4
     */
  form: {
    kind: "w9",
    legalNameField: TargetFieldRef,
    businessInfo?: {
      /**
       * Note: as of 2024 at least, the IRS allows sole proprietors and single-member
       * LLCs to use SSN in place of EIN for business W9s
       * @name EIN field
       */
      einField: TargetFieldRef,
      businessNameField: TargetFieldRef,
      /**
       * This must be one of:
       * - sole_prop
       * - c_corp
       * - s_corp
       * - partnership
       * - trust_or_estate
       * - llc_c_corp
       * - llc_s_corp
       * - llc_p
       * - other_business_type
       * If it is other_business_type, then otherBusinessType must have a value
       */
      businessTypeField: TargetFieldRef,
      otherBusinessTypeField: TargetFieldRef,
      /**
       * If businessTypeField is partnership, trust_or_estate, or llc_p,
       * then this should have either value 'yes' or 'no'.
       * 
       * Will not check the box if value is 'no', empty, undefined, etc.
       */
      box3bField: TargetFieldRef,
    }
    mailingAddressField: TargetFieldRef,
    /**
     * Note: If SSN is provided, EIN will not be used.
     * If this is a problem, consider using a computed field here that
     * is empty if an EIN exists.
     * @name Encrypted SSN field
     */
    tinField: TargetFieldRef,
    /**
         * For storing the raw signature for use elsewhere
         * @name Signature Storage Field
         */
    signatureStorageField: SelfReferencingTargetField
  },
  /**
     * Instructions are static and depend on the document. They will be imported in questions.ts 
     * depending on the document kind used
     * @hidden
     */
  instructions?: Text,
  /**
     * Defaults to 'Document Signed, click below to download a copy for your records'
     * @width 4
     */
  finishedContent?: RichText | ConditionalContentList
  /**
     * Defaults to 'You still need to fill out some things before you can continue: (lists the target fields)'
     * @width 4
     */
  notYetReadyContent?: RichText | ConditionalContentList
}

export type LikertQuestions = {
  /**
    * @width 2
    */
  label: Text,
  targetField: TargetField,
};

export type LikertDataSource = {
  /**
    * Target field where this data is stored in applicant info. Each Likert From Data only pulls from one Target Field.
    * This data is expected to be an array type.
    * @name Data Target Field
    * @width 2
    */
  targetField: TargetFieldRef,
  /**
    * Field to display as the label on the likert - if not present in the object, the entire value will display.
    * This value should be unique for each question to avoid confusion / answers being overwritten.
    * @width 2
    * @name Display Field
    */
  displayField: string,
  /**
    * When the likert is filled out, this is where the answer will go per data item.
    * @width 2
    * @name Answer Field
    */
  answerField: string,
}

export type Likert = {
  kind: 'Likert',
  /**
     * @width 3
     */
  content: RichText,
  /**
     * The value scale option displays only the first/last option labels and all option values,
     * e.g. 1 - Strongly Disagree, 2, 3, 4, 5 - Strongly Agree
     */
  showValueScale?: boolean,
  /**
     * Display Format: 'horizontal' for Likert tabular format on all screens (best for <=5 options). 'responsive_horizontal' adapts to screen size, showing Likert on desktop and stacked choice on mobile. Default is Likert for desktop and stacked for mobile or >5 options.
     * @width 2
     */
  display?: 'horizontal' | 'responsive_horizontal',
  /**
     * @width 4
     */
  choices: {
    value: string,
    /**
         * @width 2
         */
    label: Text
  }[],
  /** 
     * @name Questions or Data Source
     * @width 4
     */
  questions: LikertQuestions[] | LikertDataSource,
  randomizeOrder?: boolean,
}

/**
 * @migration LoginWidgetV2
 */
export type LegacyLoginWidgetV0 = {
  kind: 'LoginWidget'
  page: string
}

export type LoginWidget = WithLegacy<
  {
    kind: 'LoginWidget'
    page: string
    /**
     * @width 2
     */
    buttonText: Text
    /**
     * Allows storing the contact info (e.g. person@email.com)
     * in a specified field upon successful login
     * @width 4
     */
    saveContactInfo?: {
      /**
         * @width 2
         */
      contactInfoField: TargetField,
    }
    /**
     * @uuid hidden
     * @hidden yes
     */
    id?: string,
    /**
     * @hidden yes
     */
    version: 2
  }, LegacyLoginWidgetV0>

export type CustomJobKind = {
  /**
     * @width 4
     */
  value: string,
  /**
     * @width 4
     */
  label: Text
}

export type IncomeCalculator = {
  kind: 'Income Calculator',
  /**
     * @width 3
     */
  targetField: TargetField,
  customJobKinds?: CustomJobKind[],
  excludeDependents?: boolean,
  excludeCashBenefits?: boolean;
}

export type InlineLanguageSelector = {
  kind: "Inline Language Selector",
  /**
     * @width 4
     */
  content: RichText,
}

export type CustomSelect = {
  kind: 'Select';
  /**
     * @width 4
     */
  key: string;
  /**
     * @width 4
     */
  content: RichText;
  /**
         * @width 4
         */
  choices: {
    /**
         * @width 4
         */
    value: string;
    /**
         * @width 4
         */
    label: Text;
  }[],
}

type MaxCountFromField = {
  /**
    * @width 4
    * @name Dynamic limit from another field
    */
  field: TargetFieldRef
};

export type CustomTextEntry = {
  kind: 'TextEntry';
  /**
     * @width 4
     */
  key: string;
  /**
     * @width 4
     */
  content: RichText;
  /**
     * Provides validation on the input. Numbers only means you can only enter numbers; leading 0s are allowed.
     * Phone and email will enforce correct formatting for these contact methods.
     * @width 4
     * @name Formatting
     */
  formatting?: 'numbers only' | 'phone' | 'email';
  /**
     * If true, this field is unique for each entry. If a new household member shares the same value for this question 
     * as another household member, we show an error and make them enter a new value.
     * @width 4
     * @name Unique
     */
  unique?: boolean
};

export type CustomDate = {
  kind: 'Date',
  /**
     * @width 4
     */
  key: string;
  /**
     * @width 4
     */
  content: RichText;
  /**
     * @width 4
     */
  showAge?: boolean;
  /**
     * @width 4
     * @name min allowed time in past
     */
  minAllowedTimeInPast?: {
    /**
         * @width 2
         */
    amount: number,
    /**
         * @width 2
         */
    unit: 'days' | 'weeks' | 'months' | 'years',
    /**
         * @width 4
         * @name error message
         */
    errorMessage?: Text,
  };
  /**
     * @width 4
     * @name max allowed time in past
     */
  maxAllowedTimeInPast?: {
    /**
         * @width 2
         */
    amount: number,
    /**
         * @width 2
         */
    unit: 'days' | 'weeks' | 'months' | 'years',
    /**
         * @width 4
         * @name error message
         */
    errorMessage?: Text,
  };
  /**
     * @width 4
     * @name min allowed time in future
     */
  minAllowedTimeInFuture?: {
    /**
         * @width 2
         */
    amount: number,
    /**
         * @width 2
         */
    unit: 'days' | 'weeks' | 'months' | 'years'
    /**
         * @width 4
         * @name error message
         */
    errorMessage?: Text,
  };
  /**
     * @width 4
     * @name max allowed time in future
     */
  maxAllowedTimeInFuture?: {
    /**
         * @width 2
         */
    amount: number,
    /**
         * @width 2
         */
    unit: 'days' | 'weeks' | 'months' | 'years'
    /**
         * @width 4
         * @name error message
         */
    errorMessage?: Text,
  };
}

/**
 * @migration HouseholdCalculatorDataCollection
 */
type LegacyHouseholdDataCollection = {
  requireFullName?: boolean;
  hideName?: boolean;
  collectBirthdate?: "full" | "year_only";
  dependence?:
  | { preset: "independent" | "dependent_on_applicant" | "dependent_on_someone_else" | "applicant_dependent_on_them" }
  | "collect";
  maxCount?: number | MaxCountFromField;
  customQuestions?: (CustomSelect | CustomTextEntry | CustomDate)[];
};

type HouseholdDataCollection = WithLegacy<
  {
    /**
      * @width 4
      * @name Require full name
      */
    requireFullName?: 'Full name' | 'Split first and last';
    /**
      * @width 4
      * @name Hide name
      */
    hideName?: boolean;
    /**
      * @width 4
      * @name Collect birthdate
      */
    collectBirthdate?: "full" | "year_only";
    /**
      * @width 4
      * @name Dependence on applicant
      */
    dependence?:
    | { preset: "independent" | "dependent_on_applicant" | "dependent_on_someone_else" | "applicant_dependent_on_them" }
    | "collect";
    /**
      * @width 4
      * @name Max # members in this role
      */
    maxCount?: number | MaxCountFromField;
    /**
      * @width 4
      * @name Custom questions
      */
    customQuestions?: (CustomSelect | CustomTextEntry | CustomDate)[];
  }, LegacyHouseholdDataCollection>;

export type Role = {
  /**
   * @width 4
   */
  type: string;
  /**
   * @width 4
   */
  label: {
    /**
     * @width 4
     */
    singular: Text;
    /**
     * @width 4
     */
    plural?: Text;
  };
  /**
   * @width 4
   * @name data collect options
   */
  dataCollectOptions: HouseholdDataCollection;
  /**
   * @width 4
   * @name display options
   */
  displayOptions: {
    /**
     * @width 4
     * @name Form open to start
     */
    startOpen?: boolean;
    /**
     * @width 4
     * @name Hide member count for this role
     */
    hideCount?: boolean;
    /**
       * This option collapses entries that have already been filled out
       * when the user starts adding a new entry.
     * @width 4
     * @name Collapse entries
     */
    collapseEntries?: {
      /**
       * Field that will still show even when the entry is collapsed.
       * Default is name.
     * @width 4
     * @name Display field
     */
      displayField: string
    };
  };
}

export type HouseholdCalculator = {
  /**
     * @width 2
     */
  kind: 'Household Calculator';
  /**
     * @width 2
     */
  targetField: TargetField;
  roles: Role[];
  /**
   * @width 4
   * @name Custom title
   */
  title?: Text;
  /**
   * @width 4
   * @name Include applicant as a member
   */
  showYou?: {
    /**
     * @width 4
     * @name Applicant's name field
     */
    nameField: TargetFieldRef;
    /**
     * @width 4
     * @name Applicant's birthdate field
     */
    birthdateField?: TargetFieldRef;
  };
  /**
   * @width 4
   * @name Custom label for total count
   */
  memberCountLabel?: {
    /**
     * @width 4
     */
    singular: Text;
    /**
     * @width 4
     */
    plural: Text;
  };
  /**
     * @width 4
     * @name Reference date
     */
  referenceDate?: {
    year: number,
    month: number,
    day: number
  } | TargetFieldRef;
};

/**
 * @display stacked
 */
export type NumberWithUnit = {
  kind: 'NumberWithUnit',
  numberQuestion: {
    /**
         * @width 4
         */
    targetField: TargetField,
  },
  unitQuestion: {
    /**
         * @width 4
         */
    targetField: TargetField,
    choices: {
      /**
             * @width 4
             */
      value: string,
      /**
             * @width 4
             */
      label: Text,
    }[],
  }
  content: RichText,
  format?: 'usd',
  integers_only?: boolean,
}

export type Assignee = {
  kind: 'Assignee',
  /**
     * @width 3
     */
  targetField: TargetField
  /**
     * @width 4
     */
  content: RichText,
}

export type Computed = {
  kind: 'Computed',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  /**
     * @width 4
     * @validateJS (() => expr )
     * @language js
     */
  formula: Code | ValueExpr,
  format?: 'currency',

  /**
     * @width 4
     */
  testCases?: {
    /**
         * @width 4
         */
    description: string,
    /**
         * Input can be either a JSON Object (string, { "birth_date": "01/01/2000" } ) or a Persona
         * @width 4
         * @validateJS (() => { const newInfo = expr })
         * @language js
         */
    input: Code | PersonaRef,
    /**
         * Use output as the input variable here, e.g. (output === 'yes' or JSON.parse(output).eligible === true)
         * @width 4
         * @validateJS (() => expr )
         * @language js
         */
    test: Code,
  }[]
}

type PersonaRef = {
  kind: "Persona",
  /**
     * @width 3
     */
  persona: PersonaNameRef
}

export type Validated = {
  kind: 'Validated',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  /**
     * @width 4
     * @validateJS ((info) => { try { expr } catch (e) { if (!e.toString().includes('$content')) throw e; } })
     * @language js
     */
  formula: Code,
  advisory?: boolean
}

export type RankingQuestion = {
  kind: 'Ranking',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  choices: {
    value: string,
    label: Text,
  }[],
  /**
     * If specified, this will dictate the number of choices that the user must select.
     * If omitted, all choices must be ranked.
     */
  number_of_required_choices?: number
}

export type ResumeWidget = {
  kind: 'ResumeWidget',
  /**
     * @width 4
     */
  ingestedResumesToSubsurvey?: SubsurveyPathRef,
  /**
     * @width 4
     * @name Button Text
     */
  customButtonText?: Text
}

export type SubmitButton = {
  kind: 'SubmitButton',
  /**
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  customText?: Text,
  hideRequestChangesButton?: boolean
}

export type BulkDownload = {
  kind: 'Bulk Download',
  /**
     * The target fields to download on click.
     * @name Target Fields
     */
  fields: TargetField[],
  /**
     * The name of the zipfile that will be downloaded
     * @name File Name
     */
  fileName?: string
};

export type VisitLink = {
  kind: 'Visit External Link',
  /**
     * @width 4
     */
  url: string,
  /**
   * If you want to make this action required, set the target field here.
   * This target field will be set to the date and time that the action buttonwas clicked.
   * @width 4
   * @name Target Field
   */
  targetField?: TargetField,
}

/**
 * @display stacked
 */
export type Action = {
  kind: 'Action',
  /**
     * @name Button Text
     */
  content: Text,
  /**
     * Should return an updates object like { key: 'value', key2: 'value2' }, each key/value pair will be applied to the applicant info.
     * @name Action
     * @validateJS (() => { const newInfo = expr } )
     * @language js
     */
  action: Code | BulkDownload | VisitLink,
  /**
     * The explanation will be displayed above the action button.
     * @width 4
     * @name Explanation
     */
  explanation?: RichText,
  confirmation?: Text,
  toast?: Text,
  /**
     * Describe any custom fields that get set here
     * @width 4
     */
  fields?: TargetField[]
}

export type ApplicantCreator = {
  kind: 'ApplicantCreator',
  /**
     * This is the field for the existing applicant where the ID of the newly created applicant will be stored.
     * @name linkedApplicantUIDField
     * @width 2
     */
  targetField: TargetField,
  /**
     * @name Limited To Users Tagged
     * @width 2
     */
  allowedUserTags: UserTagRef[],
  /**
     * @width 2
     */
  buttonText: Text,
  /**
     * @width 4
     */
  newApplicantInitialInfo: {
    nameSourceField: TargetField,
    /**
         * We store the ID and name of the existing applicant in the info of the new applicant.
         * so if the prefix is created_by, then created_by_id will be the existing applicant's UID
         * and created_by_name will be the existing applicant's legal_name
         */
    existingApplicantReferencePrefix: Distinct<string>,
    otherFields?: {
      /**
             * @width 3
             */
      source: ValueExpr,
      destinationField: TargetField,
    }[]
  },
}

type CommonFlagProperties = {
  /**
     * Optional max number of folks who can meet the criteria before it's a problem
     */
  maxCount?: number,
  maxSum?: number,
}

/**
 * @name Unique Fields Flag
 */
type UniqueFlag = {
  kind: 'Unique',
  /**
     * @width 4
     */
  programs: {
    /**
     * To check within this program, use .
     * Otherwise use the program name i.e. brf
     * @width 4
     * @format ^[a-z.]+$
     * @formatMessage Lowercase characters only, no spaces.
     */
    program: string,
    /**
         * @width 4
         */
    fields: {
      /**
             * @width 2
             */
      field: TargetFieldRef,
      mode: 'exact' | 'fuzzy'
    }[],
    /**
         * Required Info to be part of the group checked for others to be flagged.
         * @width 4
         */
    requiredInfo?: {
      key: TargetFieldRef,
      value: string
    }[]
  }[]
}

type QueryFlag = {
  kind: 'Query Flag',
  /**
     * @width 4
     */
  programs: {
    /**
         * @width 4
         * @format ^[a-z.]+$
         * @formatMessage Lowercase characters only, no spaces.
         */
    program: string,
    /**
         * @width 4
         */
    query: SQLQuery,
    depends_on?: TargetFieldRef[],
    params?: TargetFieldRef[],
    /**
         * Required Info to be part of the group checked for others to be flagged.
         * @width 4
         */
    requiredInfo?: {
      key: TargetFieldRef,
      value: string
    }[]
  }[]
}

export type Flag = {
  kind: 'Flag',
  /**
     * @width 3
     */
  targetField: TargetField,
  /** 
     * @width 4
     */
  content: RichText,
  /**
     * @width 4
     */
  flag: (UniqueFlag | QueryFlag) & CommonFlagProperties,
  advisory?: boolean,
  /**
     * Configures whether this Flag check should be run asynchronously in the background at a later time (async=true)
     * or if it needs to be recomputed immediately when applicantinfo is ingested or updated (async=false)
     */
  async?: boolean
}

/**
 * :point_right::point_left:
 * @name Shared Decision
 */
export type SharedDecision = {
  kind?: "Shared Decision",
  /**
    * @width 4
    */
  text: Text,
  /**
    * @width 2
    */
  value: string,
  /**
   * This field gets set to the same value for each set of applicants that are matched together with a given
   * comparison decision in this Duplicate Review. If there is already a value for this field for an applicant,
   * matching applicants will have their value set to that, otherwise a new, unique value will be generated and used
   * @width 2
   */
  sharedKeyField?: TargetField,
}; // & MacroBlockProperties // for SharedDecision. This gets added by the parser in macroEditor mode

/**
 * :point_right:
 * @name Individual Decision
 */
export type IndividualDecision = {
  kind: "Individual Decision",
  /**
    * @width 4
    */
  text: Text,
  /**
    * @width 2
    */
  value: string,
  /**
    * Allows the reviewer to decide on a per-suspected-duplicate basis whether to set a target field
    * in the local app or in the other app. Useful for labeling duplicate apps but not changing 
    * all of them.
    * @width 2
   */
  individualDecisionField: TargetField
}; // & MacroBlockProperties // for IndividualDecision. This gets added by the parser in macroEditor mode

export type DuplicateReview = {
  kind: 'Duplicate Review'
  /**
     * @width 4
     */
  content?: RichText,
  /**
     * Field defined elsewhere, used to determine if an applicant shows up in this Duplicate Review
     */
  flagField: TargetFieldRef,
  /**
     * The results of this Duplicate Review will be stored here for a given applicant.
     * It will be of the form: uid1:value1,uid2:value2,...
     * where uid is the applicant that was compared against and value was the chosen comparison decision value for that other applicant.
     */
  targetField: TargetField,
  /**
     * Field used to determine which applicant(s) is/are considered duplicates and which are not
     * @width 4
     */
  tiebreakerField: {
    /**
         * @width 1
         */
    kind: 'Earliest Submission',
    /**
         * @width 3
         */
    field: TargetFieldRef
  }
  /**
     * The values of these fields will be displayed to the reviewer when they review the potential duplicates
     * @width 4
     */
  comparisonFields: TargetFieldRef[],
  /**
     * @width 4
     */
  customDecisions?: (SharedDecision | IndividualDecision)[],
  /**
     * @uuid hidden
     * @hidden yes
     */
  id: string,
  /**
     * Controls whether the original horizontal scroll UI or the new one by one modal review UI is used.
     */
  useModalUI?: boolean
}

/**
 * @name Similar Document Check
 * @display stacked
 */
export type SimilarDocumentCheck = {
  /**
     * Configure this question to enable a similar document check to be conducted on the documentTextField within this program.
     * The system will analyze all applicants and flag similar documents above the similarityThreshold configured 
     * in the targetField.
     */
  kind: 'Similar Document Check',
  /**
     * The target field where the text we want to compare is stored. This is often the target field
     * where text extraction writes to but could be any text target field we want to analyze.
    */
  documentTextFields: TargetFieldRef[],
  targetField: TargetField,
  /**
     * Similarity threshold is in percent between 0 and 100. We recommend a value above 90.
     */
  similarityThreshold: number,
  /**
     * Use this to ensure both applicants who are being flagged as having similar documents are updated.
     * For example, if Applicant A submits their application with a doc and later Applicant B submits identical documentation,
     * we would normally flag only Applicant B.
     * Checking enableReflection will flag both Applicant a and B.
     */
  enableReflection?: boolean,
  /**
     * Use this to display the Processing status in the screener view. It will show if the applicant's
     * similarity check has been processed yet and when the last processing took place.
     */
  showStatus?: boolean,
}

export type SimilarDocumentReview = {
  kind: 'Similar Document Review'
  /**
     * @width 4
     */
  content?: RichText,
  documentField: TargetFieldRef,
  targetField: TargetField,
  /**
     * @width 4
     */
  maxCount?: number,
  /**
     * Cutoff is in percent be between 0 and 100.
     * @width 4
     */
  cutoff?: number;
  /**
     * @uuid hidden
     * @hidden yes
     */
  id: string,
  /**
     * default is 'Suspicious?' Use this to override that.
     * @name Suspicious Label Override
     * @width 4
     */
  suspiciousLabel?: Text
  /**
     * @width 4
     */
  comparisonFields?: {
    field: TargetFieldRef
  }[]
  /**
     * Controls whether the original horizontal scroll UI or the new one by one modal review UI is used.
     */
  useModalUI?: boolean
}

/**
 * @name Standard
 * @display stacked
 */
type StandardLookup = {
  kind: 'Standard',
  /**
     * @name Lookup Key
     */
  key: string,
  /**
     * @name Field to Query
     */
  queryField: string
}

/**
 * @name Geo
 * @display stacked
 */
type GeoLookup = {
  kind: 'Geo',
  key: 'community_area' | 'ward' | string,
  queryField: string
}

/**
 * @name GeoShape
 * @display stacked
 */
type GeoShapeLookup = {
  /**
    * A Geo Lookup that returns a polygon shape based on the queryField. The resulting value will be a polygon shape in WKT string format.
    */
  kind: 'GeoShape',
  /**
   * The kind in the geolookup table that represents the subset of geo data we should lookup in.
   */
  key: string,
  /**
   * The target field to reference that has the lat and long slugs, typically an Address target field.
   */
  queryField: string,
}

/**
 * @name GeoPoints
 * @display stacked
 */
type GeoPointsLookup = {
  /**
    * A Geo Lookup that returns a collection of points that fall within the queryField's shape.
    */
  kind: 'GeoPoints',
  /**
   * The kind in the geolookup table that represents the subset of geo data we should lookup in.
   */
  key: string,
  /**
    * The GeoShapeLookup target field. We want to look up any points that appear within this shape queryField. This must be a GeoShapeLookup target field.
    */
  queryField: TargetFieldRef,
}

/**
 * @name Dynamo
 * @display stacked
 */
type DynamoLookup = {
  kind: 'Dynamo',
  contactConfirmationField: string,
  configPath: string,
  queryFields: {
    /**
         * @width 2
         */
    fieldInDynamo: string,
    /**
         * @width 2
         */
    fieldInAidKit: string
  }[],
  sensitiveInfo?: {
    field: string,
    action: "hide" | "encrypt" | "hash"
  }[]
}

export type RelatedApplicants = {
  kind: 'Related Applicants',
  /**
     * The key to gather related applicants by. 
     * This is a field in the applicant info.
     *
     * @name Field to Gather By
     * @width 4
     *
     */
  byKeys: TargetFieldRef[],

  /**
     * @name Title
     * @width 4
     *
     */
  title?: Text,

  /**
     * @name Show View Link
     * @width 4
     */
  showView: boolean
}

export type Lookup = {
  kind: 'Lookup',
  /** 
     * @width 3
     */
  targetField: TargetField,
  /**
     * @width 4
     */
  content: RichText,
  /** 
     * @width 4
     */
  lookup: StandardLookup | GeoLookup | GeoShapeLookup | GeoPointsLookup | DynamoLookup
}

export type Consent = {
  kind: 'Consent',
}

export type BenefitsCalculator = {
  kind: 'Benefits Calculator',
  /**
     * @width 4
     */
  waivers: {
    wic: boolean,
    snap: boolean,
    tanf: boolean,
    liheap: boolean,
    healthcare?: boolean,
    taxCredits?: boolean,
    childcare?: boolean,
  }
  /**
     * @width 4
     */
  required_info: {
    /**
         * @width 4
         */
    monthly_income: ValueExpr,
    /**
         * @width 4
         */
    live_zip: ValueExpr,
    /**
         * @width 4
         */
    gi_income: ValueExpr,
  }
  /**
     * @width 4
     */
  optional_info: {
    /**
         * This is information required to get a useful response from BK about SNAP.
         * It is NOT information about someone's existing SNAP status; for that, use the foodStamps option.
         * @width 4
         */
    snap?: {
      /**
             * @width 4
             */
      child_care_cost: ValueExpr,
      /**
             * @width 4
             */
      feed_family_cost: ValueExpr,
      /**
             * @width 4
             */
      homeless: ValueExpr,
      /**
             * @width 4
             */
      housing_costs: ValueExpr,
      /**
             * @width 4
             */
      ssi_children_number: ValueExpr,
      /**
             * @width 4
             */
      ssdi_amount: ValueExpr,
      /**
             * @width 4
             */
      ssi_spouse_amount: ValueExpr,
      /**
             * @width 4
             */
      ssi_you_amount: ValueExpr,
      /**
             * @width 4
             */
      ssi_income_kids: ValueExpr,
      /**
             * @width 4
             */
      welfare_amount: ValueExpr
    },
    /**
         * @width 4
         */
    ctc?: {
      /**
             * @width 4
             */
      married: ValueExpr
    },
    /**
         * @width 4
         */
    otherInfo?: {
      /**
             * Use this to send information about someone's EXISTING food stamps/SNAP
             * situation to BK to improve accuracy of eligibility estimations
             * @name Food Stamps/SNAP Amount
             * @width 4
             */
      food_stamps?: ValueExpr
    }
  },
  /**
     * @width 2
     */
  household_calc_target_field?: TargetField,
  /** 
     * @width 2
     */
  targetField?: TargetField,
  /**
     * If this flag is set, the individual values comprising the response from Benefits Kitchen will be stored
     * as separate target fields. NOTE: a targetField must also be set for this to work!
     */
  flattenIntoFieldsWithPrefix?: TargetField,
  /** 
     * @width 4
     */
  hideImpactIf?: BooleanExpr
}

export type SupportButton = {
  kind: 'SupportButton',
  /** 
     * Override the heading text. Leave empty to use auto-translated value.
     * @width 4
     */
  messageTextOverride?: Text,
  /** 
     * Override the button text. Leave empty to use auto-translated value.
     * @width 4
     */
  buttonTextOverride?: Text,
  /** 
     * Override the support email. Defaults to support@<program_name>.aidkit.org
     * @width 4
     */
  supportEmailOverride?: string
}

export type Configuration = {
  enable_audit: 'true' | 'false',
  [key: string]: string
}

export type Never = {
  'kind': 'Never'
};

export type FieldExists = {
  'field': TargetFieldRef
  'kind': 'Exists'
}

export type FieldDoesntExist = {
  'field': TargetFieldRef
  'kind': 'DoesntExist'
}

export type FieldEquals = {
  'field': TargetFieldRef,
  'kind': 'Equals'
  'value': string,
}

export type FieldNotEqual = {
  'field': TargetFieldRef,
  'kind': 'Not Equal'
  'value': string,
}

export type FieldLastModifiedBefore = {
  'field': TargetFieldRef,
  'kind': 'Last Modified'
  /**
     * @width 4
     */
  'ago': {
    amount: number,
    unit: 'days' | 'weeks' | 'months'
  }
}

export type Or = {
  'kind': 'Or',
  /**
     * Any of these must be true
     */
  'clauses': (BooleanExpr | GenericTemplatedBlock<BooleanExpr[]>)[]
}

export type And = {
  'kind': 'And',
  /**
     * All of these must be true
     */
  'clauses': (BooleanExpr | GenericTemplatedBlock<BooleanExpr[]>)[]
}

export type Not = {
  'kind': 'Not',
  /**
     * @width 4
     */
  'clause': BooleanExpr
}

export type After = {
  'kind': 'After',
  /**
     * @width 4
     */
  date: {
    year: number,
    month: number,
    day: number
  },
  /**
     * @width 4
     */
  time: {
    hour: number,
    minute: number
  }
}

export type Before = {
  'kind': 'Before',
  /**
     * @width 4
     */
  date: {
    year: number,
    month: number,
    day: number
  },
  /**
     * @width 4
     */
  time: {
    hour: number,
    minute: number
  }
}

export type CurrentUser = {
  'kind': 'Current User',
  property?: 'tags'
}

export type When = {
  'kind': 'When',
  /**
     * @width 4
     */
  when: {
    /**
         * @width 4
         */
    cond: BooleanExpr,
    /**
         * @width 4
         */
    then: ValueExpr
  }[]
  /**
     * @width 4
     */
  otherwise: ValueExpr
}

export type ApplicantLuck = {
  kind: 'Applicant Luck',
  prop: 'luck'
}

export type UserLookup = {
  kind: 'User',
  prop: 'name' | 'roles' | 'email'
};

export type MessageLookup = {
  kind: 'Message',
  prop: 'needs_attention',
  messageType: 'sms' | 'email'
  destination?: string,
};

export type PaymentLookup = {
  kind: 'Payment',
  prop: 'status' | 'type' | 'amount' | 'date created' | 'transaction ref'
}

export type TableLookup = UserLookup | MessageLookup | PaymentLookup;

export type Field = {
  'kind': 'Field',
  'field': TargetFieldRef,
  /**
     * @width 4
     */
  lookup?: TableLookup
}

export type FieldLastModifiedDate = {
  /**
     * @width 2
     */
  kind: 'Last Modified Date',
  /**
     * @width 2
     */
  field: TargetFieldRef,
}

export type NumericValue = {
  'kind': 'NumericValue',
  /**
     * @width 4
     */
  'value': number
}

export type StringValue = {
  'kind': 'StringValue',
  /**
     * @width 4
     */
  'value': string
}

export type BooleanValue = {
  'kind': 'BooleanValue',
  /**
     * @width 4
     */
  'value': boolean
}

export type SyncStatus = {
  'kind': 'Sync Status',
  mini?: boolean
}

type DateExpr = FieldLastModifiedDate | AsDate | ShiftDate | BucketDate | Now

export type Now = {
  'kind': 'Now'
}

export type AsDate = {
  'kind': 'As Date',
  /**
     * @width 3
     */
  'value': StringValue | NumericValue | Field
}

export type ShiftDate = {
  'kind': 'Shift Date',
  /**
     * @width 3
     */
  'interval': {
    minutes?: number,
    hours?: number,
    days?: number,
    weeks?: number,
    months?: number,
    years?: number,
  }
  /**
     * @width 4
     */
  'value': DateExpr,
}

export type ValueContains = {
  'kind': 'Value Contains',
  /**
     * @width 2
     */
  'mode': 'string' | 'comma separated list'
  'caseInsensitive'?: boolean,
  /** 
     * @width 4 
     */
  'value': ValueExpr,
  /** 
     * @width 4 
     */
  'contains': ValueExpr
}

export type ValueEquals = {
  'kind': 'Value Equals',
  /**
     * @width 4
     */
  'value': ValueExpr,
  /**
     * @width 4
     */
  'equals': ValueExpr
}

export type ValueNotEquals = {
  'kind': 'Value Not Equal',
  /**
     * @width 4
     */
  'value': ValueExpr,
  /**
     * @width 4
     */
  'notEqual': ValueExpr
}

export type ValueEmpty = {
  'kind': 'Value Empty',
  /**
     * @width 4
     */
  'value': ValueExpr
}

export type ValueNotEmpty = {
  'kind': 'Value Not Empty',
  /**
     * @width 4
     */
  'value': ValueExpr
}

export type ValueLessThan = {
  'kind': 'Value Less Than',
  /**
     * @width 4
     */
  'value': ValueExpr,
  /**
     * @width 4
     */
  'lessThan': ValueExpr
}

export type ValueGreaterThan = {
  'kind': 'Value Greater Than',
  /**
     * @width 4
     */
  'value': ValueExpr,
  /**
     * @width 4
     */
  'greaterThan': ValueExpr
}

export type TransformString = {
  /**
     * @width 2
     */
  kind: "Transform String",
  /**
     * @width 2
     */
  transform: "lowercase" | "uppercase" | "titlecase" | "trim",
  /**
     * @width 4
     */
  value: ValueExpr
}

export type Expand = {
  kind: 'Expand',
  /**
     * Must be a JSON Array.
     *
     * each element in the array becomes its own row.
     * This will cause all non-expand fields to be duplicated across all
     * of the new rows.
     *
     * @width 4
     * @name Array Reference
     */
  value: TargetFieldRef
};

export type Extract = {
  kind: 'Extract',

  /**
     * The JSON Object to grab the value from.
     *
     * @width 4
     * @name Object Reference
     *
     */
  value: TargetFieldRef | Expand,

  /**
     * The field name (or multiple nested field names) to grab the value from.
     *
     * if a list of strings are used, they will be used to traverse the JSON Object,
     * e.g. a path of ['address', 'city'] would extract the city 
     * from the object { "address": { "city": "MA" }, "name": "Luke", "pets": 1 }
     *
     * @width 4
     * @name Path
     */
  path: string | string[]
};

export type Concat = {
  kind: 'Concat',

  /**
     *
     * The expressions to combine into a single string, using the delimiter
     *
     * @name Expressions
     * @width 4
     */
  value: ValueExpr[]

  /**
     *
     * Defaults to a single space.
     *
     * This is used between the resulting 
     * string values produced by the expressions.
     *
     * @name Delimiter
     * @width 2
     */
  delimiter?: string
}

export type ValueExpr = Field | FieldLastModifiedDate | When |
  StringValue | NumericValue | BooleanValue | CurrentUser | ApplicantLuck |
  AsDate | ShiftDate | Now | ValueArithmeticExpr | BucketDate | Round |
  TransformString | SyncStatus | Expand | Concat | Extract;

export type Count = {
  kind: 'Count',
}

export type Round = {
  kind: 'Round',
  /**
     * @width 4
     */
  value: ValueExpr,
  /**
     * Places to the right of the decimal to round to. Default is 0.
     */
  places?: number,
}

export type Median = {
  kind: 'Median',
  /**
     * @width 4
     */
  value: ValueExpr,
}

export type Mean = {
  kind: 'Mean',
  /**
     * @width 4
     */
  value: ValueExpr,
}

export type Sum = {
  kind: 'Sum',
  /**
     * @width 4
     */
  value: ValueExpr,
}

export type SummaryExpr = Count | Median | Mean | Sum | SummaryArithmeticExpr;

export type SummaryAdd = {
  kind: 'Add',
  /**
     * @width 4
     */
  baseValue: SummaryExpr | NumericValue,
  /**
     * @width 4
     */
  secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummarySubtract = {
  kind: 'Subtract',
  /**
     * @width 4
     */
  baseValue: SummaryExpr | NumericValue,
  /**
     * @width 4
     */
  secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummaryMultiply = {
  kind: 'Multiply',
  /**
     * @width 4
     */
  baseValue: SummaryExpr | NumericValue,
  /**
     * @width 4
     */
  secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummaryDivide = {
  kind: 'Divide',
  /**
     * @width 4
     */
  baseValue: SummaryExpr | NumericValue,
  /**
     * @width 4
     */
  secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummaryRound = {
  kind: 'Round',
  /**
     * @width 4
     */
  value: SummaryExpr | NumericValue,
  /**
     * Places to the right of the decimal to round to. Default is 0.
     */
  places?: number,
}

export type SummaryArithmeticExpr = SummaryAdd | SummarySubtract | SummaryMultiply | SummaryDivide | SummaryRound;

export type ValueAdd = {
  kind: 'Add',
  /**
     * @width 4
     */
  baseValue: ValueExpr
  /**
     * @width 4
     */
  secondValue: ValueExpr | ValueExpr[],
}

export type ValueSubtract = {
  kind: 'Subtract',
  /**
     * @width 4
     */
  baseValue: ValueExpr
  /**
     * @width 4
     */
  secondValue: ValueExpr | ValueExpr[],
}

export type ValueMultiply = {
  kind: 'Multiply',
  /**
     * @width 4
     */
  baseValue: ValueExpr
  /**
     * @width 4
     */
  secondValue: ValueExpr | ValueExpr[],
}

export type ValueDivide = {
  kind: 'Divide',
  /**
     * @width 4
     */
  baseValue: ValueExpr
  /**
     * @width 4
     */
  secondValue: ValueExpr | ValueExpr[],
}

export type ValueArithmeticExpr = ValueAdd | ValueSubtract | ValueMultiply | ValueDivide;

export type OrderBy = {
  /**
     * @width 3
     */
  value: Field | FieldLastModifiedDate | 'Applicant Luck' | 'Random',
  /**
     * @width 1
     */
  order: 'asc' | 'desc'
}

/**
 * This is only used under the hood
 * @hidden true
 */
export type CodeBooleanExpr = {
  kind: 'Code Boolean Expr',
  code: string
}

export type BooleanExpr = Never
  | FieldExists | FieldDoesntExist | FieldEquals | FieldNotEqual | FieldLastModifiedBefore | FieldLastModifiedDate
  | Or | And | Not
  | After | Before
  | ValueNotEquals | ValueEquals | ValueEmpty | ValueNotEmpty
  | ValueLessThan | ValueGreaterThan | ValueContains
  | CodeBooleanExpr;

export type ClickQuery = {
  kind: 'Click',
  /**
     * @width 4
     */
  expr: BooleanExpr,
  /**
     * @width 4
     */
  orderBy?: OrderBy | OrderBy[],
}

export type SQLQuery = {
  kind: 'SQL',
  /**
     * @width 3
     * @language sql
     */
  sql: Code
}

export type Query = ClickQuery | SQLQuery

export type Payment = {
  kind: 'Payment',
  /**
     * @name Group Name
     */
  name: string,
  /**
     * @name Target Field
     * @width 3
     */
  targetField: TargetField,
  /**
     * @name Amount
     * @width 2
     */
  amount: string
  /**
     * @name Enable Key 
     * @width 2
     * @validateInput PaymentEnableKey
     */
  enableKey: string,
  /**
     * @name Condition
     * @width 4
     */
  condition: Query,
  /**
     * @name Payment Type
     */
  type: TargetFieldRef,
  testUIDs?: string[],
  /**
   * @width 4
   */
  ledger?: string | TargetFieldObj,
  cardIdField?: string,
  /**
     * if the payment kind is USIO Mailed Grant (usio_mail) OR GiveCard Mail (givecard_mail),
     * this field will cause the card mailed to the applicant to be
     * pre-associated.
     */
  autoAssociate?: boolean,
  /**
     * GiveCard Only Field (see GiveCardMailing kind)
     *
     * Cards that are ordered as a result of this Payment kind
     * will be allowed a higher limit (up to 8k).
     *
     * The default target_field is givecard_higher_spending_limit
     *
     * @name Higher Spending Limit
     * @width 4
     */
  higherSpendingLimit?: TargetFieldRef
  /**
     * 
     *
     * @name Auto Retry
     * @width 4
     */
  autoRetry?: {
    /**
         * Target fields and values to set on failure. For example, you may want to set the payment target field to empty when the payment fails.
         *
         * @name Set on Failure
         * @width 4
         */
    onFailure: {
      // will these get added to infodefs right away, or do I need to change something to get that to happen?
      key: TargetField,
      /**
             * @width 4
             */
      value: ValueExpr
    }[],
    /**
         * A query to exclude certain applicants from retry (for example, if _attempt > 2).
         *
         * @name Disable Retry When
         * @width 4
         */
    disableRetryWhen?: BooleanExpr
  }
}

export type CustomContact = {
  /**
     * @width 4
     */
  emailField: TargetFieldRef,
  /**
     * @width 4
     */
  phoneField: TargetFieldRef
}

export type ConditionalContent = {
  /**
     * @width 4
     */
  conditional: BooleanExpr
  /**
     * @width 4
     */
  components: (RichText | ConditionalContent)[],
  /**
     * @width 4
     */
  otherwise?: (RichText | ConditionalContent)[]
};

// This is specifically so that ConditionalContentList displays a better name in the editor
type CustomizableContent = (RichText | ConditionalContent) // & MacroBlockProperties for ConditionalContent. This gets added by the parser in macroEditor mode

export type ConditionalContentList = CustomizableContent[]

// For convenience, to be used only by external callers
export type Content = Text | RichText | ConditionalContentList;

export type TrackedLink = {
  /**
     * @width 2
     */
  link: string,
  /**
     * @width 2
     */
  targetField: TargetField
}
export type Notification = NotificationGroup | InlineNotification;

export type ContentTemplate = {
  /**
    * Content Templates require example values for each variable. Try to make this as close to what we 
    * will truly send as possible, or our message could be blocked.
    * @name Variables
    * @width 4
    */
  variables: {
    kind: 'Target Field',
    variable: TargetFieldRef,
    exampleValue: string
  }[],
  /**
    * SIDs of the content templates created in Twilio. Will be filled in automatically
    * when the Content template is created. Each translation needs its own Content Template.
    * @name Template SIDs
    */
  templateSIDs?: {
    lang: string,
    sid: string,
    /**
    * Twilio content templates do not support all of the languages that we do. When this is the case, 
    * we can get around this by selecting a different twilio language to create the template in.
    * For example, Twilio does not support Pashto (ps_AF), so we created the content template in Twilio
    * in Arabic (ar)
    * @name Twilio Lang
    */
    twilioLang?: string
  }[]
}

export type NotificationGroup = {
  kind: 'Notification',
  /**
     * @hidden yes
     */
  version: 1,
  /**
     * @name Group Name
     */
  name: string,
  /**
     * We store record of a sent notification in a target field per contact type (sms and email).
     * We derive the target field names from this field prefix.
     * @name Target Field Prefix
     */
  targetPrefix: Distinct<string>,
  /**
     * @name Recipient
     */
  recipient: 'Applicant' | 'Screener' | 'Unsubmitted Applicant' | CustomContact
  /**
     * This is taken from the notifications tab on the right as an acknowledgement
     * that you've tested the notification criteria and copy.
     * @name Enable Key
     * @validateInput NotificationEnableKey
     */
  enableKey: string,
  /**
   * Will enable markdown rendering for the initial notification and all followups.
   */
  enableEmailMarkdown?: boolean,
  /**
     * @name Initial Notification
     * @collapsible starts-collapse
     * @width 4
     */
  initial_notification: {
    /**
         * @name Condition
         * @width 4
         */
    enabled_when: Query,
    /**
         * @width 4
         * @onChangeClearNearest enableKey
         */
    message: RichText | ConditionalContentList,
    /**
         * @width 4
         * @onChangeClearNearest enableKey
         */
    email_subject: Text,
    /**
         * @width 4
         * @onChangeClearNearest enableKey
         */
    email_message?: RichText | ConditionalContentList,
    /**
         * @name Linked Subsurveys or Links
         * @width 4
         */
    subsurveys?: {
      /**
             * @width 2
             */
      name: SubsurveyPathRef | TrackedLink,
      variable: string
      /**
             * @name Expires After
             * @width 4
             */
      expiresAfter?: {
        amount: number,
        unit: 'days' | 'weeks' | 'months'
      }
    }[],
    /**
     * WhatsApp requires us to get Content Templates approved for any messages that we initiating
     * to another account. Mark this as required and provide example variables to make a Content Template
     * for this notification.
     * @name WhatsApp Content Template
     * @width 4
     */
    content_template?: ContentTemplate,
  },
  /**
     * @name Followups 
     * @collapsible starts-collapse
     */
  followups?: {
    /**
         * @width 4
         */
    after: {
      /**
             * @default 1
             */
      amount: number,
      unit: 'days' | 'weeks' | 'months'
    },
    /**
         * @name Suffix
         */
    suffix: string,
    /**
         * @name Enable Key 
         */
    enableKey: string,
    /**
         * @name Condition
         * @width 4
         */
    send_if: Query,
    /**
         * @width 2
         */
    message: RichText | ConditionalContentList,
    /**
         * @width 4
         */
    email_subject: Text,
    /**
         * @width 4
         */
    email_message?: RichText | ConditionalContentList,
    /** 
         * @name Different Recipient?
         */
    recipient?: 'Applicant' | 'Screener' | 'Unsubmitted Applicant' | CustomContact,
    /**
         * @name Linked Subsurveys or Links
         * @width 4
         */
    subsurveys?: {
      name: string | TrackedLink,
      variable: string
      /**
             * @name Expires After
             * @width 4
             */
      expiresAfter?: {
        amount: number,
        unit: 'days' | 'weeks' | 'months'
      }
    }[],
    /**
     * WhatsApp requires us to get Content Templates approved for any messages that we initiating
     * to another account. Mark this as required and provide example variables to make a Content Template
     * for this notification.
     * @name WhatsApp Content Template
     * @width 4
     */
    content_template?: ContentTemplate,
  }[],
  /**
     * @name ContactMethods
     * @width 4
     */
  contactMethod: 'all' | 'preferred' | 'confirmed' | 'email' | 'sms' | 'whatsapp',
  /**
     * @name Test UIDs
     */
  testUIDs?: string[],
  /**
     * @name Email Sender
     */
  emailSender?: string,
  /**
     * @name SMS Service SID
     */
  smsService?: string,
  /**
     * @name Comms Channel
     */
  channel?: CommsChannelNameRef,
  /**
     * @name Send Limit
     */
  sendLimit?: number,
  /**
     * @width 4
     */
  scheduleSettings?: {
    timezone: Timezone,
    limitToPartOfWeek?: 'weekdays' | 'weekends'
    // TODO(Riley): it'd be good to include some sort of validation to ensure that startTime
    // is before endTime.
    /**
         * @width 4
         */
    timeWindow?:
    {
      /**
             * @width 4
             */
      startTime: Time,
      /**
             * @width 4
             */
      endTime: Time,
    },
  }
}

export type Timezone = 'America/Chicago' | 'America/New_York' | 'America/Denver' | 'America/Los_Angeles' | 'America/Phoenix';

type Time = {
  hour: number,
  am_pm: 'AM' | 'PM',
  minute?: number
}

export type InlineNotification = {
  kind: 'InlineNotification'
  /**
     * @name Notification Name
     */
  name: string,
  /**
     * We store record of a sent notification in a target field per contact type (sms and email).
     * We derive the target field names from this field prefix.
     * @name Target Field Prefix
     * @width 2
     */
  targetPrefix: Distinct<string>,
  /**
   * Will enable markdown rendering for the initial notification and all followups.
   */
  enableEmailMarkdown?: boolean,
  /**
     * @name Initial Notification
     * @collapsible starts-collapse
     * @width 4
     */
  initial_notification: {
    /**
         * @name Condition
         * @width 4
         */
    enabled_when: Query,
    /**
         * @width 4
         */
    message: RichText | ConditionalContentList,
    /**
         * @width 4
         */
    email_subject: Text,
    /**
         * @width 4
         */
    email_message?: RichText | ConditionalContentList,
    /**
         * @name Linked Subsurveys
         * @width 4
         */
    subsurveys?: {
      name: SubsurveyPathRef,
      variable: string
      /**
             * @name Expires After
             * @width 4
             */
      expiresAfter?: {
        amount: number,
        unit: 'days' | 'weeks' | 'months'
      }
    }[],
    /**
     * WhatsApp requires us to get Content Templates approved for any messages that we initiating
     * to another account. Mark this as required and provide example variables to make a Content Template
     * for this notification.
     * @name WhatsApp Content Template
     * @width 4
     */
    content_template?: ContentTemplate,
  },
  /**
     * @name ContactMethods
     */
  contactMethod: 'all' | 'preferred' | 'confirmed' | 'email' | 'sms' | 'whatsapp',
  /**
     * @name Limited To Users Tagged
     */
  allowedUserTags: UserTagRef[],
  /**
     * @name Recipient
     */
  recipient?: CustomContact
  /**
     * @name Test UIDs
     */
  testUIDs?: string[],
  /**
     * @name Email Sender
     */
  emailSender?: string
  /**
     * @name SMS Service SID
     */
  smsService?: string,
  /**
     * @name Comms Channel
     */
  channel?: CommsChannelNameRef,
  /**
     * @name Send Timezone
     */
  timezone?: Timezone,
}

export type MapRegionDensity = {
  kind: 'Map Region Density',
  /**
    * @width 4
    * @name Chart title
    */
  title?: string | Text;
  /**
   * Shown when a user hovers over a region in the map.
   * The value that follows this label is determined by
   * the geoJSON file that was imported via geolookup.
   * Defaults to "Name"
   */
  hoverLabel?: Text;
  /**
     * Specify map width in pixels. Default is 300
     */
  width?: number,
  /**
     * Specify map height in pixels. Default is 300
     */
  height?: number,
  /**
     * @references geolookupTypes
     * @width 4
     * @name Region boundary type
     */
  boundaries: Distinct<string>,
  /**
     * @width 4
     * @name Latitude Field
     */
  latitudeField: TargetFieldRef,
  /**
     * @width 4
     * @name Longitude Field
     */
  longitudeField: TargetFieldRef,
  /**
   * @width 4
   */
  legend?: {
    /**
     * Text to display under the legend. Default is "Participants in area"
     */
    legendText?: Text;
    /**
     * Color hexcode for lowest map density.
     * Default: #fff - white
     */
    gradientStart?: string,
    /**
     * Color hexcode for median map density.
     * Unlike start and end, there is no default for this; if it is omitted, the gradient will
     * simply go from start to end, using defaults if values are not provided.
     * Use this if you want to have 3 colors in your gradient, e.g. blue to red to yellow.
     */
    gradientMiddle?: string,
    /**
     * Color hexcode for highest map density.
     * Default: #1e3a8a - tailwind blue-900
     */
    gradientEnd?: string,
  }
}

// Needed, else the type will collide with other uses of GenericTemplatedBlock
type DashboardTemplatedBlock = GenericTemplatedBlock<DashboardComponent[]>;

export type DashboardComponent =
  DashboardSection
  | PieChart
  | ApplicantTable
  | CustomQuery
  | HeroCount
  | (DashboardExplanation & CommonProperties)
  | SubsurveySummary
  | BarChart
  | HeroNumber
  | Queue
  | DataDictionary
  | MapRegionDensity
  | DashboardTemplatedBlock
  | PaymentDashboard
  | Map

export type DashboardSection = {
  kind: 'Dashboard Section'
  title: string | Text
  showAllSimultaneously: boolean,
  filters: {
    name: string,
    /**
         * @width 4
         */
    filter: BooleanExpr
  }[],
  /**
     * @hidden yes
     */
  activeFilter?: number,
  /**
     * @hidden yes
     */
  filterPath?: string,
  /**
   * @collapsible starts-shown
   */
  components: (DashboardComponent)[]
}

export type BucketDate = {
  /**
     * @width 4
     */
  kind: 'Bucket Date',
  /**
     * @width 4
     */
  bucket: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year',
  /**
     * @width 4
     */
  value: AsDate | FieldLastModifiedDate | ShiftDate,
}

export type DataDictionary = {
  kind: 'Data Dictionary',
  type: 'json' | 'csv',
  excludeFields?: TargetFieldRef[],
  /**
     * Only include fields from within the specified subsurvey
     */
  limitToSubsurvey?: SubsurveyPathRef,
  /**
     * Computations/Formulas are not included by default.
     * If desired, then JavaScript formulas are included as is, and there are 3 options for handling Distro Click computations:
     *   1. Pretty - converts Distro Click computations to a more human-readable format
     *   2. Compiled JS - converts Distro Click computations to JavaScript
     *   3. As is - leaves Distro Click computations as JSON objects
     */
  includeComputations?: 'Pretty' | 'Compiled JS' | 'As is',
}

type StackedBars = {
  kind: "Stacked",
  values: ValueExpr[]
}

type GroupedBars = {
  kind: "Grouped",
  values: ValueExpr[]
}

type BarFields = {
  /**
     * Specify chart width in pixels. Default is 300
     */
  width?: number,
  /**
     * Specify chart height in pixels. Default is 300
     */
  height?: number,
  hideTotal?: boolean,
  /**
     * Bar charts need to graph numbers without units, but if you want to specify the unit on the chart, 
     * specify that here. (ie: %)
     */
  units?: string,
  /**
     * Adds a description to the Chart.
     * Placement is customizable!
     *
     * @width 4
     * @name Description
     */
  description?: DashboardDescription,
  /**
    * @width 4
    */
  numberFormat?: NumberFields,
  /**
    * Displays the value above each bar (only for non-zero values)
    */
  showBarLabels?: boolean,
}

/**
 * @name BarChart
 */
export type BarChart = {
  kind: 'Bar Chart',
  title: string | Text,
  /**
     * @name Y Axis value
     * @width 4
     */
  summary: SummaryExpr,
  /**
     * @name X Axis groups
     * @width 4
     */
  groups: ValueExpr[],
  /**
     * @width 4
     */
  filter?: BooleanExpr,
  /**
     * Specify chart width in pixels. Default is 300
     */
  countEmpty?: boolean,
  /**
     * When checked, we include historical data (ie, latest != true).
     */
  includeNonLatest?: boolean,
  /**
    * Indicates whether we will split a field by commas into separate groups, or include 
    * the whole field (commas and all) as the one value
    * @name Allow commas in groups
    * @width 2
    */
  allowCommasInGroups?: boolean,
  /**
    * Specify further stacking and grouping for the bars
    * @name Bar Display
    * @width 4
    */
  bars?: StackedBars | GroupedBars,
  order?: 'ascending' | 'descending'
} & BarFields;

export type NumberFields = {
  /**
      * Use this to add any characters before each number. (ie: $)
      */
  prefix?: string,
  /**
      * Bar charts need to graph numbers without units, but if you want to specify the unit on the chart, 
      * specify that here. (ie: %). This is the same property as 'units' for bar charts.
      * @width 4
      */
  suffix?: string,
  /**
      * Formats as a US number with commas every 3 digits
      */
  useCommas?: boolean,
  /**
      * Default is 2
      */
  decimalPlaces?: number
}

/**
 * @name HeroNumber
 */
export type HeroNumber = {
  kind: 'Hero Number',
  title: string | Text,
  /**
     * @width 4
     */
  summary: SummaryExpr,
  /**
     * @width 4
     */
  filter?: BooleanExpr,
  /**
     * @width 4
     */
} & NumberFields;

export type DashboardExplanation = {
  kind: 'Dashboard Explanation',
  content: RichText,
  width?: 'full',
  /**
    * Default is centered
    */
  alignment?: 'left' | 'center',
};

export type SubsurveySummary = {
  kind: 'Subsurvey Summary',
  path: SubsurveyPathRef,
  title?: Text,
  notificationPrefixes?: string[],
  downloadOnly?: boolean,
  populationCrossSections?: TargetFieldRef[],
  additionalColumns?: ValueExpr[],
  excludeFields?: string[],
  hideDownload?: boolean,
}

type PieFields = {
  /**
     * Specify chart width in pixels. Default is 300
     */
  width?: number,
  /**
     * Specify chart height in pixels. Default is 300
     */
  height?: number,
  /**
     * Defaults to the program config setting, or donut if this is not set.
     */
  pieStyle?: "pie" | "donut",
  /**
     * Adds a description to the Chart.
     * Placement is customizable!
     *
     * @width 4
     * @name Description
     */
  description?: DashboardDescription
}

/**
 * @name PieChart
 */
export type PieChart = {
  kind: 'Pie Chart',
  title: string | Text,
  /**
     * @width 4
     */
  summary: SummaryExpr,
  /**
     * @width 4
     */
  groups: ValueExpr[],
  /**
     * @width 4
     */
  filter?: BooleanExpr,
  /**
    * Indicates whether we will split a field by commas into separate groups, or include 
    * the whole field (commas and all) as the one value
    * @name Allow commas in groups
    * @width 2
    */
  allowCommasInGroups?: boolean,
  countEmpty?: boolean,
} & PieFields;

export type HeroCount = {
  kind: 'Hero Count',
  title: string | Text,
  /**
     * @width 4
     */
  filter: BooleanExpr,
  /**
     * @width 4
     *
     * use offline data to determine the count,
     * instead of the count returned by the database
     */
  useOfflineData?: boolean
} & NumberFields

export type NotificationSender = {
  kind: 'NotificationSender',
  field: TargetFieldRef,
}

export type ApplicantTable = {
  kind: 'Applicant Table'
  /**
     * @width 4
     */
  title?: Text,
  columns: (ValueExpr | NamedExpression | NotificationSender)[],
  /**
     * @width 4
     */
  filter: BooleanExpr | ClickQuery,
  showUids?: boolean,
  hideViewButton?: boolean,
  hideTotalCount?: boolean,
  /**
     * @width 4
     */
  showAllSimultaneously?: boolean,

  /**
     * @width 4
     */
  download?: {
    filename: string,
    downloadOnly?: boolean,
    asyncDownload?: boolean,
    /**
     * Use this to allow a GeoJSON data export. All the fields in the applicant table will be included as GeoJSON feature attributes.
     * Configure one or more address fields to be exported as lat/long coordinates.
     * @width 4
     */
    geoJSONDownload?: {
      /**
       * Include at least one or more addresses to plot in GeoJSON. This must reference a target field that is an Address kind and we'll 
       * query the included slugs _lat and _lng
       */
      addresses: {
        /**
          * @width 4
          */
        addressField: TargetFieldRef
      }[],
      /** 
       * Enable to include a URL to the applicant in the AidKit platform as a property in the GeoJSON feature
       */
      includeUrl?: boolean,
    },
    /**
         * @hidden yes
         */
    downloadPath?: string,
  },

  offline?: {
    showDraftApplicants?: boolean,
  },
}

export type CustomQuery = {
  kind: 'Custom Query',
  /**
   * SQL for your query.
   * 
   * Note: if your CustomQuery is going to be used with DashboardSection filters, you can
   * improve the efficiency of your query by placing the substitution markers --FILTER_JOINS--
   * and --FILTER_CONDITIONS-- in the appropriate places within your query.
   * Be aware that not all CustomQueries will be compatible with this optimization.
   * 
   * See also: the optional property filterUidReplacement
   * @width 4
   * @language sql
   */
  sql: Code,

  /**
     * @width 4
     */
  visualization: Table | CustomNumber | CustomBarChart | CustomPieChart
  /**
   * If your CustomQuery is going to be filtered using a DashboardFilter,
   * and your query does not already provide a SELECT uid FROM applicant app ...
   * then you can provide an alternative UID to match against in your query here.
   * For example, if you have a query that is like:
   * SELECT ...
   * FROM applicantinfo abc
   * ...
   * then you could enter abc.applicant here as a substitute.
   * If not specified, the default is app.uid
   *
   * @name Filter UID Replacement
   */
  filterUidReplacement?: string,
}

/**
 * @name CustomBarChart
 */
export type CustomBarChart = {
  kind: 'Custom Bar Chart',
  title: string | Text,
  /**
     * @width 4
     * @name Columns
     */
  groupKeys: string[],
  /**
     * This is how the data will be grouped and will be what is shown on the X-axis
     */
  summaryKey: string,
  /**
     * This is the key that will be used to determine the total. If omitted, the sum of the groupkeys will be used
     */
  totalKey?: string,
  hideXAxisLabels?: boolean,
  stack?: boolean,
} & BarFields;

/**
 * @name CustomNumber
 */
export type CustomNumber = {
  kind: 'Custom Number',
  title?: Text
} & NumberFields;

/**
 * @name CustomPieChart
 */
export type CustomPieChart = {
  kind: 'Custom Pie Chart',
  title: string | Text,
  /**
     * These are the key(s) that will be used to group/label each pie segment
     */
  groupKeys: string[],
  /**
     * This is the key that will be used to determine the quantitative value for each pie segment
     */
  summaryKey: string,
  /**
     * This is the key will be used to contribute to the total. If omitted, summaryKey will be used
     */
  totalKey?: string,
  hideTotal?: boolean,
} & PieFields;


export type Table = {
  kind: 'Table'
  /**
     * @width 4
     */
  title?: Text,
  /**
     * @width 4
     */
  showAllSimultaneously?: boolean,
  hideViewButton?: boolean,
  hideTotalCount?: boolean,
  /**
     * @width 4
     */
  download?: {
    filename: string,
    downloadOnly?: boolean,
    asyncDownload?: boolean,
    /**
         * @hidden yes
         */
    downloadPath?: string,
  },
}

export type NamedExpression = {
  kind: 'Named Expression',
  /**
     * @width 4
     */
  expression: ValueExpr,

  /**
   * @width 4
   */
  name?: Text,
}

export type Queue = {
  kind: 'Queue',
  /**
     * @width 4
     */
  title: Text,
  /**
     * @name Applicants
     * @width 4
     */
  condition: BooleanExpr | ClickQuery,
  /** 
     * @name Managed Queue: Assignee Field
     * @width 2
     */
  assigneeField: TargetField,
  /** 
     * To redirect to a subsurvey like /a/:uid/subsurvey, start the path with a / i.e. /review
     * @name Starting Section
     * @width 2
     */
  startSection: string | SubsurveyRef,
  allowedUserTags: UserTagRef[],
  /**
     * @width 4
     */
  description?: RichText,
  /**
     * @hidden yes
     */
  numberInQueue?: number,

  /**
     * List of columns/keys that will be shown when exploring the queue in addition to UID and legal_name.
     * @name Exploration Columns
     */
  columns?: ValueExpr[],

  hideExploreButton?: boolean,

  /**
     * 
     * Notifies the related parties periodically for each queue
     * in the program if there are still unclaimed applicants
     * within them.
     *
     * @name Notify About Queue Activity
     */
  notifyAboutQueueActivity?: UserTagRef[],
}

export type DashboardDescription = {
  /**
     * A sub-title that can be placed either 
     * just below the title of the graph, or
     * just beneath the total.
     *
     * @width 4
     * @name Content
     */
  content: Text,

  /**
     * Determines where the description is located. If not specified, 'bottom' is used.
     * If 'top', it will be just beneath the title.
     * If 'bottom' it will be just below the total (or the chart itself if the total is hidden).
     *
     * @width 2
     * @name Placement
     */
  placement?: 'top' | 'bottom'
};

export type Dashboard = {
  kind: 'Dashboard',
  title: Text | string,
  /** 
     * @format ^[a-zA-Z_][a-zA-Z0-9_]*$
     * @formatMessage Can only contain alphanumeric characters and underscores.
     */
  path: string,
  /**
     * @width 4
     * @collapsible starts-collapse
     */
  components: (DashboardComponent)[],
  publicAccessKeys?: string[],
  linksToSection?: string | SubsurveyRef,
  /**
   * Sets how many queries can be sent to the backend at a time in order to reduce load
   * for pages with many/slow components.
   *
   * Generally the default should be fine, but situations where it might be desirable to
   * change it include:
   * - A smaller number on pages with extremely intensive queries
   * - A larger number on pages with a large number of very quick queries (e.g. summaries)
   *
   * Default is 10.
   */
  maxConcurrentQueries?: number,
  fullscreen?: boolean,
  /**
     * @hidden yes
     */
  version: 1
}

export type BadDashboard = {
  title: string,
  components: (DashboardComponent)[]
}

// Used to drive /explore
export type Explore = {
  /**
     * @width 4
     */
  query: PieChart | ApplicantTable | CustomQuery | HeroNumber | BarChart
}

export type Export = {
  /**
     * @width 4
     */
  name: string,
  /**
     * @width 4
     * @language sql
     */
  query: Code,
  exportTo: 's3',
  /**
     * @name Limited To Users Tagged
     */
  limitedToTags?: UserTagRef[],
  maxFrequency?: 'hourly' | 'daily' | 'weekly' | 'monthly',
}

/**
 * @display tabs 
 */
export type ExpandedSurvey = {
  /**
     * @name Survey
     */
  survey: Survey,
  /**
     * @name Notifications 
     */
  notifications?: Notification[]
  /**
     * @name Personas
     */
  personas: Persona[],
  /**
     * @name Dashboards
     */
  dashboards?: Dashboard[]
  /**
     * @name Payments 
     */
  payments?: Payment[]
  /**
     * @name Users
     */
  users?: Users
  /**
     * @name Program Config
     */
  config?: ProgramConfig,
  /**
     * @name Exports
     */
  exports?: Export[]
  /**
     * @name Fraud Flags
     */
  fraudFlags?: FraudFlag[],

  /**
     * @name Sync
     */
  crossProgramDataExchange?: CrossProgramDataExchange[],
  /**
   * @name External Data Sync
   */
  sync?: GeoDataSync[],
  //accounting?: Accounting

  // Hack to make Distro explore all the types for macros.
  // There's a bug in collecting all the types from macros because
  // they aren't referenced from the root so adding that here
  /**
     * @hidden yes
     */
  macroDefinitions?: GiveCard
  | Usio
  | NoCard
  | PaymentModule
  | CardProviders
  | OneTimePayment
  | FixedPaymentAmount
  | VariablePaymentAmount
  | RecurringPayment
  | PaymentFrequency
  | MacroConfig
  | MacroExpander
}

export type Root = Survey | ExpandedSurvey;

export const ForceSourceMap = () => '';

/**
 * @referencedIn userTags
 */
type UserTag = Distinct<string>;

/**
 * @references userTags
 */
type UserTagRef = Distinct<string>;

export type UserGroup = {
  kind: 'User Group'
  name: string,
  tags: UserTag[],
  members: Users,
  /**
     * @width 4
     */
  limitedToApplicants?: BooleanExpr,
  /**
     * The path the user should be directed to when they log in
     */
  homepage?: string
  /**
     * @name Default Comms Channel
     */
  defaultCommsChannel?: CommsChannelNameRef
}

/**
 * @referencedIn users
 */
type UserName = Distinct<string>;

/**
 * @references users
 */
type UserNameRef = Distinct<string>;

export type User = {
  kind: 'User'
  name: string,
  /**
   * @format ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   * @formatMessage Please enter a correct email format without trailing spaces.
   */
  email: UserName,
  /**
   * @format ^(\+1)(\d{10})$|^\d{10}$|^(\+\d{1,3})(\d{10})$
   * @formatMessage Please enter a valid phone number, with optional country code (starting with +), and no spaces or other special characters. For example, 2129876543 or +12129876543 or +51333444555. 
   */
  phone?: string,
  pause_notifs?: boolean,
}

export type Users = (User | UserGroup)[];

/**
 * @referencedIn commsChannels
 */
type CommsChannelName = Distinct<string>;

/**
 * @references commsChannels
 */
type CommsChannelNameRef = Distinct<string>;

type LanguageMessageOption = {
  /**
     * @width 4
     * @name Language
     */
  language: keyof Text,
  /**
     * Example: "Press 1 for English"
     * @width 4
     * @name Option Introduction
     */
  messageIntro: string,
  /**
     * @width 4
     * @name Message
     */
  message: string
}

export type CommsChannel = {
  /**
     * @width 4
     */
  kind: 'Comms Channel',
  /**
     * @width 2
     * @name Channel Name
     */
  channelName: CommsChannelName,
  /**
     * @width 2
     * @name Email
     */
  email?: string,
  /**
     * Configure additional program emails by entering them here.
     * This exists primarily to cover instances where participants
     * might be replying to multiple official program email addresses
     * @width 4
     * @name Additional Program Emails
     */
  additionalEmails?: {
    /**
         * @width 2
         */
    email: string
  }[]
  /**
     * @width 4
     */
  twilioConfig: {
    /**
         * @width 2
         * @name Phone Number
         */
    number: string,
    /**
         * @width 2
         * @name SMS Messaging Service SID
         */
    messaging_service_sid: string,
    /**
         * @width 2
         * @name WhatsApp Messaging Service SID
         */
    whatsapp_messaging_service_sid?: string,
    /**
         * These pre-approved WhatsApp messages will be able to be sent from 
         * the Comms window, to initiate a thread with an applicant when we are 
         * outside of the 24-hour free response window.
         * @width 4
         * @name Comms Window Content Templates
         */
    whatsapp_content_templates?: {
      /**
         * Internal name for the content template
         * @width 2
         * @name Name
         */
      name: string,
      /**
         * This will be shown on the Comms Channel to let the user know 
         * what template they are sending.
         * @width 2
         * @name Display Name
         */
      displayName: RichText,
      /**
         * The body of the message.
         * @width 4
         * @name Message Body
         */
      body: RichText,
      /**
         * Content templates associated with the message.
         * @width 4
         * @name Content Templates
         */
      templates: ContentTemplate
    }[],
    /**
         * @width 2
         * @name Verification Service SID
         */
    verification_service_sid?: string
    /**
         * @width 2
         * @name TwiML App SID
         */
    twiml_app_sid?: string,
    /**
         * You can either type out the message for TwiML to speak, or you can provide
         * a public URL to an audio file (mp3 required).
         * If you provide a URL, it must be https, it must have no spaces in it, and it must be the only thing you provide for the given language.
         * @width 4
         * @name Voice Message for Inbound Callers
         */
    voiceMessage?: RichText | LanguageMessageOption[],
    /**
         * Disables inbound voice message recording.
         * Please ensure the voice message informs callers that recording is disabled and provides alternate contact methods if needed.
         * @width 2
         * @name Disable Inbound Voice Recordings
         */
    disableInboundVoiceRecordings?: boolean,
    /**
         * Configure additional senders (phone numbers) by entering them here
         * @width 4
         * @name Additional Phone Senders
         */
    additionalPhoneNumbers?: {
      /**
             * @width 2
             */
      phone: string
    }[]
  }
  /**
     * @width 4
     */
  smsGatewayConfig?: {
    /**
         * @width 2
         * @name Phone Number
         */
    number: string,
    /**
         * @width 2
         * Experimental: flag to wait for previous message success before attempting to send a new messages.
         * This only applies to notifications that are queued.
         */
    waitForSuccess?: boolean,
  }
  /**
     * @width 4
     * @name Limited To Tags (Who can access this channel)
     */
  limitedToTags?: UserTagRef[]
  /**
     * @name Support Dashboard
     * @width 4
     */
  supportDashboard?: {
    /**
         * @width 3
         * @name Name
         */
    name: Text,
    /**
         * Description of the dashboard that will be shown to all users.
         * (Not yet implemented)
         * @width 4
         * @name Description
         */
    description: RichText,
    /**
         * Overrides the tags on the channel, for who can see the support dashboard.
         * Note all messages sent through this channel will still be visible to the top level tags.
         * @width 4
         * @name Limited to Tags
         */
    limitedToTags?: UserTagRef[],
    /** 
         * @width 4
         * @name Extra Columns
         */
    extraColumns?: {
      /**
             * @width 4
             * @name Target Field
             */
      targetField: TargetFieldRef,
      filterable?: boolean,
      /** 
       * The index of the column in the support dashboard.
       * These will be inserted in the order defined here at the index specified.
       * To add two columns to the beginning of the dashboard, set the first to 0 and the second to 1.
       * @width 4
       */
      columnIndex?: number,
    }[],
    /**
     * These fields will be displayed in the application case selector in addition
     * to name/UID
     * @width 2
     */
    relatedApplicationDetails?: TargetFieldRef[],
  }
}
type DefaultCommsChannel = CommsChannel & { channelName: 'default' }

/*
type AccountLedgerPair = {
        kind: 'Mecury Account' ,
        funding_pool: string
    } | {
        kind: 'Dwolla Account',
        funding_pool: string
    } | {
        kind: 'Usio Distributor',
        funding_pool: string
    } | {
        kind: 'Applicant',
    } | {
        kind: 'Applicant Chosen Payment Provider' 
    } | {
        kind: 'Third Party',
        third_party: string
    };


type Accounting = {
    funding_pools: {
        key: string,
        name: string,
        bank_account: string
        dolla_enabled?: boolean,
        paypal_enabled?: boolean,
        givecard_enabled?: boolean,
        usio_distributor?: string
    }[],
    third_parties: {
        key: string,
        name: string,
    }[]
    actions: {
        kind: string,
        name: string,
        mode: 'Anticipated' | 'Proposed' | 'Either'
        debits: AccountLedgerPair,
        credits: AccountLedgerPair,
    }[]
}
*/

type PhoneWithOptionalEmail = {
  /** 
     * @width 2
     */
  phone_key: TargetFieldRef,
  /**
     * @width 2
     */
  email_key?: TargetFieldRef
}

type EmailWithOptionalPhone = {
  /**
     * @width 2
     */
  email_key: TargetFieldRef
  /** 
     * @width 2
     */
  phone_key?: TargetFieldRef,
}

type PhoneOnly = { kind: 'Phone Only' }
type EmailOnly = { kind: 'Email Only' }
type EmailAndPhone = { kind: 'Email and Phone' }

/**
 * @display stacked
 */
export type AfterTimeElapsed = {
  kind: 'After Time Elapsed',
  days: number,
  hours: number,
  minutes: number,
}

/** 
 * @display stacked
 */
export type CommsConfig = {
  kind: 'Comms Config',
  /**
     * @width 4
     */
  default: DefaultCommsChannel,
  /**
     * @collapsible starts-shown
     */
  otherChannels?: CommsChannel[],
  /**
     * @name Alternate Contacts
     */
  alternateContacts?: ((PhoneWithOptionalEmail | EmailWithOptionalPhone) & ({
    /**
         * @width 2
         */
    name_key: string
    /**
         * @width 4
         */
    label: Text
  }))[],
  /**
     * @name Preferred Contact Method Field
     */
  preferredContactMethodField?: TargetFieldRef,
  /**
     * Adding a phone number here will enable conference calling with this number and the applicant in comms.
     * The optional dial code will be dialed when connecting to the service. `w` is a half second wait. For LanguageLink this code works well:
     * wwww<account_code>#ww2ww9
     *
     * @name Translation Service
     */
  translationService?: {
    phoneNumber: string,
    dialCode?: string
  },
  /**
     * Adding a phone number here will enable conference calling with the number and the applicant in comms.
     * The optional dial code will be dialed when connecting to the number. `w` is a half second wait. For LanguageLink this code works well:
     * wwww<account_code>#ww2ww9
     *
     * @name Conference Call Phone Numbers
     * @width 4
     */
  conferenceNumbers?: {
    label: Text,
    phoneNumber: string,
    dialCode?: string
  }[],
  notifySupportAgentsOnCaseActivity?: boolean,

  /**
     * Enables and configures support case replies to be
     * scheduled to be sent at a later time.
     *
     * Maximum of 35 days in the future, minimum of 15 minutes in the future.
     *
     * @width 4
     * @name Reply Scheduling
     * @collapsible starts-shown
     */
  replyScheduling?: ReplyScheduleConfig,
  /**
   * This option requires consent to be obtained before recording outgoing phone calls.
   * Incoming calls are always recorded since they go directly to voicemail.
   * By default, all calls are recorded.
   * 
   * @name Require consent to record calls
   */
  requireConsentToRecordCalls?: {
    /**
     * Requires caller to obtain verbal consent, then press a button to start an outgoing call recording.
     * 
     * @width 4
     */
    kind: 'verbal_consent_manual_recording',
    /**
     * Script requesting consent that will be displayed to the caller to read at the beginning of the call.
     * If none is provided, a default script will be used.
     * 
     * @width 4
     */
    customConsentScript?: Text,
  },
  /**
     * @name Enable video meetings
     * @width 4
     * @hidden yes
     * @deprecated - use the `videoCalling` object
     */
  enableMeetings?: boolean
  /**
     * @width 4
     * @name Video Calling
     */
  videoCalling?: {
    /**
         * @name Enable video calling
         * @width 4
         */
    enabled?: boolean;
    /**
         * Select one or more target fields that can be captured as snapshots
         * from the video call interface.
         * 
         * Most commonly used for capturing id docs and selfies
         * 
         * @name Snapshot target fields
         * @width 4
         */
    snapshotTargetFields?: TargetFieldRef[]
  }
  /**
     * @width 4
     * @name Automatic Message Handler
     */
  automaticMessageHandler?: {
    /**
         * What to do with messages that come in
         * @width 4
         */
    handlers: {
      /**
             * @uuid hidden
             * @hidden yes
             */
      id: string,

      /**
             * Enter a unique name for this handler
             * @width 2
             */
      name: string,

      /**
             * disabled by default, this must be true to turn it on.
             * This is so that you can keep your settings without having to delete the whole component.
             * @width 2
             * @validateInput AMHEnableKey
             */
      enableKey: string,

      /**
             * When to look back to handle messages
             * after enabling this configuration. This is to enable
             * looking back in the past and 'handling' messages from the past.
             * @width 4
             */
      startFrom: After

      /**
             * When a message needs attention, 
             * this action will be triggered either Immediately or
             * After a certain amount of time has elapsed.
             * @width 4
             */
      when: "Immediately" | AfterTimeElapsed,

      /**
             * This allows further features down the line, 
             * such as notifying the support agent on support case activity
             * @name Act as Support Agent
             * @width 4
             */
      actAsSupportAgent?: UserNameRef,

      /**
             * Leave this unchecked if you do not wish to auto handle messages
             * Example use case is if you are under high stress and just want to auto-reply, 
             * but don't want to auto-handle so the AST can still pick up the messages,
             * or so that a followup handler can pick it up.
             * @width 2
             */
      handle: boolean,

      /**
             * @width 4
             */
      reply: {
        /**
                 * @width 4
                 */
        subject: Text,
        /**
         * @name Body
         * @width 4
         */
        message: RichText,

        /**
                * How many days to wait before allowing auto reply
                * to a contact that we've already auto replied to.
                * The cache is created after the first auto reply message is sent.
                * @width 4
                */
        dropCache: "After 30 days" | AfterTimeElapsed

        /**
                 * If the reply to email is different, specify that here
                 * @name Email Message
                 * @width 4
                 */
        emailMessage?: RichText
      }

      /**
             * If dry mode is enabled, the AMH Will publish what it would do to logs instead, which will be accessible in the Automatic Message Handler pane.
             */
      dryMode?: boolean

      /**
             * If this exists, the handler will only auto reply, handle, and/or be assigned to a support case if the incoming contact is in this list.
             * Phone numbers should be in format +1xxxxxxxxxx
             * @width 4
             */
      onlyTheseContacts?: string[]
    }[]
  }
}

export type Challenge = ContactChallenge | InfoChallenge;

export type ContactChallenge = {
  kind: 'contact',
  description?: Text,
  failureMessage?: Text,
}

export type InfoChallenge = {
  /**
     * @width 2
     */
  kind: 'info',
  /**
     * @width 2
     */
  fieldRef: TargetFieldRef,
  /**
     * @width 2
     */
  description?: Text,
  /**
     * @width 2
     */
  failureMessage?: Text,
  // Valid field types thus far:
  // * date - creates the field as a Year Month Day entry.
  // * password - hides the field
  /**
     * @width 2
     */
  fieldType?: 'date' | 'password'
}

type AlwaysChallenge = {
  mode: 'always'
}

type NeverChallenge = {
  mode: 'never'
}

type AfterFirstClickChallenge = {
  mode: 'after_first_click'
}

type TimeAfterFirstClickChallenge = {
  mode: 'time_after_first_click'
  minutes: number
}

type ChallengeRequirement = AlwaysChallenge | NeverChallenge | AfterFirstClickChallenge | TimeAfterFirstClickChallenge;

type Interface2023 = {
  /**
     * @hidden yes
     */
  version: 1
  /**
     * @width 4
     */
  name: '2023'
}

type InterfaceVersions = 'legacy' | Interface2023;

export function interfaceNumber(version?: InterfaceVersions) {
  if (typeof version === 'string') {
    return 0;
  }
  return version?.version || 0;
}

export type FraudConfigType = NonNullable<ProgramConfig['fraud']>;

export type EncryptedReportConfig = {
  /**
     * Reports are stored in s3 according to the following:
     *   Bucket: 'aidkit-documents',
    *    Key: `/programs/encryptedreports/${reportPrefix}_${new Date().toISOString().slice(0, 10)}`, // YYYY-MM-DD
     */
  reportPrefix: string,
  encryptedEncryptionKey: string,
}

export type FieldGroup = {
  match: 'All' | 'Any',
  value: (TargetFieldRef | FieldGroup)[]
}

/**
 * @migration PaymentHandlingV1
 */
type legacyPaymentConfig = {
  failure_handling: 'request_changes' | 'appinfo'
}

type CustomHandling = {
  /**
    * @width 2
    */
  kind: 'custom',
  /**
    * @width 4
    * @name Custom Action Buttons
    */
  customActionButtons?: {
    /**
      * @width 4
      * @name Button Text
      */
    title: Text,
    /**
      * Target fields and values to set when button is clicked.
      * @width 4
      * @name New Info
      */
    newInfo: {
      /**
       * @width 2
       */
      key: TargetFieldRef,
      /**
       * @width 4
       */
      value: ValueExpr
    }[],
    /**
    * Instead of creating a new action button on a failure, this will merge this custom action button with the default behavior.
    * @width 2
    * @name Merge with existing button
    */
    mergeWithDefault?: boolean
  }[]
}

type appInfoFailureHandling = WithLegacy<{
  /**
     * @width 4
     * @name Failure Handling
     */
  failure_handling: 'request_changes' | CustomHandling
}, legacyPaymentConfig>;

/**
 * @display stacked
 */
export type ApplicantSearchConfig = {
  kind: 'Applicant Search Options'
  allowedUsers?: UserTagRef[],
  /**
   * Overrides the default, which is Email and Phone. 
   * In protected search, we always search By Date of Birth + a Contact field.
   * This option lets you specify which contact fields users will be allowed to search by.
   * @width 4
   * @name Search By Contact
   */
  searchByContact?: EmailAndPhone | PhoneOnly | EmailOnly
}

/**
 * @display stacked
 */
export type ProgramConfig = {
  version: 'v1'
  name: string,
  interface?: {
    /**
         * @width 2
         */
    version: InterfaceVersions,
    applicationHeadline?: string,
    /**
         * @width 4
         */
    applicantFacingLogo?: {
      /**
             * @width 3
             */
      url?: string,
      /**
             * @width 1
             */
      width?: number,
    },
    /**
         * @width 4
         */
    applicantBanner?: {
      color: 'Red' | 'Yellow' | 'Blue',
      /**
             * @width 4
             */
      content: RichText
    }
    /**
         * @width 4
         */
    staffBanner?: {
      color: 'Red' | 'Yellow' | 'Blue',
      /**
             * @width 4
             */
      content: RichText
    },
    /**
     * @width 4
     */
    loginBanner?: {
      color: 'Red' | 'Yellow' | 'Blue',
      /**
       * @width 4
       */
      content: RichText
    },
    disableReassignScreener?: boolean,
    /**
         * @width 4
         */
    sectionBottomButtons?: {
      /**
             * @width 4
             */
      logoutRedirectPath: 'apply' | SubsurveyPathRef
    },
    includeProtectedSearchInNav?: boolean,
    /**
         * To be used in the legacy interface, if we want to use new interface dashboards but still want everything
         * else to use the legacy interface. 
         */
    useNewDashboards?: boolean,
    /**
         * Overrides the default "donut" style if specified for pie charts.
         */
    defaultPieChartStyle?: "pie" | "donut",
    thirdPartyCheckStatusTab?: UserTagRef[],
    /**
     * Displayed if the user has cookies disabled, which may impact the functionality of our site
     * @width 4
     */
    cookieBanner?: {
      /**
       * @width 4
       */
      content?: RichText,
      /**
       * If true, the banner will never be displayed
       */
      disable?: boolean,
    },
    /**
     * Do not allow applicants to play the audio of the application
     * @name Disable Voiceover
     */
    disableVoiceover?: boolean,
  },
  comms?: CommsConfig,
  application?: {
    /**
         * (Deprecated, using login now)
         * @width 4
         */
    applicationLookupMatcher?: Code,

    /**
         * @width 4
         * @name Login
         */
    login?: {
      /**
             * @width 4
             */
      allowMultipleApplications?: {
        /**
                 * Inputs are:
                 *  - provided: Info provided by the user logging in (email, date of birth, phone, etc)
                 *  - dbApps: All applications in Dynamo and Postgres databases that match the contact AND the provided info
                 *  - - let dbApps: AppInfo[] = [];
                 * Output should be an array of dbApps that are matched.
                 * You do not need to match contact info that will be done by the backend.
                 * Example:
                 * ```
                 *  dbApps.filter(app => app.birth_date === provided.birth_date)
                 * ```
                 * The routes defined below will populate based on each app returned by this function
                 * @width 4
                 * @validateJS (() => expr )
                 * @language js
                 */
        matchingFunction: Code,

        /**
                 * @width 4
                 * @validateJS (() => expr )
                 * @language js
                 */
        allowNewApplicationFunction?: Code,

        /**
                 * @width 4
                 */
        summaryOfDifferences: RichText

        /**
                 * The list of fields we will dedupe against when ingesting the application.
                 * @width 4
                 */
        defaultDeduplicationTuple?: (TargetFieldRef | FieldGroup)[]

        /**
                 * @width 4
                 */
        matchedContactField?: {
          targetField: TargetFieldRef,
          fieldKind: 'array'
        }

      },

      /**
             * (Optional) Default is "We found many links for you. 
             * Please choose the one you want to login to."
             * @width 4
             * @name Login Dialog Description
             */
      dialogDescription?: RichText

      /** 
             * @width 4 
             **/
      enableSubsurveyLogin?: {

        /**
                 * @width 2
                 */
        linkStyle: "button" | "link",

        /**
                 * @width 2
                 */
        openSubsurveyInNewTab?: boolean

        /**
                 * @width 4
                 */
        subsurveyLinkExpiration?: {
          /**
                     * @default 0
                     */
          hours: number,
          /** 
                     * @default 0
                     */
          days: number,
          /** 
                     * @default 0
                     */
          weeks?: number,
          /**
                     * @default 0
                     */
          months?: number
        }
      }


      /**
             * @width 4
             */
      challengeRequirement?: ChallengeRequirement,
      defaultChallenge?: Challenge[],

      /**
             * @width 4
             */
      loginPageText?: RichText,

      /**
             * @width 2
             */
      logoutRedirectPath?: SubsurveyPathRef | string
    },
    disableSubmitNotification?: boolean,
    /**
     * Personal Identifiable Information fields that should be redacted from the application 
     * upon program archive/deletion
     * @name PII Fields
     * @width 4
     */
    piiFields?: TargetFieldRef[]
  },
  payments?: appInfoFailureHandling,
  fraud?: {
    enableTab?: boolean,
    enableSimilarityCalculations?: boolean,
    // This should be limited to AidKit staff
    useSpecificFaceCollection?: 'cdss',
    /**
     * Allow data from this program to be shared with other programs that are checking for duplicates
     * against this program.
     * @width 4
     */
    duplicateDataSharing?: {
      /**
       * This governs what data can leave this program and which programs are allowed access to it via Duplicate Review and Flags.
       * @width 4
       */
      shareableData: {
        /**
         * These fields will be viewable by Flags and in Duplicate Review in the specified programs.
         * @width 2
         */
        fields: TargetFieldRef[],
        /**
         * Which programs have access to the specified fields
         * @width 2
         */
        programsWithAccess: string[],
        /**
         * Optional. If set, only users with these tags will be able to view the specified fields in the programs listed.
         * Empty means anyone in a specified program can access.
         * @width 2
         */
        limitedToTags?: UserTagRef[],
      }[],
    },
  },
  experimental?: {
    enableQuickJS?: boolean
    enableOfflineMode?: boolean
    enableCleanUploads?: boolean
    enableCleanAttachments?: boolean
    enableRoboscreenerValidateCleanUploads?: boolean,
    newNavigationButtonStyle?: boolean,
    lateralUnnestingInDashboards?: boolean,
    speechEngine?: 'standard' | 'neural',
    extendedSpeechLength?: boolean,
    /** Applies enableEmailMarkdown to all notifications in the program, regardless of per-notification enableEmailMarkdown values. */
    enableEmailMarkdownGlobally?: boolean,
    /**
         * Enabling this flag will cause CustomQueries within a filtered DashboardSection
         * to get wrapped such that the filter will be applied to the CustomQuery.
         * This can potentially cause a moderate slowdown of the CustomQuery.
         */
    enableCustomQueryFiltering?: boolean,
    /**
         * Enabling this flag will change the way dashboards are loaded. Instead of the entire dashboard
         * waiting until all components have loaded, placeholders will be used and the dashboard will
         * populate as the queries complete.
         */
    instantLoadDashboards?: boolean,
    /**
         * Enabling this flag will enable macros in Distro Editor. This will enable the options
         * to select avaiilable macros within the survey configuration which will "unfurl" and expand
         * into starter distro content upon publishing the survey.
         */
    macros?: boolean,
    /**
     * Enabling this flag will add a breadcrumb to the top of the Distro editor indicating where you are
     * located within the config.
     * Note: You will need to publish and then refresh the page for this change to take effect
     */
    distroBreadcrumbs?: boolean,
    /**
     * @deprecated - use copy_edit tags for access, and capabilities.copyEditPublish
     * to allow publishing.
     * 
     * Allows users with role/tag inline_edit to access the InlineEditor page
     * and edit content directly for a survey. Such users do not have access to
     * the main Distro config page, nor the ability to add/move/remove components
     * of the survey.
     */
    inlineEditing?: boolean,
    /**
     * Displays the last time the data behind a dashboard component was fetched. This can be useful as we
     * cache query results, and so when a user visits a dashboard page, this will enable them to know
     * how fresh the data actually is.
     */
    showLastUpdatedOnDashboards?: boolean,
    /**
     * This flag, along with a configured ApplicantPortalPage, will enable the applicant portal.
     */
    enableApplicantPortal?: boolean,
    /**
     * Enables generating review data using screenshots provided by a program
     */
    enableAIReviewGeneration?: boolean,

    /**
     * Enables access to the AI Coach (beta!).
     *
     * Currently, this will only enable a Program Admin Page "/coach-eval" to function.
     */
    enableAICoach?: boolean,

    /**
     * Updates distro styling so that each element of a kind has it's own row.
     * TODO: This is an a/b test and should eventually be either turned on or off for all users.
     */
    distroDisplayStacked?: boolean, 
    /**
     * Configure the floating help button in the bottom right corner
     * @display stacked
     * @width 4
     */
    helpButton?: {
      /**
       * Enable the floating help button
       * @width 2
       */
      enabled: boolean,
      /**
       * Configure what content to show when the help button is clicked
       * @width 4
       * @display stacked
       */
      content?: {
        /**
         * Show questions from a FAQ subsurvey, for example
         * @name Subsurveys to Display
         * @display stacked
         * @width 4
         */
        subsurveys?: { 
          /**
           * The path to the subsurvey to display
           * @width 4
           */
          path: SubsurveyPathRef,
          /**
           * Optional, the sections to display from the subsurvey
           * If not specified, only the first section from the subsurvey will be displayed
           * @width 4
           */
          displaySections?: SectionRef[]
        }[],
      }
    },
    /**
     * Use Google Maps as a fallback for geocoding
     */
    geocodeGoogleMapsFallback?: boolean,
  },
  /**
   * @display stacked
   */
  capabilities?: {
    /**
         * Limits showing the "Add Applicant" button to 
         * users that have at least one of the tags specified here.
         *
         * Delete this setting to cause the button to show for all users.
         * Adding this setting, and not adding any tags, hides the button for all users.
         *
         * @width 4
         * @name Limit Add Applicant to Tags
         */
    addApplicant?: UserTagRef[],
    applicantSearch?: ApplicantSearchConfig | UserTagRef[],
    importKeyId?: string,
    encryptedReportConfigs?: EncryptedReportConfig[],
    thirdPartyCheckStatusTab?: UserTagRef[],
    /**
     * This toggle determines whether users can Publish via the Copy Editor.
     *
     * If not enabled, the changes made in the Copy Editor will only become live
     * when someone in Distro uses the Publish button.
     *
     * Defaults to "false"
     */
    copyEditPublish?: boolean
  },
  legacyAirtable?: {
    stopSyncing?: boolean
    /**
         * @width 4
         */
    configuration?: {
      key: string,
      /**
             * @width 3
             */
      value: string
    }[]
  },
  accounting?: {
    /**
     * For reconciliation reports sent on the 15th of each month.
     * @width 4
     */
    monthlyReconciliationReports?: {
      recipients: {
        /**
         * @width 4
         */
        emailAddress: string,
      }[],
    },
  },
  logOutInactiveUser?: {
    /**
    * Will log out a user after timeout seconds has passed
    */
    timeout: number,
    /**
     * User will be prompted when there is promptBeforeIdle seconds left. Must be less than timeout.
     */
    promptBeforeIdle: number,
  },
  /**
   * Enables passwords for two-factor authentication for admin users. Required for HIPAA compliance.
   * Users will be prompted to set a password the first time they log in with an OTP code.
   */
  adminPasswords?: boolean,
}

export type ReplyScheduleConfig = {
  /**
   * @width 4
   */
  timezone: Timezone,
  /**
   * @width 4
   */
  options: DateOption[]

}

export type FieldMapping = {
  /**
   * @name Transformations
   */
  options: {
    /**
      * If this is the value in your csv, it will be saved as whatever you enter in the correspoding 
      * "value to save" input.
      * @width 2
      */
    csvValue: string,
    /**
      * @width 2
      * @name Value to save
      */
    targetFieldValue: string,
  }[],
  /**
   * If checked, a value in your csv that does not have a matching transformation will still be included. 
   * If not checked, unknown values for this column will prevent import.
   * @width 2
   * @name Allow unknown values
   */
  allowUnknownValues?: boolean
}

export type Numeric = {
  /**
   * Require this CSV input to be a number. Use integersOnly option to specify further.
   */
  kind: 'Numeric Validation'
  /**
   * Require this CSV input to be a number greater than this value.
   */
  greaterThan?: number
  /**
   * Require this CSV input to be a number less than this value.
   */
  lessThan?: number
  /**
   * Require this CSV input to be an integer.
   */
  integersOnly?: boolean

}

export type Update = {
  kind: 'Update',
  /**
   * The uidColumn refers to the column header in your uploaded CSV file. Make sure it matches exactly with the column name that contains applicant names.
     * @references csvColumns
     */
  uidColumn: string
}

export type Create = {
  kind: 'Create',
  /**
   * The nameColumn refers to the column header in your uploaded CSV file. Make sure it matches exactly with the column name that contains applicant names.
     * @references csvColumns
     */
  nameColumn: string,
  /**
     * @references csvColumns
     */
  hashedFromColumn?: string,
  /**
     * If an orgNameColumn is provided, the system will attempt to find an existing org or create a new one with the associated org name in each row.
     * The org's UID is stored in the organization column of the applicant table.
     * @references csvColumns
     */
  orgNameColumn?: string,
}

export type CSVMapping = {
  /**
     * @width 4
     */
  mode: Update | Create,
  columns: {
    /**
         * @references csvColumns
         */
    csvColumn: string,
    targetField: string,
    /**
         * @width 4
         */
    format: 'As Is' | 'Phone' | 'Email' | 'Date' | FieldMapping | Numeric,
    encryptThisValue?: boolean,
    criteria?: 'exists',
  }[]
}

export type FraudFlag = {
  kind: 'Fraud Flag',
  /**
     * @name Flag Name
     * @width 4
     */
  flagName: string,
  /**
     * @width 4
     */
  description: RichText,
  /**
     * @width 4
     */
  formula: BooleanExpr,
  /**
     * @width 4
     */
  confidence: 'Always Fraudulent' | 'Potentially Fraudulent'
}

export type DateOption = {
  name?: string,
  hours?: number,
  minutes?: number,
  days?: number,
  relative?: boolean
}

export type GenericMacro = {
  /**
   * Generic Macros are intended as a tool for creating a macro that will be eventually codified to a fixed macro,
   * but can also be used for one off macros that don't need very specific configurations.
   */
  kind: 'Generic Macro',
  /**
   * The name you enter here should correspond to the name of the macro you are using
   * ( eg. for a macro generator written at distrolibrary.aidkit.org/macro-editor#micahtestfraudmacro you would enter "micahtestfraudmacro" here)
   * @width 4
   */
  name: string,
  /**
   * @width 4
   */
  options: {
    key: string,
    value: string
  }[]
}

type EligibilityOptions = {
  /**
   * @width 4
   */
  ineligiblePreReview: boolean,
  /**
   * @width 4
   */
  ineligiblePostReview: boolean,
}

type RecollectOptions = {
  /**
   * @width 4
   */
  maxNumberRecollects: number,
}

type DuplicateReviewOptions = {
  /**
   * @width 4
   */
  primogenitureApplies: boolean,
}

type DeadlineOptions = {
  /**
   * @width 4
   * @format ^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$
   * @formatMessage Must be in ISO timestamp format eg. 1982-10-03T00:00:00Z
   */
  isoTimestamp: string,
}

type EnrollmentOptions = {
  /**
   * Check this box if you would like payment approval to happen after enrollment.
   * The default is after review.
   */
  approvePaymentsAfterEnrollment: boolean,
  /**
   * Select none or enter an enrollment deadline in iso timestamp format. eg. 1982-10-03T00:00:00Z
   * @width 4
   */
  deadline: 'none' | DeadlineOptions,
}

type LimitAsPercentOfFederalPovertyLevel = {
  /**
   * Enter the integer representation of the federal poverty level you'd like to use as a limit.
   * If people making less than 50% of the federal poverty level should qualify, enter 50.
   * @width 4
   */
  percentOfFederalPovertyLevel: number,
}

type LimitByHouseHoldSize = {
  /**
   * Start by entering the income limit in dollars for a household size of one. 
   * Use the plus sign to add additional household size levels if needed. 
   * If the income limit is the same for all household sizes, simply enter it once in the first row.
   * If a household size is greater than the highest limit entered, they will be compared to the highest limit.
   * ie. if you only enter two limits and someone has a household size of 3, they will be compared to the limit for a household size of 2.
   * @width 4
   */
  byHouseholdSize: number[],
};

type IncomeOptions = {
  /**
   * @width 4
   */
  requiredDocumentation: 'Document & Attestation' | 'Document or Attestation' | 'Document Only' | 'Attestation Only',
  /**
   * @width 4
   */
  incomeLimit: 'none' | LimitAsPercentOfFederalPovertyLevel | LimitByHouseHoldSize,
}

type ResidencyVerificationOptions = {
  /**
   * @width 4
   */
  requiredDocumentation: 'Document & Attestation' | 'Document or Attestation' | 'Document Only' | 'Attestation Only',
  /**
   * Enter the two digit code for each state that applicants must reside in to be eligible.
   * Leave empty to not filter by state, county, or city.
   * If needed you can limit to specific counties and/or cities within the state.
   * Enter a comma separated list of all the counties and cities you'd like to allow.
   * The person must be in all entered filters to be eligible.
   * If you enter states, allowedCities, allowedCounties, and allowedZipCodes, the person must be in all of them to be eligible.
   * @width 4
   */
  allowedStateCountyCityOptions?: {
    /**
     * @width 4
     */
    state: string,
    /**
     * Comma separated list of allowed counties within the state.
     * Leave blank to not filter by county.
     * @width 4
     */
    allowedCounties?: string,
    /**
     * Comma separated list of allowed cities within the state.
     * Leave blank to not filter by city.
     * @width 4
     */
    allowedCities?: string,
  }[],
  /**
   * Comma separated list of allowed zip codes.
   * Leave blank to not filter by zip code.
   * If entered along with allowedStateCountyCityOptions, the person must be in all entered filters to be eligible.
   * @width 4
   */
  allowedZipCodes?: string,
}

type AdditionalEligibilityCriteriaOptions = {
  /**
   * Require proof of pregnancy.
   * @width 4
   */
  pregnant?: {
    /**
     * @width 4
     */
    requiredDocumentation: 'Document & Attestation' | 'Document or Attestation' | 'Document Only' | 'Attestation Only',
  },
  /**
   * Require proof of having at least one child.
   * @width 4
   */
  haveAtLeastOneChild?: {
    /**
     * @width 4
     */
    requiredDocumentation: 'Document & Attestation' | 'Document or Attestation' | 'Document Only' | 'Attestation Only',
  },
  /**
   * Require proof of being recently unemployed.
   * @width 4
   */
  recentlyUnemployed?: {
    /**
     * @width 4
     */
    requiredDocumentation: 'Document & Attestation' | 'Document or Attestation' | 'Document Only' | 'Attestation Only',
  },
  /**
   * Add a custom eligibility criteria
   * NOTE: You need to update the question text for this field in the survey for each language
   * It will have the targetField custom_criteria_select
   * @width 4
   */
  customCriteria?: {
    /**
     * @width 4
     */
    requiredDocumentation: 'Document & Attestation' | 'Document or Attestation' | 'Document Only' | 'Attestation Only',
  }
}

export type BasicInstanceMacro = {
  kind: 'Basic Instance Macro',
  /**
   * Enter this exactly as you would like it to appear in the copy
   * @width 4
   */
  programName: string,
  /**
  * Enter this exactly as you would like it to appear in the copy
   * @width 4
   */
  programSupportEmail: string,
  /**
   * Enter this exactly as you would like it to appear in the copy
   * @width 4
   */
  programSupportPhone: string,
  /**
   * @width 4
   */
  applicantStateSettings: {
    /**
     * Check this box for standard fraud detection tools.
     * @width 4
     */
    confirmedFraud: boolean,
    /**
     * Check this box to enable marking applications as needing elevated review.
     * NOTE: You will need to set up multiple levels of reviewers to use this function. ie. reviewer, elevated_reviewer.
     * @width 4
     */
    elevate: boolean,
    /**
     * Select none if sending to a predetermined list of eligible applicants.
     * Otherwise choose at which stage of the review process you would like eligibility to be determined:
     * before review, after review, or both.
     * @width 4
     */
    eligible: 'none' | EligibilityOptions,
    /**
     * Select none, or enter the number of times you would like a reviewer to be able to send an application back for corrections.
     * RECOMMENDATION: 2 recollects
     * @width 4
     */
    recollect: 'none' | RecollectOptions,
    /**
     * Select none to do no duplicate review between applications.
     * Check primogenitureApplies if you would like the first app to be the only app allowed.
     * NOTE: If you don't check primogenitureApplies, you'll need additional logic to prevent payment on multiple apps
     * @width 4
     */
    duplicateReview: 'none' | DuplicateReviewOptions,
  },
  /**
   * @width 4
   */
  subSurveySettings: {
    /**
     * If your program is using an enrollment phase after application phase, check this box.
     * @width 4
     */
    enroll: 'none' | EnrollmentOptions,
  },
  /**
   * @width 4
   */
  applicationSettings: {
    /**
     * Select none if you do not want to limit applications by income. Otherwise select the income limit and verification parameters.
     * @width 4
     */
    income: 'none' | IncomeOptions,
    /**
     * @width 4
     */
    identityVerification: 'liveness' | 'livenessAndDocumentUpload' | 'none',
    /**
     * @width 4
     */
    residencyVerification: 'none' | ResidencyVerificationOptions,
    /**
     * Add any additional eligibility requirements here.
     * @width 4
     */
    additionalEligibilityCriteria: 'none' | AdditionalEligibilityCriteriaOptions,
    /**
     * @width 4
     */
    researchAndConsents: boolean,
  },
  /**
   * @width 4
   */
  contactSettings: 'Phone and Email' | 'Phone Only' | 'Email Only' | 'Phone or Email',
  /**
   * @width 4
   */
  payments: {
    /**
     * @width 4
     */
    ach: {
      enabled: boolean,
    },
    /**
     * @width 4
     */
    debit: CardProviders,
    /**
     * @width 4
     */
    frequency: PaymentFrequency,
    /**
     * Specifying a number of cohorts will create basic scaffolding for the templated blocks needed to
     * specify different payments for different populations.
     * @width 4
     */
    cohorts?: number,
  }
  /**
  * @width 4
  */
  additionalOptions?: {
    key: string,
    value: string
  }[]
}

export type Macro = {
  kind: 'Macro',
  /**
  * @width 4
  */
  type: GenericMacro | BasicInstanceMacro,
}

// Macro expander functions take a configuration as a parameter that is specific to that macro. 
// For every new macro defined, ensure the macro-specific config is defined as a union type here.
export type MacroConfig = PaymentModule;

// Macro expander functions 'unravel' a macro config into one or more collections that will be
// inserted into the survey. Macros must return Collections
export type MacroExpander = (config: MacroConfig) => Collection[];

/**
 * @display stacked
 */
export type Usio = {
  kind: 'Usio',
  virtual: {
    enabled: boolean
  },
  pickup: {
    enabled: boolean
  },
  mail: {
    enabled: boolean,
    /**
         * Enabling auto-associate for this program will bypass a step that requires an applicant
         * to associate a physical card on receipt. For increased security, it is recommended to leave this disabled which
         * will create the requisite subsurvey that an applicant will use to associate their card in addition to 
         * activating the physical card with the provider.
         */
    autoAssociate: boolean,
  }
}

export type NoCard = {
  kind: 'None',
}

/**
 * @display stacked
 */
export type GiveCard = {
  kind: 'GiveCard',
  virtual: {
    enabled: boolean
  },
  pickup: {
    enabled: boolean,
  },
  mail: {
    enabled: boolean,
  }
}

/**
 * @display stacked
 */
export type USBank = {
  kind: 'US Bank',
  pickup: {
    enabled: boolean
  }
  mail: {
    enabled: boolean
  }
}

export type CardProviders = NoCard | GiveCard | Usio | USBank;

export type FixedPaymentAmount = {
  kind: "fixed",
  amount: number
}

export type VariablePaymentAmount = {
  /**
     * Distro will create a separate computed field where you can specify the variable payment amount 
     */
  kind: "variable",
}

/**
 * @display stacked
 */
export type OneTimePayment = {
  kind: 'One Time Payment',
  paymentAmount: FixedPaymentAmount | VariablePaymentAmount
}

/**
 * @display stacked
 */
export type RecurringPayment = {
  kind: 'Recurring Payment',
  /**
     * @width 2
     */
  numberOfPayments: number,
  paymentAmount: FixedPaymentAmount | VariablePaymentAmount
}

export type PaymentFrequency = OneTimePayment | RecurringPayment;

/**
 * @expander createPaymentModule
 * @display stacked
 */
export type PaymentModule = {
  kind: 'Payment Section',
  /**
     * The program name to be used in custom queries for payment lookups
     * @format ^[a-z]+$
     * @formatMessage Lowercase characters only, no spaces.
     */
  program: string,
  ach: {
    enabled: boolean,
  },
  debit: CardProviders,
  frequency: PaymentFrequency,
  /**
     * Specifying a number of cohorts will create basic scaffolding for the templated blocks needed to
     * specify different payments for different populations.
     */
  cohorts?: number,
}

export type CrossProgramDataExchange = {
  kind: 'Cross Program Data Exchange',
  /**
     * the name of the program that will be exchanging data with this one
     *
     * @name Program
     */
  program: string,
  /**
     * will pull data INTO this program 
     *
     * @width 4
     * @name Import
     */
  import?: {
    /**
         * A value that can be used to match an existing applicant in the other program,
         * ensuring no duplicate records are created accidentally.
         *
         * @width 4
         * @name Identity Key
         */
    idempotentKey?: ValueExpr,
    /**
         * the fields requested to be imported into this program. leave this empty to allow all fields to be imported.
         *
         * @name Fields to Copy
         * @width 4
         */
    fields: (NamedExpression | ValueExpr)[],
    /**
         * Fields that will be excluded from the import. Leave 'fields' as empty and specify
         * fields here in excluded to import <all fields from the export except for these excluded fields>
         * 
         * @name Excluded Fields
         * @width 4
         */
    excluded?: TargetFieldRef[],
    /**
         * only attempt to import certain applicants that meet this criteria.
         * IMPORTANT: any target fields used here must
         * be specified in the export config of the corresponding program 
         *
         * @name Filter
         * @width 4
         */
    filter?: BooleanExpr,
    /**
         * Additional transformations to be performed
         * on the new applicant record, before being 
         * imported into this program
         *
         * @width 4
         * @name Post Processing
         */
    postProcessing?: {
      /**
             * the target field the new value should be stored in.
             *
             * @width 2
             * @name Target Field
             */
      targetField: string,
      /**
             * the expression to create the new value.
             *
             * @width 4
             * @name Expression
             */
      expression: ValueExpr
    }[]
  },
  /**
     * Allows specific data to be pulled FROM this program
     *
     * @width 4
     */
  export?: {
    /**
         * the fields that are permitted to be exported. leave this empty to allow all fields to be exported
         *
         * @name Allowed Fields
         * @width 4
         */
    allowed?: TargetFieldRef[],
    /**
         * Fields that will be excluded in the export. Leave 'allowed' as empty and specify fields here to
         * export <all fields except for the fields specified in excluded>
         * 
         * @name Excluded Fields
         * @width 4
         */
    excluded?: TargetFieldRef[],
    /**
         * only allows a subset of the applicant records to be exported
         *
         * @width 4
         * @name Filter
         */
    filter?: BooleanExpr
  },
  /**
     * This will be given by the corresponding program.
     * Both programs MUST have these set correctly to allow the sync to proceed.     
     *
     * @name Enable Key 
     */
  enableKey: string
};


/**
 * @name Geo Data Sync
 * @display stacked
 */
export type GeoDataSync = {
  /**
    * 
   * To be used in conjunction with a Geo Lookup kind.
   * Geo Data Sync will overwrite the `lookupKey` field in the `geolookup` database when it syncs data with the remote source.
   * Because Geo Lookups are recomputed everytime Roboscreener runs, this could result in the GeoLookup value changing over time.
   * If a Computed field is referencing the GeoLookup field, take care to configure the Computed field to only use the GeoLookup
   * field appropriately (e.g. only if reviewed=false)
   */
  kind: 'Geo Data Sync',
  /**
   * Unique identifier for this geo data sync configuration
   */
  id: string,
  /**
    * The Lookup Kind in Geolookup. This is a unique identifier for the data set and what Geo Lookups reference with `key`.
    */
  lookupKey: string,
  /**
    * The URL of the GeoJSON file to load
    */
  url: {
    /**
    * @width 4
    */
    value: string,
    /**
   * @width 4
   * @display stacked
   */
    arcGISGeoService?: {
      /**
      * @width 4
       * Enable this if the URL is an ArcGIS GeoService. The system will automatically collate all pages of data on ingestion.
       * For the URL, provide the FeatureServer's endpoint for the layer (it should end in /arcgis/rest/services/<serviceName>/FeatureServer/0/)
       */
      enabled: boolean,
      /** 
       * @width 4
      * By default, we will query where=1=1 which will return all features. Use this string to override the query to the geoJSON server
      * This should be in the format of the arcgis API `where` param like: (incident='TEST' OR incident='TEST2')
      */
      customQuery?: string,
    }
  },
  /**
    * The property in the GeoJSON features to extract
    */
  property: string,
  /**
    * Enable this to load the GeoJSON data into the postgres db. Use this for GeoLookups required post-ingestion.
    */
  loadPostgres: boolean,
  /**
    * Enable this to load the GeoJSON data into dynamodb. Use this for GeoLookups required pre-ingestion.
    */
  loadDynamo: boolean,
  /**
   * Use this to override the value we should store for a specific key/value pair in a GeoJSON feature attribute.
   * This can be helpful to normalize spelling or to reference a different property and override the property we want to store.
   */
  customKeyMappings?: {
    featureKey: string,
    featureValue: string,
    value: string,
    caseInsensitive: boolean,
  }[],
  /**
    * Defines how often the geolookup data should sync with the remote version
    */
  updateInterval: {
    amount: number,
    unit: 'hours' | 'days' | 'weeks' | 'months',
  },
}

export type PaymentDashboard = {
  kind: 'Payment Dashboard',

  /**
   *
   * PLEASE ONLY ADD 2 DIMENSIONS! The component currently ignores the rest.
   *
   * The ways you want to group the payment statuses.
   *
   * e.g. 'payment', 'cohort' would group payment statuses by payment name, with cohorts being grouped underneath.
   *
   * The default is to group by the Payment Name (e.g. the names of the payment kind target fields)
   *
   * @name Dimensions
   * @width 4
   */
  dimensions?: ('payment' | TargetFieldRef)[],

  /**
   * Converts payment names from lower_snake_case to Title Case format (e.g. payment_1 to Payment 1)
   * @name Friendly Names
   * @width 2
   */
  friendlyDimensionNames?: boolean
};

type MapSource = {
  /**
    * Name of the map layer
    * @width 4
    */
  layerName: string,
  /**
    * Fill Color for the layer (polygons will use this as shading, points will use it as the point color)
    * This can be either a hex value or a named color
    */
  fillColor?: string,
  /**
    * Stroke Color for the outline of the point or the polygon
    * This can be either a hex value or a named color.
    * An empty string will disable the stroke.
    */
  strokeColor?: string,
  /**
    * Properties from the geojson that we should extract to display.
    */
  properties?: {
    /**
    * The key in the geojson properties that should be shown on the map
    */
    key: string,
    /**
     * The display name for the key to make it easier to read
     */
    displayName: string,
    /**
     * Use `url` if this should render as a clickable link instead of in the data table
     */
    type?: 'text' | 'url'
  }[],
  /**
    * Conditional coloring based on a property value
    */
  colorMapping?: {
    /**
       * Name of the property in the source geojson to check
       * @width 4
       */
    property: string,
    /**
     * Mapping from value to custom color
     * @width 4
     */
    values: { key: string, fill?: string, stroke?: string }[];
  }[],
  /**
    * Configure a minimum zoom level before this layer is used
    */
  minZoomLevel?: number,
  /**
   * @width 4
   */
  pointConfig?: {
    /**
       * If this data set has point features, this controls how big the icon is that is 
       * rendered to the map. For standard shapes (circle, triangle, star, square), 
       * the number is the radius (in px) of the shape. For custom icons (any valid IconName
       * from the Icon component), it is the width/height (in px).
       * @width 2
       */
    size?: number;
    /**
     * The shape of the point feature. Providing a custom string will 
     * use an Icon (must be a valid IconName from <Icon /> component).
     * @width 2
     */
    shape: 'circle' | 'triangle' | 'star' | 'square' | string
  },
  /**
    * Set this source as the primary data source and the map will refer 
    * to this data set's bounding box to determine where to initialize the map's zoom.
    * There should only be one primaryDataSource per Map
    */
  primaryDataSource?: boolean;
}

/**
 * @name Raw GeoJSON
 */
export type RawGeoJSONSource = MapSource & {
  kind: 'Raw GeoJSON Source',
  /**
   * Raw GeoJSON data as stringified JSON
   * @width 4
   * @language json
   */
  data: Code,
};

/**
 * @name GeoJSON URL
 */
type UrlGeoJSONSource = MapSource & {
  kind: 'URL GeoJSON Source',
  /**
   * The URL of a hosted GeoJSON data set. You can use ArcGIS data sets but make sure they're not paginated. (Not supported yet)
   * @width 4
   */
  url: string,
};

/**
 * @name Applicant Data
 */
export type ApplicantDataSource = MapSource & {
  kind: 'Applicant Data Source', 
  /**
   * Use an applicant query as a data source. Specify all the properties we should extract from an applicant's record.
   * @width 4
   */
  columns: (ValueExpr | NamedExpression)[],
  /**
    * @width 4
    */
  filter: BooleanExpr | ClickQuery,
  /**
   * Specify the address field we should use to plot them on the map
   * @width 2
   */
  addressField: TargetFieldRef,
  /**
   * Whether to include a URL property that links to the applicant in the AidKit platform
   */
  includeUrl?: boolean,
  /**
   * Whether to include the UID in the data set 
   */
  includeUid?: boolean
};

export type MapDataSource = RawGeoJSONSource | UrlGeoJSONSource | ApplicantDataSource;

export type Map = {
  kind: 'Map',
  /**
   * The list of layers that will be rendered in the map. This is an ordered list
   * so make sure the define the base layer first and then incrementally add layers 
   */
  sources: MapDataSource[],
  title: string,
  description?: string,
  /**
    * Longitude and Latitude of where to center the map
    */
  center?: {
    latitude: number,
    longitude: number,
  };
  /**
      * Zoom level
      */
  zoom?: number;
  /**
   * Include this to show a legend of a count of how many data points are being shown.
   * @width 4
   */
  legend?: {
    /**
    * @width 4
    */
    title: string,
    showLayerCount: {
      /**
      * Provide the name of a layerName in one of the sources. The count will be shown for how many
      * features are in that layer.
      */
      layerName: string,
      /**
       * The label used to describe this count in the legend
       */
      label: string,
      /**
       * Count the number of polygons in the geojson data set or the number of points
       */
      featureToCount: 'polygons' | 'points',
    }[]
  }
}

export type MacroBlockProperties = {
  /**
  * Runs on the compilation of a macro into its output.
  * Takes macroConfig and returns true if this component should be included in the macro ouput.
  *
  * @width 4
  * @language js
  */
  macroIncludeIf?: Code
  /**
  * Runs on the compilation of a macro into its output.
  * Takes macroConfig and this component and returns this component updated.
  *
  * @width 4
  * @language js
  */
  macroUpdate?: Code
}

export type Filter = {

  /**
   * @width 1
   */
  kind: 'Filter',

  /**
   * @width 4
   */
  filter: BooleanExpr
};

export type SubsurveyLink = {
  /**
   * Used within a Templated Application Block, this will resume the subsurvey for the application in the loop.
   * It will only show if they have access to the subsurvey
   */
  kind: 'Subsurvey Link',
  /**
   * @width 4
   */
  subsurvey: SubsurveyPathRef,
  /**
   * @width 4
   */
  label: Text
  /**
   * @width 4
   */
  alignment?: 'left' | 'center' | 'right',
  /**
   * @width 4
   */
  variant?: 'primary' | 'secondary' | 'link' | 'danger'
}

export type NewApplicationLink = {
  /**
   * New Application Link is only shown to logged in users who have permission to create a new application.
   * It will direct the user to /apply.
   */
  kind: 'New Application Link',
  /**
   * @width 4
   */
  label: Text
  /**
   * @width 4
   */
  showWhen: 'LoggedIn'
  /**
   * @width 4
   */
  alignment?: 'left' | 'center' | 'right',
  /**
   * @width 4
   */
  variant?: 'primary' | 'secondary' | 'link' | 'danger'
}

export type LoginApplyLink = {
  /**
   * Login Apply Link is only shown to logged out users.
   * It can be used to direct the user to any Subsurvey that has anonymous viewing available.
   * eg. /apply or /login
   */
  kind: 'Login Apply Link',
  /**
   * @width 4
   */
  subsurvey: SubsurveyPathRef,
  /**
   * @width 4
   */
  label: Text
  /**
   * @width 4
   */
  showWhen: 'LoggedOut'
  /**
   * @width 4
   */
  alignment?: 'left' | 'center' | 'right',
  /**
   * @width 4
   */
  variant?: 'primary' | 'secondary' | 'link' | 'danger'
}

export type PortalExplanation = {
  kind: 'Portal Explanation',
  /**
   * @width 4
   */
  content: RichText | ConditionalContentList
  /**
   * @width 4
   */
  alignment?: 'left' | 'center' | 'right',
  /**
   * @width 4
   */
  accordion?: {
    /**
     * @width 4
     */
    header: RichText
    /**
     * @width 4
     */
    headerAlignment?: 'left' | 'center' | 'right';
  }
};

export type TemplatedApplicationBlockComponents = (
  PortalExplanation
  | SubsurveyLink
) & {
  /**
   * @width 4
   */
  conditionalOn?: BooleanExpr
};

export type TemplatedApplicationBlock = {
  /** 
   * Loops through each of an applicants applications, checking the appsToInclude condition for each.
   * Displays the configured components with info from matching applications.
   */
  kind: 'Templated Application Block',
  /** 
   * Shows once at the top of the template if there are any apps to include. 
   * If using accordion this will show when it is closed.
   * Gets the number of applications that match the filter as $count in the content.
   * @width 4
   */
  header: RichText,
  /** 
   * @width 4
   */
  headerAlignment?: 'left' | 'center' | 'right',
  /**
   * @width 4
   * @collapsible starts-collapse
   */
  components: TemplatedApplicationBlockComponents[],
  /** 
   * Filter which apps to include in the block.
   * @width 4
   */
  appsToInclude: BooleanExpr,
  /**
   * Show the components in an accordion.
   */
  accordion?: boolean,
  /**
   * Deprecated.
   * TODO: delete this once we move brf to use the new ifEmpty options.
   */
  hideIfEmpty?: boolean,
  /**
   * If the appsToInclude doesn't return any apps, you can:
   * - Omit this key to show the header anyway
   * - Select hide to not show the entire block
   * - Show a different message or group of applicants with a different Templated Application Block.
   * @width 4
   */
  ifEmpty?: 'hide' | TemplatedApplicationBlock,
  showWhen: 'LoggedIn'
}

export type ApplicantPortalPageComponents = (
  LoginApplyLink
  | NewApplicationLink
  | TemplatedApplicationBlock
  | PortalExplanation & {
    /**
     * @width 4
     */
    showWhen?: 'Always' | 'LoggedIn' | 'LoggedOut'
  });

export type ApplicantPortalPage = {
  /** 
   * Creates an applicant portal page within an instance 
   * These will be shown in the navigation sidepane for applicants
   */
  kind: 'Applicant Portal Page',
  /**
   * The label for the page in the navigation sidepane
   * @format ^[a-zA-Z_\s-]+$
   * @width 4
   */
  title: Text,
  /**
   * The url where this page will be located in the applicant portal.
   * Use `home` for the home page.
   * NOTE: home should be set to showWhen Always. This is where applicants will be redirected to after logging in or out.
   * @format ^[a-z_-]+$
   * @width 4
   */
  path: string,
  /**
   * @width 4
   * @collapsible starts-collapse
   */
  components: ApplicantPortalPageComponents[],
  /**
   * Determines when access to this page is allowed, and when it should show in the navigation.
   * @width 4
   */
  showWhen: 'Always' | 'LoggedIn' | 'LoggedOut',
}

/**
 * @display stacked
 */
export type ArcGISAttachmentViewer = {
  /**
    * Given a ArcGIS Feature Server, look up the provided object IDs and fetch all of their 
    * attachments.
    */
  kind: 'ArcGIS Attachment Viewer',
  /**
   * An identifier for this type of attachment viewer
   */
  targetField: TargetField,
  content: RichText | ConditionalContentList,
  /**
   * The root ArcGIS feature server API endpoint for a specific layer. The format of this value will typically end with /arcgis/rest/services/serviceName/FeatureServer/0/
   */
  featureServerLayerUrl: string,
  /**
   * A comma-separated string of object IDs to look up in the layer
   */
  objectIds: string | TargetFieldObj
}

export type ImagePreview = {

  kind: 'Image Preview',

  /**
   * the URL or Base64 encoded image (e.g. "data:image/png;base64,[IMAGE_CONTENT_STRING]")
   *
   * @name Source
   * @width 4
   */
  imageSrc: string,

  /**
   *
   * If you want to fine tune the image size at all, 
   * you can control the height and width here.
   *
   * NOTE: this won't override the question's allowed size,
   * it will only allow you to make an image smaller.
   * Therefore, its of limited utility for now.
   *
   * @name Dimensions
   * @width 4
   */
  dimensions?: 'auto' | {
    height?: string,
    width?: string
  }
}

export type AIAssistantReviewSurvey = {

  /**
   * Used to produce LLM-generated questions and answers
   * to screenshots provided either manually or automagically.
   *
   * When added, a new CRON job will run that produces these surveys,
   * which then need to be pulled in using the "Generate AI Review Survey" button.
   *
   * @width 4
   */
  kind: 'AI Assistant Review Survey',

  /**
   * How many questions to generate per page.
   * 
   * The questions are going to be based on the page's contents,
   * from the perspective of an applicant with no working knowledge of 
   * the survey the pages represent.
   *
   * @width 2
   * @name Questions per Page
   */
  questionsPerPage: number,

  /**
   * How many answers will be generated per question of each page.
   *
   * @name Answers per Question
   * @width 2
   */
  answersPerQuestion?: number,

  /**
   * Screenshots of a survey that you wish to 
   * have questions and answers generated for.
   *
   * The screenshots themselves should be in PNG format.
   *
   * If you want to ensure screenshots are considered chronologically,
   * Ensure that the Last Modified metadata of the S3 records reflect that.
   *
   * @name Screenshots
   * @width 4
   */
  screenshots: {
    /**
     * The S3 bucket the screenshots are contained.
     * Defaults to aidkit-documents.
     * @width 4
     */
    bucket?: string,
    /**
     *
     * The S3 path prefix that contains all of the Screenshot PNGs.
     * The program name will preceed this, so do not include it.
     *
     * @name S3 Screenshot Prefix
     * @width 4
     */
    prefix: string,
  },

  /**
   * The OpenAI Model to use for all models.
   * defaults to gpt-4o-mini
   *
   * @width 4
   */
  model?: string
}
