import { v4 } from 'uuid';
export type Author = {
  uid?: string,
  name?: string,
  tabId?: string,
  tags?: string[]
};

export type Patch = {
  op: 'patch',
  id: string,
  value: string | number | boolean | null | undefined,
  prev_gen: number,
  deleted?: boolean,
  rev?: number,
  author?: Author
}

type Loc = {
  kind: 'array',
  id: string,
  sortKey: SortKey 
} | {
  kind: 'object',
  id: string,
  prop: string
}

export type Move = {
  op: 'move',
  id: string,
  loc?: Loc,
  prev_gen: number,
  rev?: number,
  author?: Author 
}

export type ActiveEditor = {
  uid: string,
  name: string,
  tabId: string,
  lastEditTime: number
}

export type ObjectState = {
  kind: 'array' | 'record'
  locations: Move[]
}

export type PrimitiveState = {
  kind: 'primitive'
  locations: Move[],
  versions: Patch[]
}

/**
 * id: the {@link TrackedObject} ID of the object that the operation effects
 * kind: the type of TrackedObject ('array' | 'record' | 'primitive')
 * op: the operation to apply to the TrackedObject (see {@link Move}, {@link Patch})
 */
export type ChangeSet = ([id: string, kind: string, op: Move | Patch])[];

export type UserActivity = {
  lastEditedObject: string,
  uid: string,
  name: string,
  tabId: string
}

export type State = {
  currentAuthor?: Author,
  // map from userID/tabId to the user Activity for other collab editors
  userToActivity?: Record<string, UserActivity>,
  // map from objectID to the users currectly editing the object.
  objectToActivity?: Record<string, UserActivity[]>,
  // where I am on the document - used to send to others when I move around
  myActivity?: UserActivity,
  objects: Record<string,  ObjectState | PrimitiveState>
  workingSet: Record<string, TrackedObject>
  currentRevision: number,
    
  changeSetOpen: number,
  undoOffset?: number,
  bufferedChanges?: ChangeSet,
  undoStack: ChangeSet[],
  redoStack: ChangeSet[],

  unpublishedChanges?: ChangeSet,
  // replaying means we are loading up a bunch of changes that have already been made, 
  // not taking in new changes that are happening in real time.
  replaying?: boolean,

  debug?: boolean,
    
  log?: (events: ChangeSet, replaying?: boolean) => void
}

export function reduceEvents(left: ChangeSet, right: ChangeSet): ChangeSet {
  const moves: Record<string, [string, string, Move]> = {};
  const patches: Record<string, [string, string, Patch]> = {};
  const root: ChangeSet = [];

  for (const [id, kind, op] of [...left, ...right]) {
    if (id === 'root') {
      root.push([id, kind, op]);
    } else if (op.op === 'move' && (!moves[id] || moves[id][2].prev_gen < op.prev_gen)) {
      moves[id] = [id, kind, op];
    } else if (op.op === 'patch' && (!patches[id] || patches[id][2].prev_gen < op.prev_gen)) {
      patches[id] = [id, kind, op];
    }
  }

  return [...root, ...Object.values(moves), ...Object.values(patches)];
}

export function getCompressedEvents(state: State): ChangeSet {
  // If there's a root we can safely prune
  if (state.objects['root']) {
    // Find the reachable set of objects
    const [obj,] = ReconstructState(state);

    const reachable = new Set<string>();
    const toVisit = [obj];
    while (toVisit.length) {
      const n = toVisit.pop()!;
      reachable.add(n.id);

      if (Array.isArray(n.value)) {
        n.value.forEach(v => toVisit.push(v))
      } else if (typeof n.value === 'object' && n.value !== null) {
        Object.values(n.value).forEach(v => toVisit.push(v as TrackedObject));
      }
    }

    // Find the set of objects that have no parents
    const unreachable = new Set<string>();
    for (const id in state.objects) {
      if (!reachable.has(id)) {
        unreachable.add(id);
        const currObj = state.objects[id];

        const latestLoc = currObj.locations.reduce((a, b) => a.prev_gen > b.prev_gen ? a : b, currObj.locations[0]);
        // If locations is empty, delete object bc it is hanging in outer space
        if (!latestLoc) {
          delete state.objects[id];
        }
        // If there is a location, that means that it's parented by the actual thing that was moved out/deleted so we can safely delete it
        else if (latestLoc.loc) {
          delete state.objects[id];
        }
      }
    }
    console.log('Pruning unreachable', unreachable.size);
  }

  const events: ChangeSet = [];
  for (const id in state.objects) {
    const obj = state.objects[id];
    const latestLoc = obj.locations.reduce((a, b) => a.prev_gen > b.prev_gen ? a : b, obj.locations[0]);
    events.push([id, obj.kind, latestLoc]);
    if (obj.kind === 'primitive') {
      const latestValue = obj.versions.reduce((a, b) => a.prev_gen > b.prev_gen ? a : b, obj.versions[0]);
      events.push([id, obj.kind, latestValue]);
    }   
  }

  console.log("Compressed event count", events.length);
  return events;
}

/**
 * @param onStateChangeCallback: (optional) - if passed in, this function will be called
 * whenever the State.log() function is called (which is anytime a TrackedObject is mutated)
 * provided that the State.changeSetOpen value is > 0.
 */
export function reconstructStateFromEvents(events: ChangeSet, debug?: boolean, onStateChangeCallback?: Parameters<typeof createInitialState>[0], isInitialLoad?: boolean): [TrackedObject, State] {
  const state = createInitialState(onStateChangeCallback);
  state.replaying = isInitialLoad;
  state.debug = debug;
  return mergeStateFromEvents(state, events);
}

export function mergeStateFromEvents(state: State, events: ChangeSet): [TrackedObject, State] {
  for (const [id, kind, op] of events) {
    state.objects[id] = state.objects[id] || (
      kind === 'primitive' ?
        {kind: 'primitive', locations: [], versions: []}
        : {kind, locations: []});
    // Root has no ops on it
    if (id !== 'root') {
      if (op.op === 'move') {
        state.objects[id].locations.push(op as Move);
      } else if (op.op === 'patch' && kind === 'primitive') {
        (state.objects[id] as PrimitiveState).versions.push(op as Patch);
      }
    }   
  }

  return ReconstructState(state);
}

export function updateUserActivity(state: State, newUserActivityList: UserActivity[]): State {
  for (const newUserActivity of newUserActivityList) {
    const lastActivityFromUser = state.userToActivity?.[newUserActivity.uid + '/' + newUserActivity.tabId];
    if (lastActivityFromUser && lastActivityFromUser.lastEditedObject === newUserActivity.lastEditedObject) {
      return state;
    }
  
    if (!state.userToActivity) {
      state.userToActivity = {};
    }
    if (!state.objectToActivity) {
      state.objectToActivity = {};
    }
    // update user activity map with the new location for the user
    state.userToActivity[newUserActivity.uid + '/' + newUserActivity.tabId] = newUserActivity;
  
    // remove user from previous location
    if (lastActivityFromUser) {
      state.objectToActivity[lastActivityFromUser?.lastEditedObject] = (state.objectToActivity[lastActivityFromUser?.lastEditedObject] || [])
        .filter(ua => ua.uid !== newUserActivity.uid || ua.tabId !== newUserActivity.tabId);
    }
    state.objectToActivity[newUserActivity.lastEditedObject] = [...(state.objectToActivity[newUserActivity.lastEditedObject] || []), newUserActivity];
  }

  return state;
}

export function debugIDs(state: State, id?: string, depth?: number) {
  id = id || 'root';
  depth = depth || 0;
  const indent = ' '.repeat(depth * 2);
  const obj = state.objects[id];
  const objW = state.workingSet[id];
  if (obj) {
    console.log(indent + id + ' ' + obj.kind);
    if (obj.kind === 'array') {
      for (let i = 0; i < objW.value.length; i++) {
        debugIDs(state, objW.value[i].id, depth + 1);
      }
    } else if (obj.kind === 'record') {
      for (let prop in objW.value) {
        debugIDs(state, objW.value[prop].id, depth + 1);
      }
    }
  } 
}

// TODO: this function doesn't seem to be used at this point 2024-07-17
export function trackExternalMutation(state: State, fn: (obj: any) => void) {
  const objCache = new WeakMap();
  const primitiveMap = {} as Record<string, TrackedObject>;

  function readMapped(obj: TrackedObject, parent?: TrackedObject, location?: number | string): any {
    if (Array.isArray(obj.value)) {
      const toReturn = obj.value.map((v, i) => readMapped(v, obj, i));
      objCache.set(toReturn, obj);
      return toReturn;
    } else if (typeof obj.value === 'object') {
      const toReturn = Object.keys(obj.value).reduce((a, b) => ({...a, [b]: readMapped(obj.value[b], obj, b)}), {});
      objCache.set(toReturn, obj);
      return toReturn
    } else {
      if (parent) {
        if (typeof location === 'number') {

        }
        if (typeof location === 'string') {
          primitiveMap[parent.id + ':' + location] = obj;
        }
      }
      return obj.value;
    }
  }

  const obj = readMapped(state.workingSet.root);
  const mutated = fn(obj);

}

export function undoChangeset(state: State) {
  // Find the set of all 
  const cs = state.undoStack.at(state.undoOffset === undefined ? -1 : state.undoOffset);
  // We're going to add a new changeset so we actually need to shift back two changesets
  state.undoOffset = (state.undoOffset === undefined ? -1 : state.undoOffset) - 2;
  if (cs) {
    state.redoStack.push(cs);

    // Group by object
    const byObject = {} as Record<string, ChangeSet>;
    for (const op of cs) {
      byObject[op[0]] = [...byObject[op[0]] || [], op];
    }

    state.changeSetOpen += 1;
    state.undoStack.push([]);

    // For each object, find previous location and value
    for (const id in byObject) {
      const ops = byObject[id];
      const locations = state.objects[id].locations;

      const moves = ops.map(o => o[2]).filter(op => op.op === 'move') as Move[];
      if (moves.length > 0) {
        const earliestMove = moves.reduce((a, b) => a.prev_gen < b.prev_gen ? a : b);
        const prevLocations = locations.filter(l => l.prev_gen < earliestMove.prev_gen);
        const maxOfPrevLocations = prevLocations.reduce((a, b) => a.prev_gen > b.prev_gen ? a : b, prevLocations[0]);

        // Remove the object from its current location
        const currentLoc = locations.reduce((a, b) => a.prev_gen > b.prev_gen ? a : b, locations[0])?.loc;
        if (currentLoc?.kind === 'array') {
          const array = state.workingSet[currentLoc.id] as TrackedArray;
          array.remove(array.value.indexOf(state.workingSet[id]));
        }
        if (currentLoc?.kind === 'object') {
          const obj = state.workingSet[currentLoc.id] as TrackedRecord;
          obj.set(currentLoc.prop, state.workingSet[id]);
        }

        // Add the object to its previous location
        const prevLoc = maxOfPrevLocations.loc;
        if (prevLoc?.kind === 'array') {
          const array = state.workingSet[prevLoc.id] as TrackedArray;
          array.insert(prevLoc.sortKey, state.workingSet[id]);
        }
        if (prevLoc?.kind === 'object') {
          const obj = state.workingSet[prevLoc.id] as TrackedRecord;
          obj.set(prevLoc.prop, state.workingSet[id]);
        }
      }

      const patches = ops.map(o => o[2]).filter(op => op.op === 'patch') as Patch[];
      if (patches.length > 0) {
        const earliestPatch = patches.reduce((a, b) => a.prev_gen < b.prev_gen ? a : b);
        const prevPatches = (state.objects[id] as PrimitiveState).versions.filter(l => l.prev_gen < earliestPatch.prev_gen);
        const maxOfPrevPatches = prevPatches.reduce((a, b) => a.prev_gen > b.prev_gen ? a : b, prevPatches[0]);
                 
        // Revert to previous value
        const obj = state.workingSet[id] as TrackedPrimitive;
        obj.set(maxOfPrevPatches.value);
      }
    }

    state.changeSetOpen -= 1;
  }
}

export function redoChangeset(state: State) {
  const cs = state.redoStack.pop();

  state.changeSetOpen += 1;
  state.undoStack.push([]);

  if (cs) {
    cs.sort((a, b) => a[2].prev_gen - b[2].prev_gen);
    for (const op of cs) {
      if (op[2].op === 'patch') {
        const obj = state.workingSet[op[0]] as TrackedPrimitive;
        obj.set(op[2].value);
      }
      if (op[2].op === 'move') {
        throw new Error('Not implemented');
      }
    }
  }

  state.changeSetOpen -= 1;
}

export function openChangeset(state: State) {
  state.changeSetOpen += 1;
  if (state.changeSetOpen == 1) {
    state.undoStack.push([]);
  }
}

export function closeChangeset(state: State) {
  state.changeSetOpen -= 1;
  if (state.changeSetOpen === 0) {
    if (state.bufferedChanges) state.log?.(state.bufferedChanges);

    // Empty buffered changes so that the changeset we pass from distro to distro 
    // can't grow super huge.
    state.bufferedChanges = [];
  }
}

/**
 * @param event: (optional) - if passed in, this function will be called
 * whenever the State.log() function is called (which is anytime a TrackedObject is mutated)
 * provided that the State.changeSetOpen value is > 0.
 */
export function createInitialState(event?: (events: ChangeSet, myActivity?: UserActivity) => void, objects?: State['objects']): State {
  const state: State = {
    objects: objects || {},
    workingSet: {},
    currentRevision: 0,
    changeSetOpen: 0,
    undoStack: [],
    redoStack: [],

    log: (changeset) => {
      state.currentRevision += 1; 
      
      if (!state.replaying) {
        if (!state.unpublishedChanges) {
          state.unpublishedChanges = [];
        }
        state.unpublishedChanges.push(...changeset);
      }

      if (state.changeSetOpen) {
        state.undoStack[state.undoStack.length - 1].push(...changeset);
        if (!state.bufferedChanges) {
          state.bufferedChanges = [];
        }
        state.bufferedChanges.push(...changeset);
      } else {
        state.undoStack.push(changeset);
        if (event) {
          event(changeset, state.myActivity);
        }
      }
    }
  }
  if (objects) {
    ReconstructState(state);
  }
  return state;
}

export type WorkingSet = Record<string, TrackedObject>;

export type Context = {
  state: State,
  workingSet: WorkingSet,
}

export function ReconstructState(local: State, incoming?: State): [TrackedObject, State] {
  incoming = incoming || createInitialState();

  // TODO: Track dirty arrays and records
  const dirty = {} as Record<string, true>;

  // Construct
  for (const key of [...Object.keys(incoming.objects), ...Object.keys(local.objects)]) {
    // If we've never seen this before make new state
    if (!local.objects[key] && incoming.objects[key]) {
      local.objects[key] = incoming.objects[key]
      // Otherwise merge the state into the state for this key
    } else if (incoming.objects[key]) {
      const locs = local.objects[key].locations.concat(incoming.objects[key].locations);
      // Remove duplicate locations
      local.objects[key].locations = [...new Map(locs.map(item => [item.id, item])).values()]

      if (local.objects[key].kind === 'primitive') {
        const vals = (local.objects[key] as PrimitiveState).versions
          .concat((incoming.objects[key] as PrimitiveState).versions);
        // Remove duplicate versions/patches
        (local.objects[key] as PrimitiveState).versions = [...new Map(vals.map(item => [item.id, item])).values()]
      }
    }

    const obj = local.objects[key];

    if (obj.kind === 'primitive') {
      const value = obj.versions.reduce<{ max: number, cur: any }>(
        (prev, cur) => (cur.prev_gen > prev.max ? 
          { max: cur.prev_gen, cur: cur.value } 
          : prev), 
        { max: -1, cur: undefined }).cur;

      // If this is new, set up the tracked object otherwise just update the value
      if (!local.workingSet[key]) {
        local.workingSet[key] = new TrackedPrimitive(local, key, value);
      } else {
        if (local.workingSet[key].value !== value) {
          local.workingSet[key].value = value;
          dirty[key] = true;
        }
      }
    }
    if (obj.kind === 'array') {
      if (!local.workingSet[key]) {
        local.workingSet[key] = new TrackedArray(local, key, [])
      }
    }
    if (obj.kind === 'record') {
      if (!local.workingSet[key]) {
        local.workingSet[key] = new TrackedRecord(local, key, {});
      }
    }
  }

  // Associate 
  const objProps = {} as Record<string, string[]>;
  for (const key of Object.keys(local.objects)) {
    const obj = local.objects[key];

    const location = obj.locations.reduce<{ max: number, cur?: Loc }>(
      (prev, cur) => (cur.prev_gen > prev.max ? 
        { max: cur.prev_gen, cur: cur.loc } 
        : prev),
      { max: -1, cur: undefined });

    if (location.cur) {
      if (location.cur.kind === 'array') {
        local.workingSet[key].sortKey = location.cur.sortKey;

        if (local.workingSet[key].parent?.value) {
          local.workingSet[key].parent!.value = local.workingSet[key].parent!.value
            .filter((v: TrackedObject) => v !== local.workingSet[key]);
        }
        local.workingSet[key].parent = local.workingSet[location.cur.id];

        // If the parent does not exist, it must have been deleted by and earlier event. 
        // We do not want to throw an error here.
        if (local.workingSet[location.cur.id]?.value) {
          local.workingSet[location.cur.id].value.push(local.workingSet[key]);
        } else {
          if (local.debug) {
            console.log('COLLAB DEBUG');
            console.log(`This object (${JSON.stringify(local.workingSet[key].read())}, ${key}) does not have a parent. Alleged parent: ${location.cur.id}`);
            console.log(`This was intentionally ignored.`);
          }
        }
      } else if (location.cur.kind === 'object') {
        objProps[location.cur.id + location.cur.prop] ||= [];
        const op = objProps[location.cur.id + location.cur.prop];

        if (local.workingSet[key].parent) {
          for (const k of Object.keys(local.workingSet[key].parent!.value)) {
            if (local.workingSet[key].parent!.value[k] === local.workingSet[key]) {
              delete local.workingSet[key].parent!.value[k];
            }
          }
        }

        // Same as above, we might not have received an event that creates the parent
        // ie, the parent was deleted, and we will not worry about this child node.
        if (local.workingSet[location.cur.id]?.value && 
                    (op.length === 0 || op.every(k => key < k))) {
          local.workingSet[location.cur.id].value[location.cur.prop] = local.workingSet[key];
          local.workingSet[key].parent = local.workingSet[location.cur.id];
        }
        op.push(key)
      }
    } else {
      // Handle the removal case
      if (local.workingSet[key].parent) {
        const p = local.workingSet[key].parent!;
        local.workingSet[key].parent = undefined;
        if (Array.isArray(p.value)) {
          p.value = p.value.filter((v: TrackedObject) => v !== local.workingSet[key]);
        } else if (typeof p!.value === 'object') {
          for (const k of Object.keys(p.value)) {
            if (p.value[k] === local.workingSet[key]) {
              delete p.value[k];
            }
          }
        } else {
          throw new Error('Invalid parent');
        }
      }
    }
  }

  // Sort and remove duplicates
  for (const key of Object.keys(local.objects)) {
    const obj = local.objects[key];
    if (obj.kind === 'array') {
      local.workingSet[key].value.sort((a: TrackedObject, b: TrackedObject) => 
        SortFunction(a.sortKey!, b.sortKey!));

      local.workingSet[key].value = local.workingSet[key].value.reduce(
        (a: TrackedObject[], b: TrackedObject) => {
          if (a && a.slice(-1)[0]?.id === b.id) {
            return a;
          }
          a.push(b);
          return a;
        }, []);

    }
  }

  return [local.workingSet['root'], local]
}

export type TrackedObject = TrackedArray | TrackedPrimitive | TrackedRecord;

export type SortKey = number[];
export function SortFunction(a: SortKey, b: SortKey) {
  let depth = 0;
  while (a[depth] !== undefined && a[depth] === b[depth]) {
    depth += 1;
  }
  if (a[depth] !== undefined && b[depth] !== undefined) {
    if (a[depth] === b[depth]) return 0;
    return a[depth] > b[depth] ? 1 : -1;
  } else if (a[depth] !== undefined) {
    return 1;
  } else {
    return -1;
  }
  return 0;
}

function mulberry32(a: number) {
  return function() {
    var t = a += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  }
}

export function generateUserLocation(author: Author, objID: string) : UserActivity {
  return {
    uid: author.uid!,
    name: author.name || author.uid!,
    tabId: author.tabId || '',
    lastEditedObject: objID
  }
}

// Use a deterministic PRNG in test for reproducibility
// In production/elsewhere we want to ensure that we have as much random diversity
// to that two clients don't resolve the same random number.
const rng = typeof process ? (process.env.NODE_ENV === 'test' ? mulberry32(0) : Math.random) : Math.random;

export function MakeKey(before?: SortKey, after?: SortKey) {
  if (!before && !after) {
    return [Math.round(10000*rng())]
  } else if (!before && after) {
    return [after[0] - Math.round(10000*rng()) - 1]
  } else if (before && !after) {
    return [before[0] + Math.round(10000*rng()) + 1];
  } else if (before && after) {
    let depth = 0;
    let out = [];
    while (before[depth] === after[depth]) {
      out.push(before[depth])
      depth += 1;
    }
    if (before[depth] !== undefined && after[depth] !== undefined) {
      // If before is longer, return before with the last element incremebted
      if (before.length > after.length) {
        while (depth !== before.length - 1) {
          out.push(before[depth])
          depth += 1;
        }
        out.push(before[depth] + Math.round(10000*rng()) + 1)
        // If there's no more room, bump down a level
      } else if (before[depth] + 1 === after[depth]) {
        out.push(before[depth]);
        out.push((before[depth + 1] ? before[depth + 1] : 0) + Math.round(10000*rng() + 1));
        // Split the difference (with some randomness)
      } else {
        let diff = after[depth] - before[depth];
        out.push(before[depth] + Math.round(diff*(0.5 + (rng() - 0.5)*0.3)));
      }
    } else if (before[depth] !== undefined && !after[depth]) {
      out.push(before[depth] + Math.round(10000*rng()) + 1)
    } else if (!before[depth] && after[depth] !== undefined) {
      out.push(after[depth] - Math.round(10000*rng()) - 1)
    }
    const outString = JSON.stringify(out)
    if (JSON.stringify(before) === outString || JSON.stringify(after) === outString) {
      throw new Error('Bad Key Split: ' + JSON.stringify([before, after, out]))
    }
    return out;
  }
  throw Error('Unreachable');
}

export class TrackedArray {
  id: string
  value: TrackedObject[]
  state: State
  parent?: TrackedObject
  sortKey?: SortKey
  newlyAdded?: boolean
  constructor (state: State, id?: string, value?: TrackedObject[]) {
    this.id = id || 'id' + Math.random();
    this.state = state
    this.value = [];
    if (value && value.length) {
      // Janky heuristic to see if the values have a location set already
      if (state.objects[value[0].id].locations.length == 0) {
        value.map((v, i) => {
          if (state.objects[v.id].locations.length == 0) {
            this.insert(i, v);
          }
        });
      } else {
        this.value = value;
      }
    }
    if (!state.objects[this.id]) {
      state.objects[this.id] = {
        kind: 'array',
        locations: []
      }
    }
    state.workingSet[this.id] = this;
  }
  locateUserHere(): void {
    // update myActivity so other distro users can see what object I just added.
    if (this.state.currentAuthor?.uid) {
      this.state.myActivity = generateUserLocation(this.state.currentAuthor, this.id);
    }
  }
  insert(index: number | SortKey, value: TrackedArray | TrackedPrimitive | TrackedRecord, silent?: true): void {
    // TODO: Remove from somewhere else
    const v = (this.state.objects[value.id] as PrimitiveState).locations;
    if (typeof index === 'number') {
      value.sortKey = MakeKey(this.value[index - 1]?.sortKey, this.value[index]?.sortKey);
    } else { 
      value.sortKey = index;
      index = this.value.filter((v) => SortFunction(v.sortKey!, index as SortKey) < 0).length;
    }
    const op: Move = {
      op: 'move',
      id: Math.random().toString(),
      loc: {
        kind: 'array',
        id: this.id,
        sortKey: value.sortKey
      },
      prev_gen: Math.max(0, ...v.map(p => p.prev_gen + 1)),
      author: this.state.currentAuthor
    };
    v.push(op);
    this.value.splice(index, 0, value);
    value.parent = this;
    if (!silent) this.state.log?.([[value.id, this.state.objects[value.id].kind, op]]);
  }
  remove(index: number, silent?: true): void {
    let toRemove = this.value[index];
    const v = (this.state.objects[toRemove.id] as PrimitiveState).locations;
    const op: Move = {
      op: 'move',
      id: Math.random().toString(),
      loc: undefined,
      prev_gen: Math.max(0, ...v.map(p => p.prev_gen + 1)),
      author: this.state.currentAuthor
    };
    v.push(op);
    toRemove.parent = undefined;
    this.value.splice(index, 1)
    if (!silent) this.state.log?.([[toRemove.id, this.state.objects[toRemove.id].kind, op]]);
  }
  read(includeKeys?: boolean): any {
    return this.value.map(v => v.read(includeKeys))
  }
}

export class TrackedPrimitive {
  id: string
  value: any
  state: State
  parent?: TrackedObject
  sortKey?: SortKey
  newlyAdded?: boolean
  constructor (state: State, id?: string, value?: any) {
    this.id = id || 'id' + Math.random();
    this.state = state

    if (!state.objects[this.id]) {
      state.objects[this.id] = {
        kind: 'primitive',
        locations: [],
        versions: []
      }
    }

    if (id && value !== undefined) {
      this.value = value;
    } else {
      state.objects[this.id] = {
        kind: 'primitive',
        locations: [],
        versions: []
      }
      this.value = undefined
      if (value !== undefined) {
        this.set(value);
      }
    }
    state.workingSet[this.id] = this;
  }
  locateUserHere(): void {
    // update myActivity so other distro users can see what object I just added.
    if (this.state.currentAuthor?.uid) {
      this.state.myActivity = generateUserLocation(this.state.currentAuthor, this.id);
    }
  }
  set(value: any, silent?: true): void {
    const v = (this.state.objects[this.id] as PrimitiveState).versions;
    const op: Patch = {
      op: 'patch',
      id: Math.random().toString(),
      value: value,
      prev_gen: Math.max(0, ...v.map(p => p.prev_gen + 1)),
      author: this.state.currentAuthor

    };
    v.push(op);
    this.value = value
    if (!silent) this.state.log?.([[this.id, this.state.objects[this.id].kind, op]]);
  }
  read(includeIds?: boolean): any {
    // As of 8/12/2024 we only care about object IDs if this is the TrackedPrimitive of the string of a content object,
    // because that's what is inline editable.
    // This catches all Text and RichText
    const possiblyRichText = this.parent?.value ?? {};
    // This catches all plain strings inside a content/label property
    const possiblyContentOrLabel = this.parent?.parent?.value ?? {};
    if (includeIds && ('en' in possiblyRichText || 'content' in possiblyContentOrLabel || 'label' in possiblyContentOrLabel)) {
      const v = (this.state.objects[this.id] as PrimitiveState).versions;
      return {
        // This information is necessary to create operations for this TrackedObject
        // outside of the normal Distro context (e.g. for inline editing)
        _trackedObjMeta: {
          id: this.id,
          prev_gen: Math.max(0, ...v.map(p => p.prev_gen + 1)),
          currentAuthor: this.state.currentAuthor,
          userActivity: this.state.objectToActivity?.[this.id]
        },
        value: this.value
      };
    }
    return this.value;
  }
}

export class TrackedRecord {
  id: string
  value: Record<string, any>
  state: State
  parent?: TrackedObject
  sortKey?: SortKey
  newlyAdded?: boolean
  constructor (state: State, id?: string, value?: Record<string, TrackedObject>) {
    this.id = id || 'id' + Math.random();
    this.state = state
    this.value = value || {}
    if (value) {
      Object.keys(value).map(k => {
        if (state.objects[value[k].id].locations.length == 0) {
          this.set(k, value[k])
        }
      });
    }
    if (!state.objects[this.id]) {
      state.objects[this.id] = {
        kind: 'record',
        locations: []
      }
    }
    state.workingSet[this.id] = this;
  }
  set(key: string, value: TrackedArray | TrackedPrimitive | TrackedRecord, silent?: true): void {
    // TODO: Remove from somewhere else

    const v = (this.state.objects[value.id] as PrimitiveState).locations;
    const op: Move = {
      op: 'move',
      id: Math.random().toString(),
      loc: {
        kind: 'object',
        id: this.id,
        prop: key
      },
      prev_gen: Math.max(0, ...v.map(p => p.prev_gen + 1)),
      author: this.state.currentAuthor
    };
    v.push(op);
    this.value[key] = value;
    value.parent = this;
    if (!silent) this.state.log?.([[value.id, this.state.objects[value.id].kind, op]]);
  }
  remove(key: string, silent?: true): void {
    if (!this.value[key]?.id) return;
    const id = this.value[key].id;
    const v = (this.state.objects[this.value[key].id] as PrimitiveState).locations;
    const op: Move = {
      op: 'move',
      id: Math.random().toString(),
      loc: undefined,
      prev_gen: Math.max(0, ...v.map(p => p.prev_gen + 1)),
      author: this.state.currentAuthor

    }
    v.push(op);
    this.value[key].parent = undefined;
    delete this.value[key];
    if (!silent) this.state.log?.([[id, this.state.objects[id].kind, op]]);
  }
  read(includeIds?: boolean): Record<string, any> {
    return Object.keys(this.value).reduce((acc: Record<string, any>, k) => {
      const readValue = this.value[k].read(includeIds);
      if (typeof readValue === 'object' && '_trackedObjMeta' in readValue) {
        acc['_trackedObjInfo'] ||= {};
        acc['_trackedObjInfo'][k] = readValue['_trackedObjMeta'];
        acc[k] = readValue.value;
      } else {
        acc[k] = readValue;
      }
      return acc;
    }, {});
  }
  locateUserHere(): void {
    // update myActivity so other distro users can see what object I just added.
    if (this.state.currentAuthor?.uid) {
      this.state.myActivity = generateUserLocation(this.state.currentAuthor, this.id);
    }
  }
}

export function createFromRawValue(state: State, value: any, isRoot?: true, needsNewId?: true): TrackedObject {
  openChangeset(state);
  try {
    if (Array.isArray(value)) {
      return new TrackedArray(state, isRoot ? 'root' : undefined, value.map(v => createFromRawValue(state, v)))
    }
    if (typeof value === 'object' && value !== null) {
      return new TrackedRecord(state, isRoot ? 'root' : undefined, Object.keys(value).reduce((acc, k) => {
        acc[k] = createFromRawValue(state, needsNewId && k === 'id' ? v4() : value[k]);
        return acc;
      }, {} as Record<string, TrackedObject>))
    }
    return new TrackedPrimitive(state, isRoot ? 'root' : undefined, value)
  } finally {
    closeChangeset(state);
  }
}
