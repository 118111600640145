import * as OutlineIcons from '@heroicons/react/24/outline';
import * as SolidIcons from '@heroicons/react/24/solid';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Icon } from '../../Icon';
export const getIconDataUrl = (() => {
    const cache = new Map();
    return (options) => {
        const cacheKey = `${options.name}_${options.size || '16'}_${options.fillColor || 'currentColor'}_${options.strokeColor || 'currentColor'}`;
        if (cache.has(cacheKey)) {
            return cache.get(cacheKey);
        }
        const url = generateIconDataUrl(options);
        if (url) {
            cache.set(cacheKey, url);
        }
        return url;
    };
})();
const renderIconToSvg = (options) => {
    const { name, size = 16, fillColor = 'currentColor', strokeColor = 'currentColor' } = options;
    if (!isValidIconName(name))
        return null;
    const iconElement = React.createElement(Icon, {
        name,
        size: 'md',
        color: fillColor,
        role: 'decorative',
        type: 'solid'
    });
    let renderedHtml = ReactDOMServer.renderToString(iconElement);
    if (name !== 'AidKitIcon') {
        if (renderedHtml.includes('stroke=')) {
            renderedHtml = renderedHtml.replace(/stroke="[^"]*"/, `stroke="${strokeColor}"`);
        }
        else {
            renderedHtml = renderedHtml.replace(/<svg/, `<svg stroke="${strokeColor}"`);
        }
        if (renderedHtml.includes('stroke-width=')) {
            renderedHtml = renderedHtml.replace(/stroke-width="[^"]*"/, 'stroke-width="2"');
        }
        else {
            renderedHtml = renderedHtml.replace(/<svg/, '<svg stroke-width="2"');
        }
    }
    renderedHtml = renderedHtml.replace(/width="[^"]*"/, `width="${size}"`);
    renderedHtml = renderedHtml.replace(/height="[^"]*"/, `height="${size}"`);
    return renderedHtml;
};
const svgToDataUrl = (svgString) => {
    const encoded = encodeURIComponent(svgString);
    return `data:image/svg+xml;charset=utf-8,${encoded}`;
};
const generateIconDataUrl = (options) => {
    const svgString = renderIconToSvg(options);
    if (!svgString)
        return null;
    return svgToDataUrl(svgString);
};
const validIconNames = (() => {
    const set = new Set();
    Object.keys(SolidIcons).forEach(key => set.add(key));
    Object.keys(OutlineIcons).forEach(key => set.add(key));
    set.add('AidKitIcon');
    return set;
})();
const isValidIconName = (name) => {
    return validIconNames.has(name);
};
