import { boundingExtent, extend } from "ol/extent";
import { GeoJSON } from "ol/format";
import 'ol/ol.css';
import { DEFAULT_GEOJSON_IMPORT_PROJECTION, DEFAULT_PROJECTION } from "./constants";
export const getBoundingExtent = (features) => {
    if (features.length === 0)
        return null;
    const extent = features.reduce((acc, feature) => {
        const geometry = feature.getGeometry();
        return geometry ? extend(acc, geometry.getExtent()) : acc;
    }, boundingExtent([]));
    return extent.length === 4 && extent.every((v) => !isNaN(v)) ? extent : null;
};
export const detectCRS = (geojsonData) => {
    if (geojsonData.crs && geojsonData.crs.properties && geojsonData.crs.properties.name) {
        return geojsonData.crs.properties.name;
    }
    return DEFAULT_GEOJSON_IMPORT_PROJECTION;
};
export const readFeaturesWithProjection = (geojsonData) => {
    const sourceCRS = detectCRS(geojsonData);
    return new GeoJSON().readFeatures(geojsonData, {
        dataProjection: sourceCRS,
        featureProjection: DEFAULT_PROJECTION,
    });
};
