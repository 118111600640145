import { useRef, useCallback, useEffect, useState } from "react";

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay: number = 250,
  deps: any[] = []
) {
  const timeoutHandler = useRef<NodeJS.Timeout | null>(null);
  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);

      timeoutHandler.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay, ...deps]
  );
  return debouncedFn;
}

/** 
 * Works similar to useMemo except that the recalculation will be debounced by the delay parameter.
 * This is useful for mitigating the performance effects of
 * expensive recalculations that are based on quickly-changing variables.
 */
export function useDebouncedMemo<T>(
  factory: () => T,
  delay: number = 250,
  deps: any[] = []
) {
  const [value, setValue] = useState<T>(factory());
  const timeoutHandler = useRef<NodeJS.Timeout | null>(null);
  const debouncedFn = useCallback(
    () => {
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);

      timeoutHandler.current = setTimeout(() => {
        setValue(factory());
      }, delay);
    },
    [factory, delay, ...deps]
  );
  useEffect(()=>{
    debouncedFn()
    return () => {
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);
    };
  }, [debouncedFn])
  return value;
}
