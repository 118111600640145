import { easeOut } from "ol/easing";
import { getCenter } from "ol/extent";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import 'ol/ol.css';
import { fromLonLat, transformExtent } from "ol/proj";
import { Circle, Fill, Icon, RegularShape, Stroke, Style } from "ol/style";
import View from "ol/View";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_EXTENT, DEFAULT_MAP_POINT_CENTER, DEFAULT_POINT_STROKE, DEFAULT_PROJECTION, DEFAULT_SIZE_CIRCLE, DEFAULT_SIZE_SQUARE, DEFAULT_SIZE_STAR, DEFAULT_SIZE_TRIANGLE, DEFAULT_ZOOM_LEVEL, detectCRS, getBoundingExtent, readFeaturesWithProjection } from "../data";
import { pinDataUrl } from "../icons";
import { getIconDataUrl } from "./icons";
const iconCache = new Map();
const colorCache = new Map();
const styleWeakMap = new WeakMap();
export const animatePinDrop = (feature, map) => {
    const geometry = feature.getGeometry();
    if (!(geometry instanceof Point))
        return;
    const finalCoordinates = [...geometry.getCoordinates()];
    const mapResolution = map.getView().getResolution() ?? 1;
    const startY = finalCoordinates[1] + 75 * mapResolution;
    const pinStyle = feature.get('pinStyle') || new Style({
        image: new Icon({
            src: pinDataUrl,
            scale: 1.5,
            anchor: [0.5, 1],
            crossOrigin: "anonymous",
        }),
    });
    feature.set('pinStyle', pinStyle);
    feature.setStyle(pinStyle);
    geometry.setCoordinates([finalCoordinates[0], startY]);
    let start;
    const duration = 250;
    let animationFrameId;
    const existingAnimation = feature.get('animationFrameId');
    if (existingAnimation) {
        cancelAnimationFrame(existingAnimation);
    }
    const animate = (timestamp) => {
        if (!start)
            start = timestamp;
        const elapsed = timestamp - start;
        const progress = Math.min(elapsed / duration, 1);
        const easedProgress = easeOut(progress);
        const newY = startY - easedProgress * (startY - finalCoordinates[1]);
        geometry.setCoordinates([finalCoordinates[0], newY]);
        if (progress < 1) {
            animationFrameId = requestAnimationFrame(animate);
            feature.set('animationFrameId', animationFrameId);
        }
        else {
            geometry.setCoordinates(finalCoordinates);
            feature.unset('animationFrameId');
        }
    };
    animationFrameId = requestAnimationFrame(animate);
    feature.set('animationFrameId', animationFrameId);
};
const addTransparency = (color, alpha = 0.3) => {
    const cacheKey = `${color}_${alpha}`;
    if (colorCache.has(cacheKey)) {
        return colorCache.get(cacheKey);
    }
    let result;
    if (color.startsWith("#")) {
        const hex = color.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        result = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    else {
        const canvas = document.createElement('canvas');
        canvas.width = 1;
        canvas.height = 1;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, 1, 1);
        const [r, g, b] = ctx.getImageData(0, 0, 1, 1).data;
        result = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    colorCache.set(cacheKey, result);
    return result;
};
export const styleFunction = ({ feature, colorMapping, defaultFillColor = "#fb2c36", defaultStrokeColor = "#9f0712", pointConfig = { size: 4, shape: 'circle' }, }) => {
    if (!(feature instanceof Feature)) {
        return new Style({
            fill: new Fill({ color: defaultFillColor }),
            stroke: new Stroke({ color: defaultStrokeColor }),
        });
    }
    if (styleWeakMap.has(feature)) {
        return styleWeakMap.get(feature);
    }
    const geometryType = feature.getGeometry()?.getType();
    let featureFillColor = defaultFillColor;
    let featureStrokeColor = defaultStrokeColor;
    if (colorMapping) {
        const mapping = colorMapping?.find(m => feature.get(m.property) !== undefined);
        const propertyValue = mapping ? feature.get(mapping.property) : undefined;
        const colorMappingEntry = mapping?.values.find(entry => entry.key === propertyValue);
        if (colorMappingEntry?.fill) {
            featureFillColor = colorMappingEntry.fill;
        }
        if (colorMappingEntry?.stroke) {
            featureStrokeColor = colorMappingEntry.stroke;
        }
    }
    let style;
    if (geometryType === "Point") {
        let pointStyle;
        if (pointConfig?.shape === "circle") {
            pointStyle = new Circle({
                radius: pointConfig.size ?? DEFAULT_SIZE_CIRCLE,
                fill: new Fill({ color: featureFillColor }),
                stroke: new Stroke({ color: featureStrokeColor, width: DEFAULT_POINT_STROKE }),
            });
        }
        else if (pointConfig?.shape === "triangle") {
            pointStyle = new RegularShape({
                points: 3,
                radius: pointConfig.size ?? DEFAULT_SIZE_TRIANGLE,
                fill: new Fill({ color: featureFillColor }),
                stroke: new Stroke({ color: featureStrokeColor, width: DEFAULT_POINT_STROKE }),
            });
        }
        else if (pointConfig?.shape === "star") {
            pointStyle = new RegularShape({
                points: 5,
                radius: pointConfig.size ?? DEFAULT_SIZE_STAR,
                radius2: (pointConfig.size ?? DEFAULT_SIZE_STAR) / 2,
                angle: 0,
                fill: new Fill({ color: featureFillColor }),
                stroke: new Stroke({ color: featureStrokeColor, width: DEFAULT_POINT_STROKE }),
            });
        }
        else if (pointConfig?.shape === "square") {
            pointStyle = new RegularShape({
                points: 4,
                radius: pointConfig.size ?? DEFAULT_SIZE_SQUARE,
                angle: Math.PI / 4,
                fill: new Fill({ color: featureFillColor }),
                stroke: new Stroke({ color: featureStrokeColor, width: DEFAULT_POINT_STROKE }),
            });
        }
        else if (pointConfig?.shape) {
            const iconCacheKey = `${pointConfig.shape}_${featureFillColor}`;
            let iconUrl = iconCache.get(iconCacheKey);
            if (!iconUrl) {
                iconUrl = getIconDataUrl({ name: pointConfig.shape, size: pointConfig.size ?? 16, fillColor: featureFillColor, strokeColor: featureStrokeColor });
                if (iconUrl) {
                    iconCache.set(iconCacheKey, iconUrl);
                }
            }
            if (iconUrl) {
                pointStyle = new Icon({
                    src: iconUrl,
                    scale: 1.0,
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction'
                });
            }
            else {
                pointStyle = new Circle({
                    radius: pointConfig.size ?? DEFAULT_SIZE_CIRCLE,
                    fill: new Fill({ color: featureFillColor }),
                    stroke: new Stroke({ color: featureStrokeColor, width: DEFAULT_POINT_STROKE }),
                });
            }
        }
        style = new Style({ image: pointStyle });
    }
    else if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
        style = new Style({
            fill: featureFillColor
                ? new Fill({ color: addTransparency(featureFillColor) })
                : new Fill({ color: 'rgba(0,0,0,0)' }),
            stroke: defaultStrokeColor
                ? new Stroke({ color: addTransparency(defaultStrokeColor) })
                : undefined,
        });
    }
    if (style) {
        styleWeakMap.set(feature, style);
    }
    return style;
};
export const initializeMap = async ({ selectedSource, center = DEFAULT_MAP_CENTER, zoom = DEFAULT_ZOOM_LEVEL, }) => {
    let extent = DEFAULT_MAP_EXTENT;
    let features = [];
    const geoJsonCache = new Map();
    const cacheKey = selectedSource.kind === 'URL GeoJSON Source' ? selectedSource.url : '';
    if (selectedSource.kind === 'Raw GeoJSON Source' && selectedSource?.data) {
        const geojsonData = JSON.parse(selectedSource.data);
        const sourceCRS = detectCRS(geojsonData);
        if (geojsonData.bbox && Array.isArray(geojsonData.bbox) && geojsonData.bbox.length === 4) {
            extent = transformExtent(geojsonData.bbox, sourceCRS, DEFAULT_PROJECTION);
        }
        else {
            features = readFeaturesWithProjection(geojsonData);
            const boundingBox = getBoundingExtent(features);
            if (boundingBox) {
                extent = boundingBox;
            }
        }
    }
    else if (selectedSource.kind === 'URL GeoJSON Source' && selectedSource?.url) {
        try {
            let geojsonData;
            if (geoJsonCache.has(cacheKey)) {
                geojsonData = geoJsonCache.get(cacheKey);
            }
            else {
                const response = await fetch(selectedSource.url);
                geojsonData = await response.json();
                geoJsonCache.set(cacheKey, geojsonData);
            }
            const sourceCRS = detectCRS(geojsonData);
            if (geojsonData.bbox && Array.isArray(geojsonData.bbox) && geojsonData.bbox.length === 4) {
                extent = transformExtent(geojsonData.bbox, sourceCRS, DEFAULT_PROJECTION);
            }
            else {
                features = readFeaturesWithProjection(geojsonData);
                const boundingBox = getBoundingExtent(features);
                if (boundingBox) {
                    extent = boundingBox;
                }
            }
        }
        catch (error) {
            console.error("Failed to load GeoJSON data:", error);
        }
    }
    const view = new View({
        center: center ? fromLonLat(center) : extent ? getCenter(extent) : fromLonLat(DEFAULT_MAP_POINT_CENTER),
        zoom,
    });
    return { view, extent };
};
