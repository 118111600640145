import { useEffect, useState } from "react";
import { useLocalizedStrings } from "../Localization";
import { ClickableButton } from "./Button";

export function DateTimeInput(props: { 
  id: string,
  label: string, 
  setValue: (value: string) => void,
  /** Optional: Initialize with the current UTC day or hour */
  init?: 'day' | 'hour',
  includeTime?: boolean,
  includeReset?: boolean,
  hideLabels?: boolean
}) { 

  const L = useLocalizedStrings();
  
  let init = '';
  let MM_DD_YYYY: string[] = [];
  let HH_MM_SS: string[] = [];
  if (props.init === 'hour') {
    init = new Date().toISOString().split(':')[0] + ':00:00Z';
  } else if (props.init === 'day') {
    init = new Date().toISOString().split('T')[0] + 'T00:00:00Z';
  }
  if (init) {
    let [date, time] = init.split('T');
    MM_DD_YYYY.push(date.split('-')[1], date.split('-')[2], date.split('-')[0]);
    if (props.includeTime) {
      HH_MM_SS.push(time.split(':')[0], time.split(':')[1], time.split(':')[2].split('Z')[0]);
    }
  }

  const [month, setMonth] = useState(MM_DD_YYYY.length === 3 ? MM_DD_YYYY[0] : "--" as string);
  const [day, setDay] = useState(MM_DD_YYYY.length === 3 ? MM_DD_YYYY[1] :"");
  const [year, setYear] = useState(MM_DD_YYYY.length === 3 ? MM_DD_YYYY[2] :"");

  const [hour, setHour] = useState(HH_MM_SS.length === 3 ? HH_MM_SS[0] : "");
  const [minute, setMinute] = useState(HH_MM_SS.length === 3 ? HH_MM_SS[1] : "");
  const [second, setSecond] = useState(HH_MM_SS.length === 3 ? HH_MM_SS[2] : "");

  const dayValid = ['01','03','05','07','08','10','12'].includes(month) ? 
    !!day.match(/^(0[1-9]|[12]\d|3[01])$/) :  // Days 01-31 for months with 31 days
    month === '02' ? 
      parseInt(year || '0') % 4 === 0 ? 
        !!day.match(/^(0[1-9]|1\d|2[0-9])$/) :  // Days 01-29 for February in a leap year
        !!day.match(/^(0[1-9]|1\d|2[0-8])$/) :  // Days 01-28 for February in a non-leap year
      !!day.match(/^(0[1-9]|[12]\d|30)$/);  // Days 01-30 for months with 30 days

  const yearValid = !!year.match(/^(19|20|99)[0-9][0-9]$/);

  const allValid = dayValid && yearValid && month !== '--';

  const reset = () => {
    let cur = new Date().toISOString();
    let [date, time] = cur.split('T');
    let [year, month, day] = date.split('-');
    let [hour, minute, second] = time.split(':');
    setMonth(month);
    setDay(day);
    setYear(year);
    setHour(hour);
    setMinute(minute);
    setSecond(second);
  }

  useEffect(() => {
    if (allValid) {
      if (props.includeTime) {
        props.setValue(month + "/" + day + "/" + year + ", " + hour + ":" + minute + ":" + second )
      } else {
        props.setValue(month + "/" + day + "/" + year);
      }
    } else {
      props.setValue("");
    }
  }, [allValid, day, month, year, hour, minute, second]);

  return <><fieldset>
    <legend>
      <b>{props.label}<br /></b>
    </legend>
    {props.includeReset && 
      <><ClickableButton color="gray" onClick={reset} extraClasses="my-1">
        {"Set to current date"}
      </ClickableButton><br/></>}
    <div className={"w-32 rounded-md px-1 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600" + 
        "max-w-lg inline-block mr-1 w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
        (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
          " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}>
      <label htmlFor={"month_" + props.id} className="block text-xs font-light text-gray-600">
        month
      </label>
      <select
        id={"month_" + props.id}
        name={"date_" + props.id}
        className={"appearance-none block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"}
        value={month}
        onChange={(e) => setMonth(e.target.value)}
      >
        <option value="--" disabled hidden>{L.months.month}</option>
        <option value="01">{L.months.jan}</option>
        <option value="02">{L.months.feb}</option>
        <option value="03">{L.months.mar}</option>
        <option value="04">{L.months.apr}</option>
        <option value="05">{L.months.may}</option>
        <option value="06">{L.months.jun}</option>
        <option value="07">{L.months.jul}</option>
        <option value="08">{L.months.aug}</option>
        <option value="09">{L.months.sep}</option>
        <option value="10">{L.months.oct}</option>
        <option value="11">{L.months.nov}</option>
        <option value="12">{L.months.dec}</option>
      </select>
    </div>
    <div className={"w-12 rounded-md px-1 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600" + 
      "max-w-lg inline-block mx-1 w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
      (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
        " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}>
      <label htmlFor={"day_" + props.id} className="block text-xs font-light text-gray-600">
        day
      </label>
      <input
        id={"day_" + props.id}
        className={"block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"}
        placeholder="DD"
        pattern="([0-2]\d|3[0-1])"
        value={day}
        onChange={(e) => 
          setDay(e.target.value.slice(0, 2))
        }
        onBlur={(e) => {
          if (e.target.value.length === 1) {
            setDay('0' + e.target.value);
          }
        }}
      />
    </div>
    <div className={"w-14 rounded-md px-1 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600" + 
      "max-w-lg inline-block mx-1 w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
      (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
        " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}>
      <label htmlFor={"year_" + props.id} className="block text-xs font-light text-gray-600">
        {L.admin.invoices.year}
      </label>
      <input
        id={"year_" + props.id}
        pattern="[1-2][0-9]{3}"
        className={"block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"}
        placeholder="YYYY"
        value={year}
        onChange={(e) => setYear(e.target.value.slice(0, 4))}
      />
    </div>
    {props.includeTime && <>
      <div className={"w-12 rounded-md px-1 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600" + 
      "max-w-lg inline-block mx-1 w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
      (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
        " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}>
        <label htmlFor="admin-hour" className="block text-xs font-light text-gray-600">
          hh
        </label>
        <input
          type="text"
          value={hour}
          onChange={(e) => setHour(e.target.value)}
          pattern="([0-1]\d|2[0-3])"
          id="admin-hour"
          className={"block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"}
          placeholder="00"
        />
      </div>
      <div className={"w-12 rounded-md px-1 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600" + 
      "max-w-lg mx-1 inline-block w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
      (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
        " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}>
        <label htmlFor="admin-minute" className="block text-xs font-light text-gray-600">
          min
        </label>
        <input
          type="text"
          value={minute}
          onChange={(e) => setMinute(e.target.value)}
          pattern="([0-5]\d)"
          id="admin-minute"
          className={"block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"}
          placeholder="00"
        />
      </div>
      <div className={"w-12 rounded-md px-1 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600" + 
      "max-w-lg inline-block ml-1 w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
      (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
        " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}>
        <label htmlFor="admin-second" className="block text-xs font-light text-gray-600">
          sec
        </label>
        <input
          type="text"
          value={minute}
          onChange={(e) => setSecond(e.target.value)}
          pattern="([0-5]\d)"
          id="admin-second"
          className={"block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"}
          placeholder="00"
        />
      </div>
    </>}
  </fieldset>
  </>
}