export function getBrowserDetails() {
  const browserDetails: Record<string, any> = {};
  try {
    browserDetails.userAgent = navigator.userAgent;
    browserDetails.cookiesEnabled = navigator.cookieEnabled;
    browserDetails.devicePixelRatio = window.devicePixelRatio;
    browserDetails.screenWidth = window.screen.width;
    browserDetails.screenHeight = window.screen.height;
    browserDetails.hardwareConcurrency = navigator.hardwareConcurrency;
    browserDetails.language = navigator.language;
  } catch (e) {
    console.error("Error getting browser details", e);
    browserDetails.error_message = (e as Error).message;
  }
  return browserDetails;
}