import { Icon, Modal, Button } from "@aidkitorg/component-library";
import { InlineNotification, NotificationGroup, Root, RichText } from "@aidkitorg/types/lib/survey";
import { PathNode, ArrayNode, traverseForKindsWithPath } from "@aidkitorg/types/lib/traverse";
import { usePost } from "./API";
import { ContentTemplate } from "@aidkitorg/types/src/survey";
import { useCallback, useEffect, useReducer, useState } from "react";
import { SpacedSpinner } from "./Util";
import { CommsWindowContentTemplate } from "@aidkitorg/aidkit/lib/messaging/content_templates";


type contentSIDRes = Record<string, {
  sid: string;
  whatsappApprovalStatus?: string;
  error?: string;
}>;

type followUpRes = {
  suffix: string,
  contentSIDs?: contentSIDRes,
  err?: string
};

export type NotificationWithPath = {
  item: NotificationGroup | InlineNotification | CommsWindowContentTemplate,
  path: PathNode[]
}

export function extractNotifications(root: Root, path?: PathNode[]): NotificationWithPath[] {
  let notifs: NotificationWithPath[] = [];

  // Legacy: if root is type Survey, where it is just a list of collection components...
  // All our surveys should be ExpandedSurveys now, and in fact need to be in order to have comms configs and WhatsApp 
  // configured.
  if (Array.isArray(root)) return notifs;

  // First traverse for notifications within the survey tab
  notifs.push(...traverseForKindsWithPath<InlineNotification | NotificationGroup>
  (root.survey, ['InlineNotification', 'Notification'], [{ kind: 'record', name: 'survey' }]) as NotificationWithPath[]);

  // Next, traverse notifications held within the notifications tab
  if (root.notifications) {
    notifs.push(...traverseForKindsWithPath<InlineNotification | NotificationGroup>
    (root.notifications, ['InlineNotification', 'Notification'], [{ kind: 'record', name: 'notifications' }]) as NotificationWithPath[]);
  }

  // Finally, add Comms channel content templates
  const beginningPath: PathNode[] = [
    { kind: 'record', name: 'config' },
    { kind: 'record', name: 'comms' },
  ]
  const defaultChannel = root.config?.comms?.default;
  const otherChannels = root.config?.comms?.otherChannels || [];
  if (defaultChannel) {
    const templates = defaultChannel.twilioConfig.whatsapp_content_templates || [];
    for (let i = 0; i < templates.length; i++) {
      const msg = templates[i];
      notifs.push({
        item: {
          kind: 'CommsWindow',
          ...msg
        },
        path: [
          ...beginningPath,
          { kind: 'record', name: 'default' },
          { kind: 'record', name: 'twilioConfig' },
          { kind: 'record', name: 'whatsapp_content_templates' },
          {
            kind: 'array',
            matches: {
              index: i,
            }
          }
        ]
      })
    }
  }

  for (let i = 0; i < otherChannels.length; i++) {
    const channel = otherChannels[i];
    const newBeginningPath: PathNode[] = [
      ...beginningPath, 
      {
        kind: 'array',
        matches: {
          index: i,
          channelName: channel.channelName
        }
      },
      { kind: 'record', name: 'twilioConfig' },
      { kind: 'record', name: 'whatsapp_content_templates' }
    ];
    const templates = channel.twilioConfig.whatsapp_content_templates || [];
    for (let j = 0; j < templates.length; j++) {
      const msg = templates[j];
      notifs.push({
        item: {
          kind: 'CommsWindow',
          ...msg
        },
        path: [
          ...newBeginningPath,
          {
            kind: 'array',
            matches: {
              index: j,
            }
          }
        ]
      })
    }
  }

  return notifs.filter(n => (n.item.kind === 'CommsWindow') 
    ? !!n.item.templates
    : !!n.item.initial_notification?.content_template
  );
}

function Notification(props: { n: NotificationWithPath, reloadSurvey: () => Promise<void> }) {
  const createContentTemplate = usePost('/admin/messaging/refresh_content_template');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [templateResult, setTemplateResult] = useState<{
    contentSIDs: contentSIDRes,
    flupSIDs: followUpRes[],
    distroSaveError?: string
  }>();

  const renderFollowup = useCallback((flup: any) => {
    const flupRes = templateResult?.flupSIDs.find(f => f.suffix === flup.suffix);
    return <div key={flup.suffix} className="ml-3">
      <div><span className="font-bold">Suffix: </span>{flup.suffix}</div>
      <div><span className="font-bold">Content languages: </span>
        {Array.isArray(flup.message)
          ? 'Cannot create content template for conditional content'
          : templatesNeeded(flup.message, flup.content_template, flupRes?.contentSIDs)
        }
      </div>
      {flupRes?.err && <div className="text-red-700 text-xs mb-2">Error: {flupRes?.err}</div>}
    </div> 
  }, [templateResult]);

  const templatesNeeded = (m: RichText, templates?: ContentTemplate, res?: contentSIDRes) => {
    const langsWithTemplates = new Set(templates?.templateSIDs?.filter(t => !!t.sid).map(ts => ts.lang) || []);
    return <div className="ml-2 mt-1">{Object.keys(m).filter(lang => lang !== '_id').map((lang) => 
      <div className="flex flex-row text-xs mb-1 items-center">
        <span className="text-base mr-1">{lang}:</span>{!res && (langsWithTemplates.has(lang)
          ? <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-green-700">
            Has existing template
          </span>
          : <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-red-700">
            Needs template
          </span>)}
        {res && res[lang] && <div className="text-xs flex flex-column ml-3">
          <div>SID: <code>{res[lang].sid}</code></div>
          {res[lang].whatsappApprovalStatus && <div>WhatsApp approval: {res[lang].whatsappApprovalStatus}</div>}
          {res[lang].error && <div className="text-red-700">{res[lang].error}</div>}
        </div>}
      </div>)}
    </div>
  }

  return <div className="bg-gray-50 rounded p-3 mt-2">
    <div className="flex flex-row justify-between">
      <div className='mr-2'>
        {props.n.item.kind === 'CommsWindow'
          ? <div><span className="font-bold">Comms Window Template</span></div>
          : <div><span className="font-bold">Target Field Prefix: </span>{props.n.item.targetPrefix}</div>
        }
        <div><span className="font-bold">Name: </span>{props.n.item.name}</div>
        <div><span className="font-bold">Content languages: </span>
          {props.n.item.kind === 'CommsWindow'
            ? templatesNeeded(props.n.item.body, props.n.item.templates, templateResult?.contentSIDs)
            : (Array.isArray(props.n.item.initial_notification.message)
              ? 'Cannot create content template for conditional content'
              : templatesNeeded(props.n.item.initial_notification.message, props.n.item.initial_notification.content_template, templateResult?.contentSIDs))
          }
        </div>
        {props.n.item.kind === 'Notification' && props.n.item.followups?.length && 
        <div>
          <div className="font-bold">Followups: </div>
          {(props.n.item.followups || []).map(flup => 
            renderFollowup(flup)
          )}
        </div>
        }
      </div>
      <div className="flex flex-col-reverse">
        {error && 
          <div className="text-red-700 text-xs">Error: {error}</div>
        }
        <div>
          {loading && <SpacedSpinner />}
          <Button
            disabled={loading}
            onClick={async () => {
              setError(undefined);
              setLoading(true);
              const res = await createContentTemplate({ notif: props.n.item, path: props.n.path });
              if ((res as any).error) {
                setError((res as any).error);
              } else {
                setTemplateResult((res as any).value);
                setError((res as any).value?.distroSaveError);
              }
              await props.reloadSurvey();
              setLoading(false);
            }}
            variant="primary"
            className="float-right"
          >
            Refresh Templates
          </Button>
        </div>
      </div>
    </div>
  </div>
}

export function ContentTemplateModal({
  open,
  onClose,
  survey,
  reloadSurvey
} : {
  open: boolean,
  onClose: () => void,
  survey: Root,
  reloadSurvey: () => Promise<void>
}) {
  const [notifications, setNotifications] = useState<NotificationWithPath[]>([])

  useEffect(() => {
    const notifications = extractNotifications(survey);
    setNotifications(notifications);
  }, [survey]);

  return (
    <Modal  
      title={'Manage Content Templates'}
      open={open} 
      onClose={() => {
        onClose()
      }} 
      className="p-4 rounded-xl my-10"
      size="lg"  
      preventBackdropClick={true}
    >
      <div className="absolute right-0 top-0 pr-4 pt-4 rtl:left-0 rtl:right-auto rtl:top-0 rtl:pl-4 rtl:pr-0 rtl:pt-4">
        <button
          type="button"
          onClick={() => onClose()}
          className="rounded-md bg-white text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <Icon name="XMarkIcon" />
        </button>
      </div>
      {notifications.map(n => <Notification key={n.item.name} n={n} reloadSurvey={reloadSurvey} />)}
    </Modal>
  )
}
