export const DEFAULT_PROJECTION = "EPSG:3857";
export const DEFAULT_GEOJSON_IMPORT_PROJECTION = "EPSG:4326";
export const DEFAULT_ZOOM_LEVEL = 4;
export const DEFAULT_ZOOM_INCREMENT = 3;
export const MAX_ZOOM_LEVEL = 21;
export const DEFAULT_MAP_POINT_CENTER = [0, 0];
export const DEFAULT_MAP_CENTER = [-100, 0];
export const DEFAULT_MAP_EXTENT = [0, 0, 1000, 1000];
export const DEFAULT_POINT_STROKE = 2;
export const DEFAULT_SIZE_CIRCLE = 5;
export const DEFAULT_SIZE_TRIANGLE = 6;
export const DEFAULT_SIZE_STAR = 8;
export const DEFAULT_SIZE_SQUARE = 10;
export const DEFAULT_FILL_COLOR = "#fb2c36";
export const DEFAULT_STROKE_COLOR = "#9f0712";
