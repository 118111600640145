/**
 * Generates an accessible label from the content field of a distro kind.
 * We use this in aria-labels for kinds such as TextEntry and Select kinds.
 * 
 * Because content fields can have markdown and HTML, we use regex to strip all markdown and HTML, leaving only the plaintext.
 * 
 * Usage:
 * 
 * const inaccessibleLabel = `
 *      **What is your email address?**
 *      *We will use this to provide important updates about your participation in the program.*
 * `
 * 
 * const accessibleLabel = generateAccessibleLabel(inaccessibleLabel)
 * 
 * // Result: 
 *      What is your email address?
 *      We will use this to provide important updates about your participation in the program.
 */

// Function to remove all commonly used markdown
const removeAllMarkdown = (input: string): string => {
  const lines = input.split('\n').map(line => line.trim());
  
  const markdownRegex = [
    // Headers (e.g., # Heading, ## Heading, etc.)
    /^#{1,6}\s*/gm,
    // Bold (e.g., **bold** or __bold__)
    /\*\*(.*?)\*\*/g,
    /\_\_(.*?)\_\_/g,
    // Italic (e.g., *italic* or _italic_)
    /\*(.*?)\*/g,
    /\_(.*?)\_/g,
    // Images (e.g., ![alt](url)) - Replace with just the alt text
    /!\[([^\]]+)\]\([^)]*\)/g,
    // Links (e.g., [text](url)) - Replace with just the text, remove URL
    /\[([^\]]+)\]\([^)]*\)/g,
    // Lists (unordered: - Item, * Item, + Item, ordered: 1. Item)
    /^[\*\-\+]\s+/gm,  // Matches unordered lists (starting with -, *, +)
    /^\d+\.\s+/gm,      // Matches ordered lists (starting with 1., 2., etc.)
    // Horizontal rules (e.g., --- or ***)
    /^(?:\*{3,}|-{3,}|_{3,})$/gm,
    // Escape characters (e.g., \* \_ \#)
    /\\[\\\*\_\#\[\]\(\)\+\-\.\!\`]/g
  ];
  
  let cleanedLines = lines.map(line => {
    markdownRegex.forEach(regex => {
      line = line.replace(regex, (match, p1) => {
        // If it's a link or image, return the link text (p1)
        return p1 || ''; 
      });
    });
    return line;
  });
  
  return cleanedLines.join('\n');
}
  
// Function to remove all possible HTML tags
const removeHtmlTags = (input: string): string => {
  return input.replace(/<[^>]*>/g, '');
}

export const generateAccessibleLabel = (label: string): string => {
  const labelWithNoMarkdown = removeAllMarkdown(label)
  const accessibleLabel = removeHtmlTags(labelWithNoMarkdown)

  return accessibleLabel;
}