import React, { useState, useRef, useEffect } from 'react';
import { Icon } from "@aidkitorg/component-library";
import { useAICoach } from './AICoachContext';

type Message = {
  role: "user" | "assistant";
  content: string;
  timestamp: Date;
};

export function AICoachButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [sessionStarted, setSessionStarted] = useState(false);

  const { 
    enabled, 
    startSession, 
    closeSession, 
    sendMessage, 
    dataChannel,
    channelIsOpen
  } = useAICoach();

  console.log("AICoachButton rendering, enabled:", enabled);

  // Add initial "please accept terms" message when chat is opened
  useEffect(() => {
    if (isOpen && messages.length === 0) {
      setMessages([{
        role: "assistant",
        content: "Hello! I'm Opal, your Application Navigator. Before we start, please accept the terms of service below.",
        timestamp: new Date()
      }]);
    }
  }, [isOpen]);

  // Start session when opening the chat AND terms are accepted
  useEffect(() => {
    if (isOpen && enabled && hasAcceptedTerms && !channelIsOpen()) {
      startSession().then(() => {
        setSessionStarted(true);
        
        // Add the real welcome message after terms acceptance
        setMessages(prev => [...prev, {
          role: "assistant",
          content: "Thank you! How can I help you today?",
          timestamp: new Date()
        }]);
      });
    }
  }, [isOpen, enabled, hasAcceptedTerms, channelIsOpen, startSession]);
  
  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Add welcome message after session starts
  useEffect(() => {
    if (sessionStarted && messages.length === 0) {
      // Add initial welcome message
      setMessages([{
        role: "assistant",
        content: "Hello! I'm Opal, your Application Navigator. How can I help you today?",
        timestamp: new Date()
      }]);
    }
  }, [sessionStarted, messages.length]);
  
  // Handle incoming messages separately
  useEffect(() => {
    if (!dataChannel) return;
    
    const handleMessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Received message:", data);
        
        if (data.type === "message_create" && data.message?.content) {
          const content = data.message.content.reduce((acc: string, item: any) => {
            return item.type === "text" ? acc + item.text : acc;
          }, "");
          
          if (content) {
            setMessages(prev => [...prev, {
              role: "assistant",
              content,
              timestamp: new Date()
            }]);
          }
        }
      } catch (error) {
        console.error("Error processing message:", error);
      }
    };
    
    dataChannel.addEventListener('message', handleMessage);
    return () => dataChannel.removeEventListener('message', handleMessage);
  }, [dataChannel]);
  
  const handleSendMessage = () => {
    if (inputText.trim()) {
      // Add user message to local state
      setMessages(prev => [...prev, {
        role: "user",
        content: inputText.trim(),
        timestamp: new Date()
      }]);
      
      // Call the context's sendMessage
      sendMessage(inputText.trim());
      setInputText('');
    }
  };
  
  function toggleCoach() {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      closeSession();
    }
  }

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  }
  
  // Handle terms acceptance
  const acceptTerms = () => {
    setHasAcceptedTerms(true);
  };
  
  //   // Don't render if AI Coach is disabled
  //   if (!enabled) return null;
  
  return (
    <>
      {/* Sliding chat panel - full screen on mobile, partial on desktop */}
      {isOpen && (
        <div className="fixed bottom-0 right-0 z-50 w-full sm:w-80 md:w-[360px] lg:w-[450px] max-w-[100vw] sm:max-w-[90vw] 
                      h-full sm:h-[90vh] md:h-[75vh] bg-white shadow-lg flex flex-col sm:rounded-t-lg">
          {/* Header */}
          <div className="border-b border-gray-200">
            <div className="flex flex-col p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src="https://i.postimg.cc/GmczHr3p/avatar-opal.png" alt="Opal" className="w-12 h-12 rounded-full mr-3" />
                  <h3 className="text-2xl font-semibold mt-2">Chat with Opal</h3>
                </div>
                {/* Minimize button */}
                <div className="group relative">
                  <button 
                    className="text-gray-600 bg-gray-200 hover:bg-gray-300 hover:text-indigo-600 p-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 transition-colors"
                    onClick={toggleCoach}
                    aria-label="Minimize Chat"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.5 3.5L9 9M9 9V4M9 9H4M20.5 20.5L15 15M15 15V20M15 15H20" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div className="absolute bottom-full right-0 mb-1 px-2 py-1 bg-gray-800 text-white text-xs rounded pointer-events-none opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                      Minimize Chat
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          {/* Chat content */}
          <div className="flex-1 overflow-auto p-4">
            {messages.map((message, index) => (
              <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                {message.role === 'assistant' && (
                  <div className="mr-3 flex-shrink-0">
                    <img src="https://i.postimg.cc/GmczHr3p/avatar-opal.png" alt="Opal" className="w-8 h-8 rounded-full" />
                  </div>
                )}
                <div className={`max-w-[80%] ${message.role === 'user' ? 'bg-indigo-100' : 'bg-gray-100'} p-3 rounded-lg`}>
                  <div className="text-md">{message.content}</div>
                  <div className="text-sm text-gray-500 mt-1">
                    {message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </div>
                </div>
                {message.role === 'user' && (
                  <div className="ml-3 flex-shrink-0">
                    <div className="w-8 h-8 rounded-full bg-indigo-600 flex items-center justify-center text-white font-semibold">
                      U
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          
          {/* Terms of Service - shown only before acceptance */}
          {!hasAcceptedTerms && (
            <div className="p-4 border-t border-gray-200 bg-gray-50">
              <h4 className="font-semibold text-gray-800 mb-2">Terms of Service</h4>
              <p className="text-sm text-gray-600 mb-3">
                By using Opal, you agree to our terms of service and privacy policy. 
                Your conversations may be recorded and analyzed to improve our service.
              </p>
              <button 
                onClick={acceptTerms}
                className="w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-md transition-colors"
              >
                Accept Terms & Continue
              </button>
            </div>
          )}
          
          {/* Message input - only shown after terms acceptance */}
          {hasAcceptedTerms && (
            <div className="border-t border-gray-200 p-3">
              <div className="flex items-center">
                {/* Microphone button */}
                <button
                  className="p-2 bg-gray-200 text-gray-600 hover:bg-gray-300 hover:text-indigo-600 rounded-md mr-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 transition-colors"
                  aria-label="Voice input"
                >
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
                
                <input
                  type="text"
                  className="flex-1 border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Type a message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button
                  className="ml-2 p-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                  onClick={handleSendMessage}
                  disabled={!channelIsOpen()}
                >
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 2L11 13M22 2L15 22L11 13M22 2L2 9L11 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      
      {/* Chat Button*/}
      {!isOpen && (
        <div className="fixed bottom-10 right-10 sm:bottom-12 sm:right-12 md:bottom-14 md:right-14 z-50">
          <button
            onClick={toggleCoach}
            className="bg-indigo-600 hover:bg-indigo-500 text-white rounded-full py-2.5 px-4 sm:py-3 sm:px-5 flex items-center justify-center shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            style={{ 
              paddingTop: "0.5rem", 
              paddingBottom: "0.5rem", 
              paddingLeft: "0.75rem", 
              paddingRight: "0.75rem",
              fontSize: "clamp(0.875rem, 2vw, 1.25rem)"
            }}
            aria-label="Ask Opal: Your AI Coach"
          >
            <Icon name="ChatBubbleLeftRightIcon" role="decorative" className="text-white" size="lg" />
            <span className="text-xl sm:text-md md:text-xl ml-2" style={{ marginLeft: "0.5rem" }}>Ask Opal</span>
          </button>
        </div>
      )}
    </>
  );
}