import * as v0 from '@aidkitorg/types/lib/survey';
import { PermissionScope } from '@aidkitorg/types/lib/translation/permissions';
import { CompileExpressionToEnglish } from '@aidkitorg/types/lib/translation/expr_to_english';

export type SubsurveyNode = {
  type: 'Subsurvey',
  name: string,
  targetFields: string[],
  subNodes?: PathNode[],
}

export type QuestionNodeType = {
  type: 'Question',
  questionType: string,
  targetField: string,
  content?: v0.RichText | v0.Text,
  trackedObjInfo: v0.TrackedObjectInfo, // TODO: should this be optional?
  choices?: { label: v0.Text, value: string }[],
  subContent?: (QuestionNodeType | ConditionalNode)[],
  optional?: boolean,
  hidden?: boolean,
  kind?: string,
  node?: any,
}

export type SectionNode = {
  type: 'Section',
  name: string,
  subNodes?: PathNode[],
}

export type ConditionalNode = {
  type: 'Condition',
  condition: string //v0.ClickQuery | v0.Query | v0.BooleanExpr | v0.Code,
  subNodesTrue?: PathNode[],
  subNodesFalse?: PathNode[],
}

export type TemplatedBlockNodeType = {
  type: 'Templated Block',
  iterations: {
    substitutions: { key: string, value: string }[],
  }[],
  subNodes?: PathNode[],
}

export type PathNode = SubsurveyNode | QuestionNodeType | SectionNode | ConditionalNode | TemplatedBlockNodeType;

const NON_TARGET_FIELD_QUESTION_KINDS = [
  'Action',
  'Benefits Calculator',
  'Consent',
  'Explanation',
  'Image Preview',
  'InlineNotification',
  "Likert",
  'LoginWidget',
  'Milestone',
  'NumberWithUnit',
  'Related Applicants',
  'ResumeWidget',
  'SupportButton',
];

function conditionalContentToPathNodes(conditionalContent: v0.ConditionalContentList): (QuestionNodeType | ConditionalNode)[] {
  return conditionalContent.map((customizableContent) => {
    if ('conditional' in customizableContent) {
      return {
        type: 'Condition',
        condition: CompileExpressionToEnglish(customizableContent.conditional),
        subNodesTrue: conditionalContentToPathNodes(customizableContent.components),
        ...(customizableContent.otherwise ? { subNodesFalse: conditionalContentToPathNodes(customizableContent.otherwise) } : {})
      }
    }
    // Regular content
    return {
      type: 'Question',
      questionType: 'SubContent',
      targetField: '',
      content: customizableContent,
      trackedObjInfo: (customizableContent as any)._trackedObjInfo,
    }
  });
}

/**
 * This function takes the entire program survey defintion and maps out all paths through it as a tree of PathNodes.
 * A "path" here is a sequence of consecutive questions with branches wherever there are conditionals. In this way,
 * A data object is created which represents the various possible paths someone could take through a given survey.
 * 
 * TODO: It might be worth only running this on a per-subsurvey basis at least some of the time instead of always
 * running it against the entire survey, if we can guarantee that the correct Subsurvey root is passed in.
 * - This can be done now via copyEditScope in the @param scope
 */
export function mapSubsurveyPaths(root: v0.Root, scope?: PermissionScope) {
  const subSurveys: SubsurveyNode[] = [];

  // TODO: we could just pull targetFields out entirely rather than passing it at each recursion.
  function traverse(node: any, path: PathNode[], targetFields: string[]): void {

    /**
     * Helper used a few places below to add QuestionNodes to the path and note the target fields.
     * These QuestionNodes are what will be rendered in the main view of the editor via renderSubNodeOuter()
     * and the QuestionNode React component.
     */
    function addQuestion(content?: (v0.RichText | v0.Text) & { _trackedObjInfo: v0.TrackedObjectInfo }, targetField: v0.TargetField = node.targetField) {
      if (scope && targetField && !scope.fieldScope.includes(targetField)) {
        return;
      }

      const thisQuestionContent = content || node.content || node.buttonText || node.headerText || { en: '' };

      const thisQuestionAsNode: QuestionNodeType = {
        type: 'Question',
        questionType: node.kind,
        targetField,
        content: thisQuestionContent,
        trackedObjInfo: thisQuestionContent?._trackedObjInfo,
        choices: node.choices,
        subContent: [] as QuestionNodeType[],
        optional: node.optional,
        hidden: node.hidden,
        kind: node.kind,
        node
      }

      // TODO: rename "choices" to something more generic.
      if (node.choices) {
        // Likerts are very special as they are questions that contain questions
        if (node.kind === 'Likert') {
          // This covers the labels for the choices of the likert, e.g. Bad,Neutral,Good
          thisQuestionAsNode.subContent = node.choices.map((choice: any, i: number) => {
            return {
              type: 'Question',
              questionType: 'LikertChoice',
              targetField: 'likert choice → ' + i,
              content: choice.label,
              trackedObjInfo: choice.label?._trackedObjInfo,
              optional: node.optional,
              hidden: node.hidden,
            }
          });

          // Array is the "typical" form of likert questions, the alternative is the LikertDataSource
          if (Array.isArray(node.questions)) {
            // This covers the subquestions for the Likert, e.g. "How do you feel today?", "How did you feel yesterday?"
            thisQuestionAsNode.subContent = thisQuestionAsNode.subContent?.concat(node.questions.map((choice: any) => {
              return {
                type: 'Question',
                questionType: 'SubContent',
                targetField: choice.targetField,
                content: choice.label,
                trackedObjInfo: choice.label?._trackedObjInfo,
                optional: node.optional,
                hidden: node.hidden,
              }
            }));
          }
        }
        // Other question types with choices, such as Select and Ranking
        thisQuestionAsNode.subContent = node.choices.map((choice: any) => {
          return {
            type: 'Question',
            questionType: 'SubContent',
            targetField: targetField + '→' + choice.value,
            content: choice.label,
            trackedObjInfo: choice.label?._trackedObjInfo,
            optional: node.optional,
            hidden: node.hidden,
          }
        });
      }

      // Handle ConditionalContent
      if (Array.isArray(thisQuestionContent)) {
        thisQuestionAsNode.subContent = thisQuestionAsNode.subContent?.concat(conditionalContentToPathNodes(thisQuestionContent as v0.ConditionalContentList))
      }

      // If this question itself is conditional, add a conditional in the path and set this question as the true path, else just add to path
      if (node.conditionalOn) {
        path.push({ type: 'Condition', condition: CompileExpressionToEnglish(node.conditionalOn), subNodesTrue: [thisQuestionAsNode] })
      } else {
        path.push(thisQuestionAsNode);
      }
      targetFields.push(targetField);
    }

    if (typeof node === 'object') {
      if (node.kind && node.kind === 'Templated Block') {
        const templatedBlockSubNodes: PathNode[] = [];
        traverse(node.components, templatedBlockSubNodes, targetFields);
        path.push({ type: 'Templated Block', iterations: node.iterations, subNodes: templatedBlockSubNodes });
        // return early here because we have traversed subnodes already
        return;
      }

      if (node.kind && (typeof node.targetField === 'string' || NON_TARGET_FIELD_QUESTION_KINDS.includes(node.kind))) {
        addQuestion();
        return; // return early here because we are at a leaf
      }

      // Branch on Conditional Blocks to mirror paths through the survey
      if (node.kind && node.kind === 'Conditional Block') {
        // Recurse down path where condition is met
        const truePath: PathNode[] = [];
        traverse(node.components, truePath, targetFields);
        path.push({ type: 'Condition', condition: CompileExpressionToEnglish(node.conditionalOn), subNodesTrue: truePath })

        // If present, recurse down path where condition is not met
        if (node.otherwise) {
          const falsePath: PathNode[] = [];
          traverse(node.otherwise, falsePath, targetFields);
          path.push({ type: 'Condition', condition: 'otherwise', subNodesFalse: falsePath })
        }

        return; // return early here because we have traversed subnodes already
      }

      if (node.kind && node.kind === 'Subsurvey') {
        // TODO(Riley): as of 3/27/25, this is the only thing keeping someone copy editing from
        // making changes elsewhere in the survey, and its entierely frontend, meaning we can't really trust it.
        // There is a larger issue than just this as well: even if this scoping did always prevent, editing outside
        // the subsurvey(s), it does not prevent editing non-content within that subsurvey, so a clever and devious
        // copy editor could in theory use their access to send collab events to edit non-copy parts of the survey.
        if (scope?.copyEditScope && !scope.copyEditScope.includes(node.path)) {
          return;
        }
        const subsurveyPath: PathNode[] = [];
        const subSurveyTargetFields: string[] = [];
        traverse(node.sections, subsurveyPath, subSurveyTargetFields);
        // Due to permissions, a given user's view of a subsurvey may have no viewable fields
        // If this is the case, we simply don't include the subsurvey in the list
        if (subSurveyTargetFields.length > 0) {
          subSurveys.push({ type: 'Subsurvey', name: node.path, subNodes: subsurveyPath, targetFields: subSurveyTargetFields });
        }
        return; // return early here because we have traversed subnodes already.
      }

      if (node.kind && node.kind === 'Section') {
        const thisSectionNode = { type: 'Section', name: node.title.en } as SectionNode;
        // If this section itself is conditional, add a conditional in the path and set this Section as the true path, else just add to path
        if (node.conditionalOn) {
          const sectionPath: PathNode[] = [];
          traverse(node.components, sectionPath, targetFields);
          path.push({ type: 'Condition', condition: CompileExpressionToEnglish(node.conditionalOn), subNodesTrue: [thisSectionNode, ...(sectionPath || [])] })
          return; // return early here because we have traversed subnodes already
        } else {
          path.push(thisSectionNode);
          // Fall through to array/Object code below (do we need array?)
        }
      }

      // Note: the Section path above falls through to this
      if (Array.isArray(node)) {
        node.forEach((subNode) => traverse(subNode, path, targetFields));
        return;
      }

      Object.values(node).forEach((subNode) => traverse(subNode, path, targetFields));
      return;
    }
    return;
  }

  const path: PathNode[] = [];
  traverse(root, path, []);
  return subSurveys;
}
