import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Alert, CodeDisplay, Switch } from "@aidkitorg/component-library";
import { Notification } from "@aidkitorg/types/lib/survey";
import { useMemo, useState } from "react";
import { SupportedLanguage } from "../Context";
import { langToWord } from '../Localization';
import { LanguageNames } from "@aidkitorg/i18n/lib";

export function Stats(props: { stats: { name: string, stat: string }[] }) {
  return (
    <div>
      <dl className="ml-2 mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {props.stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-2 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export function DetailList({ details }: { details: Record<string, string | React.ReactNode> }) {
  return <div>
    <div className="mt-6 border-t border-gray-100">
      <dl className="divide-y divide-gray-100">
        {Object.entries(details).map(([key, value]) =>
          <div key={key} className="hover:bg-gray-100 rounded-md p-1 items-center grid grid-flow-col-dense grid-cols-2">
            <dt className="text-sm/6 font-medium text-gray-900">{key}</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0 items-center justify-end">{value}</dd>
          </div>
        )}
      </dl>
    </div>
  </div>
}

export function SqlViewer({ sql }: { sql: string }) {
  const [showSql, setShowSql] = useState(false);

  return <div className="space-y-2">
    <Switch label="Show SQL" onCheckedChange={setShowSql} />
    <div hidden={!showSql} className="pb-5">
      <CodeDisplay code={sql} showLineNumbers copyable language="sql" />
    </div>
  </div>
}

export function NotificationSimulation({ contactMethod, simulationSource, enableEmailMarkdown, simulated }: { 
  contactMethod: Notification['contactMethod'], 
  simulationSource: "simulation" | "query" | null,
  enableEmailMarkdown: boolean | undefined,
  simulated: Record<string, Array<{ 
    emailContent: string, 
    smsContent: string, 
    langAttempted: SupportedLanguage | null, 
    emailLangResult: SupportedLanguage,
    smsLangResult: SupportedLanguage }>> | null 
}) {
  if (simulated == null) return null;
  return useMemo(() => <>{
    (!Object.keys(simulated).length && simulationSource === "simulation") ? <div className="text-red-600">Configure Test UIDs to simulate</div> :
      <Accordion type="single" dir="ltr" defaultValue={'simulated-messages'}>
        <AccordionItem value="simulated-messages">
          <AccordionTrigger>
            <h3 className="text-xl">Simulated Messages for {Object.keys(simulated || {}).length} applicant(s)</h3>
          </AccordionTrigger>
          <AccordionContent>
            {Object.keys(simulated || {}).map((uid) => {
              return <div key={uid}>
                <div><b>{uid}</b></div>
                {(simulated[uid] || []).map((sim) => <div key={sim.langAttempted}>
                  {sim.langAttempted && <h4 className="text-lg font-bold">Attempted to Simulate: {sim.langAttempted + ' (' + LanguageNames[sim.langAttempted]+ ')'}</h4>}
                  {!!sim.langAttempted && sim.langAttempted !== sim.smsLangResult && <Alert variant="danger">Warning: {LanguageNames[sim.langAttempted]} is not in the configured Notification SMS Content. If an applicant's preferred language is {LanguageNames[sim.langAttempted]}, the SMS notification will default to English.</Alert>}
                  {!!sim.langAttempted && sim.langAttempted !== sim.emailLangResult && <Alert variant="danger">Warning: {LanguageNames[sim.langAttempted]} is not in the configured Notification Email Content. If an applicant's preferred language is {LanguageNames[sim.langAttempted]}, the Email notification will default to English.</Alert>}
                  {(sim.langAttempted === null || (sim.emailLangResult === sim.langAttempted)) && <>
                    {contactMethod !== 'sms' && contactMethod !== 'whatsapp' && <>
                      <div>Email:</div>                      
                      {enableEmailMarkdown 
                        ? <div className="border rounded-xl px-3 markdown" dangerouslySetInnerHTML={{ __html: sim.emailContent }}></div> 
                        : <pre className="border rounded-xl px-3 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: sim.emailContent }}></pre>}
                    </>}
                  </>}
                  {(sim.langAttempted === null || (sim.smsLangResult === sim.langAttempted)) && <>
                    {contactMethod !== 'email' && <>
                      <div>{contactMethod === 'whatsapp' ? 'WhatsApp:' : contactMethod === 'sms' ? 'SMS:' : 'SMS/WhatsApp:'}</div>
                      <pre className="mb-5 border rounded-xl px-3 whitespace-pre-wrap">{sim.smsContent}</pre>
                    </>}
                  </>}
                </div>)}
              </div>
            })}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      // Do not update when contactMethod is changed, only when simulated changes.
      // This avoids falsely appearing like a re-simulation has occurred when it hasn't.
  }</>, [simulated]);
}
