import { useLocalizedStrings } from '../Localization';
import { ApplicantUpdatesSection } from './ApplicantUpdatesSection';
import { RunRoboScreenerSection } from './RunRoboScreenerSection';

export function RoboScreenerPage() {
  const L = useLocalizedStrings();
    
  return <div className="flex flex-col">
    <div className="mb-40" style={{ maxWidth: "800px", padding: "10px" }}>
      <h5>Run RoboScreener</h5>
      <RunRoboScreenerSection />
      <h5 className="mt-20">{L.admin.survey_design.applicant_updates}</h5>
      <ApplicantUpdatesSection />
    </div>
  </div>
}
