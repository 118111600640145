import { useLocalizedStrings } from '../Localization';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { usePost } from '../API';
import { ConfigurationContext } from '../Context';
import { SpacedSpinner } from '../Util';

export function FraudFlags(props: { uid: string, setLength: (length: number) => void }) {
  const L = useLocalizedStrings();
  const [fraudReason, setFraudReason] = useState("");
  const [markingFraud, setMarkingFraud] = useState(false);
  const [markedFraud, setMarkedFraud] = useState(false);
  const [resolving, setResolving] = useState(false);

  const markFraud = usePost('/fraud/mark_fraud');
  const resolveFraud = usePost('/fraud/resolve_fraud');
  const getFraudFlags = usePost('/fraud/fraud_flags');
  const [fraudFlags, setFraudFlags] = useState<{
    flagName: string, 
    description: string, 
    confidence: 'Potentially Fraudulent' | 'Always Fraudulent'
  }[]>([]);

  const config = useContext(ConfigurationContext);

  const [refreshing, setRefreshing] = useState(false);

  const refreshFlags = useCallback(async () => {
    setRefreshing(true);
    if (props.uid && (
      (config.roles || '').indexOf('admin') !== -1 || 
            (config.roles || '').indexOf('reviewer') !== -1)
    ) {
      const flags = await getFraudFlags({
        uid: props.uid,
      });
      setFraudFlags(flags.flags || []);
      props.setLength(flags.flags.length);
    }
    setRefreshing(false);
  }, [config, props.uid]);

  useEffect(() => {
    if (!config || Object.keys(config).length === 0) return;
    (async () => { await refreshFlags(); })();
  }, [config]);

  return (
    <div className="mb-2 p-2 rounded-md">
      <h3 className="text-xl font-bold mb-4">{L.applicant.fraud.fraud_flags}</h3>
      {refreshing && <><SpacedSpinner /> Fetching latest info...</>}
      {fraudFlags.length === 0 && <h6>{L.applicant.fraud.no_fraud_flags}</h6>}
      <ul>
        {fraudFlags.map((flag: any, index: number) => {
          return (
            <li key={`fraud_flag_${index}`} className="mb-3 p-2 bg-white shadow rounded-md">
              <h4 className="text-lg font-semibold mb-1">{flag.flagName}</h4>
              <p className="text-sm mb-2">{flag.description}</p>
              <div className={`text-xs font-bold ${
                flag.confidence === "Always Fraudulent"
                  ? "text-red-600"
                  : "text-yellow-600"
              }`}>
                {L.applicant.fraud.confidence}: {flag.confidence}
              </div>
              <div className="w-100 flex justify-content-end">
                {flag.manuallyEntered ? (
                  <button className="bg-blue-600 text-white border-white px-4 py-2 rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={resolving}
                    onClick={async () => {
                      setResolving(true);
                      await resolveFraud({ applicant: props.uid });
                      await refreshFlags();
                      setMarkedFraud(false);
                      setResolving(false);
                    }}><span>{resolving && <SpacedSpinner/>}</span>
                    <span>{L.admin.survey_design.clear}</span></button>
                ) : null}
              </div>
            </li>
          );
        })}
      </ul>
      <div className="mt-4 bg-gray-100 p-3 border border-gray-300 rounded-md">
        <div hidden={markedFraud}>
          <label htmlFor="fraud-reason" className="block text-md font-medium mb-1">
            {L.applicant.fraud.mark_as_fraudulent}
          </label>
          <textarea
            id="fraud-reason"
            className="w-full h-24 p-2 border border-gray-300 rounded-md mb-2"
            value={fraudReason}
            onChange={(e) => setFraudReason(e.target.value)}
            placeholder={L.applicant.fraud.describe_reason}
          ></textarea>
          <button
            type="button"
            className="bg-red-600 text-white border-white px-4 py-2 rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={async () => {
              setMarkingFraud(true);
              try {
                await markFraud({
                  uid: props.uid,
                  description: fraudReason
                });
                setMarkedFraud(true);
              } catch (error) {
                console.error("Error marking fraud: ", error);
              } finally {
                setMarkingFraud(false);
              }
              await refreshFlags();
            }}
            disabled={markingFraud}
          >
            {markingFraud ? (
              <span className="flex items-center">
                <span className="animate-spin inline-block w-4 h-4 border-t-2 border-white rounded-full mr-2"></span>
                {L.applicant.fraud.marking_as_fraudulent} 
              </span>
            ) : (
              L.applicant.fraud.mark_as_fraudulent
            )}
          </button>
        </div>
        {markedFraud ? (
          <div className="my-2 text-grey-800">
            {L.applicant.fraud.successfully_marked_as_fraudulent}
          </div>
        ) : null}
      </div>
    </div>
  );
}

