import React, { useCallback, useContext, useState, useEffect, useMemo, useRef } from "react";
import { Dropdown } from "react-bootstrap";

import { QuestionOption, QuestionProps } from "./Props";
import { languageContent } from "../Util";
import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";

import { SingleSelectQuestion } from "./Choice";
import { safeParse } from "../Util";
import * as v0 from "@aidkitorg/types/lib/survey";
import { fisherYatesShuffle, hash } from "../utils/orderRandomizer";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

export function LikertQuestion(props: QuestionProps) {
  const [renderLikertFromData, setRenderLikertFromData] = useState(false);
  const [mockInfo, setMockInfo] = useState<Record<string, string>>({});
  const [questionMap, setQuestionMap] = useState<Record<string, { label: string, answerField: string, prevValue: any, showData?: boolean, answer?: string }>>({});
  const previousQuestionMapRef = useRef({});
  const context = useContext(InterfaceContext);

  const formattedContent = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
  });

  useEffect(() => {
    const metadata = safeParse(props.Metadata || '{}') as {
      choices: v0.Likert['choices'],
      questions: v0.Likert['questions']
    };

    if (Array.isArray(metadata.questions)) {
      setRenderLikertFromData(false);
      return;
    }
    setRenderLikertFromData(true);
    const dataSource = metadata.questions; 

    const inputTargetFieldValue = safeParse(props.info[dataSource.targetField] || '[]');
    const outputTargetFieldValue = safeParse(props.info[props["Target Field"]!] || '[]');
    let displayableData: Record<string, {
      label: string,
      answerField: string,
      prevValue: any,
      showData?: boolean, // indicates whether or not this data should be displayed
      answer?: any
    }> = {};

    if (!Array.isArray(inputTargetFieldValue)) {
      return;
    }

    // how can we avoid re-building this every time... 
    for (const record of inputTargetFieldValue) {
      if (typeof record === 'object') {
        // use displayField as unique key. Whoever configures this in distro should make sure it is unique.
        displayableData[record[dataSource.displayField] || JSON.stringify(record)] = {
          label: record[dataSource.displayField] || JSON.stringify(record),
          answerField: dataSource.answerField,
          prevValue: record,
          showData: true
        };
      }
    }

    // populate displayable data with existing answers, if they exist
    if (Array.isArray(outputTargetFieldValue)) {
      for (const record of outputTargetFieldValue) {
        const recordStr = JSON.stringify(record);
        // if the value isn't yet present in the map, its not part of the input and is considered stale.
        const showData = !!displayableData[record[dataSource.displayField] || recordStr];
        if (typeof record === 'object') {
          if (showData) {
            displayableData[record[dataSource.displayField] || recordStr] = {
              ...displayableData[record[dataSource.displayField] || recordStr],
              answer: record[dataSource.answerField],
              showData
            };
          } else {
            displayableData[record[dataSource.displayField] || recordStr] = {
              label: record[dataSource.displayField] || recordStr,
              answerField: dataSource.answerField,
              prevValue: record,
              answer: record[dataSource.answerField],
              showData
            };
          }
        }
      }
    }

    const previousQuestionMap = previousQuestionMapRef.current;

    if (JSON.stringify(displayableData) !== JSON.stringify(previousQuestionMap)) {
      setQuestionMap(displayableData);
      previousQuestionMapRef.current = displayableData;

      // set the _output field to make sure its up to date with out new inputs. 
      // we need this to make sure the questions completeness is marked correctly.
      if (props["Target Field"]) {
        const updatedValues = Object.values(displayableData).map(v => {
          if (typeof v.prevValue === 'object') {
            return {
              ...v.prevValue,
              [v.answerField]: v.answer
            }
          } else {
            return v.answer
          }
        });
		
        props.setInfoKey(props["Target Field"], JSON.stringify(updatedValues), true, false);
      }

      const newInfo: Record<string, string> = {};
      Object.keys(displayableData).forEach(key => {
        if (displayableData[key].answer) {
          newInfo[key] = displayableData[key].answer!;
        }
      });
      setMockInfo(newInfo);
    }
  }, [props.Metadata, props.info, props.setInfoKey]);

  // we mock setInfoKey in LikertFromData since each select in the likert is not actually its own target field,
  // but will rather update the data source target field that the likert represents.
  const setInfoKey = useCallback((key: string, value: any, valid: boolean, disqualifies: boolean) => {
    const updatedQuestionMap = {
      ...questionMap,
      [key]: {
        ...questionMap[key],
        answer: value
      }
    }
    setQuestionMap(updatedQuestionMap);
    previousQuestionMapRef.current = updatedQuestionMap;
    setMockInfo(prev => {
      return {
        ...prev, 
        [key]: value
      }
    });

    // update the Target Field (<data_source_field> + _output, see questions.ts) value so our answers are saved.
    if (props["Target Field"]) {
      const updatedValues = Object.values(updatedQuestionMap).map(v => {
        if (typeof v.prevValue === 'object') {
          return {
            ...v.prevValue,
            [v.answerField]: v.answer
          }
        } else {
          return v.answer
        }
      });
	
      props.setInfoKey(props["Target Field"], JSON.stringify(updatedValues), true, false);
    }
  }, [questionMap]);

  if (renderLikertFromData) {
    const newProps = {
      ...props,
      setInfoKey,
      Metadata: JSON.stringify({
        ...safeParse(props.Metadata || '{}'),
        questions: questionMap ? Object.keys(questionMap)
          .filter(k => questionMap[k].showData)
          .map(k => {
            return {
              label: {"en": questionMap[k].label},
              targetField: k
            }
          }) : [],
        showAll: true
      }),
      info: mockInfo
    }
	
    const additionalOptions = props["Additional Options"] || [];
    const content = additionalOptions.indexOf("Formatted") !== -1
      ? formattedContent
      : <b>{props[languageContent(context.lang)]}</b>;
	
    return ((Object.keys(questionMap).length === 0) 
      ? <div>
        {content}
        <div className="py-2 px-4 mb-4 text-sm text-yellow-800 border-2 border-yellow-500 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
          No data found for question.
        </div>
      </div>
      : <>{React.createElement(LikertQuestionInner, newProps)}</>);
  }

  return React.createElement(LikertQuestionInner, props);
}

export function LikertQuestionInner(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const metadata = safeParse(props.Metadata || '{}') as { questions: v0.Likert['questions'], randomizeOrder: v0.Likert['randomizeOrder'], display: v0.Likert['display'], showAll?: boolean };
  const targetField = props["Target Field"]!;
  let questions = metadata?.questions && Array.isArray(metadata.questions)
    ? metadata.questions
    : [];

  let seed: string | null = null;
  if (metadata?.randomizeOrder && targetField) {
    seed = localStorage.getItem('ChoiceRandomizerSeed' + (props.uid || ''));
    if (seed === null) {
      seed = Math.random() + (props.uid || '');
      localStorage.setItem('ChoiceRandomizerSeed' + (props.uid || ''), seed);
    }
    questions = fisherYatesShuffle(questions, hash(0, seed), targetField);
  }

  const options = props["Options (if relevant)"] || [];
  const additionalOptions = props["Additional Options"] || [];
  const setInfoKey = useCallback(props.setInfoKey, [props.info]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  function updateValue(targetField: string, newValue: string) {
    if (props.info[targetField] !== newValue) {
      setInfoKey(
        targetField,
        newValue,
        newValue.length > 0 || false,
        false
      );
    }
  }

  async function confirmDelete() {
    if (window.confirm(L.questions.choice.are_you_sure)) {
      // Resets all questions
      for (const question of questions) {
        updateValue(question.targetField, "");
      }
    }
  }

  const formattedContent = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
  });
  const content = additionalOptions.indexOf("Formatted") !== -1
    ? formattedContent
    : <b>{props[languageContent(context.lang)]}</b>;

  const displayMode = () => {
    const isMobile = windowWidth < 600;
    if (metadata.display === 'horizontal') return 'horizontal';
    if (metadata.display === 'responsive_horizontal') return isMobile ? 'stacked' : 'horizontal';
    if (isMobile || options.length > 5) return 'stacked';
    return 'horizontal';
  }

  const showStackedOptions = useMemo(() => displayMode() === "stacked", [windowWidth, metadata.display, options.length]);

  if ((additionalOptions).indexOf("Hidden") !== -1) {
    return <span></span>
  }

  if (showStackedOptions) {
    return (
      <div className="mt-2.5">
        {content}
        {questions.map((question, index: number) => {
          const questionProps = {
            "Options (if relevant)": options,
            info: props.info,
            setInfoKey,
            "Target Field": question.targetField,
            "English Content": question.label['en'],
            "Spanish Content": question.label['es'],
          }
          let previousNotAnswered = index > 0 && !props.info[questions[index-1].targetField];
          if ((previousNotAnswered && !metadata.showAll) && !additionalOptions.includes('Optional')) return null;
          return (
            <div key={question.targetField} className="py-3.5">
              <SingleSelectQuestion {...props} {...question} {...questionProps} />
            </div>
          )
        })}
      </div>
    )
  }

  // Shows Likert scale format with horizontal options
  const showValueScale = (additionalOptions).indexOf("Show Value Scale") !== -1;
  const isVisible = (index: number) => {
    if (!showValueScale) return true;
    return index === 0 || index === options.length - 1;
  };

  return (
    <div className="w-full">
      <fieldset>
        <legend className="text-base text-gray-900">
          {content}
          <Dropdown className="applicant-led-hidden" style={{ display: 'inline' }}>
            <Dropdown.Toggle variant="link">...</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item key={`clear-answers-for-${props[languageContent(context.lang)]}`}
                onClick={() => confirmDelete()}>
                {L.questions.choice.clear_answer}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </legend>
      </fieldset>
      <table className="table-auto w-full !mt-5">
        <thead className="sticky top-0 bg-white">
          <tr className="py-4">
            <th className="placeholder w-2/5 min-w-[100px]">{L.questions.x_of_y_completed.replace('$x', (questions || []).reduce((acc, cur) => props.info[cur.targetField] ? acc + 1 : acc, 0) + '').replace('$y', questions.length + '')}</th>
            {options.map((option: any, i: number) =>
              <th
                id={"option-" + i}
                key={option.Name}
                className={`max-w-xs px-1 pb-2.5 text-xs font-normal text-center ${!isVisible(i) && ' invisible'}`}>
                {option[languageContent(context.lang).replace('Content', 'Text')] || option[context.lang]}
              </th>
            )}
          </tr>
          {showValueScale && (
            <tr className="py-4">
              <td className="placeholder w-auto max-w-xs"></td>
              {options.map((option: QuestionOption) =>
                <td key={option.Name} className="px-1 pb-2.5 text-xs font-normal text-center">{option.Name}</td>
              )}
            </tr>
          )}
        </thead>
        <tbody>
          {questions.map((q, index) => {
            let previousNotAnswered = index > 0 && !props.info[questions[index-1].targetField];
            if ((previousNotAnswered && !metadata.showAll) && !additionalOptions.includes('Optional')) return null;
            return <tr key={q.targetField} className="odd:bg-gray-50 rounded-sm">
              <td id={"question-" + index} className="px-3 py-2">{q.label[context.lang]}</td>
              {options.map((option: QuestionOption, i: number) =>
                <td key={option.Name} className="text-center">
                  <input
                    aria-labelledby={"question-" + index + " option-" + i}
                    type="radio"
                    id={option.Name}
                    name={q.targetField}
                    value={option.Name}
                    onChange={(o) => updateValue(q.targetField, option.Name)}
                    checked={props.info[q.targetField] === option.Name} />
                </td>
              )}
            </tr>
          })}
        </tbody>
      </table>
    </div>
  );
}
