import { SupportRequest } from "@aidkitorg/aidkit/lib/application/support";

const caseStatuses = ["open", "resolved", "escalated"] as const;

const fields = [
  {
    key: "source",
    sortable: false,
  },
  {
    key: "contact_methods",
    sortable: true,
  },
  {
    key: "applicant_stage",
    sortable: true
  },
  {
    key: "min",
    sortable: true,
  },
  {
    key: "unhandled_messages",
    sortable: true,
  },
  {
    key: "max",
    sortable: true,
  },
  {
    key: "support_case_status",
    sortable: true,
  },
  {
    key: "assigned_to_name",
    sortable: true
  },
  {
    key: "case_created",
    sortable: true
  }
] as const;

const stages = ['No Application', 'In Progress', 'Applied'] as const;
const getStageFromRequest = (request: SupportRequest) => {
  if (request.dynamoApplicant) return 'In Progress';
  if (Object.keys(request.applicantInfos).length) return 'Applied';
  return 'No Application';
}

export type CaseStatus = typeof caseStatuses[number];
export type TableKey = typeof fields[number]['key'];
export type SupportApplicantStage = typeof stages[number];

export { caseStatuses, fields, getStageFromRequest, stages };
