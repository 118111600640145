import { CardHeader, Card, Badge } from "@aidkitorg/component-library";
import { ConditionResultExplanation } from "@aidkitorg/types/lib/eval";
import { useState, useMemo } from "react";
import { usePost } from "../API";
import { useAsyncEffect } from "../Hooks/AsyncEffect";
import { Query } from "@aidkitorg/types/lib/survey";
import { DetailList } from "./CommonComponents";

export function EligibilityChecker({ show, cond }: { show: boolean, cond: Query }) {
  const explain = usePost('/program/admin/explain_expression');
  const [explanations, setExplanations] = useState<{ uid: string, result: ConditionResultExplanation, [field: string]: any }[]>([]);

  useAsyncEffect(async () => {
    if (!show || !cond || cond?.kind === 'SQL') {
      return;
    }

    const result = await explain({
      expression: cond.expr
    });

    setExplanations(
      Object.entries(result)
        .map(([uid, { explanation }]) => ({
          uid,
          result: explanation,
        }))
    );

  }, [cond, show]);

  const groupedExplanations = useMemo(() => {
    const groups = explanations.reduce((prev, { result }) => {
      for (const test of result.failed ?? []) {
        if (!prev[test]) {
          prev[test] = { fails: 0, passes: 0 };
        }
        prev[test].fails++;
      }
      for (const test of result.passed ?? []) {
        if (!prev[test]) {
          prev[test] = { fails: 0, passes: 0 };
        }
        prev[test].passes++;
      }
      return prev;
    }, {} as Record<string, { passes: number, fails: number }>);

    return Object.entries(groups).map(([g, { passes, fails }]) => ({
      eligibility: g,
      pass: passes,
      fail: fails
    }));
  }, [explanations]);

  return <div hidden={!show}>
    <Card>
      <CardHeader
        title={<legend className="text-sm">
          Passes/Fails totals are based on the individual
          eligibility checks of each applicant,
          so the totals of each will not always equal the
          eligible applicant count.
        </legend>
        }
        badgeText={`${explanations.length} Checked`}
        badgeVariant="info" />
      <DetailList
        details={Object.fromEntries(
          groupedExplanations.map(g => [g.eligibility,
            <div className="space-x-2">
              <Badge variant="success">{g.pass} Pass</Badge>
              <Badge variant="error">{g.fail} Fail</Badge>
            </div>])
        )}
      />
    </Card>
  </div>
}