import React, { useContext, useState, useEffect } from "react";
import { Card, Alert } from "react-bootstrap";
import { AudioStatusIcon } from "../Applicant";
import InterfaceContext, { ConfigurationContext, PublicConfigurationContext } from "../Context";
import { languageContent, safeParseValidatedFormula } from "../Util";
import { QuestionProps } from "./Props";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

export function Validated(props: QuestionProps) {
  const formula = safeParseValidatedFormula(props["Metadata"] || '')
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);

  const [result, setResult] = useState(props.info[props["Target Field"]!]);
  const [error, setError] = useState(props.info[props["Target Field"]! + "_error"] || "");

  const advisory = (props["Additional Options"] || []).indexOf('Advisory') !== -1 ? true : false;

  useEffect(() => {
    if (Object.keys(config).length === 0) return;

    if (result !== props.info[props["Target Field"]!]) {
      setResult(props.info[props["Target Field"]!]);
    }
    if (error !== props.info[props["Target Field"]! + "_error"]) {
      setError(props.info[props["Target Field"]! + "_error"]);
    }
  }, [result, error, props, props.info, config]);

  const errorContent = useModularMarkdown({
    content: (error || '').replace('$content', props[languageContent(context.lang)] || ''),
    info: props.info,
  });

  const cardHeader = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
  });

  if ((props.info[props['Target Field']!] && props.info[props['Target Field']!].indexOf('hide') !== -1) || 
    (props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <></>
  }

  const voiceoverDisabled = !!publicConfig.interface?.disableVoiceover;

  const optional = (props["Additional Options"] || []).indexOf('Optional') !== -1;
  if ((props["Additional Options"] || []).indexOf("Hidden Unless Invalid") !== -1) {
    if (!error) {
      return <></>;
    }
  }

  const bg = error ? (advisory ? 'primary' : 'danger') : 'light';

  return (
    <div className={"survey-question relative pb-8 text-clip"} 
      data-name={props['Question']}>
      {props.lastQuestion !== true && <span className="absolute top-10 left-8 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />}
      <div className="relative flex space-x-4">
        <div className={voiceoverDisabled ? 'pt-1.5' : '-pt-1'}>
          <span className={(voiceoverDisabled ? 'h-6 w-6 ' : '-ml-2 h-10 w-10 ') + 'rounded-full flex items-center justify-center ring-4 ring-white bg-white '}>
            { props.info[props["Target Field"]!] ? 
              <AudioStatusIcon questionId={props.id} state="complete" />
              : (optional ? 
                <AudioStatusIcon questionId={props.id} state="optional" /> : 
                <AudioStatusIcon questionId={props.id} state='required' />
              )}
          </span>
        </div>
        <div className={'min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'}>
          <div className="w-full">
            {error && typeof error === 'string' && error.indexOf('$content') !== -1 ? (
              <div className={`${ advisory ? 'bg-blue-100' : 'bg-red-100' } border-md p-3 rounded-md text-gray-800`}>
                <span>
                  {errorContent}
                </span>
              </div>
            ) : 
              <Card>
                <Card.Header as="h6">
                  {cardHeader}
                </Card.Header>
                <Card.Body as="div" className={`alert alert-${bg} mb-0`}>
                  <Card.Text className="text-gray-600">
                    {error && <span>{error}</span>}
                    {result && <span>{result}</span>}
                  </Card.Text>
                </Card.Body>
              </Card>}
          </div>
        </div>
      </div>
    </div>
  );
}
