import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown as TailwindDropdown } from "./Components/Dropdown";
import { Link } from "react-router-dom";
import { get_deployment, useAPI, useAPIPost, usePost } from "./API";
import { useHistory, useParams } from "react-router-dom";
import { snakeToEnglish, copyToClipboard, useInterval } from "./Util";
import { useLocalizedStrings } from "./Localization";
import InterfaceContext, { ConfigurationContext, SupportedLanguage } from "./Context";
import { Alert } from "react-bootstrap";
import { AddNew } from "./Dashboard/AddNewApplicant";
import { ClickableButton } from "./Components/Button";
import { Badge as TailwindBadge } from "./Components/Badge";
import { useToast } from "@aidkitorg/component-library";
import { URLViewer } from './Applicant'
import DLOfflineButton from "./Components/DLOfflineButton";

type ActionButtonProps = { path: string; title: string, variant?: string, onSuccess?: any };
function ActionButton(props: ActionButtonProps) {
  const L = useLocalizedStrings();
  const action = useAPIPost(props.path);
  const [result, setResult] = useState("");

  async function doAction() {
    const response = await action({});
    if (response && response.status === "ok") {
      setResult(response.message || L.dashboard.done);
      if (props.onSuccess) props.onSuccess();
    } else {
      setResult(L.dashboard.error);
    }
  }

  if (result) {
    return <b>{result}</b>;
  }

  if (props.variant && props.variant === "dropdown_link") {
    return <Dropdown.Item onClick={doAction}>{props.title}</Dropdown.Item>;
  }

  return (
    <Button onClick={doAction} variant="primary">
      {props.title}
    </Button>
  );
}

type Queues = {
  [identifier: string]: {
    name: Record<SupportedLanguage, string>,
    length: number,
    groups?: [{
      name: string,
      length: number
    }]
  }
}

export function ReferralButtons(props: {
  takeReferral: (queueName: string, options?: {
    queueObject: Record<string, any>,
    group?: string
  }) => Promise<void>, queues?: string
}) {
  const queues = useAPI("/queues/enumerate_queues?to_return=" + (props.queues || 'all')) as Queues;
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);

  if (config.breakout_queues === 'true' || 'chicagorcp' === get_deployment()) {
    return <div className="flex flex-wrap">
      {Object.keys(queues).map(key => {
        if (queues[key].groups) {
          return <div className="my-2"><TailwindDropdown
            color="blue"
            colorIntensity={100}
            label={<>{queues[key].name[context.lang]}&nbsp;<TailwindBadge color="gray">{queues[key].length}</TailwindBadge></>}
            options={(queues[key].groups || []).map(group => {
              return {
                label: (<>{group.name}&nbsp;<TailwindBadge color="gray">{group.length}</TailwindBadge></>),
                callback: async () => {
                  await props.takeReferral(key, { queueObject: queues[key], group: group.name });
                }
              }
            })} /> &nbsp;</div>
        }
        return <div className="my-2"><ClickableButton disabled={queues[key].length === 0}
          extraClasses="h-11"
          color="blue" onClick={(e) => props.takeReferral(key, { queueObject: queues[key] })}>
          {queues[key].name[context.lang]}&nbsp;
          <TailwindBadge color="gray">{queues[key].length}</TailwindBadge>
        </ClickableButton> &nbsp;</div>
      })}
    </div>
  }

  if (Object.keys(queues).length === 0) {
    return <DropdownButton style={{ "display": "inline-block" }} title={L.dashboard.add_referred_applicant}>
      <Dropdown.Item disabled={true} key={'no-queues'}>
        {L.dashboard.no_applicants}
      </Dropdown.Item>
    </DropdownButton>;
  }
  return <DropdownButton style={{ "display": "inline-block" }} title={L.dashboard.add_referred_applicant}>
    {Object.keys(queues).map((key) => {
      return <Dropdown.Item key={`queue-${key}`}
        disabled={queues[key].length === 0} onClick={(e) => props.takeReferral(key)}>
        {queues[key].name[context.lang]}&nbsp;<Badge variant="secondary">{queues[key].length}</Badge>
      </Dropdown.Item>
    })}
  </DropdownButton>;
}

type Applicant = {
  uid: string,
  hidden: boolean | null
  [infoKey: string]: any
};

function Dashboard(props: { path?: string, customId?: string }) {
  const [showAdd, setShowAdd] = useState(false);
  let { path, customId } = useParams() as Record<string, string | undefined>;
  if (props.customId) customId = props.customId;
  const fetchDashboard = usePost("/applicants/custom");
  const [ dashboard, setDashboard ] = useState<any>();
  const take_referral = useAPIPost("/queues/take_applicant");
  const history = useHistory();
  const L = useLocalizedStrings();
  const configuration = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);
  const { toast } = useToast();

  const dashboardId = customId || path;
  async function makeDashboardRequest(){
    const response = await fetchDashboard({
      id: dashboardId!
    });
    if (!(response as any).error) {
      setDashboard(response);
    }
  }

  useEffect(
    () => {
      makeDashboardRequest();
    },
    [dashboardId]
  );

  const getSignedURL = usePost('/document/view');
  const [signedURLs, setSignedURLs] = useState<Record<string, string>>({});
  const mine = props.path?.match("screened_by_me") || (customId && customId.match("screened_by_me"));
  const myOrg = path?.match("my_organization") || (customId && customId.match("my_organization"));

  const [sortedApplicants, setSortedApplicants] = useState([] as Applicant[]);
  const [sortKey, setSortKey] = useState("name");

  function sortString(a: any, b: any) {
    if (a === b) return 0;
    if (!a) return -1;
    if (!b) return 1;
    if (a < b) return -1;
    if (a > b) return 1;
  }

  function sortHidden(a: Applicant, b: Applicant) {
    if (a.hidden === b.hidden) return 0;
    if (a.hidden) return 1;
    if (b.hidden) return -1;
    return 0;
  }

  function sortIneligible(a: Applicant, b: Applicant) {
    if (a.stage === b.stage) return 0;
    if (['Ineligible', 'Opted Out', 'Unreachable'].indexOf(a.stage) !== -1) return 1;
    if (['Ineligible', 'Opted Out', 'Unreachable'].indexOf(b.stage) !== -1) return -1;
    return 0;
  }

  useEffect(() => {
    (async () => {
      let urls: string[] = [];
      (dashboard?.data || []).map((row: any) => {
        for (const [key, value] of Object.entries(row) as [string, string][]) {
          if (value && typeof value === 'string' && value.includes('https://aidkit-documents')) {
            urls.push(...value.split(','));
          }
        }
      })

      if (urls.length) {
        const paths = await getSignedURL({
          paths: urls,
        });
        setSignedURLs(paths.paths);
      }
    })();
  }, [dashboard?.data])

  useEffect(() => {
    if (dashboard?.data) {
      setSortedApplicants(
        (dashboard?.data || [])
          .sort((a: any, b: any) => {
            return sortString(a[sortKey], b[sortKey]);
          })
          .sort((a: Applicant, b: Applicant) => {
            return sortIneligible(a, b);
          })
          .sort((a: Applicant, b: Applicant) => {
            return sortHidden(a, b);
          })
          .slice(0)
      );
    }
  }, [sortKey, dashboard?.data]);

  async function reviewReferral(queue: string, options?: {
    queueObject: Record<string, any>,
    group?: string
  }) {
    const confirmTranslations = options?.queueObject.confirm;

    const confirmMessage = confirmTranslations ? confirmTranslations[context.lang] : L.are_you_sure_you_want_a_referral;

    const errorMessage = "Error taking an applicant. Please try again.";

    if (window.confirm(confirmMessage)) {
      let resp;
      try {
        resp = await take_referral({
          queue, group: options?.group
        });
      } catch (e) {
        console.warn("Error: ", e)
        toast({
          description: errorMessage,
          variant: 'error'
        })
      }
      if (resp?.applicant) {
        history.push("/applicant/" + resp.applicant + (resp.initial_section ? '#' + resp.initial_section : ''));
      } else if (resp?.status === "ok") {
        toast({
          description: "Sorry, no applicants in the queue",
        })
      } else {
        toast({
          description: errorMessage,
          variant: 'error'
        })
      }
    }
  }

  if (!configuration?.user) {
    return (
      <div className="p-5 d-flex flex-column justify-content-center" style={{ height: "500px" }}>
      </div>
    )
  }

  if (!dashboard?.data) {
    return null;
  }

  return (
    <div className="p-5">
      <h3>
        {customId && dashboard.title && !mine && customId !== 'all' ? (L.dashboard as any)[dashboard.title] ?? dashboard.title : <></>}
        {!customId && dashboard.title ? (L.dashboard as any)[dashboard.title] ?? dashboard.title : <></>}
        {((dashboard.title && (mine || customId === 'all')) || !dashboard.title) && (
          <>
            {myOrg && (
              <>
                {L.dashboard.org_applicants}
              </>
            )}
            {mine ? L.dashboard.my_applicants : (!myOrg ? L.dashboard.all_applicants : '')}{" "}
            {configuration.paused !== 'true' &&
              <>
                {(!dashboard['queues'] || (dashboard['queues'].indexOf('any') !== -1)) && (
                  <>
                    <Button variant="primary" onClick={() => setShowAdd(true)}>
                      {L.dashboard.add_new_applicant}
                    </Button>
                    &nbsp;
                    <DLOfflineButton applicants={sortedApplicants} />
                    &nbsp;
                  </>
                )}
                {configuration.enable_referrals === 'true' && !customId &&
                  <ReferralButtons takeReferral={reviewReferral} />}
              </>}
          </>
        )}
        {customId && dashboard['queues'] && <>
          <ReferralButtons queues={dashboard['queues']} takeReferral={reviewReferral} /></>}
      </h3>
      {myOrg && (
        <>
          <h6>{L.applicant.organization}: {configuration.organization?.name || ''}</h6>
        </>
      )}
      {myOrg && configuration.use_quotas === 'true' && (
        <>
          <h6>{L.dashboard.your_org_screened} {configuration.org_quota_usage} {L.dashboard.of_the} {configuration.quota} {L.dashboard.applicants_in_your_org_quota}</h6>
        </>
      )}
      {mine && (<>
        {configuration.use_quotas === 'true' &&
          <>
            <h6>{L.dashboard.your_org_screened} {configuration.org_quota_usage} {L.dashboard.of_the} {configuration.quota} {L.dashboard.applicants_in_your_org_quota}</h6>
            {(configuration.use_screener_quotas || 'undefined') !== 'true' && <h6>{L.dashboard.you_have_screened} {sortedApplicants.length}.</h6>}
          </>}
        {configuration.use_screener_quotas && configuration.use_screener_quotas === 'true' &&
          <h6>{L.dashboard.you_have_screened} {sortedApplicants.length} {L.dashboard.of_the} {configuration.screener_quota} {L.dashboard.applicants_in_your_screener_quota}</h6>}
      </>
      )}
      {dashboard.stats &&
        Object.keys(dashboard.stats).map((s) => {
          return (
            <div>
              <b>{s}: </b>
              {dashboard.stats[s]}
            </div>
          );
        })}
      <br />
      {sortedApplicants.length === 0 && <Alert variant="info">{L.dashboard.no_applicants_here}</Alert>}
      {sortedApplicants.length > 0 && (
        <Table bordered>
          <thead>
            <tr>
              {dashboard.fields &&
                dashboard.fields.map((f: string, idx: number) => {
                  return (
                    <th key={'header-' + f + '-' + idx.toString()}>
                      <Button variant="link" onClick={(e) => setSortKey(f)}>
                        {snakeToEnglish(f)}
                      </Button>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody id={'applicants-table'}>
            {sortedApplicants.map((a: Applicant) => {
              if (a.hidden && a.hidden === true && !configuration.roles?.includes('admin')) return null;

              return (
                <tr key={a.uid} className={a.hidden ? 'color-hidden' : ''}>
                  {dashboard.fields &&
                    dashboard.fields.map((f: string, idx: number) => {
                      if (f === "legal_name") {
                        return (
                          <td key={a.uid + '-' + f + '-' + idx.toString()} style={{ justifyContent: 'flex-start' }}>
                            <DLOfflineButton applicants={[a]} />
                            &nbsp;
                            <Link to={"/applicant/" + a.uid}>{a[f] || '(unnamed)'}
                              {a.hidden && <>&nbsp;{L.dashboard.hidden}</>}
                            </Link>
                            {configuration.roles?.includes('admin') && (
                              <Dropdown>
                                <Dropdown.Toggle variant="link">
                                  ...
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {!a.hidden && (
                                    <ActionButton key={'hide-' + a.uid} title={"Hide"}
                                      variant="dropdown_link"
                                      path={"/applicant/" + a.uid + "/hide_applicant"}
                                      onSuccess={makeDashboardRequest}
                                    />
                                  )}
                                  {a.hidden && (
                                    <ActionButton key={'unhide-' + a.uid} title={"Unhide"}
                                      variant="dropdown_link"
                                      path={"/applicant/" + a.uid + "/unhide_applicant"}
                                      onSuccess={makeDashboardRequest}
                                    />
                                  )}
                                  <Dropdown.Item onClick={() => { copyToClipboard(a.uid); toast({description: L.copied_to_clipboard, variant: 'success'}) }}>{L.dashboard.copy_uid}</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </td>
                        );
                      }
                      if (a[f] && a[f][0] === "/") {
                        return <ActionButton key={f} title={f} path={a[f]} />;
                      }
                      if (a[f] && typeof a[f] === 'string' && a[f].includes("https://aidkit-documents")) {
                        return <URLViewer urls={(a[f] || '').split(',').filter((a: any) => a)} signedURLs={signedURLs} Viewer={"screener"} />;
                      }
                      return <td key={a.uid + '-' + f + '-' + idx.toString()}>{a[f]}</td>;
                    })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <AddNew show={showAdd} setShow={setShowAdd} />
    </div>
  );
}

export default Dashboard;
